import React, { useState } from 'react';
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'; 
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SidebarComponent() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar 
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)} 
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='/' className='decoration-transparent text-gray-700 text-xl font-bold'>Home</a> </MenuItem>
                            <MenuItem> <a href='About' className='decoration-transparent text-gray-700 text-xl font-bold'>About Us</a> </MenuItem>
                            <MenuItem> <a href='Services' className='decoration-transparent text-gray-700 text-xl font-bold'>Services</a> </MenuItem>
                            <MenuItem> <a href='Services' className='decoration-transparent text-gray-700 text-xl font-bold'>Applications</a> </MenuItem>
                            <MenuItem> <a href='Careers' className='decoration-transparent text-gray-700 text-xl font-bold'>Careers</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div 
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`} 
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon 
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft} 
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}

export default SidebarComponent;
