import React from 'react'
import First from './First'
import FancyText from '@carefully-coded/react-text-gradient';
import { Nine } from './Nine';
import Fifth from './fifth';
import Sector from './Sectors';
import { Helmet } from 'react-helmet';
import '../Header.css'
function Resources() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT-Our Resources |Man Power Resources| Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
                <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                    <First />
                </div>
                <div className=' text-center font-extrabold pt-40'>
                    <h2 className=' font-extrabold p-3 text-5xl' id='down'>
                        <span className='text-primary'>We</span> Are Hiring
                    </h2>
                    <div className="relative inline-block" id='up'>
                        <span className="bg-primary text-white text-2xl px-3 py-2 ">Job Category</span>
                        <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                            <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
                        </span>
                    </div>
                    <p className='mt-5 text-3xl'>We are hiring NDT Technicians,Engineers and Inspectors.</p>
                </div>
                <div className='p-5 '>
                    <div className='text-center'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            <h1 className='font-bold text-6xl'>Our Resources</h1>
                        </FancyText>
                    </div>
                    <div className='h-96 overflow-scroll'>
                        <table className='border-collapse w-full text-center'>
                            <thead className=' bg-blue-500 text-white'>
                                <tr >
                                    <th className="border border-gray-400 px-4 py-2">S.No.</th>
                                    <th colspan='2' className="border border-gray-400 px-4 py-2">Product Description</th>
                                    <th className="border border-gray-400 px-4 py-2">Methodology</th>
                                    <th className="border border-gray-400 px-4 py-2">Brand Name</th>
                                    <th className="border border-gray-400 px-4 py-2">Model No</th>
                                    <th className="border border-gray-400 px-4 py-2">Availability</th>
                                    <th className="border border-gray-400 px-4 py-2">For Rental Purpose</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className="border border-gray-400  bg-white px-4 py-4">01</td>
                                    <td colspan='2' className="border border-gray-400  bg-white px-4 py-3">NDT Technicians</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400   bg-white px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 bg-blue-100 px-4 py-4">02</td>
                                    <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">NDT Engineers</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form ' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-4">03</td>
                                    <td colspan='2' className="border border-gray-400 px-4 py-2">NDT Inspectors</td>
                                    <td className="border border-gray-400 px-4 py-2">Department</td>
                                    <td className="border border-gray-400 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400 px-4 py-2"><a href='/Form.js' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-4">04</td>
                                    <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-4">05</td>
                                    <td colspan='2' className="border border-gray-400 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400 px-4 py-2">Department</td>
                                    <td className="border border-gray-400 px-4 py-2">Location</td>
                                    <td className="border border-gray-400 px-4 py-2">Department</td>
                                    <td className="border border-gray-400 px-4 py-2">Location</td>
                                    <td className="border border-gray-400 px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-4">06</td>
                                    <td colspan='2' className="border border-gray-400   bg-blue-100 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Rent </a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='p-5 '>
                    <div className='text-center'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            <h1 className='font-bold text-6xl'>Man Powers</h1>
                        </FancyText>
                    </div>
                    <div className='h-96 overflow-scroll'>
                        <table className='border-collapse w-full text-center'>
                            <thead className=' bg-blue-500 text-white'>
                                <tr >
                                    <th className="border border-gray-400 px-4 py-2">S.No.</th>
                                    <th colspan='2' className="border border-gray-400 px-4 py-2">Conventional NDT</th>
                                    <th className="border border-gray-400 px-4 py-2">Advance NDT</th>
                                    <th className="border border-gray-400 px-4 py-2">Availability</th>
                                    <th className="border border-gray-400 px-4 py-2">Contact Now</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className="border border-gray-400  bg-white px-4 py-4">01</td>
                                    <td colspan='2' className="border border-gray-400  bg-white px-4 py-3">NDT Technicians</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-white px-4 py-2">Location</td>
                                    <td className="border border-gray-400   bg-white px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 bg-blue-100 px-4 py-4">02</td>
                                    <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">NDT Engineers</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-4">03</td>
                                    <td colspan='2' className="border border-gray-400 px-4 py-2">NDT Inspectors</td>
                                    <td className="border border-gray-400 px-4 py-2">Department</td>
                                    <td className="border border-gray-400 px-4 py-2">Location</td>
                                    <td className="border border-gray-400 px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-4">04</td>
                                    <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-4">05</td>
                                    <td colspan='2' className="border border-gray-400 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400 px-4 py-2">Department</td>
                                    <td className="border border-gray-400 px-4 py-2">Location</td>
                                    <td className="border border-gray-400 px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400   bg-blue-100 px-4 py-4">06</td>
                                    <td colspan='2' className="border border-gray-400   bg-blue-100 px-4 py-2">NDT Technicians</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Department</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                                    <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Contact</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Fifth />
                <Sector />
                <Nine />
            </div>
        </div>
    )
}

export default Resources
