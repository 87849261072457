import React from 'react'
import First from './First'
import { Nine } from './Nine'
import { Helmet } from 'react-helmet'
import '../Header.css'
function Team() {
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <div className='bg-black fixed z-10 w-full'>
          <First />
        </div>
      </div>
      <div className=' pt-24'>
        <Nine />
      </div>
    </div>
  )
}

export default Team
