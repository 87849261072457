import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleRight, faChevronLeft, faGears, faLongArrowAltRight, faMicroscope } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { RadialGradient } from 'react-text-gradients'
import FancyText from '@carefully-coded/react-text-gradient';
import '../Header.css'
import { Helmet } from 'react-helmet'
function Conventional() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Conventional Non-Destructive Testing (NDT) Services in Chennai |Conventional NDT Services in Padappai |Conventional NDT Services in Mumbai |Conventional NDT Services in Haryana |Conventional NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Conventional non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Conventional Non-Destructive Testing (NDT) Services in Chennai ,Conventional NDT Services in Mumbai ,Conventional NDT Services in Haryana ,Conventional NDT Services in India,Best Conventional NDT services in Padappai,Best Conventional NDT Services in Chennai,Best Conventional NDT Services in Bangalore" />
                <meta name="keywords" content="Best Conventional ndt services in Andhra Pradesh,Best Conventional ndt services in Arunachal Pradesh,Best Conventional ndt services in Assam,Best Conventional ndt services in Bihar,Best Conventional ndt services in Chhattisgarh,Best Conventional ndt services in Goa,Best Conventional ndt services in Gujarat,Best Conventional ndt services in Haryana,
                        Best Conventional ndt services in Himachal Pradesh,Best Conventional ndt services in Jammu and kashmir,Best Conventional ndt services in Jharkhand,Best Conventional ndt services in Karnataka,Best Conventional ndt services in Kerala,Best Conventional ndt services in Madhya Pradesh,Best Conventional ndt services in Maharashtra,Best Conventional ndt services in Manipur,Best Conventional ndt services in Meghalaya,
                    Best Conventional ndt services in Mizoram,Best Conventional ndt services in Nagaland,Best Conventional ndt services in Odisha,Best Conventional ndt services in Punjab,Best Conventional ndt services in Rajasthan,Best Conventional ndt services in Sikkim,Best Conventional ndt services in TamilNadu,Best Conventional ndt services in Telengana,Best Conventional ndt services in Tripura,Best Conventional ndt services in Uttar Pradesh,
                   Best Conventional ndt services in Uttarakhand,Best Conventional ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Conventional NDT Services
                        </FancyText>
                    </h1>
                    <div className='row '>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/VisualTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Visual Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Visual testing, a conventional NDT method, entails direct observation to inspect materials for surface defects, a service provided by AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/VisualTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/VisualTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\vt1.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/PenetrantTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Penetrant Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Penetrant testing, a core NDT technique, identifies surface defects through penetrant application, provided by AMEEE NDT.
                                            </div><br />
                                            <div className='items-readmore text-left '>
                                                <a href='/PenetrantTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/PenetrantTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\penetrant.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>03</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/MagneticTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Magnetic Particle Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Magnetic particle testing, an essential NDT method, identifies defects near surfaces using magnetic particles, offered by AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/MagneticTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/MagneticTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\mpt.jpeg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>04</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/UltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Ultrasonic testing, a pivotal NDT method, detects internal defects using high-frequency sound waves, a specialty service at AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/UltasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/UltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\ut1.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>05</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/RadiographyTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Radiography Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Radiographic testing, a NDT technique, reveals internal flaws using X-rays or gamma rays, a specialized service provided by AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/RadiographyTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/RadiographyTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rt1.png' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>06</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='/PositiveMaterialIdentification' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Positive Material Identification</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                PMI verifies the chemical composition of materials, crucial for quality control and compliance, offered by AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/PositiveMaterialIdentification' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/PositiveMaterialIdentification' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\pmi.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>07</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/HardnessTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Hardness Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Hardness testing determines material hardness for quality assessment, available as a specialized service at AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/HardnessTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/HardnessTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\hardness.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>08</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='/LeakTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Leak Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Leak testing, a technique, detects fluid or gas leaks in components or systems, ensuring integrity, a specialized service provided by AMEEE NDT.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/LeakTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/LeakTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\leak2.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conventional
