import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Positive = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Positive Material Identification (PMI) Services in Chennai |Positive Material Identification (PMI) Services in Padappai |Positive Material Identification (PMI) Services in Mumbai |Positive Material Identification (PMI) Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Positive Material Identification (PMI) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Positive Material Identification (PMI) Services in Chennai ,Positive Material Identification (PMI) Services in Mumbai ,Positive Material Identification (PMI) Services in Haryana ,Positive Material Identification (PMI) Services in India,Best Positive Material Identification (PMI) services in Padappai,Best Positive Material Identification (PMI) Services in Chennai,Best Positive Material Identification (PMI) Services in Bangalore" />
                <meta name="keywords" content="Best Positive Material Identification (PMI) services in Andhra Pradesh,Best Positive Material Identification (PMI) services in Arunachal Pradesh,Best Positive Material Identification (PMI) services in Assam,Best Positive Material Identification (PMI) services in Bihar,Best Positive Material Identification (PMI) services in Chhattisgarh,Best Positive Material Identification (PMI) services in Goa,Best Positive Material Identification (PMI) services in Gujarat,Best Positive Material Identification (PMI) services in Haryana,
                        Best Positive Material Identification (PMI) services in Himachal Pradesh,Best Positive Material Identification (PMI) services in Jammu and kashmir,Best Positive Material Identification (PMI) services in Jharkhand,Best Positive Material Identification (PMI) services in Karnataka,Best Positive Material Identification (PMI) services in Kerala,Best Positive Material Identification (PMI) services in Madhya Pradesh,Best Positive Material Identification (PMI) services in Maharashtra,Best Positive Material Identification (PMI) services in Manipur,Best Positive Material Identification (PMI) services in Meghalaya,
                    Best Positive Material Identification (PMI) services in Mizoram,Best Positive Material Identification (PMI) services in Nagaland,Best Positive Material Identification (PMI) services in Odisha,Best Positive Material Identification (PMI) services in Punjab,Best Positive Material Identification (PMI) services in Rajasthan,Best Positive Material Identification (PMI) services in Sikkim,Best Positive Material Identification (PMI) services in TamilNadu,Best Positive Material Identification (PMI) services in Telengana,Best Positive Material Identification (PMI) services in Tripura,Best Positive Material Identification (PMI) services in Uttar Pradesh,
                   Best Positive Material Identification (PMI) services in Uttarakhand,Best Positive Material Identification (PMI) services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/pmii3.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Positive-material-identification.jpeg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/pmii2.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/VisualTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Visual Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 group-hover:text-white font-medium'>Penetrant Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group '>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/LeakTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Leak Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Positive Material Identification</h1>
                                    <img src='assets/ameee/pmi.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'>  AMEEE NDT</strong>specializes in providing precise Positive Material Identification (PMI) services, ensuring material integrity and compliance with industry standards through advanced analytical techniques.
                                        Positive Material Identification (PMI) is a non-destructive testing (NDT) technique used to determine the elemental composition of metallic materials. It involves analyzing the chemical composition of a material to ensure it meets specified requirements and standards. PMI is typically performed using handheld X-ray fluorescence (XRF) analyzers or optical emission spectrometers (OES), which emit radiation or use electrical discharges to excite the atoms in the material, causing them to emit characteristic energy wavelengths.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>
                                            Positive Material Identification (PMI) is indispensable in industries prioritizing material integrity and safety. From verifying welded joints in pipelines to identifying flaws in aircraft components, PMI plays a crucial role in quality assurance, asset management, and regulatory compliance across sectors like aerospace, automotive, construction, oil and gas, and manufacturing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/pmii1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10 '>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className=' text-gray-500 text-lg'>
                                                Positive Material Identification (PMI) has a rich history, evolving alongside advancements in analytical chemistry and spectroscopy. Originating from early spectroscopic techniques, modern PMI methods have significantly improved accuracy, speed, and portability.
                                            </p>
                                            <h4 className='text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-gray-700 text-lg'>
                                                Positive Material Identification (PMI) uses techniques like XRF or OES to analyze material composition swiftly and accurately. It ensures material integrity and compliance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='justify-center sm:block md:block lg:flex mr-44'>
                                <div className='mr-10'>
                                    <div className=''>
                                        <div>
                                        <Card className='pr-8 pt-3  ' style={{ width: '330px' }}>
                                                <h1 className='mb-3 underline text-center'>Applications</h1>
                                                <ul className=' mt-3'>
                                                    <li className='border border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='border mt-4 border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='TubeTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='border mt-4 border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='PipelineTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='border mt-4 border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='ShipTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='border mt-4 border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='ReactorTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='border mt-4 border-neutral-400 text-lg group'>
                                                        <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                            <div>
                                                                <a href='PressureVesselTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='text-left'>
                                        <div className='flex justify-center '>
                                            <div className='w-full mr-5'>
                                                <img src='assets/ameee/PMI images.jpeg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                            </div>
                                            <div className='w-full'>
                                                <img src='assets/ameee/pmii2.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                            </div>
                                        </div>
                                        <div className='flex justify-center mt-5'>
                                            <ul className='text-left'>
                                                <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                                <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />Rapid identification of material composition.</li><hr />
                                                <li className='text-lg text-gray-700' ><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                    Non-destructive testing method.</li><hr />
                                                <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                    Enhances safety and reliability of valves and related equipment.</li><hr />
                                            </ul>
                                            <ul className='text-left'>
                                                <li ><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                                <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />  Limited to surface analysis.</li><hr />
                                                <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                    May require calibration for accuracy.</li><hr />
                                                <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /> Relies on operator skill for optimal results.
                                                </li><hr />

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3 mt-5' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div className='ml-2'>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                    </div>
                    <Fourth />
                    <div style={{ backgroundImage: 'url(assets/ameee/pmii2.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <Foooter />
                    </div>
                </div >
            </div >
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Positive Material Identification - How We Work</h4>
                    <p className=' font-semibold'>
                        Positive Material Identification (PMI), conducted by AMEEE NDT, is vital for verifying material composition. We utilize techniques such as X-ray fluorescence (XRF) or optical emission spectroscopy (OES) to analyze elemental composition swiftly and accurately.
                        Our detailed reports provide valuable information for material verification and compliance, ensuring the reliability and quality of critical components.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Positive Material Identification Equipment & Consumables</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT provides a comprehensive array of advanced XRF and OES equipment, ranging from portable devices for on-site inspections to stationary systems for laboratory settings. Our inventory includes XRF analyzers, OES spectrometers, calibration standards, sample preparation tools, and safety gear customized to meet diverse testing needs.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Positive Material Identification Standards</h4>
                    <p className=' font-semibold'>
                        Our procedures adhere to globally accepted standards and guidelines set forth by organizations like the American Society for Testing and Materials (ASTM), International Organization for Standardization (ISO), and American Welding Society (AWS). Compliance with these standards guarantees the consistency, accuracy, and reliability of our material identification results.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Positive Material Identification Parameters</h4>
                    <p className=' font-semibold'>
                        Key parameters monitored during positive material identification include instrument calibration, sample preparation, measurement accuracy, detection limits, analysis time, and data interpretation criteria. Strict control over these parameters is crucial for ensuring accurate and dependable identification results.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Elements Identified by Positive Material Identification</h4>
                    <p className=' font-semibold'>
                        Positive Material Identification (PMI) facilitates the identification of various elements within materials, including but not limited to carbon, sulfur, phosphorus, silicon, and alloying elements like chromium, nickel, and titanium. Our skilled technicians employ advanced analytical techniques to accurately determine elemental composition, offering clients valuable data for material verification and quality control.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Positive Material Identification</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT provides reference calibration standards crafted from certified materials, featuring precisely calibrated elemental compositions. These standards are indispensable for validating the accuracy and reliability of positive material identification equipment, ensuring standardized and traceable testing procedures.
                        For unparalleled expertise in Positive Material Identification solutions, rely on AMEEE NDT as your trusted partner for excellence in material analysis. Contact us today to discuss your specific identification requirements and schedule a consultation with our experienced team of engineers and technicians.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Positive
