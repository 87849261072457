import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import VanillaTilt from 'vanilla-tilt';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faTag, faChevronRight, faLaptop, } from '@fortawesome/free-solid-svg-icons';
import FancyText from '@carefully-coded/react-text-gradient';
function Fourth() {
  const options = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.service-image'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5
    });
  }, []);

  return (
    <div className="p-2">
      <section className="service-section bg-gray-100 py-16">
        <div className="text-center ">
          <div className='sm:w-72 md:w-80 lg:w-full mx-auto'>
            <div className='text-center text-5xl font-bold'>
              <h2 className=' font-extrabold p-1 text-5xl' id='down'>
                <FancyText
                  gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                  animate
                  animateDuration={2000}
                >
                  What We Do
                </FancyText>
              </h2>
              <div className="relative inline-block" id='up' >
                <span className="bg-primary text-white px-3 py-2  text-xl" >Outstanding Services</span>
                <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                  <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
                </span>
              </div>
              <div className='text-center text-3xl font-bold mt-3 mx-5' ><p style={{ color: '#025' }}>Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p></div>
            </div>
          </div>
          <OwlCarousel options={options}>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\tube.jpg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Boiler Tube Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon group group-hover:bg-blue-600'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} className=' group-hover:text-white' /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600 w-20 h-20 flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900 '>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl ' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\Pipeline UFD.jpg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Pipeline Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900 '>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faLaptop} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group' >
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\ndt-hull-thickness-survey SHIP.jpg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Ship Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\pressurevessel.jpeg" className='w-306 ' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Pressure Vessel Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\Reactor Nuclear.png" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Reactor Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\offshore.jpg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Offshore Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\Air Compressor tank.jpg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Air Compressor Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\valve.jpeg" className='w-306' alt='' style={{ height: '350px' }} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Valve Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='item group'>
              <div className='service overflow-hidden'>
                <div className='service-image' data-tilt>
                  <img src="assets\ameee\automotive.jpg" className='w-306 ' alt='' style={{ height: '350px'}} />
                  <div className="absolute inset-3 border-2 border-dashed border-white pointer-events-none"></div>
                  <div className='flex justify-between relative top-1'>
                    <div className='brand-content px-2'>
                      <a href='/' className='text-light no-underline text-xl font-bold'>Automobile Parts Testing & Inspection</a><br></br>
                    </div>
                    <div className='arrowContet bg-blue-600  w-20 h-20  flex  rounded-br-8xl rounded-tl-full group-hover:bg-slate-900'>
                      <span className='text-white text-center font-bold'>
                        <div className='mt-4 ml-7 text-center font-bold bg-white arrow-icon'>
                          <FontAwesomeIcon icon={faChevronRight} aria-hidden='true' style={{ color: 'blue' }} /></div></span>
                    </div>
                  </div>
                  <div className='absolute top-0 left-0'>
                    <div className='bg-blue-600  w-20 h-20  flex pt-3 pl-5 rounded-br-8xl rounded-br-full group-hover:bg-slate-900'>
                      <FontAwesomeIcon icon={faTag} className='text-white text-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
          <div className="text-center mt-2">
            <p className="find-solution bg-primary text-white py-2 px-8 inline-block ">
              Explore our range of services tailored to your needs.
              <a href="/" className="bg-white text-primary ml-2 py-1 px-3">Find Your Solution</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Fourth;
