import React from 'react'
import '../Header.css'
function Breathe() {
    return (
        <div>
            <div className="">
                <div className="floater">
                    <div className="text p-2">
                        <h6 className='text-center font-bold text-2xl'>About AMEEE NDT</h6>
                        <p className='font-bold text-justify'>
                            AMEEENDT is originated from AMEEE NDT Inspection Engineers PVT LTD is well known in India for NDT & Advance NDT company was found in March 2022 by committed team of
                            NDT Engineers well versed with field experience over the Ten Years.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breathe
