import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Upv = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Ultra Pulse Velocity Testing Services in Chennai |Ultra Pulse Velocity Testing Services in Padappai |Ultra Pulse Velocity Testing Services in Mumbai |Ultra Pulse Velocity Testing Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Ultra Pulse Velocity Testing services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Ultra Pulse Velocity Testing Services in Chennai ,Ultra Pulse Velocity Testing Services in Mumbai ,Ultra Pulse Velocity Testing Services in Haryana ,Ultra Pulse Velocity Testing Services in India,Best Ultra Pulse Velocity Testing services in Padappai,Best Ultra Pulse Velocity Testing Services in Chennai,Best Ultra Pulse Velocity Testing Services in Bangalore" />
                <meta name="keywords" content="Best Ultra Pulse Velocity Testing services in Andhra Pradesh,Best Ultra Pulse Velocity Testing services in Arunachal Pradesh,Best Ultra Pulse Velocity Testing services in Assam,Best Ultra Pulse Velocity Testing services in Bihar,Best Ultra Pulse Velocity Testing services in Chhattisgarh,Best Ultra Pulse Velocity Testing services in Goa,Best Ultra Pulse Velocity Testing services in Gujarat,Best Ultra Pulse Velocity Testing services in Haryana,
                        Best Ultra Pulse Velocity Testing services in Himachal Pradesh,Best Ultra Pulse Velocity Testing services in Jammu and kashmir,Best Ultra Pulse Velocity Testing services in Jharkhand,Best Ultra Pulse Velocity Testing services in Karnataka,Best Ultra Pulse Velocity Testing services in Kerala,Best Ultra Pulse Velocity Testing services in Madhya Pradesh,Best Ultra Pulse Velocity Testing services in Maharashtra,Best Ultra Pulse Velocity Testing services in Manipur,Best Ultra Pulse Velocity Testing services in Meghalaya,
                    Best Ultra Pulse Velocity Testing services in Mizoram,Best Ultra Pulse Velocity Testing services in Nagaland,Best Ultra Pulse Velocity Testing services in Odisha,Best Ultra Pulse Velocity Testing services in Punjab,Best Ultra Pulse Velocity Testing services in Rajasthan,Best Ultra Pulse Velocity Testing services in Sikkim,Best Ultra Pulse Velocity Testing services in TamilNadu,Best Ultra Pulse Velocity Testing services in Telengana,Best Ultra Pulse Velocity Testing services in Tripura,Best Ultra Pulse Velocity Testing services in Uttar Pradesh,
                   Best Ultra Pulse Velocity Testing services in Uttarakhand,Best Ultra Pulse Velocity Testing services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/upvc1.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/upvc.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/upvc2.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between group-hover:bg-blue-900 p-2'>
                                                    <div>
                                                        <a href='/UltraPulseVelocity' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ultra Pulse Velocity</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 group border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/ReboundHammer' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Rebound Hammer</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/HalfCellPotential' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Half-Cell Potential </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/ThicknessGauging' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Thickness Gauging</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Ultra Pulse Velocity</h1>
                                    <img src='assets/ameee/upvc2.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE Ndt </strong>specializes in Ultra Pulse Velocity (UPV) services, offering accurate and efficient testing for assessing the integrity and quality of concrete structures.Ultra Pulse Velocity (UPV) is a non-destructive testing method used to assess the quality and integrity of concrete structures by measuring the speed of ultrasonic pulses through the material, providing valuable information about its density, uniformity, and potential defects.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>Ultra Pulse Velocity Testing is utilized to assess the integrity and quality of concrete or other solid materials by measuring the speed of ultrasonic pulses through them. It helps identify anomalies such as cracks, voids, and delaminations.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                 <div>
                                 <div className='' style={{ width: '330px' }} >
                                        <img src='assets/ameee/upvc3.jpg' className=' border rounded-lg' />
                                    </div>
                                 </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className=' text-gray-500 text-lg'>Ultra Pulse Velocity Testing originated during World War II for assessing concrete structure integrity. Initially used in civil engineering, it has expanded to various industries, including aerospace. Known by names like Ultra Pulse Velocity Testing and UPV Testing.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-left text-gray-700 text-lg '>Ultra Pulse Velocity Testing (UPV) is a non-destructive testing method used to evaluate the integrity of materials, particularly concrete. It operates on the principle of measuring the travel time of ultrasonic pulses through the material.
                                                The material is subjected to ultrasonic pulses, and the time taken for the pulses to travel through the material is measured. Variations in travel time indicate anomalies such as cracks or voids.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900  justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/upvc1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/Upv1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Non-Destructive:</b> UPV testing is non-destructive, meaning it does not cause any damage to the concrete structure being inspected.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Versatility:  </b> It can be applied to a wide range of concrete structures, including bridges, buildings, dams, tunnels, and more.<br /><br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Quick and Efficient:</b>  It provides rapid results, allowing for fast assessment of concrete quality and integrity, which can be crucial for time-sensitive projects. </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Surface Preparation: </b> UPV testing requires proper surface preparation, including cleaning and smoothing the concrete surface, which can be time-consuming and labor-intensive.<br /><br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Detection Capability: </b> While UPV testing is effective for detecting some defects like cracks and voids, it may not identify certain types of internal defects or damage, such as delamination or alkali-silica reaction.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b> Cost: </b> While UPV testing is generally more cost-effective than destructive testing methods, it still incurs expenses related to equipment, training, and maintenance.<br /><br /></li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                           <div>
                           <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                           </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/Upv1.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultra Pulse Velocity Testing - How We Work</h4>
                    <p className=' font-semibold'>Ultra Pulse Velocity Testing, performed by AMEEE NDT, is essential for assessing the integrity of concrete or solid materials. We generate ultrasonic pulses and measure their travel time through the material. Variations in travel time indicate anomalies such as cracks or voids.
                        Our comprehensive reports offer actionable insights for maintenance planning and structural integrity assessment, ensuring the reliability and safety of critical structures.</p>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultra Pulse Velocity Testing Equipment & Consumables</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT provides a comprehensive range of equipment, accessories, and consumables specifically tailored for ultra pulse velocity testing.
                        Our offerings include ultrasonic pulse generators, transducers, coupling agents, signal analyzers, and data recording devices.
                        Additionally, we offer training materials, documentation tools, and calibration standards to ensure accurate and reliable testing procedures.
                        With our complete solutions, we empower organizations to conduct ultra pulse velocity testing with precision and efficiency.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultra Pulse Velocity Testing Standards</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as ASTM C597, ASTM C1383, and ACI 228.2R for ultra pulse velocity testing.
                        Our dedication to these standards ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultra Pulse Velocity Testing Parameters</h4>
                    <p className=' font-semibold'>
                        During ultra pulse velocity testing, we assess various parameters such as transducer frequency, coupling agent application, pulse duration, receiver sensitivity, and environmental conditions.
                        Our meticulous evaluation ensures accurate measurements and reliable results, empowering informed decision-making for structural integrity assessment and maintenance planning.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Anomalies Detected by Ultra Pulse Velocity Testing</h4>
                    <p className=' font-semibold'>
                        Through ultra pulse velocity testing, AMEEE NDT effectively identifies anomalies such as cracks, voids, delaminations, and other structural irregularities.
                        Our meticulous testing methods ensure thorough anomaly detection, enabling proactive maintenance and risk mitigation measures.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Ultra Pulse Velocity Testing</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as ASTM C597, ASTM C1383, and ACI 228.2R for ultra pulse velocity testing.
                        Our dedication to these standards ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Upv
