import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Rvi = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Remote Visual Inspection (RVI) Services in Chennai |Remote Visual Inspection (RVI) Services in Padappai |Remote Visual Inspection (RVI) Services in Mumbai |Remote Visual Inspection (RVI) Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Remote Visual Inspection (RVI) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Remote Visual Inspection (RVI) Services in Chennai ,Remote Visual Inspection (RVI) Services in Mumbai ,Remote Visual Inspection (RVI) Services in Haryana ,Remote Visual Inspection (RVI) Services in India,Best Remote Visual Inspection (RVI) services in Padappai,Best Remote Visual Inspection (RVI) Services in Chennai,Best Remote Visual Inspection (RVI) Services in Bangalore" />
                <meta name="keywords" content="Best Remote Visual Inspection (RVI) services in Andhra Pradesh,Best Remote Visual Inspection (RVI) services in Arunachal Pradesh,Best Remote Visual Inspection (RVI) services in Assam,Best Remote Visual Inspection (RVI) services in Bihar,Best Remote Visual Inspection (RVI) services in Chhattisgarh,Best Remote Visual Inspection (RVI) services in Goa,Best Remote Visual Inspection (RVI) services in Gujarat,Best Remote Visual Inspection (RVI) services in Haryana,
                        Best Remote Visual Inspection (RVI) services in Himachal Pradesh,Best Remote Visual Inspection (RVI) services in Jammu and kashmir,Best Remote Visual Inspection (RVI) services in Jharkhand,Best Remote Visual Inspection (RVI) services in Karnataka,Best Remote Visual Inspection (RVI) services in Kerala,Best Remote Visual Inspection (RVI) services in Madhya Pradesh,Best Remote Visual Inspection (RVI) services in Maharashtra,Best Remote Visual Inspection (RVI) services in Manipur,Best Remote Visual Inspection (RVI) services in Meghalaya,
                    Best Remote Visual Inspection (RVI) services in Mizoram,Best Remote Visual Inspection (RVI) services in Nagaland,Best Remote Visual Inspection (RVI) services in Odisha,Best Remote Visual Inspection (RVI) services in Punjab,Best Remote Visual Inspection (RVI) services in Rajasthan,Best Remote Visual Inspection (RVI) services in Sikkim,Best Remote Visual Inspection (RVI) services in TamilNadu,Best Remote Visual Inspection (RVI) services in Telengana,Best Remote Visual Inspection (RVI) services in Tripura,Best Remote Visual Inspection (RVI) services in Uttar Pradesh,
                   Best Remote Visual Inspection (RVI) services in Uttarakhand,Best Remote Visual Inspection (RVI) services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rvi2.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rvi3.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rvi4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PhasedArrayUltrasonicTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Phased Array Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TimeOfFlightDiffraction' className=' group-hover:text-white decoration-transparent text-gray-700 font-medium'>Time Of Flight Diffraction</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='LongRangeUltrasonicTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Long Range Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShortRangeUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Short Range Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='MagneticResonanceUltrasonicTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Magnetic Resonance Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='AutomatedUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Automated Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='EddyCurrentTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Eddy Current Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='RemoteFieldTechnique' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Remote Field Technique </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='InternalRotaryInspectionSystem' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Internal Rotary Inspection System</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='MagneticFluxLeakage' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Magnetic Flux Leakage</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='NearFieldElectromagneticTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Near Field Electromagnetic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Remote Visual Inspection</h1>
                                    <img src='assets/ameee/rvi3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE Ndt </strong> is a leading provider of advanced non-destructive testing (NDT)
                                        solutions, specializing in Remote Visual Inspection (RVI) services. With a commitment to excellence and
                                        innovation, we offer comprehensive inspection solutions tailored to diverse industries and applications. Our
                                        team of highly skilled engineers and technicians leverages cutting-edge technology and industry expertise to
                                        deliver reliable insights, ensuring the integrity, safety, and performance of critical assets.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>Remote Visual Inspection (RVI) serves as a vital tool in the field of non-destructive testing (NDT), offering a non-intrusive method to assess the condition of components and structures. The primary objective of RVI is to identify surface irregularities, defects, or discontinuities that could compromise the integrity, safety, or functionality of a component or structure. By employing advanced camera systems and remote access technologies, RVI enables inspectors to examine areas that are otherwise inaccessible or hazardous, such as confined spaces, high elevations, or areas with restricted entry.</p>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>RVI plays a crucial role across various industries, including aerospace, automotive, energy, manufacturing, and infrastructure. In aerospace, for instance, RVI is used to inspect aircraft engines, wings, and other critical components for signs of wear, corrosion, or damage. In the automotive sector, RVI helps in quality control during manufacturing processes, detecting flaws in engine parts, chassis, and body panels. Similarly, in the energy sector, RVI is employed for inspecting pipelines, storage tanks, and offshore structures to ensure operational safety and prevent environmental hazards.</p>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>By facilitating remote access to hard-to-reach areas, RVI minimizes the need for costly and time-consuming physical inspections, while also enhancing safety for inspection personnel. Furthermore, RVI allows for real-time monitoring and recording of inspection data, enabling prompt decision-making and maintenance planning.</p>
                                    </div>


                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div className=''>
                                        <div style={{ width: '330px' }}>
                                        <img src='assets/ameee/rvi4.jpg' className=' border rounded-lg mt-5' style={{height:'300px'}} />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div>
                                            <div className='ml-12'>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className=' text-gray-500 text-lg'>Remote Visual Inspection (RVI) has evolved as a modern application of visual inspection techniques, leveraging technological advancements to enable remote assessment of components and structures. While visual inspection has been a part of human practices for centuries, its integration with remote technologies has significantly expanded its capabilities and applications in various industries.</p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg '>Remote Visual Inspection (RVI) is a non-destructive testing method that utilizes advanced camera systems and remote access technologies to evaluate the surface integrity of components or structures from a distance. It involves the careful examination of surfaces for irregularities, defects, or discontinuities that could compromise their performance or safety. By enabling inspectors to assess inaccessible or hazardous areas remotely, RVI enhances safety, efficiency, and accuracy in inspection processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 group-hover:text-white font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/rvi1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/rvi.png' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Cost-effective:</b> Reduces downtime and operational costs associated with manual inspections, thereby
                                                optimizing asset management. </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Enhanced Safety: </b>Minimizes human exposure to hazardous environments, reducing the risk of accidents or
                                                injuries.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Detailed Insights: </b> Provides high-definition visual data, enabling inspectors to detect defects with greater
                                                accuracy and reliability.</li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Technical Challenges: </b>Remote inspection systems may encounter connectivity issues, signal interference, or
                                                equipment malfunctions, requiring skilled troubleshooting.<br /><br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b> Limited Sensory Input:</b> Lack of tactile feedback may impede the detection of certain defects or abnormalities
                                                that require physical inspection.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Initial Investment:</b> Acquiring and maintaining remote inspection equipment can involve significant upfront
                                                costs, although the long-term benefits often outweigh the investment.</li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className=''>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className=''>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className=' ' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/rvi3.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Process - How We Work</h4>
                    <p className=' font-semibold'>Remote visual inspection (RVI) by AMEEE NDT assesses surface conditions and internal characteristics of components without physical presence.
                        It entails comprehensive visual examination for anomalies such as cracks, corrosion, or weld defects, facilitated remotely through advanced technologies.
                        Findings are meticulously documented and compared against industry standards.
                        Detailed reports are generated, facilitating stakeholders in decision-making and maintenance planning.
                        AMEEE NDT ensures strict adherence to safety protocols, ensuring precise and dependable inspection outcomes even in remote scenarios.</p>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Equipment, Accessories & Consumables</h4>
                    <p className=' font-semibold'>
                        For remote visual inspection requirements, AMEEE NDT provides an extensive array of equipment, accessories, and consumables tailored for remote operations.
                        Our offerings encompass remote inspection cameras, remote viewing devices, lighting systems optimized for remote environments, digital recording and documentation tools, protective gear suitable for remote use, as well as specialized consumables designed for remote visual inspection tasks.
                        From remote cameras to remote documentation solutions, we furnish all essentials necessary for conducting thorough and precise remote visual inspections.</p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Standards and Standard Codes</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT adheres to established industry standards such as ASTM E3022, ASTM E1316, and ASNT SNT-TC-1A specifically tailored for remote visual inspection.
                        Our commitment to these standards guarantees precise and dependable inspections, safeguarding the safety and integrity of essential assets even in remote scenarios.</p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Parameters</h4>
                    <p className=' font-semibold'>
                        During remote visual inspections, we evaluate various parameters such as image clarity, resolution, and remote access capability to detect anomalies and potential concerns.
                        Our meticulous assessments provide valuable information for asset maintenance and integrity management, facilitating informed decision-making and proactive maintenance planning in remote settings.</p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Defects we can Capture</h4>
                    <p className=' font-semibold'>
                        At AMEEE NDT, our remote visual inspection services excel in capturing a wide range of defects and anomalies, including surface cracks, corrosion, dimensional variations, and coating irregularities.
                        Through precise remote inspection methodologies, we guarantee thorough defect detection, enabling proactive maintenance and effective risk mitigation strategies.</p>
                </div>
            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Remote Visual Inspection</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT adheres to industry-standard references such as ASTM E3022, ASTM E1316, and ASNT SNT-TC-1A specifically tailored for remote visual inspection.
                        Our dedication to these references guarantees the precision and dependability of our inspections, preserving the integrity and safety of essential assets, even in remote scenarios.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Rvi
