import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
import SidebarComponent from './Sidebar'
function Boiler() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Boiler Tube Testing Services in Chennai |Boiler Tube Testing Services in Padappai |Boiler Tube Testing Services in Mumbai |Boiler Tube Testing Services in Haryana |Boiler Tube Testing Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Boiler Tube Testing services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Boiler Tube Testing Services in Chennai ,Boiler Tube Testing Services in Mumbai ,Boiler Tube Testing Services in Haryana ,Boiler Tube Testing Services in India,Best Boiler Tube Testing services in Padappai,Best Boiler Tube Testing Services in Chennai,Best Boiler Tube Testing Services in Bangalore " />
                <meta name="keywords" content="Best Boiler Tube Testing services in Andhra Pradesh,Best Boiler Tube Testing services in Arunachal Pradesh,Best Boiler Tube Testing services in Assam,Best Boiler Tube Testing services in Bihar,Best Boiler Tube Testing services in Chhattisgarh,Best Boiler Tube Testing services in Goa,Best Boiler Tube Testing services in Gujarat,Best Boiler Tube Testing services in Haryana,
                    Best Boiler Tube Testing services in Himachal Pradesh,Best Boiler Tube Testing services in Jammu and kashmir,Best Boiler Tube Testing services in Jharkhand,Best Boiler Tube Testing services in Karnataka,Best Boiler Tube Testing services in Kerala,Best Boiler Tube Testing services in Madhya Pradesh,Best Boiler Tube Testing services in Maharashtra,Best Boiler Tube Testing services in Manipur,Best Boiler Tube Testing services in Meghalaya,
                  Best Boiler Tube Testing services in Mizoram,Best Boiler Tube Testing services in Nagaland,Best Boiler Tube Testing services in Odisha,Best Boiler Tube Testing services in Punjab,Best Boiler Tube Testing services in Rajasthan,Best Boiler Tube Testing services in Sikkim,Best Boiler Tube Testing services in TamilNadu,Best Boiler Tube Testing services in Telengana,Best Boiler Tube Testing services in Tripura,Best Boiler Tube Testing services in Uttar Pradesh,
                  Best Boiler Tube Testing services in Uttarakhand,Best Boiler Tube Testing services in West Bengal, Boiler Tube Testing services in Andhra Pradesh,Boiler Tube Testing services in Arunachal Pradesh, Boiler Tube Testing services in Assam, Boiler Tube Testing services in Bihar,Boiler Tube Testing services in Chhattisgarh,Boiler Tube Testing services in Goa, Boiler Tube Testing services in Gujarat,Boiler Tube Testing services in Haryana,
                  Boiler Tube Testing services in Himachal Pradesh,Boiler Tube Testing services in Jammu and kashmir,Boiler Tube Testing services in Jharkhand, Boiler Tube Testing services in Karnataka, Boiler Tube  Testing services in Kerala,Boiler Tube Testing services in Madhya Pradesh,Boiler Tube Testing services in Maharashtra,Boiler Tube  Testing services in Manipur, Boiler Tube Testing services in Meghalaya,
                  Boiler Tube Testing services in Mizoram,Best Boiler Tube Testing services in Nagaland,Boiler Tube Testing services in Odisha,Boiler Tube Testing services in Punjab,Boiler Tube Testing services in Rajasthan,Boiler Tube Testing services in Sikkim,Boiler Tube Testing services in TamilNadu,Boiler Tube Testing services in Telengana, Boiler Tube Testing services in Tripura,Boiler Tube Testing services in Uttar Pradesh,
                  Boiler Tube Testing services in Uttarakhand, Boiler Tube Testing services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Boiler Tube Testing & Inspection</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-justify text-lg '>
                                        <strong className='text-xl'>Boiler Tube Testing</strong> is a critical aspect of ensuring the safety and efficiency of boiler systems. At AMEEE NDT, we specialize in providing comprehensive non-destructive testing (NDT) services tailored specifically for boiler tubes. Our expert technicians utilize advanced techniques and equipment to inspect boiler tubes for defects such as corrosion, erosion, cracks, and weld integrity issues. By conducting thorough inspections, we help our clients identify potential problems early, preventing costly downtime and ensuring optimal performance of their boiler systems.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Boiler Tube Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Boiler tube testing serves the critical purpose of ensuring the integrity and reliability of boiler tubes within industrial systems. The primary goal is to detect and assess any defects or damage that could lead to leaks, failures, or reduced efficiency in boiler operations. By conducting thorough inspections, boiler tube testing helps to mitigate risks, prevent accidents, and prolong the lifespan of boiler systems.</p>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className='text-gray-500 text-lg'>Boiler tube testing has a rich history dating back to the early days of steam power. As boilers became essential components in industrial processes, the need for reliable testing methods grew. Over time, boiler tube testing evolved to meet the demands of safety, efficiency, and regulatory compliance in various industries.</p>

                                            <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-left text-gray-700 text-lg '>Boiler tube testing is a critical aspect of boiler maintenance and safety. It involves the inspection of boiler tubes to detect defects, corrosion, and other issues that could lead to leaks or failures. Various non-destructive testing methods, including visual inspection, ultrasonic testing, and eddy current testing, are employed to assess the condition of boiler tubes and ensure their integrity.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cost-effective:</b> Boiler tube testing typically requires minimal equipment and training compared to other testing methods.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                <b>Versatility:</b> Suitable for inspecting a wide range of boiler tube materials and surfaces.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                <b>Real-time assessment:</b> Provides immediate results, allowing for quick decision-making in boiler maintenance and safety.
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Subjectivity:</b> Interpretation of test results may vary among inspectors, leading to inconsistent assessments.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Limited to surface defects:</b> Boiler tube testing may not detect subsurface flaws or defects, potentially missing critical issues.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Environmental limitations:</b> Poor lighting conditions or limited visibility can hinder the accuracy of inspections, especially in confined boiler spaces.
                                            </li><hr />
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Process - Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        Boiler tube testing by AMEEE NDT involves comprehensive assessment of both surface conditions and internal characteristics of boiler tubes.
                        Our visual testing (VT) procedure includes meticulous visual examination for various anomalies such as cracks, corrosion, erosion, and weld defects within the boiler tubes.
                        All findings are meticulously documented and evaluated against industry standards to ensure accuracy and reliability.
                        Detailed reports are prepared, providing stakeholders with valuable insights for decision-making and maintenance planning.
                        AMEEE NDT prioritizes safety protocols throughout the testing process, ensuring the delivery of precise and reliable inspection results.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        For conducting visual testing on boiler tubes, AMEEE NDT offers a specialized array of equipment, accessories, and consumables tailored to meet the specific requirements of boiler tube inspection.
                        Our offerings encompass a wide range of tools and materials essential for comprehensive boiler tube testing, including inspection lights designed for confined spaces, magnifying devices for detailed examination, surface preparation tools for cleaning and prepping surfaces, marking and identification tools for labeling defects, protective equipment for ensuring safety, as well as NDT consumables such as penetrants and magnetic particles for detecting surface and subsurface flaws.
                        Additionally, we provide documentation tools, calibration standards, and training materials to support inspectors in their tasks.
                        With our comprehensive range of equipment and resources, we enable thorough and accurate visual inspections of boiler tubes, ensuring the integrity and reliability of boiler systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        When performing visual testing on boiler tubes, AMEEE NDT strictly adheres to industry-recognized standards and standard codes such as ASTM E18, ASTM E1444, and ASNT SNT-TC-1A.
                        By following these established standards, we ensure that our inspections are conducted with precision and consistency, guaranteeing the safety and integrity of critical boiler components.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        When conducting visual inspections on boiler tubes, we meticulously assess various parameters including surface condition, dimensions, and defect visibility.
                        By thoroughly evaluating these parameters, we can effectively identify anomalies and potential issues, providing valuable insights for asset maintenance and integrity management.
                        This detailed analysis aids in informed decision-making and proactive maintenance planning, ensuring the reliable and safe operation of boiler systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Captured in Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        Our visual inspection services at AMEEE NDT excel in capturing a wide range of defects and anomalies specific to boiler tube testing.
                        These defects include surface cracks, corrosion, dimensional deviations, and coating imperfections.
                        By employing meticulous inspection techniques, we ensure comprehensive defect detection, enabling proactive maintenance and effective risk mitigation strategies for boiler systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Boiler Tube Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as ASTM E165, ASTM E1417, and ASNT SNT-TC-1A for boiler tube testing.
                        Our commitment to these standards ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical boiler components.
                    </p>
                </div>

            </div>
        </div>
    );
}

export default Boiler
