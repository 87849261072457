// import React from 'react';

// export default function Form2() {
//     function Submit(e) {
//         const formEle = document.querySelector('form');
//         e.preventDefault();
//         console.log("Submitted");
//         const formData = new FormData(formEle);
//         fetch("https://script.google.com/macros/s/AKfycbwSIjZaioSEUTx1EREOYlFwdpOuwpBhPamala2nlUv8lnCGBQaBsLrCmekktSvuULuT/exec", {
//             method: 'POST',
//             body: formData
//         }).then((res) => res.json()).then((data) => {
//             console.log(data);
//         }).catch((error) => console.log(error));
//     }

//     return (
//         <div>
//             <div className='' style={{ borderRadius: '4px' }}>
//                 <form className='form' onSubmit={(e) => Submit(e)}>
//                     <input type='text' name='firstName' placeholder='First Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
//                     <input type='text' name='lastName' placeholder='Last Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
//                     <input type='email' name='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
//                     <input type='tel' name='tel' placeholder='Phone Number' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
//                     <input type='text' name='subject' placeholder='Subject' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
//                     <textarea name='message' placeholder='Message' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required></textarea>
//                     <button type='submit' className='darksoul-gradient-card6 lg:ml-40 p-2 text-white font-bold' style={{ borderRadius: '50px', boxShadow: '0px 5px 5px blue' }}><span className='ml-3'>Send Message</span></button>
//                 </form>
//             </div>
//         </div>
//     );
// }

// import React, { useState, useEffect } from 'react';

// function Form2() {
//     const [jobs, setJobs] = useState([]);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         fetchJobs();
//     }, []);

//     const fetchJobs = async () => {
//         try {
//             const response = await fetch('http://localhost:8069/api/jobs');
//             if (!response.ok) {
//                 throw new Error(`Failed to fetch jobs: ${response.status} ${response.statusText}`);
//             }
//             const data = await response.json();
//             setJobs(data);
//         } catch (error) {
//             console.error('Error fetching job postings:', error);
//             setError(error.message);
//         }
//     };

//     return (
//         <div>
//             {error && <p>Error fetching job postings: {error}</p>}
//             {jobs.length > 0 ? (
//                 jobs.map(job => (
//                     <div className="job-posting" key={job.id}>
//                         <h2><a href={job.url}>{job.name}</a></h2>
//                         <p><strong>Location:</strong> {job.location}</p>
//                         <p><strong>Department:</strong> {job.department}</p>
//                         <p><strong>Contract Type:</strong> {job.contract_type}</p>
//                         <p>{job.description}</p>
//                     </div>
//                 ))
//             ) : (
//                 !error && <p>No job postings available</p>
//             )}
//         </div>
//     );
// }

// export default Form2;


import React, { useEffect, useState } from 'react';

function Form2() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetch('http://localhost:8069/api/jobs') // Replace with your actual server address
      .then(response => response.json())
      .then(data => setJobs(data))
      .catch(error => console.error('Error fetching jobs:', error));
  }, []);

  return (
    <div className="App">
      <h1>Job Listings</h1>
      <ul>
        {jobs.map(job => (
          <li key={job.id}>
            <h2>{job.name}</h2>
            <p>{job.description}</p>
            <p><em>Posted on: {job.create_date}</em></p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Form2;
