import React from 'react'
import '../Header.css'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faSmile, faTrophy, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import  { useState, useEffect } from 'react';

const useCounterScroll = (targetClass) => {
    const [counter, setCounter] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const targetOffsetTop = document.querySelector(targetClass)?.offsetTop;
        const windowHeight = window.innerHeight;
  
        if (scrollTop + windowHeight > targetOffsetTop) {
          setCounter(0);
          const interval = setInterval(() => {
            setCounter(prevCounter => {
              if (prevCounter < 20) {
                return prevCounter + 1;
              } else {
                clearInterval(interval); 
                return prevCounter;
              }
            });
          }, 1000);
  
          return () => clearInterval(interval);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [targetClass]);
  
    return counter;
  };

  const useCounterScroll1 = (targetClass) => {
    const [counter, setCounter] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const targetOffsetTop = document.querySelector(targetClass)?.offsetTop;
        const windowHeight = window.innerHeight;
  
        if (scrollTop + windowHeight > targetOffsetTop) {
          setCounter(0);
          const interval = setInterval(() => {
            setCounter(prevCounter => {
              if (prevCounter < 99) {
                return prevCounter + 1;
              } else {
                clearInterval(interval); 
                return prevCounter;
              }
            });
          }, 1000);
  
          return () => clearInterval(interval);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [targetClass]);
  
    return counter;
  };

  const useCounterScroll2 = (targetClass) => {
    const [counter, setCounter] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const targetOffsetTop = document.querySelector(targetClass)?.offsetTop;
        const windowHeight = window.innerHeight;
  
        if (scrollTop + windowHeight > targetOffsetTop) {
          setCounter(0);
          const interval = setInterval(() => {
            setCounter(prevCounter => {
              if (prevCounter < 20) {
                return prevCounter + 1;
              } else {
                clearInterval(interval); 
                return prevCounter;
              }
            });
          }, 1000);
  
          return () => clearInterval(interval);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [targetClass]);
  
    return counter;
  };

  const useCounterScroll3 = (targetClass) => {
    const [counter, setCounter] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const targetOffsetTop = document.querySelector(targetClass)?.offsetTop;
        const windowHeight = window.innerHeight;
  
        if (scrollTop + windowHeight > targetOffsetTop) {
          setCounter(0);
          const interval = setInterval(() => {
            setCounter(prevCounter => {
              if (prevCounter < 700) {
                return prevCounter + 1;
              } else {
                clearInterval(interval); 
                return prevCounter;
              }
            });
          }, 150);
  
          return () => clearInterval(interval);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [targetClass]);
  
    return counter;
  };


function Owldemo() {
    const counter = useCounterScroll('.counter');
    const counter1 = useCounterScroll1('.counter');
    const counter2 = useCounterScroll2('.counter');
    const counter3 = useCounterScroll3('.counter');
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="assets/ameee/ameeelogo.png" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="twitter:image" content="assets/ameee/ameeelogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='container bg-transparent '>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <div className='funfact-box'>
                            <div className='funfact-icons'>
                                <FontAwesomeIcon icon={faSmile} className=' text-4xl' aria-hidden='true' />
                                <div className='effect-border'>

                                </div>
                            </div>
                            <div className='funfact-content'>
                                <h3>
                                    <span className='text1'>+{counter}</span>
                                </h3>
                                <span className='text-2xl'>Satisfied Clients</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <div className='funfact-box'>
                            <div className='funfact-icons'>
                                <FontAwesomeIcon icon={faLaptop} className=' text-4xl' aria-hidden='true' />
                                <div className='effect-border'>

                                </div>
                            </div>
                            <div className='funfact-content'>
                                <h3>
                                    <span className='text'>+{counter1}</span>
                                </h3>
                                <span className=' text-2xl text-blue-700'>Projects Completed</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <div className='funfact-box'>
                            <div className='funfact-icons'>
                                <FontAwesomeIcon icon={faUsers} className=' text-4xl' aria-hidden='true' />
                                <div className='effect-border'>

                                </div>
                            </div>
                            <div className='funfact-content'>
                                <h3>
                                <span className='text'>+{counter2}</span>
                                </h3>
                                <span className='text-2xl'>Team Members</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <div className='funfact-box'>
                            <div className='funfact-icons'>
                                <FontAwesomeIcon icon={faTrophy} className=' text-4xl' aria-hidden='true' />
                                <div className='effect-border'>

                                </div>
                            </div>
                            <div className='funfact-content'>
                                <h3>
                                    <span className='text1'>+{counter3}</span>
                                </h3>
                                <span className='text-2xl'>Awards Win </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Owldemo

