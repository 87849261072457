import { faQuoteLeft, faQuoteRight, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Carousel } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel2';
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';
function Testimony() {
    const options = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            800: {
                items: 1
            }
            ,
            1000: {
                items: 1
            }
            ,
            1000: {
                items: 1
            }
        },
        dots: false,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    };
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className=' bg-slate-950 p-5'>
                <div className='text-center mb-3'>
                    <div className=''>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={1000}
                        >
                            <span className=' text-white font-bold lg:text-6xl sm:text-3xl md:text:4xl '>
                                Outstanding Testimonial
                            </span>
                        </FancyText>
                    </div>
                </div>
                <div className='text-center '>
                    <span className='text-blue-600 text-5xl  font-bold'>Client </span><span className=' text-white text-5xl  font-bold'>Says</span>
                    <div><p className='text-center text-white p-5 text-3xl font-bold'>Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p>
                    </div>
                </div>
                <OwlCarousel options={options}>
                    <div className='container mx-auto'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 p-5'>
                                <FontAwesomeIcon icon={faUsers} style={{ width: '150px', height: '150px', border: '2px solid blue', borderRadius: '50%', color: 'white', padding: '20px' }} />
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-8 '>
                                <div className='content p-3 text-white' style={{ border: '1px solid grey' }}>

                                    <p className=''>  <FontAwesomeIcon icon={faQuoteLeft} className=' text-4xl text-blue-600' /> Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p>
                                    <div className='info m-3'>
                                        <h2 className=' text-blue-600'>MADHAN M</h2>
                                        <span>Thank you sir for your support.</span>

                                    </div><FontAwesomeIcon icon={faQuoteRight} className=' text-4xl text-blue-600 ' style={{ marginLeft: '750px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='container '>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 p-5'>
                                <FontAwesomeIcon icon={faUsers} style={{ width: '150px', height: '150px', border: '2px solid blue', borderRadius: '50%', color: 'white', padding: '20px' }} />
                                {/* <img src='assets/ameee/funfact.jpg' alt='' style={{ width: '150px', height: '150px', border: '2px solid blue', borderRadius: '50%' }} /> */}
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-8 '>
                                <div className='content p-3 text-white' style={{ border: '1px solid grey' }}>

                                    <p className=''>  <FontAwesomeIcon icon={faQuoteLeft} className=' text-4xl text-blue-600' /> Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p>
                                    <div className='info m-3'>
                                        <h2 className=' text-blue-600'>VIGNESH KUMAR</h2>
                                        <span>Very good exposure in AMEE NDT service, Quality of inspection will be Genuine - very perfect and it make as per client requirement.
                                            We can trust AMEE NDT inspection in all aspect.Good luck</span>

                                    </div><FontAwesomeIcon icon={faQuoteRight} className=' text-4xl text-blue-600 ' style={{ marginLeft: '750px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='container '>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 p-5'>
                                <FontAwesomeIcon icon={faUsers} style={{ width: '150px', height: '150px', border: '2px solid blue', borderRadius: '50%', color: 'white', padding: '20px' }} />
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-8 '>
                                <div className='content p-3 text-white' style={{ border: '1px solid grey' }}>

                                    <p className=''>  <FontAwesomeIcon icon={faQuoteLeft} className=' text-4xl text-blue-600' /> Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p>
                                    <div className='info m-3'>
                                        <h2 className=' text-blue-600'>VIMAL V K</h2>
                                        <span>We have requested for PMI, Ultrasonic flaw detection. AMEEE Team have given us on time services and team is proactive system | On time delivery of service
                                            | Best Cost | Good Communication Skills.
                                        </span>

                                    </div><FontAwesomeIcon icon={faQuoteRight} className=' text-4xl text-blue-600 ' style={{ marginLeft: '750px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='container '>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 p-5'>
                                <FontAwesomeIcon icon={faUsers} style={{ width: '150px', height: '150px', border: '2px solid blue', borderRadius: '50%', color: 'white', padding: '20px' }} />
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-8 '>
                                <div className='content p-3 text-white' style={{ border: '1px solid grey' }}>

                                    <p className=''>  <FontAwesomeIcon icon={faQuoteLeft} className=' text-4xl text-blue-600' /> Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects and assets.</p>
                                    <div className='info m-3'>
                                        <h2 className=' text-blue-600'>BALU AMBORE</h2>
                                        <span>
                                            Thank you for your Kind Support.
                                            Ultrasonic Testing & Eddy Current Testing, Ultrasonic Thickness
                                            AMEEENDT Team proactively provided the report and explain the defects character and their impact
                                            NDT inspections have wide knowledge of NDT & Welding, Forged material.
                                            Strong In NDT service & Welding Qualification
                                            We Recommend this company.
                                        </span>

                                    </div><FontAwesomeIcon icon={faQuoteRight} className=' text-4xl text-blue-600 ' style={{ marginLeft: '750px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default Testimony
