import React, { useState } from 'react'
import FancyText from '@carefully-coded/react-text-gradient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { faPhone, faPlus, faMinus, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown, faCircleCheck, faBuilding, faLightbulb, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
function About2() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
                <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-black'>
                <div className='container p-5'>
                    <div className=''>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            <h2 className='text-6xl font-bold'>We Support You</h2>
                            <span className='text-4xl font-bold'>How Can We Help You</span>
                        </FancyText>
                        <p className='text-2xl font-bold text-center text-gray-300 mt-3'> AMEEE NDT Inspection Engineers PVT LTD offers expert guidance in Quality, Safety, and Environmental standards.</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <Trees />
                        </div>
                        <div className='col-lg-8'>
                            <Scroll />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



function Trees() {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);

    const toggleDetails = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleDetails1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    const toggleDetails2 = () => {
        setIsExpanded2(!isExpanded2);
    };

    const toggleDetails3 = () => {
        setIsExpanded3(!isExpanded3);
    };

    return (
        <div className=''>
            <div className='bg-slate-900 flex justify-between  py-2 my-3' onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>All </span></div>
                <div>
                    <span className=' px-3  pt-2 pb-2 text-center font-bold text-2xl text-blue'>
                        {isExpanded ? <FontAwesomeIcon icon={faMinus} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <div className='p-2 font-bold text-white  bg-blue-100 text-left'>
                    <a href='#head1' className='decoration-transparent text-gray-700 '>What are the key features and benefits of your products/services?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700 '>What is your return /refund policy?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700'>What is your return /refund policy?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700'>What is your return /refund policy?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700'>What is your return /refund policy?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700'>What is your return /refund policy?</a><br /><br />
                    <a href='#head1' className='decoration-transparent text-gray-700'>What is your return /refund policy?</a><br /><br />
                </div>)}



            <div className='bg-slate-900 flex justify-between  py-2 my-3' onClick={toggleDetails1}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>Order Status</span></div>
                <div>
                    <span className=' px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                        {isExpanded1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
                </div>
            </div>
            {isExpanded1 && (
                <div className='p-2 font-bold text-white  bg-blue-100 text-left'>
                    <a href='#head2' className='decoration-transparent text-gray-700 '>What are the key features and benefits of your products/services?</a><br /><br />
                    <a href='#head2' className='decoration-transparent text-gray-700 '>What is your return /refund policy?</a><br /><br />
                </div>)}



            <div className=' bg-slate-900 flex justify-between  py-2 my-3' onClick={toggleDetails2}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>Other</span></div>
                <div>
                    <span className=' px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                        {isExpanded2 ? <FontAwesomeIcon icon={faMinus} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded2 && (
                <div className='p-2 font-bold text-white  bg-blue-100 text-left'>
                    <a href='#head3' className='decoration-transparent text-gray-700 '>What are the key features and benefits of your products/services?</a><br /><br />

                </div>)}


            <div className='flex justify-between bg-slate-900 py-2 my-3' onClick={toggleDetails3}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>Privacy & Policy</span></div>
                <div>
                    <span className=' px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                        {isExpanded3 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
                </div>
            </div>
            {isExpanded3 && (
                <div className='p-2 font-bold text-white  bg-blue-100 text-left'>
                    <a href='#head4' className='decoration-transparent text-gray-700 '>What are the key features and benefits of your products/services?</a><br /><br />
                    <a href='#head4' className='decoration-transparent text-gray-700 '>What is your return /refund policy?</a><br /><br />
                </div>)}

            <div className='bg-slate-900 flex justify-between  py-2 my-3' onClick={toggleDetails1}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>Terms & Conditions</span></div>
                <div>
                    <span className=' px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                        {isExpanded1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
                </div>
            </div>
            {isExpanded1 && (
                <div className='p-2 font-bold text-white  bg-blue-100 text-left'>
                    <a href='#head5' className='decoration-transparent text-gray-700 '>What are the key features and benefits of your products/services?</a><br /><br />
                    <a href='#head5' className='decoration-transparent text-gray-700 '>What is your return /refund policy?</a><br /><br />
                </div>)}


        </div>
    )
}


function Scroll() {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);

    const toggleDetails = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleDetails1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    const toggleDetails2 = () => {
        setIsExpanded2(!isExpanded2);
    };

    const toggleDetails3 = () => {
        setIsExpanded3(!isExpanded3);
    };

    return (
        <div className=' overflow-y-scroll' style={{ maxHeight: '400px' }}>
            <h3 className='text-white text-left'>All</h3>
            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' id='head1' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}

            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}

            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}

            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}
            <h3 className='text-white text-left' id='head2'>Order Status</h3>
            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}
            <h3 className='text-white text-left' id='head3'>Other</h3>
            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}
            <h3 className='text-white text-left' id='head4'>Privacy & Policy</h3>
            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}
            <h3 className='text-white text-left' id='head5'>Terms & Conditions</h3>
            <div className=' bg-slate-900 flex justify-between py-2 my-3 p-2' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '25px' }} onClick={toggleDetails}>
                <div><span className='px-4 text-lg text-white font-bold w-96'>What are the key features and benefits of our services ?</span></div>
                <div className=''>
                    <span className=' text-center  font-bold text-3xl text-blue' style={{ border: '1px solid #0073c7', borderRadius: '5px', padding: '2px 8px', backgroundColor: '#0073c7' }}>
                        {isExpanded ? <FontAwesomeIcon icon={faAngleUp} style={{ color: 'white' }} /> : <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white' }} />} </span>
                </div>
            </div>
            {isExpanded && (
                <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}
        </div>
    )
}

export default About2
