import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Valve7() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Dye Penetration Testing Services in Valve Testing | Dye Penetration Testing Services in Padappai | Dye Penetration Testing Services in Mumbai | Dye Penetration Testing Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Dye Penetration Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert dye penetration testing services!" />
                <meta name="title" content="Dye Penetration Testing Services in Valve Testing, Dye Penetration Testing Services in Mumbai, Dye Penetration Testing Services in Haryana, Dye Penetration Testing Services in India, Best Dye Penetration Testing Services in Padappai, Best Dye Penetration Testing Services in Chennai, Best Dye Penetration Testing Services in Bangalore" />
                <meta name="keywords" content="Best Dye Penetration Testing Services in Andhra Pradesh, Best Dye Penetration Testing Services in Arunachal Pradesh, Best Dye Penetration Testing Services in Assam, Best Dye Penetration Testing Services in Bihar, Best Dye Penetration Testing Services in Chhattisgarh, Best Dye Penetration Testing Services in Goa, Best Dye Penetration Testing Services in Gujarat, Best Dye Penetration Testing Services in Haryana, Best Dye Penetration Testing Services in Himachal Pradesh, Best Dye Penetration Testing Services in Jammu and Kashmir, Best Dye Penetration Testing Services in Jharkhand, Best Dye Penetration Testing Services in Karnataka, Best Dye Penetration Testing Services in Kerala, Best Dye Penetration Testing Services in Madhya Pradesh, Best Dye Penetration Testing Services in Maharashtra, Best Dye Penetration Testing Services in Manipur, Best Dye Penetration Testing Services in Meghalaya, Best Dye Penetration Testing Services in Mizoram, Best Dye Penetration Testing Services in Nagaland, Best Dye Penetration Testing Services in Odisha, Best Dye Penetration Testing Services in Punjab, Best Dye Penetration Testing Services in Rajasthan, Best Dye Penetration Testing Services in Sikkim, Best Dye Penetration Testing Services in Tamil Nadu, Best Dye Penetration Testing Services in Telangana, Best Dye Penetration Testing Services in Tripura, Best Dye Penetration Testing Services in Uttar Pradesh, Best Dye Penetration Testing Services in Uttarakhand, Best Dye Penetration Testing Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Dye Penetration Testing Services in Valve Testing" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive Dye Penetration Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert dye penetration testing services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Dye Penetration Testing Services in Valve Testing" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive Dye Penetration Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert dye penetration testing services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/trevi1.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/trevi2.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/trevi.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full  '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Trevi Testing of Safety Valves- Valve Testing</h1>
                                    <img src='assets/ameee/trevi.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Trevi Testing of Safety Valves in Valve Testing</strong> is provided by AMEEE NDT, a trusted provider of comprehensive testing solutions. Our specialized team conducts meticulous Trevi testing processes in valve testing environments. Leveraging advanced technology and expertise, we ensure precise inspection of safety valve materials and components for defects and irregularities. Our Trevi testing services enable us to detect operational and performance issues, ensuring compliance with industry standards and specifications.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Trevi Testing in Valve Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Trevi testing in valve testing serves the critical purpose of detecting operational and performance issues in safety valve materials and components. This specialized testing method allows us to assess the functionality and reliability of safety valves, including critical areas, without causing damage to the tested components. By conducting Trevi testing, our services aim to ensure the reliability, safety, and performance of safety valves used in industrial applications.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/trevi1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Trevi Testing in Valve Testing</h4>
                                            <p className='text-gray-500 text-lg'>Trevi testing in valve testing has a significant historical background within the valve manufacturing and industrial sectors. As the demand for reliable and safe safety valve systems has increased, Trevi testing techniques have evolved to ensure the quality and integrity of safety valves used in various applications. The historical development of Trevi testing methods has been instrumental in enhancing the detection of operational and performance issues, thereby improving the overall performance and safety of valve systems.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Trevi Testing in Valve Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Trevi testing in valve testing utilizes specialized methodologies to detect operational and performance issues in safety valve materials and components. By applying specific test pressures and monitoring the valve's response, this non-destructive testing method highlights performance issues that may not be visible through visual inspection alone. Through precise interpretation of Trevi testing results, manufacturers and industries can ensure the reliability and safety of safety valves in diverse industrial applications.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/trevi2.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/trevi3.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Non-Destructive Inspection:</b> Trevi testing allows for thorough inspection of safety valve components without causing damage, providing valuable insights into their operational integrity and potential issues.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Early Detection of Performance Issues:</b> By detecting operational and performance issues early on, Trevi testing helps prevent potential failures and ensures the reliability of safety valve operation.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Accurate Evaluation:</b> Trevi testing provides precise evaluations of safety valve performance, aiding in quality control and optimization of valve functionality.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Equipment Complexity:</b> Trevi testing equipment and procedures can be complex, requiring trained personnel and specialized tools for accurate and reliable results.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Specialized Conditions Needed:</b> Trevi testing may have limitations in detecting performance issues under specific operational conditions, potentially affecting inspection accuracy.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cost Considerations:</b> Implementing Trevi testing may involve initial setup costs and ongoing expenses for maintenance and training, which should be weighed against the benefits for each application.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px', height: '570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/trevi1.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Trevi Testing in Safety Valve Testing Process - How We Work</h4>
                    <p className='font-semibold'>Trevi testing in safety valve testing at AMEEE NDT involves a meticulous approach to ensure the integrity and reliability of safety valve components. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Initial Assessment: Conducting a comprehensive assessment of safety valve components to evaluate their operational integrity and identify any performance issues using Trevi testing techniques.</li>
                        <li>Testing Planning: Developing a customized Trevi testing plan based on valve material, geometry, and inspection requirements to optimize issue detection.</li>
                        <li>Testing Execution: Performing Trevi testing with advanced equipment and techniques, ensuring thorough coverage and accurate detection of operational and performance issues.</li>
                        <li>Quality Assurance: Implementing rigorous quality assurance protocols during Trevi testing to maintain accuracy, reliability, and compliance with industry standards.</li>
                        <li>Data Analysis: Analyzing Trevi test results to assess the severity and location of detected issues, guiding decisions on repair, replacement, or further inspection.</li>
                        <li>Documentation and Reporting: Documenting all Trevi testing procedures, findings, and conclusions in detailed reports for audit trails and regulatory compliance.</li>
                    </ul>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Trevi Testing in Safety Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for Trevi testing in safety valve testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Trevi Testing Equipment: State-of-the-art Trevi testing kits, applicators, and inspection instruments tailored for accurate detection of operational and performance issues in safety valve components.</li>
                        <li>Testing Accessories: Fixturing systems, application tools, and inspection aids optimized for efficient and thorough Trevi testing of safety valves, ensuring precise results and reliable issue detection.</li>
                        <li>Consumables: Test fluids, pressure gauges, and sealing materials formulated for optimal performance and consistency in Trevi testing, enhancing the accuracy and reliability of detected issues.</li>
                        <li>Data Analysis Tools: Software solutions for analyzing and interpreting Trevi test results, facilitating issue characterization, documentation, and reporting.</li>
                        <li>Personal Protective Equipment (PPE): Safety gear including gloves, goggles, and protective clothing to ensure the safety of personnel engaged in Trevi testing operations.</li>
                        <li>Training Resources: Comprehensive training programs, workshops, and educational materials to enhance the proficiency and expertise of personnel in Trevi testing techniques and procedures.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our extensive range of equipment and resources, we provide everything necessary to conduct precise and reliable Trevi testing in safety valve testing applications.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Trevi Testing in Safety Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT strictly adheres to industry-recognized standards and codes specifically tailored for Trevi testing in safety valve testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME PTC 25: Pressure Relief Devices</li>
                        <li>ISO 4126: Safety Devices for Protection Against Excessive Pressure</li>
                        <li>API RP 576: Inspection of Pressure-Relieving Devices</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the precision, reliability, and safety of our Trevi testing procedures in safety valve testing. This helps to identify operational and performance issues accurately and optimize the integrity of safety valve components for various industrial applications.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Trevi Testing in Safety Valve Testing</h4>
                    <p className='font-semibold'>
                        During Trevi testing in safety valve testing, AMEEE NDT evaluates various parameters to ensure accurate detection and characterization of operational and performance issues in safety valve components. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Test Pressure: Selecting appropriate test pressures based on the specifications and operational requirements of the safety valve to ensure accurate performance evaluation.</li>
                        <li>Response Time: Measuring the time taken for the safety valve to respond to the applied test pressure, ensuring it operates within the acceptable range.</li>
                        <li>Leakage Rate: Assessing the leakage rate at various pressure levels to determine the sealing efficiency and overall performance of the safety valve.</li>
                        <li>Set Pressure Verification: Confirming that the safety valve opens at the designated set pressure to ensure it provides adequate protection under overpressure conditions.</li>
                        <li>Functional Testing: Conducting functional tests to observe the safety valve's behavior under simulated operating conditions, ensuring it performs reliably and consistently.</li>
                        <li>Data Analysis: Interpreting the results obtained during Trevi testing to accurately assess the performance and operational readiness of the safety valve, guiding maintenance or replacement decisions.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our meticulous evaluation of these parameters ensures the effectiveness and integrity of Trevi testing processes in safety valve testing, leading to enhanced reliability and performance of safety valve components across various industrial applications.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Trevi Testing in Safety Valve Testing</h4>
                    <p className='font-semibold'>
                        During Trevi testing processes conducted by AMEEE NDT as part of safety valve testing, we have the capability to identify various anomalies and deviations, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Performance Deviations: Detecting deviations from expected performance characteristics, such as improper response time, leakage, or failure to open at designated set pressure.</li>
                        <li>Sealing Irregularities: Identifying issues with sealing surfaces or mechanisms that may lead to improper sealing or leakage during operation.</li>
                        <li>Material Degradation: Recognizing signs of material degradation or wear that could compromise the structural integrity and reliability of safety valve components.</li>
                        <li>Valve Misalignment: Detecting misalignment or improper installation of safety valves, which may affect their ability to function effectively under pressure.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous Trevi testing processes and adherence to stringent quality standards, we ensure accurate detection and characterization of operational and performance issues in safety valve components. This facilitates the identification of potential issues early on, ensuring the reliability and safety of safety valve systems in industrial applications.
                    </p>
                </div>
            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Trevi Testing in Safety Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for Trevi testing in safety valve testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME PTC 25 Pressure Relief Devices: A standard for evaluating the performance of pressure relief devices, including safety valves, ensuring compliance with design, testing, and performance requirements.</li>
                        <li>ISO 4126 Safety Devices for Protection Against Excessive Pressure: Provides guidelines for the design, selection, installation, and maintenance of safety devices, including safety valves, to prevent overpressure incidents.</li>
                        <li>API RP 576 Inspection of Pressure-Relieving Devices: Offers recommendations for the inspection and maintenance of pressure-relieving devices, including safety valves, to ensure their reliable operation and compliance with industry standards.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our Trevi testing processes in safety valve testing, ultimately contributing to the integrity and performance of critical safety valve components.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Valve7
