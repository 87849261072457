
import React, { useState, useEffect, useRef, Component } from 'react';
import './Header.css'
import { Navbar, Nav, Container, CarouselItem, CarouselCaption, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import First from './Components/First';
import Third from './Components/Third';
import Fourth from './Components/fourth';
import Fifth from './Components/fifth';
import Seventh from './Components/seventh';
import { Eight } from './Eight';
import { Nine } from './Components/Nine';

import PageWithCarousel from './Components/PageWithCarousel';
import Sectors from './Components/Sectors';
import Testimony from './Components/Testimony';

const Demo = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const carouselItems = [
    {
      imageSrc: 'assets/ameee/images4.jpeg',
      caption: 'First slide caption',
      heading: ' NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images6.jpeg',
      caption: 'Second slide caption',
      heading: 'Conventional NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images5.jpeg',
      caption: 'third slide caption',
      heading: ' Advance NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images1.jpeg',
      caption: 'fourth slide caption',
      heading: ' Coating Inspection'
    },
    {
      imageSrc: 'assets/ameee/images2.jpeg',
      caption: 'fifth slide caption',
      heading: ' Welding Inspection'
    },
    {
      imageSrc: 'assets/ameee/images3.jpeg',
      caption: 'Sixth slide caption',
      heading: ' Rope Access'
    },
    {
      imageSrc: 'assets/ameee/images6.jpeg',
      caption: 'Seventh slide caption',
      heading: ' NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images4.jpeg',
      caption: 'eighth slide caption',
      heading: ' NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images5.jpeg',
      caption: 'ninth slide caption',
      heading: ' NDT Services & Inspection'
    },
    {
      imageSrc: 'assets/ameee/images2.jpeg',
      caption: 'tenth slide caption',
      heading: ' NDT Services & Inspection'
    },
  ];



  const divStyle = {
    backgroundImage: 'url(/assets/ameee/background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    opacity: 0.9,
  };
  const navStyle = {
    backgroundImage: 'url(/assets/ameee/slideimage3.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    opacity: 0.8,
  };

  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="w-auto overflow-x-hidden">
        <div className="header-container w-full ">
          <div>
            <Carousel interval={5000} style={{ position: 'sticky', top: 0, zIndex: 1000 }} prevIcon={null} nextIcon={null}>
              <Carousel.Item className='switch'>
                <Nav className={`nav ${scrolled ? 'scrolled' : ''}`}>
                  <div className='w-full sm:w-full'>
                    <First />
                  </div>
                </Nav>
              </Carousel.Item>
            </Carousel>
            <div className="home-page " >
              <PageWithCarousel carouselItems={carouselItems} />

            </div>

          </div>

          <div className='p-0 bg-cover bg-center relative  water-ripple-container mt-20' style={{ backgroundImage: `url('a1.jpg')` }}>
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <Third />
          </div>

          <div>
            <Fourth />
          </div>

          <div className=''>
            <Fifth />
          </div>
          <div>
            <Sectors />
          </div>
          <div>
            <Seventh />
          </div>
          <div>
            <Eight />
          </div>

          <div>
            <Nine />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
