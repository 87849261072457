import React, { useState } from 'react'
import Ten from './ten'
import Foooter from './Footer'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import Card from 'react-bootstrap/Card';
import { CardFooter } from 'react-bootstrap';
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { faFacebook, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faEnvelopeOpen, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import FancyText from '@carefully-coded/react-text-gradient';
import { RadialGradient } from 'react-text-gradients';
export const Nine = () => {
  const options = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      800: {
        items: 1
      }
      ,
      1000: {
        items: 4
      }
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  return (
    <div>
      <Helmet>
        <title>Meet Our Team at AMEEE NDT |Ndt Expertise |ndt team |ndt expertise at Padappai </title>
        <meta name="description" content="Meet the dedicated and skilled team members at AMEEE NDT in Padappai. Our team consists of experts in non-destructive testing (NDT) services, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="keywords" content="AMEEE NDT team, NDT experts, Padappai,best NDT services in Chennai ,NDT experts in Chennai,Best Advance NDT services in India, Best Advance NDT services in India,Best civil ndt services in India,non-destructive testing, third-party inspection, rope access, coating inspection, welding services" />
        <meta property="og:title" content="Meet Our Team at AMEEE NDT" />
        <meta property="og:description" content="Meet the dedicated and skilled team members at AMEEE NDT in Padappai. Our team consists of experts in non-destructive testing (NDT) services, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Meet Our Team at AMEEE NDT" />
        <meta name="twitter:description" content="Meet the dedicated and skilled team members at AMEEE NDT in Padappai. Our team consists of experts in non-destructive testing (NDT) services, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='bgImage bg-fixed  '>
        <div className='bg-white py-20 bg-cover'>

          <div className=' text-center sm:w-64 md:w-84 lg:w-full'>
            <h2 className=' font-extrabold p-3 text-5xl' id='down'>
              <FancyText
                gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                animate
                animateDuration={2000}
              >
                What We Are
              </FancyText>
            </h2>
            <div className="relative inline-block" id='up' >
              <span className="bg-primary text-white px-3 py-2  text-xl" >Outstanding Team</span>
              <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
              </span>
            </div>
            <p className='mt-4 font-bold text-2xl mx-10' style={{ color: '#025' }}>Our versatile team excels across diverse sectors including mechanical engineering, NDT services, and IT, leveraging our expertise to provide innovative solutions tailored to the specific needs of each industry.</p>
          </div>
          <div className='ml-16 mt-5'>
            <div className='lg:flex lg:flex-wrap'>
              <OwlCarousel options={options}>
                <div >
                  <div className='item-inner'>
                    <div className='item-image'>
                      <div className='team-avatar '>
                        <img src='assets/ameee/ananthi.jpg'
                          className={isHovered ? "colorful-image" : "grayscale-image"}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)} />
                        <div className="overlay"></div>
                      </div>
                      <div className='item-social'  >
                        <div className='mb-2' >
                          <a href='/' style={{ backgroundColor: isHovered ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faFacebook} className='fa-icon' style={{ color: isHovered ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faTwitter} className='fa-icon' style={{ color: isHovered ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faInstagram} className='fa-icon' style={{ color: isHovered ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faLinkedinIn} className='fa-icon' style={{ color: isHovered ? 'white' : 'blue' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='wp-content'>
                      <div className='item-meta'>
                        <div className='line-top-meta'>

                        </div>
                        <h4 className='item--title font-bold'>
                          ANANDI ALLIRAJ
                        </h4>
                        <div className='item-position'>
                          Operations Manager
                        </div>
                        <div className='item-readmore font-bold'>
                          <a href='/'>
                            Read more
                            <FontAwesomeIcon icon={faAngleRight} />
                          </a>
                        </div>
                      </div>
                      <div className='item-holder ' style={{ display: isHovered ? 'block' : 'none' }}>
                        <div className='item-holder-inner '>
                          <h4 className=''>
                            ANANDI ALLIRAJ
                          </h4>
                          <div className=''>
                            <FontAwesomeIcon icon={faPhone} className='mr-3' />
                            +91 8169361089
                          </div>
                          <div className=''>
                            <FontAwesomeIcon icon={faEnvelopeOpen} className='mr-3' />
                            inspection@ameeendt.com
                          </div>
                          <div className='item-readmore'>
                            <a href='/' className=''>
                              Read More
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='item-inner'>
                    <div className='item-image'>
                      <div className='team-avatar'>
                        <img src='assets/ameee/sandhya.jpg' className={isHovered1 ? "colorful-image" : "grayscale-image"}
                          onMouseEnter={() => setIsHovered1(true)}
                          onMouseLeave={() => setIsHovered1(false)} />
                        <div className="overlay"></div>
                      </div>
                      <div className='item-social'>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered1 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faFacebook} className='fa-icon' style={{ color: isHovered1 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered1 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faTwitter} className='fa-icon' style={{ color: isHovered1 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered1 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faInstagram} className='fa-icon' style={{ color: isHovered1 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered1 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faLinkedinIn} className='fa-icon' style={{ color: isHovered1 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='wp-content'>
                      <div className='item-meta'>
                        <div className='line-top-meta'>

                        </div>
                        <h4 className='item--title font-bold'>
                          SANDHYA P C
                        </h4>
                        <div className='item-position'>
                          Finance Manager
                        </div>
                        <div className='item-readmore font-bold'>
                          <a href='/'>
                            Read more
                            <FontAwesomeIcon icon={faAngleRight} />
                          </a>
                        </div>
                      </div>
                      <div className='item-holder' style={{ display: isHovered1 ? 'block' : 'none' }}>
                        <div className='item-holder-inner'>
                          <h4 className=''>
                            SANDHYA P C
                          </h4>
                          <div className=''>
                            <FontAwesomeIcon icon={faPhone} className='mr-3' />
                            +91 8169361089
                          </div>
                          <div className=''>
                            <FontAwesomeIcon icon={faEnvelopeOpen} className='mr-3' />
                            inspection@ameeendt.com
                          </div>
                          <div className='item-readmore'>
                            <a href='/' className=''>
                              Read More
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div>
                  <div className='item-inner'>
                    <div className='item-image'>
                      <div className='team-avatar'>
                        <img src='assets/ameee/Boobathi.jpg' className={isHovered2 ? "colorful-image" : "grayscale-image"}
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)} />
                        <div className="overlay"></div>
                      </div>
                      <div className='item-social'>
                        <div className='mb-2' >
                          <a href='/' style={{ backgroundColor: isHovered2 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faFacebook} className='fa-icon' style={{ color: isHovered2 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered2 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faTwitter} className='fa-icon' style={{ color: isHovered2 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered2 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faInstagram} className='fa-icon' style={{ color: isHovered2 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered2 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faLinkedinIn} className='fa-icon' style={{ color: isHovered2 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='wp-content'>
                      <div className='item-meta'>
                        <div className='line-top-meta'>

                        </div>
                        <h4 className='item--title font-bold'>
                          BOOPATHI RAJA S
                        </h4>
                        <div className='item-position'>
                          Team Leader
                        </div>
                        <div className='item-readmore font-bold'>
                          <a href='/'>
                            Read more
                            <FontAwesomeIcon icon={faAngleRight} />
                          </a>
                        </div>
                      </div>
                      <div className='item-holder' style={{ display: isHovered2 ? 'block' : 'none' }}>
                        <div className='item-holder-inner'>
                          <h4 className=''>
                            BOOPATHI RAJA S
                          </h4>
                          <div className=''>
                            <FontAwesomeIcon icon={faPhone} className='mr-3' />
                            +91 8169361089
                          </div>
                          <div className=''>
                            <FontAwesomeIcon icon={faEnvelopeOpen} className='mr-3' />
                            inspection@ameeendt.com
                          </div>
                          <div className='item-readmore'>
                            <a href='/' className=''>
                              Read More
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='item-inner'>
                    <div className='item-image'>
                      <div className='team-avatar'>
                        <img src='assets/ameee/shiny.jpg' className={isHovered3 ? "colorful-image" : "grayscale-image"}
                          onMouseEnter={() => setIsHovered3(true)}
                          onMouseLeave={() => setIsHovered3(false)} />
                        <div className="overlay"></div>
                      </div>
                      <div className='item-social'>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered3 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faFacebook} className='fa-icon' style={{ color: isHovered3 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered3 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faTwitter} className='fa-icon' style={{ color: isHovered3 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered3 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faInstagram} className='fa-icon' style={{ color: isHovered3 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                        <div className='mb-2'>
                          <a href='/' style={{ backgroundColor: isHovered3 ? 'blue' : 'white' }}>
                            <FontAwesomeIcon icon={faLinkedinIn} className='fa-icon' style={{ color: isHovered3 ? 'white' : 'blue' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='wp-content'>
                      <div className='item-meta'>
                        <div className='line-top-meta'>

                        </div>
                        <h4 className='item--title font-bold'>
                          JESHLY SHINY J
                        </h4>
                        <div className='item-position'>
                          Engineer in Technology
                        </div>
                        <div className='item-readmore font-bold'>
                          <a href='/'>
                            Read more
                            <FontAwesomeIcon icon={faAngleRight} />
                          </a>
                        </div>
                      </div>
                      <div className='item-holder' style={{ display: isHovered3 ? 'block' : 'none' }}>
                        <div className='item-holder-inner'>
                          <h4 className=''>
                            JESHLY SHINY J
                          </h4>
                          <div className=''>
                            <FontAwesomeIcon icon={faPhone} className='mr-3' />
                            +91 8169361089
                          </div>
                          <div className=''>
                            <FontAwesomeIcon icon={faEnvelopeOpen} className='mr-3' />
                            inspection@ameeendt.com
                          </div>
                          <div className='item-readmore '>
                            <a href='/' className=''>
                              Read More
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div className='bgRgb'>
          <Ten />
        </div>
        <div>
          <Foooter />
        </div>
      </div>
    </div>
  )
}
