import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Ultrasonic = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Ultrasonic Testing Services in Chennai |Ultrasonic Testing Services in Padappai |Ultrasonic Testing Services in Mumbai |Ultrasonic Testing Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Ultrasonic Testing services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Ultrasonic Testing Services in Chennai ,Ultrasonic Testing Services in Mumbai ,Ultrasonic Testing Services in Haryana ,Ultrasonic Testing Services in India,Best Ultrasonic Testing services in Padappai,Best Ultrasonic Testing Services in Chennai,Best Ultrasonic Testing Services in Bangalore" />
                <meta name="keywords" content="Best Ultrasonic Testing services in Andhra Pradesh,Best Ultrasonic Testing services in Arunachal Pradesh,Best Ultrasonic Testing services in Assam,Best Ultrasonic Testing services in Bihar,Best Ultrasonic Testing services in Chhattisgarh,Best Ultrasonic Testing services in Goa,Best Ultrasonic Testing services in Gujarat,Best Ultrasonic Testing services in Haryana,
                        Best Ultrasonic Testing services in Himachal Pradesh,Best Ultrasonic Testing services in Jammu and kashmir,Best Ultrasonic Testing services in Jharkhand,Best Ultrasonic Testing services in Karnataka,Best Ultrasonic Testing services in Kerala,Best Ultrasonic Testing services in Madhya Pradesh,Best Ultrasonic Testing services in Maharashtra,Best Ultrasonic Testing services in Manipur,Best Ultrasonic Testing services in Meghalaya,
                    Best Ultrasonic Testing services in Mizoram,Best Ultrasonic Testing services in Nagaland,Best Ultrasonic Testing services in Odisha,Best Ultrasonic Testing services in Punjab,Best Ultrasonic Testing services in Rajasthan,Best Ultrasonic Testing services in Sikkim,Best Ultrasonic Testing services in TamilNadu,Best Ultrasonic Testing services in Telengana,Best Ultrasonic Testing services in Tripura,Best Ultrasonic Testing services in Uttar Pradesh,
                   Best Ultrasonic Testing services in Uttarakhand,Best Ultrasonic Testing services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/ultrasonic.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Ultrasonic-Testing.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/ultrasonic-testing-services.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl group '>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/VisualTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Visual Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PenetrantTesting' className='decoration-transparent  group-hover:text-white text-gray-700 font-medium'>Penetrant Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl group '>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/LeakTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Leak Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-3 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Ultrasonic Testing</h1>
                                    <img src='assets/ameee/ultrasonic-testing-services.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT</strong> offers comprehensive ultrasonic testing (UT) services for accurate and reliable inspection of materials and components across diverse industries.Ultrasonic Testing (UT) is a non-destructive testing (NDT) technique used to inspect the internal structure of materials for defects or discontinuities. It utilizes high-frequency sound waves (ultrasound) that are transmitted into the material being tested.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>
                                            The primary purpose of ultrasonic testing for Horton Sphere storage tanks is to detect and assess internal defects that may compromise structural integrity and containment capability. By identifying flaws early, ultrasonic testing facilitates proactive maintenance and repair strategies, reducing the risk of leaks, spills, or other hazardous incidents.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                  <div>
                                  <div className='' style={{ width: '330px' }}>
                                        <img src='assets/ameee/ultrasonic.jpg' className=' border rounded-lg'  />
                                    </div>
                                  </div>
                                    <div className='text-justify '>
                                        <div>
                                            <div className=' ml-10'>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className=' text-gray-500 text-lg'>
                                                    Since the late 1940s, ultrasonic testing has evolved with technological advancements, becoming a prominent non-destructive testing (NDT) method. It's widely utilized across industries to inspect structural integrity, including in storage tanks like Horton Spheres.
                                                </p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg '>
                                                    Ultrasonic testing (UT) uses high-frequency sound waves to detect flaws in materials. In Horton Sphere storage tanks, UT involves sending ultrasonic waves into the tank's structure and analyzing the echoes that bounce back from internal surfaces or defects.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/Ultrasonic Testing.jpeg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/ut3.png' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li ><h4 className='font-bold text-blue-900'  >Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                High accuracy in defect detection.<br /><br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                Non-destructive nature allows for inspection without damaging the tank.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                Can detect internal flaws regardless of material thickness.<br />
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                Requires trained personnel to operate specialized equipment and interpret results accurately.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                Surface preparation is necessary for effective testing.<br /><br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                Limited to materials that allow sound waves to propagate effectively.
                                            </li><br/><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                               <div>
                               <div className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </div>
                               </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div className='ml-2'>
                                            <ul className='sm:block md:block lg:flex justify-center mr-5'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className=''>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-2'>
                                        <ul className='sm:block md:block lg:flex justify-center mr-5'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className=''>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/Ultrasonic-Testing.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>

        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultrasonic Testing -How We Work</h4>
                    <p className=' font-semibold'>
                        Ultrasonic Testing, conducted by AMEEE NDT, is vital for identifying internal flaws within materials. We utilize high-frequency sound waves to penetrate the material, analyzing echo patterns for indications like cracks and thickness variations.
                        Our detailed reports provide valuable information for maintenance scheduling and asset management, guaranteeing the reliability and safety of crucial components.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultrasonic Testing Equipment & Consumables</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT provides a diverse array of equipment, accessories, and consumables tailored for ultrasonic testing.
                        Our offerings encompass ultrasonic transducers, flaw detectors, couplant, calibration blocks, inspection software, protective gear, and inspection enclosures.
                        Moreover, we furnish training resources, documentation aids, and calibration standards to uphold the accuracy and dependability of testing protocols.
                        Through our comprehensive solutions, we empower organizations to execute ultrasonic testing with exactitude and effectiveness.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultrasonic Testing Standards</h4>
                    <p className=' font-semibold'>
                        UT inspections of Horton Sphere storage tanks are typically conducted in accordance with
                        industry standards such as API 650 for welded steel tanks or API 620 for large, low-pressure
                        storage tanks.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Ultrasonic Testing Parameters</h4>
                    <p className=' font-semibold'>
                        During ultrasonic testing, we evaluate multiple parameters such as probe selection, material coupling, signal calibration, scanning techniques, and inspection conditions.
                        Our meticulous assessment ensures effective flaw detection and reliable outcomes, enabling informed decisions regarding maintenance and asset integrity management.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Defects Detected by Ultrasonic Testing</h4>
                    <p className=' font-semibold'>
                        Through ultrasonic testing, AMEEE NDT proficiently identifies defects including internal cracks, thickness variations, and material anomalies.
                        Our precise testing procedures ensure comprehensive defect detection, enabling proactive maintenance and risk mitigation strategies.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Ultrasonic Testing</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT follows industry-standard guidelines like ASTM E317 and ASNT SNT-TC-1A for ultrasonic testing.
                        Our commitment to these benchmarks ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Ultrasonic
