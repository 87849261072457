import React from 'react'
import First from './First'
import Civil from './Civil'
import Advance from './Advance'
import Welding from './Welding'
import Coat from './Coat'
import Conventional from './Conventional'
import { Nine } from './Nine'
import { Helmet } from 'react-helmet'
function Civilndt() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Civil NDT Services in Chennai |Civil NDT Services in Padappai |Civil NDT Services in Mumbai |Civil NDT Services in Haryana |Civil NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Civil NDT services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Civil NDT Services in Chennai ,Civil NDT Services in Mumbai ,Civil NDT Services in Haryana ,Civil NDT Services in India,Best Civil NDT services in Padappai,Best Civil NDT Services in Chennai,Best Civil NDT Services in Bangalore " />
                <meta name="keywords" content="Best Civil NDT services in Andhra Pradesh,Best Civil NDT services in Arunachal Pradesh,Best Civil NDT services in Assam,Best Civil NDT services in Bihar,Best Civil NDT services in Chhattisgarh,Best Civil NDT services in Goa,Best Civil NDT services in Gujarat,Best Civil NDT services in Haryana,
                    Best Civil NDT services in Himachal Pradesh,Best Civil NDT services in Jammu and kashmir,Best Civil NDT services in Jharkhand,Best Civil NDT services in Karnataka,Best Civil NDT services in Kerala,Best Civil NDT services in Madhya Pradesh,Best Civil NDT services in Maharashtra,Best Civil NDT services in Manipur,Best Civil NDT services in Meghalaya,
                  Best Civil NDT services in Mizoram,Best Civil NDT services in Nagaland,Best Civil NDT services in Odisha,Best Civil NDT services in Punjab,Best Civil NDT services in Rajasthan,Best Civil NDT services in Sikkim,Best Civil NDT services in TamilNadu,Best Civil NDT services in Telengana,Best Civil NDT services in Tripura,Best Civil NDT services in Uttar Pradesh,
                  Best Civil NDT services in Uttarakhand,Best Civil NDT services in West Bengal, Civil NDT services in Andhra Pradesh,Civil NDT services in Arunachal Pradesh,Civil NDT services in Assam, Civil NDT services in Bihar,Civil NDT services in Chhattisgarh,Civil NDT services in Goa, Civil NDT services in Gujarat,Civil NDT services in Haryana,
                  Civil NDT services in Himachal Pradesh,Civil NDT services in Jammu and kashmir,Civil NDT services in Jharkhand, Civil NDT services in Karnataka, Civil NDT services in Kerala,Civil NDT services in Madhya Pradesh,Civil NDT services in Maharashtra,Civil NDT services in Manipur, Civil NDT services in Meghalaya,
                  Civil NDT services in Mizoram,Best Civil NDT services in Nagaland,Civil NDT services in Odisha,Civil NDT services in Punjab,Civil NDT services in Rajasthan,Civil NDT services in Sikkim,Civil NDT services in TamilNadu,Civil NDT services in Telengana,Civil NDT services in Tripura,Civil NDT services in Uttar Pradesh,
                  Civil NDT services in Uttarakhand, Civil NDT services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className=' z-10  w-full' style={{ backgroundColor: '#020225' }}>
                <First />
            </div>
            <div>
                <Civil />
                <div>
                    <h4 className='text-center text-5xl font-bold text-blue-900'>Explore Other Services</h4>
                    <Conventional />
                    <Advance />
                    <Welding />
                    <Coat />
                </div>
                <Nine />
            </div>
        </div>
    )
}

export default Civilndt
