import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Wps = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Welding Inspection Services in Chennai |Welding Inspection Services in Padappai |Welding Inspection Services in Mumbai |Welding Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Welding Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Welding Inspection Services in Chennai ,Welding Inspection Services in Mumbai ,Welding Inspection Services in Haryana ,Welding Inspection Services in India,Best Welding Inspection services in Padappai,Best Welding Inspection Services in Chennai,Best Welding Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Welding Inspection services in Andhra Pradesh,Best Welding Inspection services in Arunachal Pradesh,Best Welding Inspection services in Assam,Best Welding Inspection services in Bihar,Best Welding Inspection services in Chhattisgarh,Best Welding Inspection services in Goa,Best Welding Inspection services in Gujarat,Best Welding Inspection services in Haryana,
                        Best Welding Inspection services in Himachal Pradesh,Best Welding Inspection services in Jammu and kashmir,Best Welding Inspection services in Jharkhand,Best Welding Inspection services in Karnataka,Best Welding Inspection services in Kerala,Best Welding Inspection services in Madhya Pradesh,Best Welding Inspection services in Maharashtra,Best Welding Inspection services in Manipur,Best Welding Inspection services in Meghalaya,
                    Best Welding Inspection services in Mizoram,Best Welding Inspection services in Nagaland,Best Welding Inspection services in Odisha,Best Welding Inspection services in Punjab,Best Welding Inspection services in Rajasthan,Best Welding Inspection services in Sikkim,Best Welding Inspection services in TamilNadu,Best Welding Inspection services in Telengana,Best Welding Inspection services in Tripura,Best Welding Inspection services in Uttar Pradesh,
                   Best Welding Inspection services in Uttarakhand,Best Welding Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/welding.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual1.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border group border-neutral-400 font-bold text-xl'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/WeldingProcedureSpecification' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welding Procedure Specification</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2 '>
                                                    <div>
                                                        <a href='/WeldingProcedureQualificationRecord' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welding Procedure Qualification Record</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/WeldersPerformanceQualificationTest' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welders Performance Qualification Test </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Welding Procedure Specification</h1>
                                    <img src='assets/ameee/welding.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT </strong>specializes in providing comprehensive Welding Procedure Specification (WPS) services, ensuring the quality, integrity, and compliance of welding processes across diverse industries.A Welding Procedure Specification (WPS) is a document that details the welding parameters and procedures required to produce a weld that meets specific quality and performance criteria, ensuring consistency and compliance with industry standards and codes.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Welding Procedure Specification (WPS) testing is conducted to establish guidelines and parameters for welding processes. By defining welding procedures, it ensures consistency, quality, and safety in welding operations. WPS testing involves evaluating factors such as material compatibility, welding techniques, and post-welding inspections, aiming to optimize weld quality and performance.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                <div>
                                <div className='' style={{ width: '330px' }}>
                                        <img src='assets/ameee/weldingvisual1.jpg' className=' border rounded-lg'  />
                                    </div>
                                </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <div>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className='text-gray-500 text-lg'>Welding Procedure Specification (WPS) has its roots in the industrial development of welding practices, emerging as a structured approach to standardize welding procedures. Initially devised to ensure consistency and quality in welding operations, WPS gradually evolved to become an integral part of welding standards and regulations across industries.</p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg'>Welding Procedure Specification (WPS) outlines the detailed parameters and procedures for specific welding tasks, including materials, welding techniques, pre-weld and post-weld treatments, and inspection requirements. By defining standardized procedures, WPS ensures uniformity, quality, and safety in welding operations, facilitating efficient and reliable welds across diverse applications.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/weldingvisual1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/weldingvisual.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Consistency:</b> WPS ensures that welding processes are performed consistently, leading to uniform quality across multiple welds and projects.<br /><br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Quality Assurance:</b>   By specifying welding parameters and procedures, WPS helps maintain high-quality welds that meet industry standards and regulatory requirements.<br /><br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Compliance:</b> WPS ensures compliance with relevant codes and standards, such as those set by organizations like the American Welding Society (AWS) or the International Organization for Standardization (ISO).
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Rigidity: </b> Strict adherence to WPS may limit flexibility in adapting to specific project requirements or changing conditions, potentially hindering innovation or optimization. <br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Complexity:</b> Developing and maintaining WPS documentation can be time-consuming and resource-intensive, especially for complex welding processes or specialized applications.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Cost:</b>  Implementation of WPS may involve additional costs related to documentation, training, and certification processes, particularly for smaller businesses or projects. </li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                            <div>
                            <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/welding.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Procedure Specification - How We Work</h4>
                    <p className='font-semibold'>Welding Procedure Specification (WPS), executed by AMEEE NDT, is pivotal for establishing standardized guidelines and parameters for welding processes. We meticulously analyze welding requirements, material properties, and regulatory standards to develop tailored WPS documents.
                        Our comprehensive reports outline welding procedures, inspection criteria, and quality assurance measures, ensuring consistency, quality, and compliance in welding operations across various industries.</p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Procedure Specification Equipment & Resources</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, resources, and documentation tailored specifically for welding procedure specification (WPS).
                        Our product lineup includes WPS development software, welding parameter recorders, welding procedure specification templates, and welding procedure qualification record (PQR) forms.
                        Additionally, we provide training materials, technical support, and consulting services to assist organizations in developing and implementing effective welding procedures.
                        With our dedicated support and expertise, we empower organizations to establish standardized and reliable welding practices, ensuring quality and compliance in welding operations.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Procedure Specification Standards</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as AWS D1.1 and ASME Section IX for welding procedure specification.
                        Our commitment to these standards ensures the consistency, quality, and safety of welding operations, supporting compliance and best practices in the welding industry.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Procedure Specification Parameters</h4>
                    <p className='font-semibold'>
                        In welding procedure specification, we evaluate parameters such as welding techniques, material properties, pre-weld and post-weld treatments, and inspection criteria.
                        Our thorough analysis ensures the development of standardized and effective welding procedures, promoting consistency, quality, and safety in welding operations across diverse industries.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Anomalies Detected by Welding Procedure Specification</h4>
                    <p className='font-semibold'>
                        Through welding procedure specification, AMEEE NDT proficiently identifies anomalies such as welding defects, inconsistencies in welding parameters, and deviations from welding standards.
                        Our rigorous testing protocols ensure thorough anomaly detection, enabling corrective actions and quality improvements in welding processes for diverse industrial applications.
                    </p>

                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Welding Procedure Specification</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard guidelines and specifications such as AWS D1.1 and ASME Section IX for welding procedure specification.
                        Our commitment to these references ensures the consistency, quality, and safety of our welding procedures, supporting compliance and best practices in welding operations across various industries.
                    </p>

                </div>
            </div>
        </div>
    );
}

export default Wps
