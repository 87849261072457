import { faBuilding, faHardHat, faMountain, faPaintRoller, faTools, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import '../Header.css'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
function Tree() {
  const options = {
    items: 5,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      100: {
        items: 2
      },
      200: {
        items: 3
      }
      ,
      300: {
        items: 4
      },
      1000: {
        items: 5
      }
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const CarouselItem = ({ link, icon, text, isHovered, setIsHovered }) => {
    return (
      <div className='text-center p-3 text-white' style={{
        borderRadius: '4px', height: '100px', outlineOffset: '-10px', position: 'relative', backgroundColor: isHovered ? "" : 'black',
        transition: 'background-color 0.3s ease'
      }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div className='flex justify-center pt-3 '>
          <div className='pr-3'> <FontAwesomeIcon icon={icon} className='text-3xl' /> </div>
          <div className='font-bold'><a href={link} className='decoration-transparent text-white'>{text}</a></div>
        </div>
      </div>
    );
  };


  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <Container>
          <div className='row p-5   '>
            <OwlCarousel options={options} >
              <div className='mr-4'>
                <div className="darksoul-gradient-card2 p-0.5">
                  <div className="darksoul-content-card ">
                    <CarouselItem link='/CoventionalNDTServices' icon={faWrench} text='Conventional NDT' isHovered={isHovered} setIsHovered={setIsHovered} />
                  </div>
                </div>
              </div>
              <div className='mr-4'>
                <div className="darksoul-gradient-card2 p-0.5">
                  <div className="darksoul-content-card">
                    <CarouselItem link='AdvanceNDTServices' icon={faTools} text='Advance  NDT' isHovered={isHovered1} setIsHovered={setIsHovered1} />
                  </div>
                </div>
              </div>
              <div className='mr-4'>
                <div className="darksoul-gradient-card2 p-0.5">
                  <div className="darksoul-content-card">
                    <CarouselItem link='WeldingInspection' icon={faHardHat} text='Welding Inspection' isHovered={isHovered2} setIsHovered={setIsHovered2} />
                  </div>
                </div>
              </div>
              <div className=''>
                <div className="darksoul-gradient-card2 p-0.5">
                  <div className="darksoul-content-card">
                    <CarouselItem link='RopeAccess' icon={faMountain} text='Rope Access' isHovered={isHovered3} setIsHovered={setIsHovered3} />
                  </div>
                </div>
              </div>
              <div className=''>
                <div className="darksoul-gradient-card2 p-0.5">
                  <div className="darksoul-content-card">
                    <CarouselItem link='CoatingInspection' icon={faPaintRoller} text='Coating Inspection' isHovered={isHovered4} setIsHovered={setIsHovered4} />
                  </div>
                </div>
              </div>
              <div className=''>
                <div className="darksoul-gradient-card2 p-0.5 ">
                  <div className="darksoul-content-card">
                    <CarouselItem link='CivilNDT' icon={faBuilding} text='Civil NDT' isHovered={isHovered5} setIsHovered={setIsHovered5} />
                  </div>
                </div>
              </div>
            </OwlCarousel>

          </div>
        </Container>
      </div>
    </div>
  )
}

export default Tree
