import React, { useState, useEffect } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import Advance from './Advance'
import Civil from './Civil'
import Welding from './Welding'
import Coat from './Coat'
import Rope from './Rope'
import { Carousel, Nav } from 'react-bootstrap';
import Quote from './Quote'
import Quotation from './Quotation'
import Work from './Work'
import { Eight } from '../Eight'
import Testimony from './Testimony'
import Foooter from './Footer'
import Conventional from './Conventional'
import { Helmet } from 'react-helmet'

function Services() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>Services Offered by AMEEE NDT</title>
                <meta name="description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including conventional NDT services,Advance NDT services,third-party inspection, rope access, coating inspection, and welding services." />
                <meta name="keywords" content="AMEEE NDT services, NDT testing services,best Conventional NDT services in India,Advance NDT Services in India,Best NDT services in India,testing & Inspection ,best UT services in India, non-destructive testing, Padappai, third-party inspection, rope access, coating inspection, welding services,Best Ndt services in Chennai,leading service provider" />
                <meta property="og:title" content="Services Offered by AMEEE NDT" />
                <meta property="og:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta name="twitter:title" content="Services Offered by AMEEE NDT" />
                <meta name="twitter:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
                <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='bg-slate-100'>

                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>

                   <div className='pt-40'>
                   <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/ameee/ultrasonic.jpg"
                                alt="First slide"
                                style={{ height: '400px' }}
                            />
                            {/* <Carousel.Caption>
                                <ul className='flex text-white text-2xl font-bold'>
                                    <li>Home <FontAwesomeIcon icon={faAngleDoubleRight} /></li>
                                    <li>Services</li>
                                </ul>
                                <h1 className='text-white font-bold text-left ml-8'>What We Do</h1>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/ameee/coat.jpg"
                                alt="First slide"
                                style={{ height: '400px' }}
                            />
                            {/* <Carousel.Caption>
                                <ul className='flex text-white text-2xl font-bold'>
                                    <li>Home <FontAwesomeIcon icon={faAngleDoubleRight} /></li>
                                    <li>Services</li>
                                </ul>
                                <h1 className='text-white font-bold text-left ml-8'>What We Do</h1>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="assets/ameee/rope.jpg"
                                alt="First slide"
                                style={{ height: '400px' }}
                            />
                            {/* <Carousel.Caption>
                                <ul className='flex text-white text-2xl font-bold'>
                                    <li>Home <FontAwesomeIcon icon={faAngleDoubleRight} /></li>
                                    <li>Services</li>
                                </ul>
                                <h1 className='text-white font-bold text-left ml-8'>What We Do</h1>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                    </Carousel>
                   </div>
                </div>
                <div id='section1'>
                    <Conventional />
                </div>
                <div id='section2'>
                    <Advance />
                </div>
                <div id='section3'>
                    <Civil />
                </div>
                <div>
                    <Welding />
                </div>
                <div>
                    <Coat />
                </div>
                {/* <div>
                <Rope />
            </div> */}
                <div>
                    <Quotation />
                </div>
                <div>
                    <Work />
                </div>
                <div >
                    <Eight />
                </div>
                <div className=''>
                    <Testimony />
                </div>
                <div style={{ backgroundImage: 'url(assets/ameee/ultrasonic.jpg)' }}>
                    <Foooter />
                </div>
            </div>

        </div>
    )
}

export default Services
