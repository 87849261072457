import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Valve3() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Ultrasonic Testing Services in Valve Testing | Ultrasonic Testing Services in Padappai | Ultrasonic Testing Services in Mumbai | Ultrasonic Testing Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Ultrasonic Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert ultrasonic testing services!" />
                <meta name="title" content="Ultrasonic Testing Services in Valve Testing, Ultrasonic Testing Services in Mumbai, Ultrasonic Testing Services in Haryana, Ultrasonic Testing Services in India, Best Ultrasonic Testing Services in Padappai, Best Ultrasonic Testing Services in Chennai, Best Ultrasonic Testing Services in Bangalore" />
                <meta name="keywords" content="Best Ultrasonic Testing Services in Andhra Pradesh, Best Ultrasonic Testing Services in Arunachal Pradesh, Best Ultrasonic Testing Services in Assam, Best Ultrasonic Testing Services in Bihar, Best Ultrasonic Testing Services in Chhattisgarh, Best Ultrasonic Testing Services in Goa, Best Ultrasonic Testing Services in Gujarat, Best Ultrasonic Testing Services in Haryana, Best Ultrasonic Testing Services in Himachal Pradesh, Best Ultrasonic Testing Services in Jammu and Kashmir, Best Ultrasonic Testing Services in Jharkhand, Best Ultrasonic Testing Services in Karnataka, Best Ultrasonic Testing Services in Kerala, Best Ultrasonic Testing Services in Madhya Pradesh, Best Ultrasonic Testing Services in Maharashtra, Best Ultrasonic Testing Services in Manipur, Best Ultrasonic Testing Services in Meghalaya, Best Ultrasonic Testing Services in Mizoram, Best Ultrasonic Testing Services in Nagaland, Best Ultrasonic Testing Services in Odisha, Best Ultrasonic Testing Services in Punjab, Best Ultrasonic Testing Services in Rajasthan, Best Ultrasonic Testing Services in Sikkim, Best Ultrasonic Testing Services in Tamil Nadu, Best Ultrasonic Testing Services in Telangana, Best Ultrasonic Testing Services in Tripura, Best Ultrasonic Testing Services in Uttar Pradesh, Best Ultrasonic Testing Services in Uttarakhand, Best Ultrasonic Testing Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Ultrasonic Testing Services in Valve Testing" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive Ultrasonic Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert ultrasonic testing services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Ultrasonic Testing Services in Valve Testing" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive Ultrasonic Testing services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert ultrasonic testing services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full  '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Ultrasonic Testing- Valve Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Ultrasonic Testing Services in Valve Testing</strong> are offered by AMEEE NDT, a leading provider of comprehensive testing solutions. Our specialized team conducts meticulous ultrasonic testing processes in valve testing environments. Utilizing advanced technology and expertise, we ensure precise ultrasonic inspection of valve materials and components in various industrial settings. Our ultrasonic testing services enable us to detect internal flaws, defects, and irregularities in valves through high-frequency sound waves, ensuring compliance with industry standards and specifications.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Ultrasonic Testing in Valve Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Ultrasonic testing in valve testing serves the critical purpose of detecting internal flaws, defects, and irregularities in valve materials and components through high-frequency sound waves. This non-destructive testing method allows us to assess the structural integrity of valves, including welds and critical areas, without causing damage to the tested components. By conducting ultrasonic testing, our services aim to ensure the reliability, safety, and performance of valves used in industrial applications.</p>
                                    </div>




                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Ultrasonic Testing in Valve Testing</h4>
                                            <p className='text-gray-500 text-lg'>Ultrasonic testing in valve testing has a significant historical background within the valve manufacturing and industrial sectors. As the demand for reliable and safe valve systems has increased, ultrasonic testing techniques have evolved to ensure the quality and integrity of valves used in various applications. The historical development of ultrasonic testing methods has been instrumental in enhancing the detection of internal flaws and defects, thereby improving the overall performance and safety of valve systems.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Ultrasonic Testing in Valve Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Ultrasonic testing in valve testing utilizes high-frequency sound waves to detect internal flaws, defects, and irregularities in valve materials and components. By emitting ultrasonic waves into the material and analyzing the reflected signals, this non-destructive testing method provides valuable insights into the structural integrity of valves without causing damage to the tested components. Through precise interpretation of ultrasonic testing results, manufacturers and industries can ensure the reliability and safety of valves in diverse industrial applications.</p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Non-Destructive Inspection:</b> Ultrasonic testing allows for thorough inspection of valve components without causing damage, providing valuable insights into their integrity and potential defects.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Early Detection of Flaws:</b> By detecting flaws such as cracks or inclusions early on, ultrasonic testing helps prevent potential failures and ensures the reliability of valve operation.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Accurate Evaluation:</b> Ultrasonic testing provides precise measurements and evaluations of material properties, aiding in quality control and optimization of valve performance.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Equipment Complexity:</b> Ultrasonic testing equipment and procedures can be complex, requiring trained personnel and specialized tools for accurate and reliable results.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface Finish Impact:</b> Ultrasonic testing may have limitations in detecting flaws near the surface or in highly polished valve components, potentially affecting inspection accuracy.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cost Considerations:</b> Implementing ultrasonic testing may involve initial setup costs and ongoing expenses for maintenance and training, which should be weighed against the benefits for each application.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px', height: '570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Ultrasonic Testing in Valve Testing Process - How We Work</h4>
                    <p className='font-semibold'>Ultrasonic testing in valve testing at AMEEE NDT involves a meticulous approach to ensure the integrity and reliability of valve components. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Initial Assessment: Conducting a comprehensive assessment of valve components to evaluate their integrity and identify any flaws or defects using ultrasonic testing techniques.</li>
                        <li>Testing Planning: Developing a customized ultrasonic testing plan based on valve material, geometry, and inspection requirements to optimize flaw detection.</li>
                        <li>Testing Execution: Performing ultrasonic testing with advanced equipment and techniques, ensuring thorough coverage and accurate detection of flaws, cracks, or abnormalities.</li>
                        <li>Quality Assurance: Implementing rigorous quality assurance protocols during ultrasonic testing to maintain accuracy, reliability, and compliance with industry standards.</li>
                        <li>Data Analysis: Analyzing ultrasonic test results to assess the severity and location of detected flaws, guiding decisions on repair, replacement, or further inspection.</li>
                        <li>Documentation and Reporting: Documenting all ultrasonic testing procedures, findings, and conclusions in detailed reports for audit trails and regulatory compliance.</li>
                    </ul>
                </div>


            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Ultrasonic Testing in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a wide array of equipment, accessories, and consumables specifically tailored for ultrasonic testing in valve testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Ultrasonic Testing Equipment: State-of-the-art ultrasonic flaw detectors, transducers, and probes designed for accurate detection and characterization of defects in valve components.</li>
                        <li>Testing Accessories: Fixturing and scanning systems, couplants, and wedges optimized for efficient and reliable ultrasonic testing of valves, ensuring thorough coverage and precise results.</li>
                        <li>Consumables: Ultrasonic couplants, calibration standards, and reference blocks calibrated for maintaining the performance and accuracy of ultrasonic testing equipment and procedures.</li>
                        <li>Data Analysis Tools: Software solutions for processing, analyzing, and interpreting ultrasonic test data, facilitating defect sizing, characterization, and reporting.</li>
                        <li>Personal Protective Equipment (PPE): Safety gear including gloves, goggles, and hearing protection to ensure the safety of personnel involved in ultrasonic testing operations.</li>
                        <li>Training Resources: Comprehensive training programs, workshops, and educational materials to enhance the skills and knowledge of personnel in ultrasonic testing techniques and procedures.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our extensive range of equipment and resources, we provide everything necessary to perform precise and reliable ultrasonic testing in valve testing applications.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Ultrasonic Testing in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT strictly adheres to industry-recognized standards and codes tailored for ultrasonic testing in valve testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASTM E213 Standard Practice for Ultrasonic Testing of Metal Pipe and Tubing</li>
                        <li>ASME B&PV Code, Section V: Nondestructive Examination</li>
                        <li>ISO 16811 Non-destructive Testing – Ultrasonic Testing – General Principles</li>
                    </ul>
                    <p className='font-semibold'>
                        Our adherence to these standards ensures the precision, reliability, and safety of our ultrasonic testing procedures in valve testing. This helps to identify defects accurately and optimize the integrity of valve components for various industrial applications.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Ultrasonic Testing in Valve Testing</h4>
                    <p className='font-semibold'>
                        During ultrasonic testing in valve testing, AMEEE NDT evaluates various parameters to ensure accurate detection and characterization of defects in valve components. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Transducer Selection: Choosing appropriate transducers based on frequency, size, and configuration to optimize flaw detection sensitivity and resolution.</li>
                        <li>Probe Angulation: Adjusting the angle and orientation of the ultrasonic probe to maximize flaw detection capability, especially for complex valve geometries.</li>
                        <li>Ultrasonic Coupling: Ensuring proper coupling between the transducer and the valve surface to facilitate efficient transmission and reception of ultrasonic waves.</li>
                        <li>Calibration: Calibrating ultrasonic equipment and settings to a reference standard to maintain accuracy and reliability in defect sizing and characterization.</li>
                        <li>Signal Analysis: Analyzing ultrasonic waveforms and echoes to distinguish between flaw signals and background noise, enabling accurate defect interpretation.</li>
                        <li>Scanning Technique: Employing appropriate scanning techniques, such as pulse-echo or through-transmission, to ensure thorough coverage and inspection of valve components.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our meticulous evaluation of these parameters ensures the effectiveness and integrity of ultrasonic testing processes in valve testing, leading to enhanced reliability and performance of valve materials across various industrial applications.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Ultrasonic Testing in Valve Testing</h4>
                    <p className='font-semibold'>
                        During ultrasonic testing processes conducted by AMEEE NDT as part of valve testing, we have the capability to identify various anomalies and deviations, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Cracks: Detecting cracks, fissures, or discontinuities within valve components that may compromise structural integrity and reliability.</li>
                        <li>Inclusions: Identifying foreign materials or inclusions embedded within the material matrix, which may weaken the valve component or lead to failure under stress.</li>
                        <li>Porosity: Detecting voids, gas pockets, or pores within the material that may reduce mechanical strength and increase susceptibility to corrosion or fatigue.</li>
                        <li>Bonding Issues: Recognizing inadequate bonding or bonding defects between different material layers or interfaces, which may affect component performance and durability.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous ultrasonic testing processes and adherence to stringent quality standards, we ensure accurate detection and characterization of defects in valve components. This facilitates the identification of potential issues early on, ensuring the reliability and safety of valve systems in industrial applications.
                    </p>
                </div>

            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Ultrasonic Testing in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for ultrasonic testing in valve testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME B16.34 Valves - Flanged, Threaded, and Welding End: A standard for valve materials, dimensions, tolerances, and marking requirements, ensuring compliance with material specifications.</li>
                        <li>ASTM E213 Standard Practice for Ultrasonic Testing of Metal Pipe and Tubing: Provides guidelines for ultrasonic testing procedures, equipment, and acceptance criteria for detecting defects in valve components.</li>
                        <li>ISO 16811 Non-destructive Testing – Ultrasonic Testing – General Principles: Offers general principles and guidelines for conducting ultrasonic testing, including equipment calibration, probe selection, and data interpretation.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our ultrasonic testing processes in valve testing, ultimately contributing to the integrity and performance of critical valve components.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Valve3
