import React, { useState } from 'react';
import { faMessage, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import FancyText from '@carefully-coded/react-text-gradient';
import axios from 'axios';
function Quote() {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    subject: '',
    message: ''
  });


  // const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://server66.hostingraja.org/submit-quote-form', formData);

      if (response.status === 200) {
        console.log('Form data saved successfully!');
        window.alert('Thank You for Contacting AMEEE NDT!');
        // Handle success (e.g., show a success message to the user)
      }
    } catch (error) {
      console.error('Error saving form data:', error);
      window.alert('An error occurred while saving form data. Please try again.'); 
      // Handle error (e.g., show an error message to the user)
    }
  };
  

  return (
    <div className='container p-5'>
      <div className='text-center'>

        <FancyText
          gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
          animate
          animateDuration={5000}
        >
          <h1 className=' text-7xl p-3'>
            Request For Quote
          </h1>
        </FancyText>


      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 p-5'>
        <div className='' style={{ borderRadius: '4px' }}>
          <form onSubmit={handleSubmit}>
            <input type='text' name='firstName' value={formData.firstName} onChange={handleChange} placeholder='First Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
            <input type='text' name='lastName' value={formData.lastName} onChange={handleChange} placeholder='Last Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
            <input type='email' name='email' value={formData.email} onChange={handleChange} placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
            <input type='tel' name='tel' value={formData.tel} onChange={handleChange} placeholder='Phone Number' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
            <input type='text' name='subject' value={formData.subject} onChange={handleChange} placeholder='Subject' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required />
            <textarea name='message' value={formData.message} onChange={handleChange} placeholder='Message' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} required></textarea>
            <button type='submit' className='darksoul-gradient-card6 lg:ml-40 p-2 text-white  font-bold ' style={{ borderRadius: '50px', boxShadow: '0px 5px 5px blue' }}><span className='ml-3'>Send Message</span></button>
          </form>
          {/* {isSent && <p className="text-green-500">Email sent successfully!</p>} */}
        </div>
        <div className=' border-1 p-5 text-center' style={{ borderRadius: '4px' }}>
          <img src='assets/ameee/ameeelogo.png' alt='' className='lg:w-full lg:h-48 mb-5 sm:w-36 sm:h-24' style={{ backgroundColor: '#eaff99' }} /><br />
          <div>
            <h3 className='text-white md:text-xl lg:text-3xl font-bold'><FontAwesomeIcon icon={faPhone} className='text-blue-500' />&nbsp;<a href='tel:+91 8169361089' style={{ textDecoration: 'none', color: 'white' }}>+91 8169361089</a> </h3>
            <p className='text-white sm:text-xl md:text-xl lg:text-3xl font-bold'>Contact us now to receive a personalized quote tailored to your needs.</p>
            <button className='darksoul-gradient-card5 lg:ml-32 mt-5 p-2  font-bold ' style={{ borderRadius: '50px', boxShadow: '0px 5px 5px blue' }}><a href='https://web.whatsapp.com/send?phone=8110877771' target='blank' className='lg:text-xl lg:ml-8 sm:ml-0' style={{ color: 'white', textDecoration: 'none' }}>Live Chat | <FontAwesomeIcon icon={faMessage} /></a></button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Quote;
