import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Reactor1() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Visual Inspection in Reactor Testing Services | Visual Inspection in Reactor Testing Services in Chennai | Visual Inspection in Reactor Testing Services in Padappai | Visual Inspection in Reactor Testing Services in Mumbai | Visual Inspection in Reactor Testing Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Visual Inspection in Reactor Testing services in Padappai, India. We offer reliable testing solutions for industries like nuclear, power generation, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Visual Inspection in Reactor Testing Services in Chennai, Visual Inspection in Reactor Testing Services in Mumbai, Visual Inspection in Reactor Testing Services in Haryana, Visual Inspection in Reactor Testing Services in India, Best Visual Inspection in Reactor Testing Services in Padappai, Best Visual Inspection in Reactor Testing Services in Chennai, Best Visual Inspection in Reactor Testing Services in Bangalore" />
                <meta name="keywords" content="Best Visual Inspection in Reactor Testing Services in Andhra Pradesh, Best Visual Inspection in Reactor Testing Services in Arunachal Pradesh, Best Visual Inspection in Reactor Testing Services in Assam, Best Visual Inspection in Reactor Testing Services in Bihar, Best Visual Inspection in Reactor Testing Services in Chhattisgarh, Best Visual Inspection in Reactor Testing Services in Goa, Best Visual Inspection in Reactor Testing Services in Gujarat, Best Visual Inspection in Reactor Testing Services in Haryana, Best Visual Inspection in Reactor Testing Services in Himachal Pradesh, Best Visual Inspection in Reactor Testing Services in Jammu and Kashmir, Best Visual Inspection in Reactor Testing Services in Jharkhand, Best Visual Inspection in Reactor Testing Services in Karnataka, Best Visual Inspection in Reactor Testing Services in Kerala, Best Visual Inspection in Reactor Testing Services in Madhya Pradesh, Best Visual Inspection in Reactor Testing Services in Maharashtra, Best Visual Inspection in Reactor Testing Services in Manipur, Best Visual Inspection in Reactor Testing Services in Meghalaya, Best Visual Inspection in Reactor Testing Services in Mizoram, Best Visual Inspection in Reactor Testing Services in Nagaland, Best Visual Inspection in Reactor Testing Services in Odisha, Best Visual Inspection in Reactor Testing Services in Punjab, Best Visual Inspection in Reactor Testing Services in Rajasthan, Best Visual Inspection in Reactor Testing Services in Sikkim, Best Visual Inspection in Reactor Testing Services in Tamil Nadu, Best Visual Inspection in Reactor Testing Services in Telangana, Best Visual Inspection in Reactor Testing Services in Tripura, Best Visual Inspection in Reactor Testing Services in Uttar Pradesh, Best Visual Inspection in Reactor Testing Services in Uttarakhand, Best Visual Inspection in Reactor Testing Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full  '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Visual Inspection- Reactor Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Remote Visual Inspection in Reactor Testing and Inspection Services</strong> are offered by AMEEE NDT, a prominent provider of comprehensive non-destructive testing (NDT) solutions. Our dedicated team ensures thorough inspection and assessment of reactors using remote visual inspection techniques across various industrial settings. Through advanced technology and expertise, we conduct remote visual inspection of reactors, enabling us to detect anomalies and assess structural integrity without the need for physical presence. This approach enhances efficiency and safety while maintaining high standards of inspection accuracy and thoroughness.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Remote Visual Inspection in Reactor Testing and Inspection</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Remote visual inspection in reactor testing and inspection serves the crucial purpose of remotely assessing the condition and integrity of reactors without the need for physical intervention. This inspection process enables the detection of anomalies, defects, and structural issues, contributing to the maintenance of reactor safety and reliability. By utilizing advanced visual inspection techniques, remote visual inspection in reactor testing and inspection plays a vital role in upholding industry standards and ensuring efficient and safe operation of reactor systems across various sectors.</p>
                                    </div>



                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                    <div className='' style={{ width: '330px' }} >
                                        <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                    </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Visual Inspection in Reactor Testing and Inspection</h4>
                                            <p className='text-gray-500 text-lg'>Visual inspection in reactor testing and inspection has a rich history dating back several decades in industrial sectors. As manufacturing and engineering practices have evolved, so too have the methods for visually assessing reactor integrity. The introduction of advanced visual inspection technologies has revolutionized the inspection process, enabling more efficient and thorough evaluations of reactor conditions.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Visual Inspection in Reactor Testing and Inspection</h4>
                                            <p className='text-left text-gray-700 text-lg'>Visual inspection in reactor testing and inspection employs advanced techniques to assess the condition of reactors without the need for physical intervention. These methods utilize cameras, borescopes, and other visual inspection tools to detect anomalies, defects, and structural issues in reactors. By ensuring accurate assessment of reactor conditions, this process plays a crucial role in maintaining safety and reliability in industrial reactor systems.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>High accuracy:</b> Visual inspection in reactor testing provides precise assessment of reactor conditions.
                                            </li><br/><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Non-invasive:</b> It does not require physical alteration of the reactors, minimizing downtime and operational disruptions.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Comprehensive coverage:</b> Capable of inspecting reactor conditions thoroughly, ensuring comprehensive inspection coverage.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Equipment complexity:</b> Specialized equipment and trained personnel are required for conducting visual inspection, increasing operational complexity.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface preparation:</b> Proper surface preparation is essential for obtaining accurate results, adding to the inspection time and effort.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Interpretation challenges:</b> Interpretation of visual inspection results requires expertise and may vary among inspectors, leading to potential inconsistencies.
                                            </li>
                                        </ul>
                                    </div>



                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5'>
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Visual Inspection in Reactor Testing and Inspection Process - How We Work</h4>
                    <p className='font-semibold'>Visual inspection in reactor testing and inspection at AMEEE NDT involves a meticulous assessment of reactor integrity using advanced testing techniques. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Conducting visual inspection of reactors using specialized equipment and techniques to assess reactor integrity without physical intervention.</li>
                        <li>Recording testing observations and capturing data to identify anomalies and assess reactor conditions.</li>
                        <li>Analysis of visual inspection results to evaluate reactor integrity and identify any deviations from expected conditions.</li>
                        <li>Preparation of comprehensive reports detailing the testing observations, including any anomalies detected and recommendations for further actions.</li>
                        <li>Strict adherence to safety protocols and standards to ensure the well-being of personnel and the integrity of the testing environment during the inspection process.</li>
                    </ul>
                </div>



            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Visual Inspection in Reactor Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for visual inspection in reactor testing and inspection. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Visual inspection equipment featuring specialized cameras, robotic systems, and viewing apparatus tailored for accurate and efficient detection of defects in reactors.</li>
                        <li>Testing accessories such as lighting systems, extension tools, and cleaning equipment to ensure clear and reliable inspection results in visual inspection.</li>
                        <li>Consumables including cleaning agents, lubricants, and sealing materials optimized for enhancing the effectiveness of visual inspection procedures.</li>
                        <li>Calibration standards calibrated for the specific requirements of visual inspection equipment, essential for verifying inspection accuracy.</li>
                        <li>Advanced software for image analysis, defect identification, and report generation to streamline the interpretation and reporting of visual inspection results.</li>
                        <li>Personal protective equipment (PPE) for inspection personnel, including gloves, goggles, and safety apparel, to ensure their safety during visual inspection procedures.</li>
                        <li>Training materials and resources to educate personnel on the principles of visual inspection, equipment operation, interpretation of testing data, and safety procedures in reactor testing and inspection.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our comprehensive range of equipment and resources, we provide everything necessary to conduct thorough and accurate visual inspection in reactor testing and inspection.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Visual Inspection in Reactor Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for visual inspection in reactor testing and inspection. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASNT SNT-TC-1A Recommended Practice for Personnel Qualification and Certification in Nondestructive Testing</li>
                        <li>ASTM E543 Standard Specification for Agencies Performing Nondestructive Testing</li>
                        <li>ISO 17636 Non-destructive testing of welds — Radiographic testing of fusion-welded joints</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the accuracy, reliability, and safety of our visual inspection procedures in reactor testing and inspection, helping to safeguard critical assets and infrastructure.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Visual Inspection in Reactor Testing and Inspection</h4>
                    <p className='font-semibold'>
                        During visual inspection in reactor testing and inspection, AMEEE NDT evaluates various parameters to ensure the integrity and reliability of reactors and associated systems. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Material Identification: Analyzing the material composition of reactors remotely to verify material grades or specifications.</li>
                        <li>Defect Detection: Detecting surface and subsurface defects such as cracks, corrosion, or erosion in reactor materials without physical intervention.</li>
                        <li>Alloy Verification: Verifying the alloy content and composition of reactors to ensure they meet specified requirements and standards.</li>
                        <li>Material Traceability: Ensuring traceability of materials used in reactor fabrication and installation to maintain quality control and regulatory compliance.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our comprehensive evaluations provide valuable insights for quality assurance, regulatory compliance, and maintenance planning, ensuring the continued reliability and safety of reactor systems.
                    </p>
                </div>



            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Visual Inspection in Reactor Testing and Inspection</h4>
                    <p className='font-semibold'>
                        During visual inspection in reactor testing and inspection conducted by AMEEE NDT, we have the capability to detect various defects and anomalies, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Material Inconsistencies: Detecting variations in material composition, including deviations from specified alloy grades or elemental concentrations.</li>
                        <li>Surface and Subsurface Defects: Identifying defects such as cracks, corrosion, or erosion on the surface or within the material of reactors without physical intervention.</li>
                        <li>Alloy Verification: Verifying the alloy content and composition of reactors to ensure they meet specified requirements and standards.</li>
                        <li>Material Degradation: Assessing changes in material properties, such as hardness, conductivity, or chemical stability, indicative of degradation or aging.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous visual inspection techniques and rigorous quality standards, we ensure comprehensive detection of material-related issues, facilitating informed decision-making and proactive maintenance to enhance the reliability and safety of reactor systems.
                    </p>
                </div>


            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Visual Inspection in Reactor Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for visual inspection in reactor testing and inspection. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASNT SNT-TC-1A Recommended Practice for Personnel Qualification and Certification in Nondestructive Testing</li>
                        <li>ASTM E570 Standard Practice for Microstructure Examination of Ferromagnetic Steel Tubular Products</li>
                        <li>ISO 17636 Non-destructive testing of welds — Radiographic testing of fusion-welded joints</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our visual inspection processes in reactor testing and inspection, ultimately contributing to the integrity and performance of critical assets.
                    </p>
                </div>



            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Reactor1
