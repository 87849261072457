import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import Second from './second';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Tree from './Tree';
import { Helmet } from 'react-helmet';
import Nav from './Nav';
import '../Header.css'
import Form1 from './Form1';
const PageWithCarousel = ({ carouselItems }) => {

  return (
    <div className=''>
      <Helmet>
        <title>AMEEE NDT - Non-Destructive Testing Services</title>
        <meta name="description" content="AMEEE NDT is a leading provider of non-destructive testing (NDT) services in Padappai. We specialize in delivering high-quality testing solutions for various industries, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="keywords" content="AMEEE NDT, NDT services, Padappai, non-destructive testing,best conventional NDT services in Chennai,best Advance NDT services in Chennai,best civil NDT services in Chennai,third-party inspection, rope access in India , coating inspection in India, welding services" />
        <meta property="og:title" content="AMEEE NDT - Non-Destructive Testing Services" />
        <meta property="og:description" content="AMEEE NDT is a leading provider of non-destructive testing (NDT) services in Padappai. We specialize in delivering high-quality testing solutions for various industries, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT - Non-Destructive Testing Services" />
        <meta name="twitter:description" content="AMEEE NDT is a leading provider of non-destructive testing (NDT) services in Padappai. We specialize in delivering high-quality testing solutions for various industries, including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div style={{ position: 'relative' }}>
        <Carousel interval={9000} indicators={false}>
          {carouselItems.map((item, index) => (
            <Carousel.Item key={index}>
              <div className="relative">
                <img className="d-block bg-fixed w-full h-80 sm:h-auto sm:w-full" src={item.imageSrc} alt={`Slide ${index + 1}`} />
                <div className="absolute inset-0 bg-black opacity-70"></div>
              </div>
              <Carousel.Caption className='top-10 sm:top-1/4 sm:right-0 left-0 bottom-auto' >
                <div className='w-full mt-20 '>

                  <h1 className='font-bold text-xl sm:text-4xl w-full'><Nav /> </h1>
                  <span className='font-bold text-lg sm:text-4xl text-blue-700'>{item.heading}</span>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="absolute text-center top-24 flex right-4 left-4 sm:right-32 sm:left-32 sm:top-1/4 sm:inline ">
          <span className='text topText  text-xs  px-2 font-bold sm:text-lg sm:py-2'>
            <span className='text-white text-xl px-1 font-bold sm:text-6xl sm:px-2 '>.</span><span className='hidden sm:inline'>Setting the Benchmark</span>
            <span className='text-white'> Leaders in NDT Services and Global Inspection Excellence</span><span className='text-white text-xl px-1 font-bold sm:text-6xl sm:px-2'>.</span></span>
        </div>
        <div className='absolute  right-12 left-12  flex justify-center mt-4 top-60 sm:top-1/2  sm:mt-4 sm:right-1/3 sm:left-1/3 '>
          {/* <Button href='Services' variant="outline-primary" className='px-2 mr-10 text-xs sm:px-2 sm:mx-2 hover:underline' style={{ boxShadow: '0 3px 5px' }}>Get Started</Button> */}

          <Form1 href='Services'>Get Started</Form1>
          <a href="Form2" class="vedio-button  ">
            <FontAwesomeIcon icon={faPlay} className='text-3xl' style={{ color: 'white' }} />
          </a>
          {/* <Button href='Services' variant="outline-primary" className='ml-10 px-1  text-xs sm:px-2 sm:mx-2 hover:underline' style={{ boxShadow: '0 3px 5px' }} >    Read More    </Button> */}
          <Form1 href='Services' >Read More</Form1>
        </div>
        <div style={{ position: 'absolute', top: '69%', right: '6%' }} className='hidden md:flex'> {/* Adjust position as needed */}
          <Tree />
        </div>
      </div>

    </div>
  );
};

export default PageWithCarousel;
