
import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Mfl = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
              <Helmet>
                <title>AMEEE NDT |Magnetic Flux Leakage (MFL) Services in Chennai |Magnetic Flux Leakage (MFL) Services in Padappai |Magnetic Flux Leakage (MFL) Services in Mumbai |Magnetic Flux Leakage (MFL) Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Magnetic Flux Leakage (MFL) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Magnetic Flux Leakage (MFL) Services in Chennai ,Magnetic Flux Leakage (MFL) Services in Mumbai ,Magnetic Flux Leakage (MFL) Services in Haryana ,Magnetic Flux Leakage (MFL) Services in India,Best Magnetic Flux Leakage (MFL) services in Padappai,Best Magnetic Flux Leakage (MFL) Services in Chennai,Best Magnetic Flux Leakage (MFL) Services in Bangalore" />
                <meta name="keywords" content="Best Magnetic Flux Leakage (MFL) services in Andhra Pradesh,Best Magnetic Flux Leakage (MFL) services in Arunachal Pradesh,Best Magnetic Flux Leakage (MFL) services in Assam,Best Magnetic Flux Leakage (MFL) services in Bihar,Best Magnetic Flux Leakage (MFL) services in Chhattisgarh,Best Magnetic Flux Leakage (MFL) services in Goa,Best Magnetic Flux Leakage (MFL) services in Gujarat,Best Magnetic Flux Leakage (MFL) services in Haryana,
                        Best Magnetic Flux Leakage (MFL) services in Himachal Pradesh,Best Magnetic Flux Leakage (MFL) services in Jammu and kashmir,Best Magnetic Flux Leakage (MFL) services in Jharkhand,Best Magnetic Flux Leakage (MFL) services in Karnataka,Best Magnetic Flux Leakage (MFL) services in Kerala,Best Magnetic Flux Leakage (MFL) services in Madhya Pradesh,Best Magnetic Flux Leakage (MFL) services in Maharashtra,Best Magnetic Flux Leakage (MFL) services in Manipur,Best Magnetic Flux Leakage (MFL) services in Meghalaya,
                    Best Magnetic Flux Leakage (MFL) services in Mizoram,Best Magnetic Flux Leakage (MFL) services in Nagaland,Best Magnetic Flux Leakage (MFL) services in Odisha,Best Magnetic Flux Leakage (MFL) services in Punjab,Best Magnetic Flux Leakage (MFL) services in Rajasthan,Best Magnetic Flux Leakage (MFL) services in Sikkim,Best Magnetic Flux Leakage (MFL) services in TamilNadu,Best Magnetic Flux Leakage (MFL) services in Telengana,Best Magnetic Flux Leakage (MFL) services in Tripura,Best Magnetic Flux Leakage (MFL) services in Uttar Pradesh,
                   Best Magnetic Flux Leakage (MFL) services in Uttarakhand,Best Magnetic Flux Leakage (MFL) services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
    
        <div className='bg-slate-100'>
            <div>
                <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                    <First />
                </div>
                
                <div className='pt-40'>
                    <div>
                        <Carousel >
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="assets/ameee/mfl1.png"
                                    alt="First slide"
                                    style={{ height: '400px' }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="assets/ameee/MFL Tube.jpg"
                                    alt="Second slide"
                                    style={{ height: '400px' }}
                                />

                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="assets/ameee/MFL Tank.jpeg"
                                    alt="Third slide"
                                    style={{ height: '400px' }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className='m-2'>
                        <div className='justify-center sm:block md:block lg:flex'>
                            <div className='mr-10 mt-2'>
                                <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                    <h1 className='text-center underline mb-3'>All Service</h1>
                                    <ul className='text-left'>
                                        <li className='border border-neutral-400 font-bold text-xl group '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='RemoteVisualInspection' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Remote Visual Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                </div>
                                            </div>
                                        </li>

                                        <li className='border mt-4 group border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='PhasedArrayUltrasonicTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Phased Array Ultrasonic Testing</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='TimeOfFlightDiffraction' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Time Of Flight Diffraction</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='LongRangeUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Long Range Ultrasonic Testing</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='ShortRangeUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Short Range Ultrasonic Testing </a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='MagneticResonanceUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Magnetic Resonance Ultrasonic  Testing</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex  justify-between group-hover:bg-blue-900 p-2'>
                                                <div>
                                                    <a href='AutomatedUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Automated Ultrasonic Testing </a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex  group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='EddyCurrentTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Eddy Current Testing </a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='RemoteFieldTechnique' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Remote Field Technique</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='InternalRotaryInspectionSystem' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Internal Rotary Inspection System</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                            <div className='flex  justify-between group-hover:bg-blue-900 p-2'>
                                                <div>
                                                    <a href='NearFieldElectromagneticTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Near Field Electromagnetic Testing</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Card>
                                <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                    <div>
                                        <h5 className='text-center underline mb-3 '>
                                           Download Our Company Profile
                                        </h5>
                                    </div>
                                </Card>
                            </div>
                            <div className='w-full'>
                                <h1 className='font-bold text-left mb-3  text-blue-900'>Magnetic Flux Leakage</h1>
                                <img src='assets/ameee/MFL.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                <p className='p-0 text-gray-500 text-justify text-lg'>
                                    <strong className='text-xl'> AMEEE NDT </strong>offers advanced Magnetic Flux Leakage (MFL) services, providing accurate and efficient inspection solutions for detecting surface and subsurface defects in conductive materials.
                                </p>
                                <div>
                                    <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>Magnetic Flux Leakage (MFL) is instrumental in non-destructive testing (NDT), providing an advanced method to assess the integrity of structures and components using magnetic flux scanning. The primary objective of MFL is to detect and evaluate defects, corrosion, or anomalies that may compromise the performance or safety of assets.</p>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>MFL finds extensive applications across various industries such as manufacturing, aerospace, automotive, and electronics. In manufacturing, for example, MFL is used to inspect welds, tubes, and components for internal flaws or defects, ensuring product quality and reliability. Similarly, in aerospace and automotive sectors, MFL aids in detecting defects in critical components, contributing to safety and performance standards.</p>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>By offering high-resolution inspection capabilities using magnetic flux scanning, MFL minimizes the need for extensive dismantling or disassembly, leading to time and cost savings. Moreover, MFL enables swift detection of defects, allowing for timely interventions and maintenance actions to enhance asset longevity and reliability.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='justify-center mb-10 sm:block md:block lg:flex'>
                             <div>
                             <div className='mt-5'  style={{ width: '330px' }}>
                                    <img src='assets/ameee/mfl1.png' className=' border rounded-lg' />
                                </div>
                             </div>
                                <div className='text-justify '>
                                    <div className='ml-12'>
                                        <h4 className='text-blue-900 font-bold'>History</h4>
                                        <p className='text-gray-500 text-lg'>Magnetic Flux Leakage (MFL) has emerged as an innovative technique in non-destructive testing, utilizing magnetic flux scanning principles. Its development has been driven by the need for precise and efficient inspection methods across various industries, including manufacturing, aerospace, automotive, and electronics.</p>
                                        <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                        <p className='text-left text-gray-700 text-lg'>Magnetic Flux Leakage (MFL) is a sophisticated non-destructive testing method that utilizes magnetic flux scanning to examine the integrity of structures and components. The principle involves the generation of a magnetic field within the inspected component, allowing for detailed inspection of its internal features. MFL offers high resolution and accuracy, making it essential for ensuring the quality and reliability of critical assets.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' justify-center sm:block md:block lg:flex'>
                            <div className='mr-10'>
                                <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                    <h1 className='mb-3 underline'>Applications</h1>
                                    <ul className=' mt-3'>
                                        <li className='border border-neutral-400 text-lg group'>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border mt-4 border-neutral-400 text-lg group'>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='TubeTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border mt-4 group border-neutral-400 text-lg'>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='PipelineTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border mt-4 group border-neutral-400 text-lg'>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='ShipTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border mt-4 group border-neutral-400 text-lg'>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='ReactorTesting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                        <li className='border mt-4 group border-neutral-400 text-lg '>
                                            <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                <div>
                                                    <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Card>
                            </div>
                            <div className='text-left'>
                                <div className='flex justify-center '>
                                    <div className='w-full mr-5'>
                                        <img src='assets/ameee/MFL Tube Service.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                    </div>
                                    <div className='w-full'>
                                        <img src='assets/ameee/MFl Tank Service.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                    </div>
                                </div>
                                <div className='flex justify-center mt-5'>
                                    <ul className='text-left'>
                                        <li>
                                            <h4 className='text-blue-900 font-bold'>Advantages</h4>
                                        </li>
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                            <b>High Sensitivity:</b> Magnetic Flux Leakage (MFL) is highly sensitive, capable of detecting small defects close to the surface of materials.
                                        </li>
                                        <hr />
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                            <b>Non-Destructive:</b> Like other magnetic flux leakage methods, MFL is non-destructive, meaning it does not cause damage to the inspected material during testing.<br/>
                                        </li>
                                        <hr />
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                            <b>Versatility:</b> MFL can be applied to a wide range of materials, including metals, composites, and plastics, making it a versatile inspection technique.<br/><br/>
                                        </li>
                                        <hr />
                                    </ul>
                                    <ul className='text-left'>
                                        <li>
                                            <h4 className='text-blue-900 font-bold'>Disadvantages</h4>
                                        </li>
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                            <b>Limited Penetration Depth:</b> MFL is designed for shallow-depth inspections, so it may not be suitable for detecting defects deeper within thick materials.
                                            <br />
                                        </li>
                                        <hr />
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                            <b>Surface Preparation Requirements:</b> Surface preparation is crucial for MFL to ensure optimal magnetic flux propagation, which can increase inspection time and complexity.
                                        </li>
                                        <hr />
                                        <li className='text-lg text-gray-700'>
                                            <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                            <b>Skill and Training Requirements:</b> Effective MFL requires skilled technicians with specialized training in magnetic flux leakage techniques and equipment operation.
                                        </li>
                                        <hr />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='sm:block md:block lg:flex'>
                          <div>
                          <Card className='p-3' style={{width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                <form>
                                    <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                    <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                    <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                </form>
                                <div className=''>
                                    <div className="darksoul-gradient-card3">
                                        <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                    </div>
                                </div>
                            </Card>
                          </div>
                            <div className=''>
                                <div className='justify-between sm:block md:block lg:flex' >
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleProcedure}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                            </li>
                                            <li onClick={toggleEquipment}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)'}}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                            </li>
                                            <li onClick={toggleStandard}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)'}}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {procedureVisible && <Procedure />}
                                                {equipmentVisible && <Equipment />}
                                                {standardVisible && <Standards />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                        <li onClick={toggleParameters}>
                                            <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                        </li>
                                        <li onClick={toggleDefect}>
                                            <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)'}}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                        </li>
                                        <li onClick={toggleReferences}>
                                            <h6 className='font-bold  bg-black text-white p-3' style={{border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                        </li>

                                    </ul>
                                    <div className='process-content px-5'>
                                        <div className='ml-2'>
                                            {parametersVisible && <Parameters />}
                                            {defectsVisible && <Defects />}
                                            {referencesVisible && <References />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Service1 />
                     <div className=' ' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                   </div>
                    <Fourth />
                    <div style={{ backgroundImage: 'url(assets/ameee/MFL.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <Foooter />
                    </div>
                </div >
            </div >
        </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Process - How We Work</h4>
                    <p className='font-semibold'>Magnetic Flux Leakage (MFL) by AMEEE NDT offers advanced assessment of both surface conditions and internal characteristics of components without the need for physical contact.
                        It involves automated scanning using magnetic flux leakage probes to detect and characterize anomalies such as cracks, voids, or material inconsistencies within materials, utilizing state-of-the-art MFL technology.
                        Inspection findings undergo meticulous documentation and comparison against industry standards.
                        Detailed reports are generated, providing stakeholders with crucial insights for decision-making and maintenance planning.
                        AMEEE NDT prioritizes adherence to safety protocols, ensuring precise and reliable inspection outcomes, even in challenging remote environments.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables</h4>
                    <p className='font-semibold'>
                        For Magnetic Flux Leakage (MFL) requirements, AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically tailored for magnetic flux leakage inspection operations.
                        Our offerings include advanced MFL systems, specialized probes and configurations suitable for MFL applications, automated scanning systems for efficient inspections, data acquisition and analysis software tailored for MFL, as well as calibration standards and reference blocks designed for MFL calibration.
                        From state-of-the-art MFL equipment to essential consumables, we provide all necessary resources for conducting precise and reliable MFL inspections.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard references such as ASTM E213, ASTM E543, and ISO 15548 specifically tailored for Magnetic Flux Leakage (MFL).
                        Our commitment to these standards ensures the precision and reliability of our inspections, preserving the safety and integrity of critical assets.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters</h4>
                    <p className='font-semibold'>
                        During Magnetic Flux Leakage (MFL), we evaluate various parameters including probe selection, frequency, and lift-off distance to ensure accurate and efficient inspection of structures and components.
                        Our comprehensive assessments provide essential insights for asset maintenance and integrity management, facilitating informed decision-making and proactive maintenance planning.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects we can Capture</h4>
                    <p className='font-semibold'>
                        At AMEEE NDT, our magnetic flux leakage (MFL) services excel in capturing a wide array of defects and anomalies, including surface and near-surface flaws such as cracks, corrosion, and material changes.
                        Through precise MFL inspection techniques, we ensure comprehensive defect detection, enabling proactive maintenance and effective risk mitigation strategies.
                    </p>

                </div>
            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Magnetic Flux Leakage (MFL)</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard references such as ASTM E2192, ASTM E2375, and ISO 22825 specifically tailored for Magnetic Flux Leakage (MFL).
                        Our commitment to these standards ensures the precision and reliability of our inspections, preserving the safety and integrity of critical assets.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Mfl
