import React from 'react'
import First from './First'
import Quotation from './Quotation'
import Foooter from './Footer'
import Quote from './Quote'
import { Helmet } from 'react-helmet'
function Quotation1() {
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT-Request For Quote | Quote Us|Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div>
        <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
          <First />
        </div>
        <div className='pt-40'>
          <Quotation />
        </div>
        <div className='p-5 mt-5 bg-black' >
          <Quote />
        </div>
        <div style={{ backgroundImage: 'url(assets/ameee/ultrasonic.jpg)',backgroundRepeat:'no-repeat' ,backgroundSize:' cover'}}>
          <Foooter />
        </div>
      </div>
    </div>
  )
}

export default Quotation1
