import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faAngleRight, faUsers, faFlask, faMicroscope, faTools, faOilWell, faWaterLadder, faFan, faShip, faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';
function Fifth() {

  return (
    <div>
      <Helmet>
        <title>Services Offered by AMEEE NDT| NDT testing | ndt services & Inspection</title>
        <meta name="description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including conventional NDT services,Advance NDT services,third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="keywords" content="AMEEE NDT services, NDT testing,Conventional NDT,Advance NDT,Best NDT services in India,testing & Inspection ,best UT services in India, non-destructive testing, Padappai, third-party inspection, rope access, coating inspection, welding services" />
        <meta property="og:title" content="Services Offered by AMEEE NDT" />
        <meta property="og:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Services Offered by AMEEE NDT" />
        <meta name="twitter:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='mt-10 ml-10 h-full w-full '>
        <div className='text-center mb-16 sm:w-72 md:w-80 lg:w-full'>
          <h2 className=' font-extrabold p-3 text-5xl' id='down'>
            <span className='text-6xl mb-10 text-gray-700 font-bold '>
              <FancyText
                gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                animate
                animateDuration={5000}
              >
                <span className='text-center font-bold text-5xl' id='down'>Explore</span><br />

              </FancyText>

            </span>
          </h2>
          <div className="relative inline-block" id='up' >
            <span className='text-center font-bold text-5xl'>Our Services</span>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='services-box active  shadow-2xl shadow-blue-300 ' >
              <div className='grid-text relative group'>
                <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='services-content bg-transparent '>
                  <div className='services-icon group-hover:text-white '>
                    <FontAwesomeIcon icon={faFlask} aria-hidden='true' className='text-6xl hover-pendulum' />
                  </div>
                  <spna className='text-center'>
                    <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Boiler Tube Testing & Inspection</h4>
                    <p className='  group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }} >We specialize in comprehensive boiler tube testing and inspection services.</p>
                    <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600 '>Read More
                      <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                    </Button>
                  </spna>
                </div>
              </div>
              <div className='image-container'>
                <img src='assets\ameee\tube.jpg' alt='skill' className='background-image' />
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='services-box active    shadow-2xl shadow-blue-300 '>
              <div className='grid-text relative group'>
                <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='services-content bg-transparent'>
                  <div className='services-icon group-hover:text-white '>
                    <FontAwesomeIcon icon={faMicroscope} aria-hidden='true' className='text-6xl' />
                  </div>
                  <spna className='text-center'>
                    <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Tube Testing & Inspection</h4>
                    <p className='group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }} >We excel in offering meticulous tube testing and inspection solutions.</p>
                    <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                      <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                    </Button>
                  </spna>
                </div>
              </div>
              <div className='image-container'>
                <img src='assets\ameee\tube.jpg' alt='skill' className='background-image' />
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='services-box active    shadow-2xl shadow-blue-300 '>
              <div className='grid-text relative group'>
                <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='services-content bg-transparent'>
                  <div className='services-icon group-hover:text-white '>
                    <FontAwesomeIcon icon={faTools} aria-hidden='true' />
                  </div>
                  <spna className='text-center'>
                    <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Pipeline Testing & Inspection</h4>
                    <p className='group-hover:text-white font-bold text-center' style={{ color: '#000435', fontSize: '20px' }}  >We are dedicated to providing thorough and reliable pipeline testing services.</p>
                    <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                      <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                    </Button>
                  </spna>
                </div>
              </div>
              <div className='image-container'>
                <img src='assets\ameee\Pipeline UFD.jpg' alt='skill' className='background-image' />
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6'>
            <div className='services-box active shadow-2xl shadow-blue-300 '>
              <div className='grid-text relative group'>
                <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='absolute left-3 bottom-3 border-l-4 border-b-4   border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                <div className='services-content bg-transparent'>
                  <div className='services-icon group-hover:text-white '>
                    <FontAwesomeIcon icon={faOilWell} aria-hidden='true' />
                  </div>
                  <spna className='text-center'>
                    <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Storage Tank Testing & Inspection</h4>
                    <p className='group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }}>
                      We offer comprehensive storage tank testing services.</p>
                    <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600 mt-4'>Read More
                      <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                    </Button>
                  </spna>
                </div>
              </div>
              <div className='image-container'>
                <img src='assets\ameee\tube.jpg' alt='skill' className='background-image' />
              </div>
            </div>
          </div>
          <div className='text-center mb-16'><span className='text-center text-blue-600 font-bold'> </span>
            <br></br><span className='text-4xl underline mb-10 text-gray-700 font-bold '></span>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='services-box active  shadow-2xl shadow-blue-300 '>
                <div className='grid-text relative group'>
                  <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='services-content bg-transparent '>
                    <div className='services-icon group-hover:text-white '>
                      <FontAwesomeIcon icon={faWaterLadder} aria-hidden='true' className='text-6xl hover-pendulum' />
                    </div>
                    <spna className='text-center'>
                      <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Valve Testing & Inspection</h4>
                      <p className='  group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }}>We specialize in thorough valve testing and inspection services.</p>
                      <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                        <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' /></Button>
                    </spna>
                  </div>
                </div>
                <div className='image-container'>
                  <img src='assets\ameee\valve.jpeg' alt='skill' className='background-image' />
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='services-box active shadow-2xl shadow-blue-300 '>
                <div className='grid-text relative group'>
                  <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='services-content bg-transparent'>
                    <div className='services-icon group-hover:text-white '>
                      <FontAwesomeIcon icon={faFan} aria-hidden='true' className='text-6xl' />
                    </div>
                    <spna className='text-center'>
                      <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Air Compressor Testing & Inspection</h4>
                      <p className='group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }} >We provide meticulous air compressor testing and inspection services.</p>
                      <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                        <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                      </Button>
                    </spna>
                  </div>
                </div>
                <div className='image-container'>
                  <img src='assets/ameee/Air Compressor tank.jpg' alt='skill' className='background-image' />
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='services-box active shadow-2xl shadow-blue-300 '>
                <div className='grid-text relative group'>
                  <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute left-3 bottom-3 border-l-4 border-b-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='services-content bg-transparent'>
                    <div className='services-icon group-hover:text-white '>
                      <FontAwesomeIcon icon={faShip} aria-hidden='true' />
                    </div>
                    <spna className='text-center'>
                      <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Ship Testing & Inspection</h4>
                      <p className='group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }} >We specialize in comprehensive ship testing and inspection services.</p>
                      <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                        <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                      </Button>
                    </spna>
                  </div>
                </div>
                <div className='image-container'>
                  <img src='assets/ameee/ndt-hull-thickness-survey SHIP.jpg' alt='skill' className='background-image' />
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='services-box active shadow-2xl shadow-blue-300 '>
                <div className='grid-text relative group'>
                  <div className='absolute left-3 top-3 border-l-4 border-t-4  border-blue-600  h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 top-3 border-r-4 border-t-4  border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute right-3 bottom-3 border-r-4 border-b-4  border-blue-600 h-10 w-10 group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='absolute left-3 bottom-3 border-l-4 border-b-4   border-blue-600 h-10 w-10  group-hover:border-white group-hover:transition duration-1000 group-hover:h-48 group-hover:w-32 '></div>
                  <div className='services-content bg-transparent'>
                    <div className='services-icon group-hover:text-white '>
                      <FontAwesomeIcon icon={faWeightHanging} aria-hidden='true' />
                    </div>
                    <spna className='text-center'>
                      <h4 className='text-center text-blue-600 font-bold text-xl group-hover:text-white'>Pressure Vessel Testing & Inspection</h4>
                      <p className='group-hover:text-white font-bold' style={{ color: '#000435', fontSize: '20px' }} >We offer meticulous pressure vessel testing and inspection services.</p>
                      <Button variant='outline-primary' className=' group-hover:bg-white ml-12 text-blue-600'>Read More
                        <FontAwesomeIcon icon={faAngleRight} aria-hidden='true' />
                      </Button>
                    </spna>
                  </div>
                </div>
                <div className='image-container'>
                  <img src='assets/ameee/pressurevessel.jpeg' alt='skill' className='background-image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Fifth;
