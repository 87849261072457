import React, { useState } from 'react';
import { Col, Container, Row, Dropdown, Button, Carousel, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import About from './About';
import Service from './Service';
import { faAward, faContactBook, faBars, faCompactDisc, faHouse, faEnvelope, faMicroscope, faFileContract, faSquarePhoneFlip, faPhone, faTrophy, faCircleChevronRight, faSearch, faMessage, faEllipsisH, faSquarePhone, faHandPointUp, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { Helmet } from 'react-helmet';
import '../Header.css'

import MobileMenu from './MobileMenu';
import Wave from './Wave';

function First() {
  const [showMenu, setShowMenu] = useState(false);

  const handleIconClick = () => {
    setShowMenu(!showMenu);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [certifiedItems, setCertifiedItems] = useState([
    "An ISO 9001:2015",
    "MSME-Certified",
    "Startup India",
    // "AS9100",
    // "ISO/IEC 17025"
  ]);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [phoneNumbers, setPhoneNumbers] = useState([
    "+91 8169361089",
    "+91 8110877771",
    "+044 29994089"
  ]);

  const [inde, setInde] = useState(true);

  const handleSelects = (selectedInde, e) => {
    setInde(selectedInde);
  };

  const [showDetails, setShowDetails] = useState(false);

  const handleHover = () => {
    setShowDetails(true);
  };

  const handleMouseOut = () => {
    setShowDetails(false);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdow, setShowDropdow] = useState(false);
  const [showDropdowns, setShowDropdowns] = useState(false);
  return (
    <div className='p-0 '>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India|ndt services|Third party inspections in Padappai</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='hedbg  text-white border-x border-y mr-0.5 ml-0.5 border-neutral-500'>
        {/* <Container className='w-full  '> */}
        <Row className=''>

          <Col className='border-r  border-neutral-500 relative ' md={3} >
            <img src='assets/ameee/ameeelogo.png' alt='logo' className='border-neutral-500 hidden sm:inline' style={{ width: '600px', height: '150px', backgroundColor: '' }} />
            {/* <span className='font-bold absolute bottom-0 left-0 textlogo  px-2 py-1    ml-5 mb-3 text-sm hidden sm:inline ' style={{ color: '' }}>AMEEE NDT INSPECTIO ENGINEERS PRIVATE LIMITED</span> */}
            <div className='flex sm:hidden'>
              <MobileMenu />
            </div>
          </Col>

          <Col md={9} className='border-left border-neutral-500 hidden sm:inline '>
            <Row className='py-4 border-b border-neutral-500 '>
              <Col className='d-flex align-items-center text-primary' md={2} onMouseOver={handleHover} onMouseOut={handleMouseOut}>
                <FontAwesomeIcon icon={faPhone} className="pr-2 hover:translate-x-3 hover:-translate-x-4 transition duration-1000 " size="xl" />

                <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
                  <span className='text-white font-bold' >Call Us</span>
                  {phoneNumbers.map((phoneNumber, idx) => (
                    <Carousel.Item key={idx} style={{ transition: 'transform 1s ease' }}>
                      <span className='text-white'>
                        <br />
                        {phoneNumber}
                      </span>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
              <Col className='d-flex align-items-center ml-12' md={3}>
                <FontAwesomeIcon icon={faEnvelope} className='pr-2 text-primary hover:translate-x-3 hover:-translate-x-4 transition duration-1000 hover:text-white' size='xl' />
                <span style={{ textAlign: 'left' }}>
                  <span className='font-bold'>Write Us</span> <br></br>
                  <a href="mailto:inspection@ameeendt.com" className='text-white ' style={{ textDecoration: 'none' }}>inspection@ameeendt.com </a>
                </span>

              </Col>
              <Col className='d-flex align-items-center ml-12' md={2}>
                <FontAwesomeIcon icon={faAward} className='pr-2 text-primary hover:translate-x-3 hover:-translate-x-4  transition duration-1000 ' size='2xl' />
                <Carousel activeInde={inde} onSelect={handleSelects} controls={false} indicators={false}>
                  <span className='text-white font-bold'>Certified </span>
                  {certifiedItems.map((item, idx) => (
                    <Carousel.Item key={idx} style={{ transition: 'transform 1s ease' }} >
                      <br></br>
                      <span className='text-white'>{item}</span>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
              <Col className='d-flex align-items-center ml-12 ' md={2} >
                <FontAwesomeIcon icon={faTrophy} className='pr-2 text-primary hover:translate-x-3 hover:-translate-x-4 transition duration-1000 hover:text-white' size='xl' />
                <span>
                  <span className='font-bold'>Leading NDT</span><br></br>Service Provider </span>
              </Col>
            </Row>
            <Row className='hidden md:flex font-bold '>
              <Col className='hidden md:flex pt-3  hover:text-blue-600 ' >
                <Dropdown className=''>
                  <a href='/' className='link bg-transparent border-0 font-bold  hover:text-blue-600' style={{ textDecoration: 'none', color: 'white' }}> <FontAwesomeIcon icon={faHouse} />  Home</a>
                </Dropdown>
              </Col>
              <Col className='hidden md:flex pt-2'>
                <Dropdown onMouseEnter={() => setShowDropdowns(true)} onMouseLeave={() => setShowDropdowns(false)} show={showDropdowns}>
                  <Dropdown.Toggle variant="light" id="dropdown-basic" className='link bg-transparent border-0 text-white '>
                    <span className=' font-bold hover:text-blue-600 text-white' style={{ textDecoration: 'none' }}> About</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href='/'>
                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                      <a href='About' className='text-gray-700 hover:bg-blue-900 hover:text-white' >About Our Company</a>
                    </Dropdown.Item>
                    <Dropdown.Item href='/'>
                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                      <a href='Focus' className='text-gray-700 hover:bg-blue-900 hover:text-white' >About Our Focus</a>
                    </Dropdown.Item>
                    <Dropdown.Item href='/'>
                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                      <a href='Team' className='text-gray-700 hover:bg-blue-900 hover:text-white'>About Our Team</a>
                    </Dropdown.Item>
                    <Dropdown.Item href='/'>
                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                      <a href='OurResources' className='text-gray-700 hover:bg-blue-900 hover:text-white'>Our Resources</a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className='hidden md:flex pt-2'>
                <Dropdown onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                  show={showDropdown}>
                  <Dropdown.Toggle variant="light" id="dropdown-basic" className='link bg-transparent border-0 text-white '>
                    <a href='/Services' className=' font-bold hover:text-blue-600 text-white decoration-transparent'> Services</a>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href='/' >
                      <ul className={`list-unstyled d-flex ${showDropdown ? 'block' : 'hidden'}`} >
                        <li>
                          <div className='row '>
                            <div className='flex'>
                              <div className=' hover:bg-white'>
                                <div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='CoventionalNDTServices' style={{ textDecoration: 'none', color: 'white' }}>Conventional NDT</a>

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='VisualTesting' className='text-gray-700 hover:text-white'>Visual Testing (VT) </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PenetrantTesting' className='text-gray-700 hover:text-white'>Liquid Penetrant Test(PT) </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MagneticTesting' className='text-gray-700 hover:text-white'>Magnetic Particle Testing(MPT) </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='UltrasonicTesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing(UT) </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RadiographyTesting' className='text-gray-700 hover:text-white'>Radiography Testing (RT)  </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='LeakTesting' className='text-gray-700 hover:text-white'>Leak Testing </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PositiveMaterialIdentification' className='text-gray-700 hover:text-white'>Positive Material Identification (PMI) </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='HardnessTesting' className='text-gray-700 hover:text-white'>Hardness Testing </a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className=' hover:bg-white'><div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                                <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='AdvanceNDTServices' style={{ textDecoration: 'none', color: 'white' }}>Advance NDT</a>

                                </h6>
                                <ul className='list-unstyled mb-3'>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='RemoteVisualInspection' className='text-gray-700 hover:text-white'>Remote Visual Inspection (RVI) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='PhasedArrayUltrasonicTesting' className='text-gray-700 hover:text-white'>Phased Array UltrasonicTesting (PAUT) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='TimeOfFlightDiffraction' className='text-gray-700 hover:text-white'>Time Of Flight Diffraction (TOFD)</a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='LongRangeUltrasonicTesting' className='text-gray-700 hover:text-white'>Long Range Ultrasonic Testing (LRUT)  </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='ShortRangeUltrasonicTesting' className='text-gray-700 hover:text-white'>Short Range Ultrasonic Testing(SRUT) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='MagneticResonanceUltrasonicTesting' className='text-gray-700 hover:text-white'>Magnetic Resonance Ultrasonic Testing(MRUT) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='AutomatedUltrasonicTesting' className='text-gray-700 hover:text-white'>Automated Ultrasonic Testing (AUT)</a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='EddyCurrentTesting' className='text-gray-700 hover:text-white'>Eddy Current Testing (ECT) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='RemoteFieldTechnique' className='text-gray-700 hover:text-white'>Remote Field Technique (RFET) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='InternalRotaryInspectionSystem' className='text-gray-700 hover:text-white'>Internal Rotary Inspection System(IRIS)  </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='MagneticFluxLeakage' className='text-gray-700 hover:text-white'>Magnetic Flux Leakage (MFL) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='NearFieldElectromagneticTesting' className='text-gray-700 hover:text-white'>Near Field Electromagnetic Testing(NFET) </a>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              </div>
                              <div className=' hover:bg-white'><div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                                <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='CivilNDT' style={{ textDecoration: 'none', color: 'white' }}>Civil NDT</a>

                                </h6>
                                <ul className='list-unstyled mb-3'>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='UltraPulseVelocity' className='text-gray-700 hover:text-white'>Ultra Pulse Velocity (UPVC) </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='ReboundHammer' className='text-gray-700 hover:text-white'>Rebound Hammer </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='HalfCellPotential' className='text-gray-700 hover:text-white'>Half-Cell Potential </a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='ThicknessGauging' className='text-gray-700 hover:text-white'>Thickness Gauging</a>
                                    </span>
                                  </li>
                                </ul>

                              </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div>
                          <div className=' hover:bg-white'>
                            <div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                              <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}>
                                <a href='CoatingInspection' style={{ textDecoration: 'none', color: 'white' }}>Coating Inspection</a>
                              </h6>
                              <ul className='list-unstyled mb-3'>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='Coat' className='text-gray-700 hover:text-white'>Coating Inspection</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ul>
                      <ul className='list-unstyled d-flex '>
                        <li>
                          <div className='row'>
                            <div className='flex'>
                              <div className=' hover:bg-white'>
                                <div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className='p-2 text-white' style={{ backgroundColor: '#025' }}>
                                    <a href='WeldingInspection' style={{ textDecoration: 'none', color: 'white' }}>Welding Inspection</a>
                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='WeldingProcedureSpecification' className='text-gray-700 hover:text-white'>Welding Procedure Specification </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='WeldingProcedureQualificationRecord' className='text-gray-700 hover:text-white'>Welding Procedure Qualification Record</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='WeldersPerformanceQualificationTest' className='text-gray-700 hover:text-white'>Welders Performance Qualification Test </a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className=' hover:bg-white '><div className='col-lg-4 col-md-6 col-sm-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                                <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='RopeAccess' style={{ textDecoration: 'none', color: 'white' }}>Rope Access</a>

                                </h6>
                                <ul className='list-unstyled mb-3'>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='RopeAccesswithNDT' className='text-gray-700 hover:text-white'>Rope Access with NDT</a>
                                    </span>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                    <span className='  hover:bg-blue-900 hover:text-white'>
                                      <a href='RopeAccesswithPainting' className='text-gray-700 hover:text-white'>Rope Access with Painting</a>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              </div>
                            </div>
                            <div>
                              <div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div>
                          <div>
                            <div className='col-md-6 col-lg-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>
                              <div className=''>
                                {/* <Wave /> */}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className='col-md-6 col-lg-6' style={{ position: 'relative', width: '400px', paddingRight: '15px', paddingLeft: '15px' }}>

                          <div className='' >

                            <Wave />
                          </div>
                        </div>
                      </ul>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>


              <Col className='hidden md:flex pt-2'>
                <Dropdown onMouseEnter={() => setShowDropdow(true)}
                  onMouseLeave={() => setShowDropdow(false)}
                  show={showDropdow}  >
                  <Dropdown.Toggle variant="light" id="dropdown-basic" className='link bg-transparent border-0 text-white '>
                    <span className=' font-bold'><FontAwesomeIcon icon={faMicroscope} /> Application</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className=''>
                    <Dropdown.Item href='/'>
                      <ul className={`list-unstyled d-flex ${showDropdow ? 'block' : 'hidden'}`} >
                        <li>
                          <div className='row hover:bg-white '>
                            <div className='flex'>
                              <div>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'white' }}>Boiler Tube Testing & Inspection</a>

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='BoilerVisualInspection' className='text-gray-700 hover:text-white'>Boiler Visual Inspection </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='BoilerRemoteVisualInspection' className='text-gray-700 hover:text-white'>Boiler Remote Visual Inspection </a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='BoilerTubeUltrasonicTesting' className='text-gray-700 hover:text-white'>Boiler Tube Ultrasonic Testing </a>
                                      </span>
                                    </li>

                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='Boilermicro' className='text-gray-700 hover:text-white'>Boiler Micro Testing</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='Boilermacro' className='text-gray-700 hover:text-white'>Boiler Macro Testing</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='Boilerthickness' className='text-gray-700 hover:text-white'>Boiler Tube Thickness Measurement</a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className='p-2 text-white' style={{ backgroundColor: '#025' }}><a href='TubeTesting' style={{ textDecoration: 'none', color: 'white' }}>Tube Testing & Inspection</a> &nbsp;

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RemoteVisualInspection-Tubetesting' className='text-gray-700 hover:text-white'>Remote Visual Inspection (RVI)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='EddyCurrentTesting-Tubetesting' className='text-gray-700 hover:text-white'>Eddy Current Testing (ECT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PositiveMaterialIdentification-Tubetesting' className='text-gray-700 hover:text-white'>Positive Material Identification (PMI)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RemoteFieldEddyCurrentTesting-Tubetesting' className='text-gray-700 hover:text-white'>Remote Field Eddy Current Testing (RFECT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='IRIS-Tubetesting' className='text-gray-700 hover:text-white'>Internal Rotary Inspection System (IRIS)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MFL-Tubetesting' className='text-gray-700 hover:text-white'>Magnetic Flux Leakage (MFL)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='NFET-Tubetesting' className='text-gray-700 hover:text-white'>NFET</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MicroStructure-Tubetesting' className='text-gray-700 hover:text-white'>Micro Structure</a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className='p-2 text-white' style={{ backgroundColor: '#025' }}><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'white' }}>Pipeline Testing & Inspection</a>

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RemoteVisualInspection-Pipelinetesting' className='text-gray-700 hover:text-white'>Remote Visual Inspection (RVI)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='EddyCurrentTesting-Pipelinetesting' className='text-gray-700 hover:text-white'>Eddy Current Testing (ECT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PositiveMaterialIdentification-Pipelinetesting' className='text-gray-700 hover:text-white'>Positive Material Identification (PMI)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MicroStructure-Pipelinetesting' className='text-gray-700 hover:text-white'>Micro Structure</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='ImageReplica-Pipelinetesting' className='text-gray-700 hover:text-white'>Image Replica / Insitu Metallography</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='HolidayTesting-Pipelinetesting' className='text-gray-700 hover:text-white'>Holiday Testing</a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div >
                          <div><div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                            <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='StorageTesting' style={{ textDecoration: 'none', color: 'white' }}>Storage Tank Testing & Inspection</a>

                            </h6>
                            <ul className='list-unstyled mb-3'>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='LongRangeUltrasonicTesting-Storagetesting' className='text-gray-700 hover:text-white'>Long Range Ultrasonic Testing</a>
                                </span>
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='ShortRangeUltrasonicTesting-Storagetesting' className='text-gray-700 hover:text-white'>Short Range Ultrasonic Testing</a>
                                </span>
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='MagneticResonanceUltrasonicTesting-Storagetesting' className='text-gray-700 hover:text-white'>Magnetic Resonance Ultrasonic Testing</a>
                                </span>
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='PhasedArrayUltrasonicTesting-Storagetesting' className='text-gray-700 hover:text-white'>Phased Array Ultrasonic Testing</a>
                                </span>
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='TimeOfFlightDiffraction-Storagetesting' className='text-gray-700 hover:text-white'>Time Of Flight Diffraction</a>
                                </span>
                              </li>
                              <li>
                                <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                <span className='  hover:bg-blue-900 hover:text-white'>
                                  <a href='UltrasonicTesting-Storagetesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing</a>
                                </span>
                              </li>
                            </ul>
                          </div>
                          </div>
                        </div>
                      </ul>
                      <ul className='list-unstyled d-flex '>
                        <li>
                          <div className='row hover:bg-white'>
                            <div className='flex'>
                              <div>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='ValveTesting' style={{ textDecoration: 'none', color: 'white' }}>Valve Testing & Inspection</a>

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PositiveMaterialIdentification- ValveTesting' className='text-gray-700 hover:text-white'>Positive Material Identification(PMI)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='HeatTreatment- ValveTesting' className='text-gray-700 hover:text-white'>Heat Treatment (PWHT) , SR</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='UltrasonicTesting- ValveTesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing(UT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RadiographicTesting- ValveTesting' className='text-gray-700 hover:text-white'>Radiographic Testing (RT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MagneticParticleTesting- ValveTesting' className='text-gray-700 hover:text-white'>Magnetic Particle Testing (MPT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='DyePenetrationTesting- ValveTesting' className='text-gray-700 hover:text-white'>Dye Penetration Testing</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='TreviTesting- ValveTesting' className='text-gray-700 hover:text-white'>Trevi Testing of Safety Valves</a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='ShipTesting' style={{ textDecoration: 'none', color: 'white' }}>Ship Testing & Inspection</a> &nbsp;

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='VisualInspection- ShipTesting' className='text-gray-700 hover:text-white'>Visual Inspection</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='UltrasonicTesting- ShipTesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing (UT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RadiographicTesting- ShipTesting' className='text-gray-700 hover:text-white'>Radiographic Testing(RT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MagneticParticleTesting- ShipTesting' className='text-gray-700 hover:text-white'>Magnetic Particle Testing (MPT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='LiquidPenetrantTesting- ShipTesting' className='text-gray-700 hover:text-white'>Liquid Penetrant Testing (LPT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='DestructiveTesting- ShipTesting' className='text-gray-700 hover:text-white'>Destructive Testing (DT)</a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className=''>
                                <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '350px', paddingRight: '15px', paddingLeft: '15px' }}>
                                  <h6 className=' p-2 text-white' style={{ backgroundColor: '#025' }}><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'white' }}>Reactor Testing & Inspection&nbsp;</a>

                                  </h6>
                                  <ul className='list-unstyled mb-3'>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='VisualInspection- ReactorTesting' className='text-gray-700 hover:text-white'>Visual Inspection</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='UltrasonicTesting- ReactorTesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing (UT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='RadiographicTesting- ReactorTesting' className='text-gray-700 hover:text-white'>Radiographic Testing (RT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='MagneticParticleTesting- ReactorTesting' className='text-gray-700 hover:text-white'>Magnetic Particle Testing (MPT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='LiquidPenetrantTesting- ReactorTesting' className='text-gray-700 hover:text-white'>Liquid Penetrant Testing (LPT)</a>
                                      </span>
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                      <span className='  hover:bg-blue-900 hover:text-white'>
                                        <a href='PressureTesting- ReactorTesting' className='text-gray-700 hover:text-white'>Pressure Testing </a>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div>
                          <div>
                            <div className='col-md-6 col-lg-3' style={{ position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px' }}>
                              <h6 className='p-2 text-white' style={{ backgroundColor: '#025' }}><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'white' }}>Pressure Vessel Testing & Inspection &nbsp;</a>

                              </h6>
                              <ul className='list-unstyled mb-3'>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='VisualInspection- PressureVesselTesting' className='text-gray-700 hover:text-white'>Visual Inspection</a>
                                  </span>
                                </li>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='UltrasonicTesting- PressureVesselTesting' className='text-gray-700 hover:text-white'>Ultrasonic Testing (UT)</a>
                                  </span>
                                </li>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='RadiographicTesting- PressureVesselTesting' className='text-gray-700 hover:text-white'>Radiographic Testing (RT)</a>
                                  </span>
                                </li>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='MagneticParticleTesting- PressureVesselTesting' className='text-gray-700 hover:text-white'>Magnetic Particle Testing (MPT)</a>
                                  </span>
                                </li>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='DyePenetrantTesting- PressureVesselTesting' className='text-gray-700 hover:text-white'>Dye Penetrant Testing (DPT)</a>
                                  </span>
                                </li>
                                <li>
                                  <FontAwesomeIcon icon={faHandPointRight} className='mr-3' style={{ color: '#025' }} />
                                  <span className='  hover:bg-blue-900 hover:text-white'>
                                    <a href='AcousticEmissionTesting- PressureVesselTesting' className='text-gray-700 hover:text-white'>Acoustic Emission Testing (AET)</a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className=' hidden md:flex pt-3'>
                <Dropdown className='ml-3'>
                  <a href='Careers' className='link font-bold bg-transparent border-0 hover:text-blue-600' style={{ textDecoration: 'none', color: 'white' }}>  Careers</a>
                </Dropdown>
              </Col>
              <Col className='pt-3' >
                <Dropdown className=''>
                  <a href='/Contact' className='link bg-transparent border-0' style={{ textDecoration: 'none', color: 'white' }}><FontAwesomeIcon icon={faSquarePhone} /> Contact Us</a>
                </Dropdown>
              </Col>
              <Col md={2} className='hidden md:flex p-2 border-l border-neutral-500 d-flex'>
                <Button href='Quotation1' className='darksoul-gradient-card4 text-decoration-none  border-1  text-white p-2'>Request for Quote</Button>
              </Col>
              <Col className='hidden md:flex border-l border-neutral-500 d-flex align-items-center  '>
                <FontAwesomeIcon icon={faSearch} className='pl-10' />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </div>
  )
}
export default First;
