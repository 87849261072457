import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Boilermicro() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Boiler Micro Testing Services in Chennai | Boiler Micro Testing Services in Padappai | Boiler Micro Testing Services in Mumbai | Boiler Micro Testing Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Boiler Micro Testing services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Boiler Micro Testing Services in Chennai, Boiler Micro Testing Services in Mumbai, Boiler Micro Testing Services in Haryana, Boiler Micro Testing Services in India, Best Boiler Micro Testing Services in Padappai, Best Boiler Micro Testing Services in Chennai, Best Boiler Micro Testing Services in Bangalore" />
                <meta name="keywords" content="Best Boiler Micro Testing Services in Andhra Pradesh, Best Boiler Micro Testing Services in Arunachal Pradesh, Best Boiler Micro Testing Services in Assam, Best Boiler Micro Testing Services in Bihar, Best Boiler Micro Testing Services in Chhattisgarh, Best Boiler Micro Testing Services in Goa, Best Boiler Micro Testing Services in Gujarat, Best Boiler Micro Testing Services in Haryana, Best Boiler Micro Testing Services in Himachal Pradesh, Best Boiler Micro Testing Services in Jammu and Kashmir, Best Boiler Micro Testing Services in Jharkhand, Best Boiler Micro Testing Services in Karnataka, Best Boiler Micro Testing Services in Kerala, Best Boiler Micro Testing Services in Madhya Pradesh, Best Boiler Micro Testing Services in Maharashtra, Best Boiler Micro Testing Services in Manipur, Best Boiler Micro Testing Services in Meghalaya, Best Boiler Micro Testing Services in Mizoram, Best Boiler Micro Testing Services in Nagaland, Best Boiler Micro Testing Services in Odisha, Best Boiler Micro Testing Services in Punjab, Best Boiler Micro Testing Services in Rajasthan, Best Boiler Micro Testing Services in Sikkim, Best Boiler Micro Testing Services in Tamil Nadu, Best Boiler Micro Testing Services in Telangana, Best Boiler Micro Testing Services in Tripura, Best Boiler Micro Testing Services in Uttar Pradesh, Best Boiler Micro Testing Services in Uttarakhand, Best Boiler Micro Testing Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Boiler Micro Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Boiler Micro Testing Services</strong> are offered by AMEEE NDT, a prominent provider of comprehensive non-destructive testing (NDT) solutions. Our dedicated team ensures the integrity and reliability of boiler tubes across various industrial settings using micro testing techniques. Through advanced technology and expertise, we conduct micro-level inspections of boiler tubes, enabling us to detect defects or inconsistencies at a microscopic level without the need for physical presence. This approach enhances efficiency and safety while maintaining high standards of inspection accuracy and thoroughness.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Boiler Micro Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Boiler micro testing serves the crucial purpose of remotely detecting and assessing internal irregularities, defects, or discontinuities in boiler tubes at a microscopic level. This comprehensive examination ensures the integrity, safety, and optimal performance of boiler tubes and the systems they support without the need for physical intervention. By utilizing advanced micro testing techniques, boiler micro testing plays a vital role in upholding stringent standards of reliability and efficiency across various industrial sectors.</p>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                   <div>
                                   <div className=''  style={{ width: '330px' }}>
                                        <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                    </div>
                                   </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Boiler Micro Testing</h4>
                                            <p className='text-gray-500 text-lg'>Boiler micro testing has a rich history dating back several decades in industrial sectors. As manufacturing and engineering practices have evolved, so too have the methods for inspecting and ensuring the reliability and safety of boiler tubes, with the introduction of micro testing technologies revolutionizing the inspection process.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Boiler Micro Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Boiler micro testing employs advanced non-destructive testing methods to assess the structural integrity and detect flaws within boiler tubes at a microscopic level without the need for physical intervention. These methods utilize microscopic examination techniques to identify any defects, anomalies, or irregularities that could compromise the safety or functionality of boiler tubes.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>High accuracy:</b> Boiler micro testing provides precise detection of defects and flaws within the material.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Non-invasive:</b> It does not require physical alteration of the boiler tubes, minimizing downtime and operational disruptions.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Depth penetration:</b> Capable of detecting subsurface defects, ensuring comprehensive inspection coverage.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Equipment complexity:</b> Specialized equipment and trained personnel are required for conducting micro testing, increasing operational complexity.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface preparation:</b> Proper surface preparation is essential for accurate micro testing results, adding to the inspection time and effort.
                                            </li><br /><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Interpretation challenges:</b> Interpretation of micro test results requires expertise and may vary among inspectors, leading to potential inconsistencies.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Boiler Micro Testing Process - How We Work</h4>
                    <p className='font-semibold'>Boiler micro testing at AMEEE NDT involves a meticulous assessment of the integrity and condition of boiler tubes using advanced microscopic inspection techniques. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Microscopic examination of boiler tubes to detect micro-scale defects such as pitting, erosion, and material degradation using specialized microscopic probes and equipment.</li>
                        <li>Recording microscopic images and analyzing them to identify the presence, location, and extent of any detected defects within the material.</li>
                        <li>Analysis of micro test results to evaluate the severity and implications of detected defects, determining the appropriate course of action for maintenance and repair.</li>
                        <li>Preparation of comprehensive reports detailing the findings of the microscopic inspection, including defect locations, sizes, morphologies, and recommendations for further actions.</li>
                        <li>Strict adherence to safety protocols and standards to ensure the well-being of personnel and the integrity of the testing environment during the microscopic testing process.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Boiler Micro Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for boiler micro testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Microscopic inspection equipment featuring high-resolution microscopes and imaging systems tailored for examining boiler tubes at the microscopic level, capable of detecting micro-scale defects such as pitting, erosion, and material degradation.</li>
                        <li>Microscopic examination accessories such as sample holders, slides, and coverslips to facilitate the handling and positioning of boiler tube samples during microscopic inspection.</li>
                        <li>Microscopic consumables including staining agents, mounting media, and cleaning solutions optimized for enhancing the visibility and contrast of microscopic features within boiler tube samples.</li>
                        <li>Microscopic calibration standards calibrated for the specific magnification levels and resolution requirements of microscopic inspections, essential for verifying the accuracy and performance of microscopic equipment.</li>
                        <li>Advanced image analysis software for processing microscopic images, quantifying defects, and generating detailed reports on the condition of boiler tubes at the micro-scale.</li>
                        <li>Personal protective equipment (PPE) for inspection personnel, including gloves, goggles, and safety apparel, to ensure their safety during the microscopic testing process.</li>
                        <li>Training materials and resources to educate personnel on the principles of microscopic testing, equipment operation, interpretation of microscopic images, and safety procedures.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our comprehensive range of microscopic testing equipment and resources, we provide everything necessary to conduct thorough and accurate boiler micro testing.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Boiler Micro Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for boiler micro testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME Boiler and Pressure Vessel Code, Section V: Nondestructive Examination</li>
                        <li>ASTM E213 Standard Practice for Ultrasonic Testing of Metal Pipe and Tubing</li>
                        <li>ASTM E587 Standard Practice for Ultrasonic Angle-Beam Examination by the Contact Method</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the accuracy, reliability, and safety of our boiler micro testing procedures, helping to safeguard critical assets and infrastructure.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Boiler Micro Testing</h4>
                    <p className='font-semibold'>
                        During boiler micro testing, AMEEE NDT evaluates various parameters to ensure the integrity and reliability of boiler tubes and associated systems. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Tube Wall Thickness: Measuring the thickness of boiler tubes to detect thinning, erosion, or corrosion.</li>
                        <li>Presence of Defects: Detecting internal defects such as cracks, inclusions, and laminations within the material of boiler tubes.</li>
                        <li>Bonding Integrity: Assessing the bonding integrity between different layers or materials in composite boiler tube structures.</li>
                        <li>Tube Geometry: Verifying the geometric properties of boiler tubes, including roundness, straightness, and alignment.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our comprehensive evaluations provide valuable insights for asset maintenance and integrity management, enabling informed decision-making and proactive maintenance planning to ensure the continued reliability and safety of boiler systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Boiler Micro Testing</h4>
                    <p className='font-semibold'>
                        During boiler micro testing conducted by AMEEE NDT, we have the capability to detect various defects and anomalies, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Internal Corrosion: Detecting corrosion or erosion within the material of boiler tubes that may compromise structural integrity.</li>
                        <li>Wall Thinning: Identifying areas of reduced wall thickness in boiler tubes, indicating potential weakening of the material.</li>
                        <li>Cracking: Detecting cracks, inclusions, or other discontinuities within the material of boiler tubes.</li>
                        <li>Bonding Defects: Assessing bonding defects or lack of fusion between layers or materials in composite boiler tube structures.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous micro testing techniques and rigorous quality standards, we ensure comprehensive defect detection, enabling proactive maintenance and risk mitigation strategies to safeguard boiler systems and ensure their continued reliability and safety.
                    </p>
                </div>

            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Boiler Micro Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for boiler micro testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME Boiler and Pressure Vessel Code (BPVC), Section V: Nondestructive Examination: Provides guidelines for the use of micro testing techniques in examining the integrity of materials, including boiler tubes.</li>
                        <li>ASTM International Standards: ASTM standards such as ASTM E213, ASTM E213-20, ASTM E213-20a, and others offer comprehensive guidance on micro testing procedures for various applications, including boiler tube inspection.</li>
                        <li>API Standards: Standards from the American Petroleum Institute (API), such as API RP 5A5, API 5CT, and API RP 5UE, address specific requirements for micro testing of tubing used in oil and gas exploration and production.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our boiler micro testing processes, ultimately contributing to the integrity and performance of critical assets.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Boilermicro
