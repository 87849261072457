import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Rope2 = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Rope Access with Painting Services in Chennai |Rope Access with Painting Services in Padappai |Rope Access with Painting Services in Mumbai |Rope Access with Painting Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Rope Access with Painting services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Rope Access with Painting Services in Chennai ,Rope Access with Painting Services in Mumbai ,Rope Access with Painting Services in Haryana ,Rope Access with Painting Services in India,Best Rope Access with Painting services in Padappai,Best Rope Access with Painting Services in Chennai,Best Rope Access with Painting Services in Bangalore" />
                <meta name="keywords" content="Best Rope Access with Painting services in Andhra Pradesh,Best Rope Access with Painting services in Arunachal Pradesh,Best Rope Access with Painting services in Assam,Best Rope Access with Painting services in Bihar,Best Rope Access with Painting services in Chhattisgarh,Best Rope Access with Painting services in Goa,Best Rope Access with Painting services in Gujarat,Best Rope Access with Painting services in Haryana,
                        Best Rope Access with Painting services in Himachal Pradesh,Best Rope Access with Painting services in Jammu and kashmir,Best Rope Access with Painting services in Jharkhand,Best Rope Access with Painting services in Karnataka,Best Rope Access with Painting services in Kerala,Best Rope Access with Painting services in Madhya Pradesh,Best Rope Access with Painting services in Maharashtra,Best Rope Access with Painting services in Manipur,Best Rope Access with Painting services in Meghalaya,
                    Best Rope Access with Painting services in Mizoram,Best Rope Access with Painting services in Nagaland,Best Rope Access with Painting services in Odisha,Best Rope Access with Painting services in Punjab,Best Rope Access with Painting services in Rajasthan,Best Rope Access with Painting services in Sikkim,Best Rope Access with Painting services in TamilNadu,Best Rope Access with Painting services in Telengana,Best Rope Access with Painting services in Tripura,Best Rope Access with Painting services in Uttar Pradesh,
                   Best Rope Access with Painting services in Uttarakhand,Best Rope Access with Painting services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope2.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/RopeAccesswithNDT' className='decoration-transparent text-gray-700 font-medium'>Rope Access with NDT</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border border-neutral-400 mt-4 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/RopeAccesswithPainting' className='decoration-transparent text-gray-700 font-medium'>Rope Access with Painting</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className=' p-2 mt-4 font-bold text-xl underline '><h3>Explore Other Services:</h3></li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex  justify-between p-2 '>
                                                    <div>
                                                        <a href='/VisualTesting' className='decoration-transparent text-gray-700 font-medium'>Visual Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium'>Penetrant Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium'>Magnetic Particle Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium'>Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent text-gray-700 font-medium'>Radiography Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/PositiveTesting' className='decoration-transparent text-gray-700 font-medium'>Positive Material Identification</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent text-gray-700 font-medium'>Hardness Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border p-2 mt-4 border-neutral-400 font-bold text-xl hover:bg-blue-900 '>
                                                <div className='flex justify-between p-2'>
                                                    <div>
                                                        <a href='/LeakTesting' className='decoration-transparent text-gray-700 font-medium'>Leak Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Rope Access with Painting</h1>
                                    <img src='assets/ameee/rope1.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT </strong>offers comprehensive rope access services, including expert painting solutions, ensuring efficient and high-quality maintenance for structures and assets in challenging environments.
                                        Rope access with painting refers to a method of performing painting and maintenance work on structures, buildings, or assets using specialized rope access techniques. This approach involves trained technicians utilizing ropes, harnesses, and other safety equipment to access difficult-to-reach areas safely and efficiently.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Rope Access with Painting is conducted to assess the proficiency and competence of technicians in performing painting tasks while utilizing rope access techniques. This evaluation ensures that technicians can effectively carry out painting activities in challenging-to-reach areas, such as tall structures or confined spaces, using rope access methods. By certifying technicians, this test guarantees compliance with industry standards and safety regulations. Rope Access with Painting entails a thorough assessment of technician skills, adherence to safety protocols, and proficiency in painting techniques, aiming to demonstrate their capability to consistently perform painting tasks and ensure adherence to defined criteria for safety and quality.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                   <div>
                                   <div className='mt-5' style={{ width: '330px' }}>
                                        <img src='assets/ameee/rope.jpg' className=' border rounded-lg'  />
                                    </div>
                                   </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <div>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className='text-gray-500 text-lg'>Rope Access with Painting finds its origins in the need to certify technicians and ensure effective painting of structures using rope access techniques. It emerged as a structured approach to evaluate technician competence and ensure adherence to safety standards and regulations. Initially developed to verify painting techniques and proficiency in challenging environments, Rope Access with Painting has evolved into a critical aspect of technician certification and safety assurance practices across various industries.</p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg'>Rope Access with Painting assesses the proficiency and capabilities of technicians through meticulous testing and evaluation of rope access techniques, adherence to safety protocols, and proficiency in painting tasks. By maintaining comprehensive records of qualified technicians, Rope Access with Painting ensures confidence in technician competence and performance, guaranteeing alignment with safety standards and regulatory requirements in various challenging environments where rope access and painting are utilized.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg hover:bg-blue-900 '>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 hover:bg-blue-900  border-neutral-400 text-lg'>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 hover:bg-blue-900  border-neutral-400 text-lg'>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 hover:bg-blue-900  border-neutral-400 text-lg'>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 hover:bg-blue-900  border-neutral-400 text-lg'>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 hover:bg-blue-900  border-neutral-400 text-lg '>
                                                <div className='flex  justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/rope1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/rope2.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Versatility: </b>  Rope access methods can be adapted to various structures and environments, making them suitable for a wide range of maintenance and painting projects.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Cost-Effective:</b>Generally, rope access requires less equipment and setup time compared to alternatives, resulting in cost savings for maintenance projects.<br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Efficiency:</b> Rope access allows technicians to access difficult-to-reach areas quickly and efficiently, minimizing downtime and completing tasks faster.<br /><br />
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Training Requirements:</b>: Proper training and certification are essential for rope access technicians, which can require time and investment.<br /><br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Weather Dependency: </b> Rope access work may be affected by adverse weather conditions, which can pose challenges and lead to delays in project completion.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Payload:</b> Rope access systems have weight limitations, which may restrict the equipment and materials that technicians can bring with them to the work site.</li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                             <div>
                             <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                             </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/rope.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div >
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with Painting - How We Work</h4>
                    <p className='font-semibold'>Rope Access with Painting, conducted by AMEEE NDT, is essential for evaluating and certifying the proficiency and competency of technicians in performing painting tasks while utilizing rope access techniques. We meticulously administer tests and assessments of rope access skills, adherence to safety protocols, and proficiency in painting techniques to qualify technicians.
                        Our detailed records serve as documentation of certified technicians, outlining the testing procedures, results, and alignment with safety standards. This instills confidence in technician competence and performance, ensuring compliance with regulatory requirements and safety standards in various challenging environments where rope access and painting are utilized.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with Painting Equipment & Consumables</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, resources, and documentation tailored specifically for rope access with painting tasks.
                        Our product lineup includes specialized painting equipment suitable for rope access, safety gear for technicians, inspection report templates, and educational materials.
                        Additionally, we provide technical support, training sessions, and consulting services to assist organizations in conducting rope access with painting tasks and certifying technicians.
                        With our dedicated support and expertise, we empower organizations to evaluate and certify the proficiency of technicians, ensuring adherence to safety standards and quality in painting tasks conducted through rope access methods.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with Painting Standards</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as ASNT, IRATA, and SPRAT for rope access with painting tasks.
                        Our commitment to these standards ensures the evaluation and certification of technician proficiency and capabilities in alignment with established industry practices and safety regulations.
                    </p>

                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with Painting Parameters</h4>
                    <p className='font-semibold'>
                        In rope access with painting, we assess parameters including rope access proficiency, safety practices, painting technique proficiency, and adherence to painting protocols.
                        Our thorough evaluation guarantees the assessment and certification of technician proficiency and capabilities to perform painting tasks safely and effectively while utilizing rope access techniques, ensuring compliance with industry standards and safety regulations.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Anomalies Detected by Rope Access with Painting</h4>
                    <p className='font-semibold'>
                        Through rope access with painting, AMEEE NDT proficiently identifies anomalies such as paint defects, improper surface preparation, and deviations from specified painting protocols.
                        Our meticulous testing procedures ensure comprehensive anomaly detection, facilitating corrective measures and enhancements in technician proficiency and techniques to uphold industry standards and quality compliance across various challenging environments.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Rope Access with Painting</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard guidelines and specifications such as ASNT, IRATA, and SPRAT for rope access with painting tasks.
                        Our commitment to these references ensures the evaluation and certification of technician proficiency and capabilities in alignment with established industry practices and safety regulations, supporting the reliability and safety compliance of painting tasks conducted through rope access methods.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Rope2
