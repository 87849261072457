import React from 'react'
import { Carousel, Nav } from 'react-bootstrap';
function Wave() {
    return (
        <div>
          
            <div style={{ position: 'absolute', left: '-50%', top: '-190px', width: '500px' }}>
            <h4 className='text-blue-900 font-bold text-center'>Our Experiences</h4>
                <Carousel interval={5000}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/penetrant.jpg"
                            alt="First slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5 className='text-2xl '>
                                Conventional NDT
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/iris Tube.jpg"
                            alt="Second slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5>
                                Advance NDT
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/half-cell3.jpeg"
                            alt="Third slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5>
                                Civil NDT
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/welding.jpg"
                            alt="Third slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5>
                                Welding Inspection
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/coat2.jpg"
                            alt="Third slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5>
                               Coating Inspection
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="assets/ameee/rope1.jpg"
                            alt="Third slide"
                            style={{ height: '300px', borderRadius: '20px' }}
                        />
                        <Carousel.Caption>
                            <h5>
                               Rope Access
                            </h5>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Wave
