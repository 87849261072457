import React from 'react'
import First from './First'
import Foooter from './Footer'
import Third from './Third'
import Fourth from './fourth'
import Fifth from './fifth'
import Seventh from './seventh'
import { Eight } from '../Eight'
import { Nine } from './Nine'
import FancyText from '@carefully-coded/react-text-gradient';
import { Helmet } from 'react-helmet'
import { Card } from 'react-bootstrap'
import Timeline from './Timeline'
import About1 from './About1'
function About() {
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className='fixed w-full z-10 ' style={{ backgroundColor: '#020225' }}>
        <First />
      </div>
      <div className=''>
        <About1 />
      </div>

      <div className='p-0 bg-cover bg-center relative  water-ripple-container mt-20' style={{ backgroundImage: `url('a1.jpg')` }}>
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <Third />
      </div>
      <div className='p-5'>
        <Fifth />
      </div>
      <div className='p-5'>
        <Eight />
      </div>
      <section className=' bg-blue-100 p-5'>
        <div>
          <div className='text-center font-extrabold'>
            <FancyText
              gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
              animate
              animateDuration={5000}
            >
              <h2 className=' font-extrabold text-5xl'>
                We Are Working
              </h2>
            </FancyText>
            <p className='text-2xl text-blue-900'>Explore Exciting Opportunities:Join Us and Make a Difference!</p>
          </div>
        </div>
        <div className='row p-5'>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/pt.jpeg' className='zooms-image' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>01</div>
            </div>
            <div className='process-content'>
              <h4 className='text-2xl text-blue-900 font-bold'>Conventional NDT</h4>
              <p className='text-xl text-gray-700 text-left  font-semibold'>Experience the Precision:Elevate your quality standards with our Conventional NDT services!</p>
            </div>
          </div>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/Advance NDT.jpg' className='zooms-image' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count  bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>02</div>
            </div>
            <div className='process-content'>
              <h4 className='text-2xl text-blue-900 font-bold'>Advance NDT</h4>
              <p className='text-xl text-gray-700 text-left font-semibold'>Breaking Boundaries:Unleash the power of Advance NDT solutions with us!</p>
            </div>
          </div>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/welding.jpg' className='zooms-image' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>03</div>
            </div>
            <div className='process-content'>
              <h4 className='text-2xl text-blue-900 font-bold'>Welding Inspection</h4>
              <p className='text-xl text-gray-700 text-left font-semibold'>Ensure Excellence:Trust our expertise for comprehensive Welding Inspection solutions!</p>
            </div>
          </div>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/rope.jpg' className='zooms-image' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>04</div>
            </div>
            <div className='process-content'>
              <h4 className='text-2xl text-blue-900 font-bold'>Rope Access</h4>
              <p className='text-xl text-gray-700 text-left font-semibold'>Reach new heights safely:Experience superior Rope Access solutions with us!</p>
            </div>
          </div>
        </div>
      </section>
      <div className=''>
        <Nine />
      </div>

    </div>
  )
}

export default About
