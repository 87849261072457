import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'
import FancyText from '@carefully-coded/react-text-gradient';
import { Helmet } from 'react-helmet'
function Coat() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Coating Inspection Services in Chennai |Coating Inspection Services in Padappai |Coating Inspection Services in Mumbai |Coating Inspection Services in Haryana |Coating Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Coating Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Coating Inspection Services in Chennai ,Coating Inspection Services in Mumbai ,Coating Inspection Services in Haryana ,Coating Inspection Services in India,Best Coating Inspection services in Padappai,Best Coating Inspection Services in Chennai,Best Coating Inspection Services in Bangalore " />
                <meta name="keywords" content="Best Coating Inspection services in Andhra Pradesh,Best Coating Inspection services in Arunachal Pradesh,Best Coating Inspection services in Assam,Best Coating Inspection services in Bihar,Best Coating Inspection services in Chhattisgarh,Best Coating Inspection services in Goa,Best Coating Inspection services in Gujarat,Best Coating Inspection services in Haryana,
                    Best Coating Inspection services in Himachal Pradesh,Best Coating Inspection services in Jammu and kashmir,Best Coating Inspection services in Jharkhand,Best Coating Inspection services in Karnataka,Best Coating Inspection services in Kerala,Best Coating Inspection services in Madhya Pradesh,Best Coating Inspection services in Maharashtra,Best Coating Inspection services in Manipur,Best Coating Inspection services in Meghalaya,
                  Best Coating Inspection services in Mizoram,Best Coating Inspection services in Nagaland,Best Coating Inspection services in Odisha,Best Coating Inspection services in Punjab,Best Coating Inspection services in Rajasthan,Best Coating Inspection services in Sikkim,Best Coating Inspection services in TamilNadu,Best Coating Inspection services in Telengana,Best Coating Inspection services in Tripura,Best Coating Inspection services in Uttar Pradesh,
                  Best Coating Inspection services in Uttarakhand,Best Coating Inspection services in West Bengal, Coating Inspection services in Andhra Pradesh,Coating Inspection services in Arunachal Pradesh,Coating Inspection services in Assam, Coating Inspection services in Bihar,Coating Inspection services in Chhattisgarh,Coating Inspection services in Goa, Coating Inspection services in Gujarat,Coating Inspection services in Haryana,
                  Coating Inspection services in Himachal Pradesh,Coating Inspection services in Jammu and kashmir,Coating Inspection services in Jharkhand, Coating Inspection services in Karnataka, Coating Inspection services in Kerala,Coating Inspection services in Madhya Pradesh,Coating Inspection services in Maharashtra,Coating Inspection services in Manipur, Coating Inspection services in Meghalaya,
                  Coating Inspection services in Mizoram,Best Coating Inspection services in Nagaland,Coating Inspection services in Odisha,Coating Inspection services in Punjab,Coating Inspection services in Rajasthan,Coating Inspection services in Sikkim,Coating Inspection services in TamilNadu,Coating Inspection services in Telengana,Coating Inspection services in Tripura,Coating Inspection services in Uttar Pradesh,
                  Coating Inspection services in Uttarakhand, Coating Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Coating Inspection
                            <span className='ml-24'>
                                <span className='font-bold text-5xl'>
                                    <FancyText
                                        gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                                        animate
                                        animateDuration={5000}
                                    >
                                        Rope Access
                                    </FancyText>
                                </span>
                            </span>
                        </FancyText>
                    </h1>

                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='CoatingInspection' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Coating Inspection</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers specialized coating testing for robust asset protection.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='CoatingInspection' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='CoatingInspection' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\coat.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='RopeAccesswithNDT' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Rope Access with NDT</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers versatile rope access combined with NDT for thorough inspections.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='RopeAccesswithNDT' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RopeAccesswithNDT' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rope.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='RopeAccesswithPainting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Rope Access with Painting</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers versatile rope access painting for thorough coating application.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='RopeAccesswithPainting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RopeAccesswithPainting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rope.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Coat
