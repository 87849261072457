import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'
import FancyText from '@carefully-coded/react-text-gradient';
import { Helmet } from 'react-helmet'
function Welding() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Welding Inspection Services in Chennai |Welding Inspection Services in Padappai |Welding Inspection Services in Mumbai |Welding Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Welding Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Welding Inspection Services in Chennai ,Welding Inspection Services in Mumbai ,Welding Inspection Services in Haryana ,Welding Inspection Services in India,Best Welding Inspection services in Padappai,Best Welding Inspection Services in Chennai,Best Welding Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Welding Inspection services in Andhra Pradesh,Best Welding Inspection services in Arunachal Pradesh,Best Welding Inspection services in Assam,Best Welding Inspection services in Bihar,Best Welding Inspection services in Chhattisgarh,Best Welding Inspection services in Goa,Best Welding Inspection services in Gujarat,Best Welding Inspection services in Haryana,
                        Best Welding Inspection services in Himachal Pradesh,Best Welding Inspection services in Jammu and kashmir,Best Welding Inspection services in Jharkhand,Best Welding Inspection services in Karnataka,Best Welding Inspection services in Kerala,Best Welding Inspection services in Madhya Pradesh,Best Welding Inspection services in Maharashtra,Best Welding Inspection services in Manipur,Best Welding Inspection services in Meghalaya,
                    Best Welding Inspection services in Mizoram,Best Welding Inspection services in Nagaland,Best Welding Inspection services in Odisha,Best Welding Inspection services in Punjab,Best Welding Inspection services in Rajasthan,Best Welding Inspection services in Sikkim,Best Welding Inspection services in TamilNadu,Best Welding Inspection services in Telengana,Best Welding Inspection services in Tripura,Best Welding Inspection services in Uttar Pradesh,
                   Best Welding Inspection services in Uttarakhand,Best Welding Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Welding Inspection
                        </FancyText>
                    </h1>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='WeldingProcedureSpecification' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Welding Procedure Specification</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT crafts precise Welding Procedure Specifications for quality results.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='WeldingProcedureSpecification' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='WeldingProcedureSpecification' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\welding.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='WeldingProcedureQualificationRecord' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Welding Procedure Qualification Record</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT verifies welding procedures for industry compliance.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='WeldingProcedureQualificationRecord' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='WeldingProcedureQualificationRecord' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\welding.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>03</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='WeldersPerformanceQualificationTest' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Welders Performance Qualification Test</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT tests welders for industry standards compliance.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='WeldersPerformanceQualificationTest' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='WeldersPerformanceQualificationTest' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\welding.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welding
