import { faCircle, faClock, faComments, faLocationDot, faEnvelope, faPhone, faMobileAndroid, faRobot, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import CardGroup from 'react-bootstrap/CardGroup';
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';
import '../Header.css'
import Breathe from './Breathe';
const Foooter = () => {
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  return (
    <div>
      <Helmet>
        <title>ndt services in Chennai,Chengalpattu,Kancheepuram,Thiruvallur, Padappai</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Conventional NDT services in India,Best Advance NDT services in India ,Best Civil NDT services in India,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | ndt services in Chennai,Chengalpattu,Kancheepuram,Thiruvallur, Padappai" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | ndt services in Chennai,Chengalpattu,Kancheepuram,Thiruvallur, Padappai" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className=' bgRgb pb-10 '>
        <div className='justify-center sm:block md:block lg:flex'>
          <div className='mx-3'>
            <Breathe />
          </div>

          <div className='mx-3'>
            <Card className='text-white' style={{ backgroundColor: 'transparent', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0', height: '360px', width: '310px' }}>
              <Card.Header className=' text-white text-2xl font-bold text-center'>Categories</Card.Header>
              <Card.Body>
                <Card.Text>
                  <ul className='p-0  text-lg  hover:text-blue-500 text-left'>
                    <li className='p-2 shadow-md'><FontAwesomeIcon icon={faCircle} className='text-blue-500 mr-2' /><span className='underline'>Conventional NDT</span></li>
                    <li className='p-2 shadow-md'><FontAwesomeIcon icon={faCircle} className='text-blue-500 mr-2' /><span className='underline'>Advance NDT</span></li>
                    <li className='p-2 shadow-md'><FontAwesomeIcon icon={faCircle} className='text-blue-500 mr-2' /><span className='underline'>Welding Inspection</span></li>
                    <li className='p-2 shadow-md'><FontAwesomeIcon icon={faCircle} className='text-blue-500 mr-2' /><span className='underline'>Rope Access</span></li>
                    <li className='p-2 shadow-md'><FontAwesomeIcon icon={faCircle} className='text-blue-500 mr-2' /><span className='underline'>Coating Inspection</span></li>
                  </ul>
                </Card.Text>

              </Card.Body>

            </Card>
          </div>
          <div className='mx-3'>
            <Card className='text-white' style={{ backgroundColor: 'transparent', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0', height: '360px', width: '310px' }}>
              <Card.Header className='text-2xl font-bold' >Office Hours</Card.Header>
              <Card.Body>
                <Card.Text>
                  <div className='justify-between px-3 sm:block md:block lg:flex'>
                    <div className='font-bold text-lg text-left'>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Mon:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Tue:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Wed:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Thu:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Fri:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Sat:</div>
                      <div className='p-1'><FontAwesomeIcon icon={faClock} className='pr-2 text-xl text-sky-600' />Sun:</div>
                    </div>
                    <div className=' text-lg'>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>08:30-08:30</div>
                      <div className='p-1'>Closed</div>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className='mx-2'>
            <Card className='text-white' style={{ backgroundColor: 'transparent', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0', height: '360px', width: '310px' }}>
              <Card.Header className=' font-bold text-2xl'>Locate Us</Card.Header>
              <Card.Body>
                <Card.Text>
                  <div className='mt-2  sm:block md:block lg:flex'>
                    <div className='px-3  text-lg '>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.312618049315!2d80.02488137483988!3d12.8876085874201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f7859b386a4d%3A0x609dab6a9988aba6!2sAMEEE%20NDT%20Inspection%20Engineers%20Private%20Limited!5e0!3m2!1sen!2sin!4v1712225109041!5m2!1sen!2sin"
                        width="220"
                        height="220"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className='p-3  sm:block md:block lg:flex'>
          <div className='p-5 flex lg:flex'>
            <Card className={`p-0 text-center justify-center transform-gpu transition-transform ${isHovered1 ? '' : ''}`}
              style={{
                backgroundColor: isHovered1 ? ' #003bff' : '#fbf7f723',
                width: '100px',
                height: 'auto',
                boxShadow: '0 0.2px 0 0.2px gray',
                borderRadius: '0',
                transition: 'transform 0.3s ease',
                color: isHovered1 ? 'white' : 'blue',
                transform: isHovered1 ? 'rotateX(90deg)' : 'rotateY(0deg)',
              }}
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)}>
              <FontAwesomeIcon icon={faLocationDot} className='h-11' />
            </Card>
            <Card style={{ backgroundColor: 'transparent', width: '300px', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0' }}>
              <span className='text-white px-3 py-3 font-bold text-left text-2xl'>Address</span>
              <p className='text-white px-3 text-left'>
                <a href='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.312618049315!2d80.02488137483988!3d12.8876085874201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f7859b386a4d%3A0x609dab6a9988aba6!2sAMEEE%20NDT%20Inspection%20Engineers%20Private%20Limited!5e0!3m2!1sen!2sin!4v1712225109041!5m2!1sen!2sin' className='text-white decoration-transparent'>Padappai, TamilNadu.</a>

              </p>
            </Card>
          </div>

          <div className='flex text-white p-5 lg:flex'>
            <Card
              className={`p-0 text-center justify-center transform-gpu transition-transform ${isHovered2 ? '' : ''}`}
              style={{
                backgroundColor: isHovered2 ? ' #003bff' : '#fbf7f723',
                width: '100px',
                height: 'auto',
                boxShadow: '0 0.2px 0 0.2px gray',
                borderRadius: '0',
                transition: 'transform 0.3s ease',
                color: isHovered2 ? 'white' : 'blue',
                transform: isHovered2 ? 'rotateX(90deg)' : 'rotateY(0deg)',
              }}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
            >
              <FontAwesomeIcon icon={faEnvelope} className='h-11 ' />

            </Card>
            <Card style={{ backgroundColor: 'transparent', width: '300px', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0' }}>

              <span className='text-white px-3 py-3 font-bold text-left text-2xl'>Write Us</span>
              <p className='text-left'> <a href="mailto:inspection@ameeendt.com" className='text-white px-3' style={{ textDecoration: 'none' }}> inspection@ameeendt.com</a></p>
            </Card>
          </div>

          <div className='flex p-5  lg:flex'>
            <Card className={`p-0 text-center justify-center transform-gpu transition-transform ${isHovered3 ? '' : ''}`}
              style={{
                backgroundColor: isHovered3 ? ' #003bff' : '#fbf7f723',
                width: '100px',
                height: 'auto',
                boxShadow: '0 0.2px 0 0.2px gray',
                borderRadius: '0',
                transition: 'transform 0.3s ease',
                color: isHovered3 ? 'white' : 'blue',
                transform: isHovered3 ? 'rotateX(90deg)' : 'rotateY(0deg)',
              }}
              onMouseEnter={() => setIsHovered3(true)}
              onMouseLeave={() => setIsHovered3(false)}>
              <FontAwesomeIcon icon={faPhone} className='h-11' />
            </Card>
            <Card style={{ backgroundColor: 'transparent', width: '300px', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0' }}>
              <span className='text-white px-3 py-3 font-bold text-left text-2xl'>Call Us</span>
              <a href='tel:+91 8169361089' className='text-white text-left px-3' style={{ textDecoration: 'none' }}>+91 8169361089</a>
            </Card>
          </div>
        </div>
        <div>
          <Card style={{ backgroundColor: 'transparent', width: '100%', boxShadow: '0 0.2px 0 0.2px gray', borderRadius: '0' }} className=''>
            <div className='lg:flex lg:flex-wrap'>
              <div className=''>
                <h6 className='text-white sm:text-left sm:ml-5'>AMEEE NDT Inspection | Training | Consulting</h6>
                <p className=' text-white  sm:text-left sm:ml-5'>Copyright &copy;AMEEE NDT Inspection 2024</p>
              </div>
              <div className='ml-48'>
                <ul className='  text-white text-justify  sm:block md:block lg:flex'>
                  <li className='p-2 '><a href='/' className=' text-lg text-white '>Home</a></li>
                  <li className='p-2'><a href='/About' className=' text-lg text-white'>About Us</a></li>
                  <li className='p-2'><a href='/Services' className=' text-lg text-white'>Services</a></li>
                  <li className='p-2'><a href='/Services' className=' text-lg text-white'>Applications</a></li>
                  <li className='p-2'><a href='/Careers' className=' text-lg text-white'>Careers</a></li>
                  <li className='p-2'><a href='/Contact' className=' text-lg text-white'>Contact Us</a></li>
                  <li className='p-2'><a href='/Contact' className=' text-lg text-white'>Request For Quote</a></li>
                </ul>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>

  )
}

export default Foooter
