import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile, faEnvelope, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown, faGears, faGear, faCertificate, faMedal } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import Owldemo1 from '../Owldemo1';
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';
import Owldemo from './Owldemo';
const Seventh = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [awardVisible, setAwardVisible] = useState(true)
  const [funfactVisible, setFunfactVisible] = useState(false)
  const [achievementVisible, setAchievementVisible] = useState(false)
  const [certificationVisible, setCertificationVisible] = useState(false)
  const toggleAward = () => {
    setAwardVisible(true);
    setFunfactVisible(false);
    setAchievementVisible(false);
    setCertificationVisible(false);
  }
  const toggleFunfact = () => {
    setAwardVisible(false);
    setFunfactVisible(true);
    setAchievementVisible(false);
    setCertificationVisible(false);
  }
  const toggleAchievement = () => {
    setAwardVisible(false);
    setFunfactVisible(false);
    setAchievementVisible(true);
    setCertificationVisible(false);
  }
  const toggleCertification = () => {
    setAwardVisible(false);
    setFunfactVisible(false);
    setAchievementVisible(false);
    setCertificationVisible(true);
  }



  return (
    <div>
      <Helmet>
        <title>Our Future Plans - AMEEE NDT | Future Plans</title>
        <meta name="description" content="Explore the future plans and vision of AMEEE NDT in Padappai. We are committed to expanding our non-destructive testing (NDT) services, embracing new technologies, and maintaining excellence in serving our clients across various industries." />
        <meta name="keywords" content="AMEEE NDT, future plans, vision, non-destructive testing,best ut services in India,Best conventional NDT services in India,Best Advance NDT services in India,Best civil NDT services in India,Dye Penetrant services,PMI testing,Eddy Current Testing, Padappai, expansion, technology, excellence" />
        <meta property="og:title" content="Our Future Plans - AMEEE NDT" />
        <meta property="og:description" content="Explore the future plans and vision of AMEEE NDT in Padappai. We are committed to expanding our non-destructive testing (NDT) services, embracing new technologies, and maintaining excellence in serving our clients across various industries." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Our Future Plans - AMEEE NDT" />
        <meta name="twitter:description" content="Explore the future plans and vision of AMEEE NDT in Padappai. We are committed to expanding our non-destructive testing (NDT) services, embracing new technologies, and maintaining excellence in serving our clients across various industries." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='funfact relative opacity-85' style={{ backgroundImage: 'url(a2.jpg)' }}>
        <div className='text-center mt-10'>
          <span className='p-0 text-center text-white text-5xl font-bold '>Technology for tomorrow</span>
          <div className='flex flex-col justify-between overflow-y-auto scroll-container sm:block md:block lg:flex'>
            <FancyText
              gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
              animate
              animateDuration={1000}
            >
              <h1 className='px-3 mt-3'>Outstanding</h1>
            </FancyText>
            <div className='animation mt-3'>
              <div className='d-flex flex-wrap justify-center'>
                <Button className='font-bold mx-3' style={{ boxShadow: '0 3px 5px' }} onClick={toggleAward}>Awards

                </Button>

                <Button className='font-bold mx-3' style={{ boxShadow: '0 3px 5px ' }} onClick={toggleFunfact}>Funfacts</Button>
                <Button className='font-bold mx-3' style={{ boxShadow: '0 3px 5px' }} onClick={toggleAchievement}>Achievements</Button>
                <Button className='font-bold mx-3' style={{ boxShadow: '0 3px 5px' }} onClick={toggleCertification}>Certifications</Button>
              </div>
            </div>

            <p className=' font-bold p-5 mt-5' style={{ color: 'white', fontSize: '25px' }}>Our future plans include diversifying our service portfolio to encompass advanced NDT techniques, expanding our expertise in rope access solutions, and enhancing our capabilities in coating inspection, ensuring comprehensive support for our clients across multiple industries.
            </p>
          </div>
        </div>

        <div className='flex flex-row justify-center mt-4'>
          <div className='circle-border col-lg-4 w-1/2 sm:w-auto'>
            <img src="a3.jpg" alt="funfact" className='w-full h-auto sm:w-96 sm:h-96 rounded-full' />
          </div>
          <div className='pl-24'>
            <div className='flex justify-between'>
              <div>
                {awardVisible && <Award />}
              </div>
              <div>
                {funfactVisible && <Funfact />}
              </div>
              <div>
                {achievementVisible && <Achievement />}
              </div>
              <div>
                {certificationVisible && <Certification />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

function Award() {
  return (
    <div>
      <Owldemo1 />
    </div>
  )
}


function Funfact() {
  return (
    <div className='text-white font-bold'>
      <div className='text-left'>
        <h5 className=' underline '>
          Did you know :
        </h5>
        <p className=' font-semibold'>AMEEE NDT Service and Inspection has conducted over 10,000 inspections worldwide since its inception?</p>
      </div>
      <div className='text-left'>
        <h5 className=' underline'>Funfact :</h5>
        <p className=' font-semibold'> The average AMEEE NDT Service and Inspection technician spends over 100 hours per year in continuous training and skill development, ensuring top-notch expertise in the field.</p>
      </div>
      <div className='text-left'>
        <h5 className=' underline'>Funfact :</h5>
        <p className=' font-semibold'> AMEEE NDT Service and Inspection's equipment fleet spans the equivalent length of 20 football fields when lined up end-to-end.</p>
      </div>
    </div>
  )
}


function Achievement() {
  return (
    <div className='text-white font-bold'>
      <div className='text-left'>
        <h3>Global Expansion Milestone: </h3><br />
        <p>AMEEE NDT Service and Inspection celebrates its successful expansion into five new international markets within the past year, solidifying its global presence.</p>
      </div>
      <div className='text-left'>
        <h3> Client Testimonials : </h3><br />
        <p>AMEEE NDT Service and Inspection proudly showcases a collection of client testimonials, highlighting the positive impact of its services on various industries worldwide.</p>
      </div>
    </div>
  )
}

function Certification() {
  const [iconColor, setIconColor] = useState('rgb(59, 238, 221)');

  useEffect(() => {

    const toggleIconColor = () => {
      const newColor = iconColor === '#a8a632' ? 'rgb(59, 238, 221)' : '#a8a632';
      setIconColor(newColor);
    };

    toggleIconColor();
  }, []);
  return (
    <div>
      <div className=' text-white text-left '>
        <div className='text-left text-white font-bold'>
          <h3>Our Certifications:</h3><br />
          <p>AMEEE NDT Service and Inspection proudly showcases a collection of certifications earned by our company, highlighting our commitment to quality and safety in various industries worldwide.</p>
        </div>
        <div >
          <ul >
            <li className='text-3xl font-bold'>
              <FancyText
                gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                animate
                animateDuration={5000}
              >
                <FontAwesomeIcon icon={faMedal} className=' mr-5 text-4xl' style={{ color: iconColor, transition: 'color 0.5s' }}

                />
              </FancyText>
              ISO 9001:2015

            </li>
            <li className='text-3xl font-bold mt-3'>
              <FancyText
                gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                animate
                animateDuration={5000}
              >
                <FontAwesomeIcon icon={faMedal} className=' mr-5 text-4xl' style={{ color: iconColor, transition: 'color 0.5s' }}

                />
              </FancyText>
              ISO 9001:2015

            </li>
            <li className='text-3xl font-bold mt-3'>
              <FancyText
                gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                animate
                animateDuration={5000}
              >
                <FontAwesomeIcon icon={faMedal} className=' mr-5 text-4xl' style={{ color: iconColor, transition: 'color 0.5s' }}

                />
              </FancyText>
              ISO 9001:2015

            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Seventh