import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'
import FancyText from '@carefully-coded/react-text-gradient';
import '../Header.css'
import { Helmet } from 'react-helmet'
function Rope() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Rope Access Services in Chennai |Rope Access Services in Padappai |Rope Access Services in Mumbai |Rope Access Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Rope Access services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Rope Access Services in Chennai ,Rope Access Services in Mumbai ,Rope Access Services in Haryana ,Rope Access Services in India,Best Rope Access services in Padappai,Best Rope Access Services in Chennai,Best Rope Access Services in Bangalore" />
                <meta name="keywords" content="Best Rope Access services in Andhra Pradesh,Best Rope Access services in Arunachal Pradesh,Best Rope Access services in Assam,Best Rope Access services in Bihar,Best Rope Access services in Chhattisgarh,Best Rope Access services in Goa,Best Rope Access services in Gujarat,Best Rope Access services in Haryana,
                        Best Rope Access services in Himachal Pradesh,Best Rope Access services in Jammu and kashmir,Best Rope Access services in Jharkhand,Best Rope Access services in Karnataka,Best Rope Access services in Kerala,Best Rope Access services in Madhya Pradesh,Best Rope Access services in Maharashtra,Best Rope Access services in Manipur,Best Rope Access services in Meghalaya,
                    Best Rope Access services in Mizoram,Best Rope Access services in Nagaland,Best Rope Access services in Odisha,Best Rope Access services in Punjab,Best Rope Access services in Rajasthan,Best Rope Access services in Sikkim,Best Rope Access services in TamilNadu,Best Rope Access services in Telengana,Best Rope Access services in Tripura,Best Rope Access services in Uttar Pradesh,
                   Best Rope Access services in Uttarakhand,Best Rope Access services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Rope Access
                        </FancyText>
                    </h1>
                   
                    <div className='row p-5'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='RopeAccesswithNDT' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Rope Access with NDT</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers versatile rope access combined with NDT for thorough inspections.
                                            </div><br />
                                            <div className='items-readmore text-left '>
                                                <a href='RopeAccesswithNDT' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RopeAccesswithNDT' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rope.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='RopeAccesswithPainting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Rope Access with Painting</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers versatile rope access painting for thorough coating application.
                                            </div><br />
                                            <div className='items-readmore text-left '>
                                                <a href='RopeAccesswithPainting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RopeAccesswithPainting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rope.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rope
