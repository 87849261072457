import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Hammer = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Rebound Hammer Services in Chennai |Rebound Hammer Services in Padappai |Rebound Hammer Services in Mumbai |Rebound Hammer Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Rebound Hammer services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Rebound Hammer Services in Chennai ,Rebound Hammer Services in Mumbai ,Rebound Hammer Services in Haryana ,Rebound Hammer Services in India,Best Rebound Hammer services in Padappai,Best Rebound Hammer Services in Chennai,Best Rebound Hammer Services in Bangalore" />
                <meta name="keywords" content="Best Rebound Hammer services in Andhra Pradesh,Best Rebound Hammer services in Arunachal Pradesh,Best Rebound Hammer services in Assam,Best Rebound Hammer services in Bihar,Best Rebound Hammer services in Chhattisgarh,Best Rebound Hammer services in Goa,Best Rebound Hammer services in Gujarat,Best Rebound Hammer services in Haryana,
                        Best Rebound Hammer services in Himachal Pradesh,Best Rebound Hammer services in Jammu and kashmir,Best Rebound Hammer services in Jharkhand,Best Rebound Hammer services in Karnataka,Best Rebound Hammer services in Kerala,Best Rebound Hammer services in Madhya Pradesh,Best Rebound Hammer services in Maharashtra,Best Rebound Hammer services in Manipur,Best Rebound Hammer services in Meghalaya,
                    Best Rebound Hammer services in Mizoram,Best Rebound Hammer services in Nagaland,Best Rebound Hammer services in Odisha,Best Rebound Hammer services in Punjab,Best Rebound Hammer services in Rajasthan,Best Rebound Hammer services in Sikkim,Best Rebound Hammer services in TamilNadu,Best Rebound Hammer services in Telengana,Best Rebound Hammer services in Tripura,Best Rebound Hammer services in Uttar Pradesh,
                   Best Rebound Hammer services in Uttarakhand,Best Rebound Hammer services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Rebound Hammer.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Rebound1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Rebound3.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border group border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/UltraPulseVelocity' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ultra Pulse Velocity</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 group border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/ReboundHammer' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Rebound Hammer</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/HalfCellPotential' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Half-Cell Potential </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/ThicknessGauging' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Thickness Gauging</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Rebound Hammer</h1>
                                    <img src='assets/ameee/Rebound2.png' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT </strong> offers comprehensive rebound hammer testing services for assessing the compressive strength and structural integrity of concrete surfaces with precision and efficiency.The rebound hammer is a portable device used for non-destructive testing of concrete strength by measuring the rebound of a spring-loaded mass impacting the concrete surface, providing an indication of surface hardness and potential strength characteristics.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500  text-justify text-lg '>Rebound Hammer Testing is used to assess the compressive strength and uniformity of concrete structures by measuring the rebound of a spring-loaded hammer. It provides insights into the structural integrity and quality of concrete surfaces.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/Rebound1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className=' text-gray-500 text-lg'>Rebound Hammer Testing was developed to assess the compressive strength of concrete structures. It originated in the mid-20th century and gained prominence in civil engineering for its simplicity and effectiveness. Over time, it has become a standard method for evaluating concrete quality and integrity in various industries.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-left text-gray-700 text-lg '>Rebound Hammer Testing is a non-destructive testing method used to assess the compressive strength of concrete. It operates on the principle of measuring the rebound of a spring-loaded hammer after it impacts the concrete surface.
                                                The rebound distance of the hammer correlates with the compressive strength of the concrete, providing insights into its structural integrity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 text-lg '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/Rebound2.png' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/Rebound3.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Non-Destructive:</b> Rebound hammer testing is non-destructive, meaning it doesn't damage the concrete structure being tested.<br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Portability:  </b> The rebound hammer is a portable device, allowing for on-site testing in various locations and positions, including vertical and overhead surfaces.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Quick and Efficient:</b>  It provides rapid results, allowing for immediate assessment of concrete strength without the need for laboratory testing. </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Surface Sensitivity: </b>  Results can be influenced by factors such as surface conditions, moisture content, and texture, leading to potential inaccuracies.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Depth Of Penetration: </b> The rebound hammer only assesses the surface hardness of concrete, providing limited information about the overall strength and condition of the structure.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b> Subjectivity: </b> Interpretation of rebound values may vary between operators, leading to inconsistencies in results.</li><br /><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/Rebound2.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Rebound Hammer Testing - How We Work</h4>
                    <p className=' font-semibold'>Rebound Hammer Testing, conducted by AMEEE NDT, is crucial for assessing the compressive strength and uniformity of concrete structures. We utilize a spring-loaded hammer to impact the concrete surface and measure the rebound distance.
                        Our detailed reports provide valuable insights for maintenance planning and structural integrity assessment, ensuring the reliability and safety of critical structures.</p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Rebound Hammer Testing Equipment & Consumables</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically tailored for rebound hammer testing.
                        Our offerings include rebound hammers, calibration anvils, surface preparation tools, measurement software, and safety gear.
                        Additionally, we provide training materials, documentation tools, and calibration standards to ensure accurate and reliable testing procedures.
                        With our complete solutions, we empower organizations to conduct rebound hammer testing with precision and efficiency.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Rebound Hammer Testing Standards</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT follows industry-standard specifications such as ASTM C805 and BS EN 12504-2 for rebound hammer testing.
                        Our dedication to these standards ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Rebound Hammer Testing Parameters</h4>
                    <p className=' font-semibold'>
                        During rebound hammer testing, we assess various parameters such as impact energy, rebound value, surface moisture content, and surface roughness.
                        Our meticulous evaluation ensures accurate measurements and reliable results, empowering informed decision-making for structural integrity assessment and maintenance planning.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Anomalies Detected by Rebound Hammer Testing</h4>
                    <p className=' font-semibold'>
                        Through rebound hammer testing, AMEEE NDT effectively identifies anomalies such as areas of low compressive strength, honeycombing, and delamination in concrete structures.
                        Our meticulous testing methods ensure thorough anomaly detection, enabling proactive maintenance and risk mitigation measures.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Rebound Hammer Testing</h4>
                    <p className=' font-semibold'>
                        AMEEE NDT follows industry-standard specifications such as ASTM C805 and BS EN 12504-2 for rebound hammer testing.
                        Our dedication to these standards ensures the accuracy and reliability of our inspections, safeguarding the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Hammer
