import React, { useState, useEffect, useRef, Component } from 'react';
import '../Header.css'
import { Navbar, Nav, Container, CarouselItem, CarouselCaption, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlus, faMinus, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Carousel } from 'react-bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import OwlCarousel from './sec_foooter';
import First from './First';
import Seventh from './seventh';
import Foooter from './Footer';
import Sector from './Sectors';
import { Helmet } from 'react-helmet';
import Third from './Third';
import { Nine } from './Nine';
import Fifth from './fifth';
const Focus = () => {
  const [showContent, setShowContent] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | ndt awards | ndt services</title>
        <meta name="description" content="Discover the awards received by AMEEE NDT and its focus areas in providing high-quality non-destructive testing (NDT) services in Padappai. We are committed to excellence and specialize in third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="keywords" content="AMEEE NDT awards, NDT services, Padappai, third-party inspection, rope access, coating inspection, welding services, focus areas" />
        <meta property="og:title" content="Awards and Focus Areas of AMEEE NDT" />
        <meta property="og:description" content="Discover the awards received by AMEEE NDT and its focus areas in providing high-quality non-destructive testing (NDT) services in Padappai. We are committed to excellence and specialize in third-party inspection, rope access, coating inspection, and welding services." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT" />
        <meta name="twitter:description" content="Discover the awards received by AMEEE NDT and its focus areas in providing high-quality non-destructive testing (NDT) services in Padappai. We are committed to excellence and specialize in third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='bg-black  fixed z-10 w-full'>
        <First />
      </div>
      <div className='pt-40' >
       {/* <p> Offer the most reliable and
        consistent quality service by evaluating and developing methods of testing and
        training services to keep pace with the latest developments in technology and
        client requirements.Offer the most reliable and
        consistent quality service by evaluating and developing methods of testing and
        training services to keep pace with the latest developments in technology and
        client requirements.Offer the most reliable and
        consistent quality service by evaluating and developing methods of testing and
        training services to keep pace with the latest developments in technology and
        client requirements.Offer the most reliable and
        consistent quality service by evaluating and developing methods of testing and
        training services to keep pace with the latest developments in technology and
        client requirements.Offer the most reliable and
        consistent quality service by evaluating and developing methods of testing and
        training services to keep pace with the latest developments in technology and
        client requirements.</p>   */}
         {/* Extra-content */}
      </div>
      <div className='p-0 bg-cover bg-center relative  water-ripple-container' style={{ backgroundImage: `url('a1.jpg')` }}>
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <Third />
      </div>
      <div>
        <Sector />
      </div>
      <div className='p-5'>
        <Seventh />
      </div>
      <div>
        <Fifth />
      </div>
      <div>
        <Nine />
      </div>
    </div>
  )
}


function Tree() {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);




  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDetails1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleDetails2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const toggleDetails3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  return (
    <div className=''>
      <div className=' blackRgb flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Our Vision </span></div>
        <div>
          <span className='bg-white rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-blue'>
            {isExpanded ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'blue' }} />} </span>
        </div>
      </div>
      {isExpanded && (
        <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}



      <div className='blueBg flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails1}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Our Mission</span></div>
        <div>
          <span className='bg-blue-600 rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
        </div>
      </div>
      {isExpanded1 && (
        <p className='p-2  font-bold text-white'>Strive to meet, or exceed, our Client's needs at every opportunity here.</p>)}
      <div className=' blackRgb flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails2}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Objectives</span></div>
        <div>
          <span className='bg-white rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded2 ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'blue' }} />} </span>
        </div>
      </div>
      {isExpanded2 && (
        <p className='p-2  font-bold text-white '>Inspections,Trainings & Consultancy Services in conjunction with Quality,Health,Safety and Environment Management.</p>)}
      <div className='flex justify-between bg-black rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails3}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Purpose</span></div>
        <div>
          <span className='bg-blue-600 rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded3 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
        </div>
      </div>
      {isExpanded3 && (
        <p className='p-2  font-bold text-white'>Offer the most reliable and
          consistent quality service by evaluating and developing methods of testing and
          training services to keep pace with the latest developments in technology and
          client requirements.</p>)}
    </div>
  )
}
export default Focus