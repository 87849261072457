// import React, { useState } from 'react';
// import '../Header.css';

// const Form1 = ({ onClick, children }) => {
//     const [waveClass, setWaveClass] = useState('');

//     const handleClick = (e) => {
      
//       const rect = e.target.getBoundingClientRect();
//       const x = e.clientX - rect.left;
//       const y = e.clientY - rect.top;
  
//      const style = `top: ${y}px; left: ${x}px;`;
//       setWaveClass('wave ' + style);
  
      
//       onClick && onClick(e);
//     };
  
//     return (
//       <button className="water-wave-button " onClick={handleClick}>
//         {children}
//         <span className={waveClass}></span>
//       </button>
//     );
//   };
 
// export default Form1;
import React, { useState } from 'react';
import '../Header.css';

const Form1 = ({ children }) => {
    const [waveClass, setWaveClass] = useState('');

    const handleHoverEnter = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const style = `top: ${y}px; left: ${x}px;`;
        setWaveClass('wave ' + style);
    };

    const handleHoverLeave = () => {
        setWaveClass('');
    };

    return (
        <button 
            className="water-wave-button" 
            onMouseEnter={handleHoverEnter} 
            onMouseLeave={handleHoverLeave}
        >
            {children}
            <span className={waveClass}></span>
        </button>
    );
};

export default Form1;
