import { faStar, faStarAndCrescent, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import FancyText from '@carefully-coded/react-text-gradient';
import { Button } from 'react-bootstrap';
import '../Header.css'
import Timeline from './Timeline';
import { faStackExchange, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import Bargraph from './Bargraph';
import About2 from './About2';
import About3 from './About3';
import First from './First';
import { Helmet } from 'react-helmet';
function About1() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
                <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
                   Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
                    Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
                    Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className=' bg-blue-100'>
                <div className='container'>
                    <div className=''>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            <h2 className='text-6xl font-bold mt-44'>What We Do</h2>
                            <span className='text-4xl font-bold'>About Us</span>
                        </FancyText>
                        <p className='text-2xl font-bold text-justify text-gray-800 mt-3'>AMEEE NDT Inspection Engineers PVT LTD is a professional organization that
                            engages in providing Quality, Fitness, Safety and Environmental guidance expertise resources
                            as Professionals.
                        </p>
                    </div>
                    <div className='row p-2 mt-5'>
                        <div className='col-lg-2'>
                            <div className='border-r-2 border-black'>
                                <div>
                                    <h2 className='text-6xl font-bold'>
                                        <FontAwesomeIcon icon={faTrophy} className='text-blue-900 mr-3' />
                                        12+
                                    </h2>
                                    <p className='mt-4 text-3xl font-semibold'>Awards Won</p><br />
                                    <Button href='Services' variant="outline-primary" className='px-3 mx-3 hover:underline' style={{ boxShadow: ' 0 3px 5px ' }}>Get Started</Button>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6 text-left'>
                            <h2 className='text-5xl text-blue-900 font-bold'>Welcome To AMEEE NDT Inspection </h2>
                            <p className='text-lg text-gray-600 font-semibold'> AMEEENDT is Originated from AMEEE NDT Inspection Engineers PVT LTD is well
                                known in India for NDT & Advance NDT Company was found in March 2022 by committed team of
                                NDT Engineers Well versed with field experience over the Ten Years.</p>
                        </div>
                        <div className=' col-lg-4'>
                            <div className=''>
                                <img src='assets/ameee/ut.jpg' className='about' style={{ height: '300px', width: '300px', objectFit: 'cover', borderRadius: '120px 0 0 0' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Timeline />
            </div>
            <div className='container mt-28'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className='circles-border'>
                            <div className='img-container'>
                                <img src='assets/ameee/ut.jpg' className='zoom-image' style={{ borderRadius: '50%', height: '620px', width: '620px', border: '10px solid ' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 text-left'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            <h2 className='text-6xl font-bold'>We Are Here</h2>
                            <span className='text-4xl font-bold'>About Us</span>
                        </FancyText>
                        <p className='text-4xl font-bold mt-10'>Work With A Dedicated Company</p>
                        <p className='text-gray-700 text-xl'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt quae delectus voluptatum sunt ea rem!</p>
                        <div>
                            <div className='bg-blue-100 p-2 border flex'>
                                <div className=''>
                                    <img src='assets/ameee/ut.jpg' style={{ borderRadius: '50%', height: '150px', width: '300px' }} />
                                </div>
                                <div className='ml-10'>
                                    <h2>Name,Founder</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt quae delectus voluptatum sunt ea rem!</p>
                                </div>
                            </div>
                            <div className='mt-5 p-4 border flex' style={{ backgroundImage: 'url(assets/ameee/ut.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                <div className='ml-12 flex font-bold'>
                                    <h4 className='text-white text-4xl'>
                                        2+ Years Of Experiences
                                    </h4>
                                    {/* <Button href='/' className='ml-10 bg-white text-black hover:underline' style={{ border: '1px solid white' }}>Learn More</Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-5 mt-28 bg-slate-950'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <h4 className='text-white text-5xl text-left'>
                            <span className='text-blue-500'>Stock Market</span> &nbsp;
                            Research Financial Report Analysis
                        </h4>
                        <dl className='text-left mt-5 text-white'>
                            <dt className='text-3xl'>
                                <FontAwesomeIcon icon={faStar} className='text-blue-500 mr-3' />
                                Top Ranking Market Share
                            </dt>
                            <dd className='text-justify text-xl mt-3 ml-12'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eligendi, nobis sed beatae exercitationem aliquam dignissimos distinctio sapiente officia obcaecati, aspernatur fugit cum nostrum quo?</dd>
                            <dt className='text-3xl mt-5'>
                                <FontAwesomeIcon icon={faStar} className='text-blue-500 mr-3' />
                                Top Ranking Market Share
                            </dt>
                            <dd className='text-justify text-xl mt-3 ml-12'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eligendi, nobis sed beatae exercitationem aliquam dignissimos distinctio sapiente officia obcaecati, aspernatur fugit cum nostrum quo?</dd>
                            <dt className='text-3xl mt-5'>
                                <FontAwesomeIcon icon={faStar} className='text-blue-500 mr-3' />
                                Top Ranking Market Share
                            </dt>
                            <dd className='text-justify text-xl mt-3 ml-12'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit eligendi, nobis sed beatae exercitationem aliquam dignissimos distinctio sapiente officia obcaecati, aspernatur fugit cum nostrum quo?</dd>
                        </dl>
                    </div>
                    <div className='col-lg-5 text-left'>
                        <Bargraph />
                    </div>
                </div>
            </div>
            <div>
                <About2 />
            </div>
            <div>
                <About3 />
            </div>
        </div>
    )
}




export default About1
