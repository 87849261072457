import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlus, faMinus, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown, faGear } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function Service1() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);


    const toggleDetails = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleDetails1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    const toggleDetails2 = () => {
        setIsExpanded2(!isExpanded2);
    };

    const toggleDetails3 = () => {
        setIsExpanded3(!isExpanded3);
    };

    return (
        <div>
            <Helmet>
                <title>Services Offered by AMEEE NDT</title>
                <meta name="description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including conventional NDT services,Advance NDT services,third-party inspection, rope access, coating inspection, and welding services." />
                <meta name="keywords" content="AMEEE NDT services, NDT testing services,best Conventional NDT services in India,Advance NDT Services in India,Best NDT services in India,testing & Inspection ,best UT services in India, non-destructive testing, Padappai, third-party inspection, rope access, coating inspection, welding services,Best Ndt services in Chennai,leading service provider" />
                <meta property="og:title" content="Services Offered by AMEEE NDT" />
                <meta property="og:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta name="twitter:title" content="Services Offered by AMEEE NDT" />
                <meta name="twitter:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
                <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <div className='lg:ml-96'>
                <h1 className='font-bold text-center '>Discover Our NDT Services</h1>
                <div className='container'>
                    <div className=' bg-blue-500 flex justify-between py-2 my-3' onClick={toggleDetails}>
                        <div><span className='px-4 text-lg text-black font-bold w-96'>Unveiling Our NDT Services</span></div>
                        <div>
                            <span className='bg-black px-3  pt-2 pb-2 text-center font-bold text-2xl text-blue'>
                                {isExpanded ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} className='text-blue-500' />} </span>
                        </div>
                    </div>
                    {isExpanded && (
                        <p className='p-2 font-bold text-gray-700 text-justify text-lg'>
                            At AMEEE NDT, we provide non-destructive testing (NDT) services, a method used to evaluate the properties of a material, component, or system without causing damage. This type of testing allows for inspection, testing, or evaluation without altering the integrity of the object being tested. NDT is crucial in various industries like manufacturing, aerospace, and construction to ensure the quality and safety of products.</p>)}
                    <div className='bg-black flex justify-between py-2 my-5' onClick={toggleDetails1}>
                        <div><span className='px-4 text-lg text-white font-bold w-96'>Discovering Various NDT Procedures</span></div>
                        <div>
                            <span className='bg-blue-600  px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                                {isExpanded1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
                        </div>
                    </div>
                    {isExpanded1 && (
                        <p className='p-2  font-bold text-gray-700'>
                            <ul className='text-left grid grid-cols-3'>
                                <li><a href='VisualTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Visual Testing</a></li>
                                <li><a href='PenetrantTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Penetrant Testing</a></li>
                                {/* <li><a href='MagneticTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Magnetic Particle Testing</a></li> */}
                                <li><a href='UltrasonicTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Ultrasonic Testing</a></li>
                                <li className='mt-2'><a href='EddyCurrentTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Eddy Current Testing</a></li>
                                <li className='mt-2'><a href='UltraPulseVelocity' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' />Ultra Pulse Velocity</a></li>
                                <li className='mt-2'><a href='RadiographyTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Radiography Testing</a></li>
                                <li className='mt-2'><a href='RemoteVisualInspection' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' />Remote Visual Inspection</a></li>
                                <li className='mt-2'><a href='HardnessTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' />Hardness Testing</a></li>
                                <li className='mt-2'><a href='LeakTesting' className='text-gray-700 decoration-transparent'><FontAwesomeIcon icon={faGear} className='text-blue-500' /> Leak Testing</a></li>
                            </ul>
                        </p>)}



                    <div className='bg-blue-500 flex justify-between py-2 my-5' onClick={toggleDetails2}>
                        <div><span className='px-4 text-lg text-black font-bold w-96'>Diving into NDT and NDE Methods</span></div>
                        <div>
                            <span className='bg-black px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                                {isExpanded2 ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} className=' text-blue-500' />} </span>
                        </div>
                    </div>
                    {isExpanded2 && (
                        <p className='p-2  font-bold text-gray-700 text-lg text-justify'>
                            AMEEE NDT provides non-destructive testing (NDT) services, and non-destructive evaluation (NDE) are often used interchangeably.
                            NDT refers to the techniques used to inspect materials and components without causing damage, while NDE involves the interpretation of the data collected from NDT methods to assess the condition and integrity of the object being tested.
                        </p>)}


                    <div className='flex justify-between bg-black  py-2 my-3' onClick={toggleDetails3}>
                        <div><span className='px-4 text-lg text-white font-bold w-96'>Exploring the Advantages of NDT with AMEEE NDT</span></div>
                        <div>
                            <span className='bg-blue-600 px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
                                {isExpanded3 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
                        </div>
                    </div>
                    {isExpanded3 && (
                        <p className='p-2  font-bold text-gray-700 text-lg text-justify'>NDT provides a range of benefits, including cost-effectiveness, enhanced safety, improved quality assurance, increased productivity, non-invasiveness, versatility across materials, and environmental friendliness, making it an essential tool in various industries for ensuring product integrity and reliability.</p>)}

                </div>
            </div>
        </div>
    )
}
export default Service1
