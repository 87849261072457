import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';
export const Eight = () => {

  const now = 90;

  const now1 = 75;

  const now2 = 60;

  const divStyle = {
    backgroundImage: 'url(/assets/ameee/bgimgtransformed.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    // opacity: 0.8,
  };

  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div style={divStyle} >
        <div className='row p-5 sm:w-64 md:w-80 lg:w-full'>
          <div className='col-lg-6 col-md-6 col-sm-12 '>
            <div className=' overflow-hidden'>
              <div className='  water-ripple-container'>
                <div className='water-ripple-overlay'></div>
                {/* <div className='square-border' style={{ width: '600px', borderRadius: '25px', animation: 'moveLeftRights 5s infinite alternate'}}>
                  <img src='assets\ameee\clipimage.jpeg' className='' style={{ width: '500px', height: '570px', position: 'relative',borderRadius:'50px' }} />
                </div> */}

                {/* <div className='' style={{ width: '600px', borderRadius: '25px', animation: 'moveLeftRight 5s infinite alternate', background: 'conic-gradient(from 45deg, #0073c7, #0000)' }}>
                  <img src='assets/ameee/clipimage.jpeg' style={{ width: '500px', height: '570px', padding: '10px', borderRadius: '25px', border: '4px solid #0000', objectFit: 'cover' }} />
                </div> */}
                <div className='max-w-full md:max-w-[550px] mx-auto'>
                  <div className='relative' style={{ animation: 'moveLeftRights 5s infinite alternate' }}>
                    <div className='square-border'></div>
                    <img
                      src='assets\ameee\clipimage.jpeg'
                      className='w-full rounded-lg overflow-hidden'

                      alt='Responsive Image'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12  font-bold bg-slate-900' style={{ borderRadius: '10px' }}>
            <h5 className='my-2 pt-2 font-bold text-5xl text-gray-300 text-center' >
              Technology For Tomorrow
            </h5>
            <div>
              <h2 className='py-2 text-4xl font-bold text-center'>

                <FancyText
                  gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                  animate
                  animateDuration={1000}
                >
                  Outstanding
                </FancyText>
              </h2>
              <div className='flex  justify-center'>
                <div className='first' style={{ boxShadow: '0 3px 5px black' }}>
                  <div ><a href='Services' className='text-white font-bold decoration-transparent' >Services</a></div>
                </div>
                <div className='second' style={{ boxShadow: '0 3px 5px black' }}>
                  <div><a href='Services' className='text-white font-bold decoration-transparent'>Inspection</a></div>
                </div>
                <div className='third' style={{ boxShadow: '0 3px 5px black' }}>
                  <div><a href='Services' className='text-white font-bold decoration-transparent'>Engineers</a></div>
                </div>
              </div>
            </div>
            <p className='mt-5 text-4xl text-gray-300 text-center' >Navigating the business </p>
            <div className=''>
              <div>
                <span className='text-2xl text-white' >NDT Services</span>
                <ProgressBar now={now} label={`${now}%`} className='bg-black' />
              </div>

              <div className='my-4'>
                <span className='text-2xl text-white' >Testing & Inspection</span>
                <ProgressBar now={now1} label={`${now1}%`} className='bg-black' />
              </div>

              <div className='my-4'>
                <span className='text-2xl text-white'>Welder & Welding Certification</span>
                <ProgressBar now={now2} label={`${now2}%`} className='bg-black' />
              </div>
              <div className='text-center'>
                <Button href='Services' className='mt-4' style={{ fontWeight: 'bold', padding: '10px', boxShadow: '0 3px 5px black' }}>More Information</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
