import React from 'react'
import First from './First'
import Seventh from './seventh'
import Sector from './Sectors'
import Foooter from './Footer'
import { Helmet } from 'react-helmet'
import { Nine } from './Nine'
import '../Header.css'
function Careers() {

  return (
    <div>
      <Helmet>
        <title>Careers at AMEEE NDT|NDT jobs in Padappai|NDT jobs in Kanchipuram|NDT jobs in Chennai|NDT jobs & Trainings</title>
        <meta name="description" content="Explore career opportunities at AMEEE NDT, a leading provider of non-destructive testing (NDT) services in Padappai. Join us to contribute to delivering high-quality testing solutions for various industries." />
        <meta name="keywords" content="AMEEE NDT careers, NDT jobs, job opportunities, non-destructive testing careers, Padappai careers, employment, job openings,inspection & training,NDT jobs in India,NDT jobs in Tamilnadu,jobs in Padappai,NDT sevices,Third party Inspection" />
        <meta property="og:title" content="Careers at AMEEE NDT" />
        <meta property="og:description" content="Explore career opportunities at AMEEE NDT, a leading provider of non-destructive testing (NDT) services in Padappai. Join us to contribute to delivering high-quality testing solutions for various industries." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Careers at AMEEE NDT" />
        <meta name="twitter:description" content="Explore career opportunities at AMEEE NDT, a leading provider of non-destructive testing (NDT) services in Padappai. Join us to contribute to delivering high-quality testing solutions for various industries." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className=' font-bold'>
      <div className=' z-10 fixed w-full' style={{backgroundColor:'#020225'}}>
          <First />
        </div>

        <div className=' text-center font-extrabold pt-40'>
          <h2 className=' font-extrabold p-3 text-5xl' id='down'>
            <span className='text-primary'>We</span> Are Hiring
          </h2>
          <div className="relative inline-block" id='up'>
            <span className="bg-primary text-white text-2xl px-3 py-2 ">Job Category</span>
            <span className="absolute top-full left-1/2 transform -translate-x-1/2">
              <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
            </span>
          </div>
          <p className='mt-5 text-4xl'>We are hiring NDT Technicians,Engineers and Inspectors.</p>
        </div>
        <div className='p-5  m-5 '>
          <div className='h-72 overflow-scroll'>
            <table className='border-collapse w-full text-center'>
              <thead className=' bg-blue-500 text-white'>
                <tr >
                  <th className="border border-gray-400 px-4 py-2 w-5">S.No.</th>
                  <th colspan='2'  className="border border-gray-400 px-4 py-2">Job Title</th>
                  <th  className="border border-gray-400 px-4 py-2">Department</th>
                  <th className="border border-gray-400 px-4 py-2">Location</th>
                  <th className="border border-gray-400 px-4 py-2">No.Of.Openings</th>
                  <th className="border border-gray-400 px-4 py-2">Apply Now</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td className="border border-gray-400  bg-white px-4 py-4">01</td>
                  <td colspan='2'className="border border-gray-400  bg-white px-4 py-3">NDT Technicians with Level II</td>
                  <td className="border border-gray-400  bg-white px-4 py-2">Projects</td>
                  <td className="border border-gray-400  bg-white px-4 py-2">Pan India</td>
                  <td className="border border-gray-400  bg-white px-4 py-2">50 Members</td>
                  <td className="border border-gray-400   bg-white px-4 py-2"><a href='http://localhost:8069/jobs/apply/ndt-tessssting-10' target="_blank" className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr>
                <tr>
                  <td className="border border-gray-400 bg-blue-100 px-4 py-4">02</td>
                  <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">Welding Engineers</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">Projects</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">Pan India</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">13 Members</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form ' target="_blank" className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-4">03</td>
                  <td colspan='2' className="border border-gray-400 px-4 py-2">Safety Engineers</td>
                  <td className="border border-gray-400 px-4 py-2">Projects</td>
                  <td className="border border-gray-400 px-4 py-2">Pan India</td>
                  <td className="border border-gray-400 px-4 py-2">8 Members</td>
                  <td className="border border-gray-400 px-4 py-2"><a href='/Form.js' target="_blank" className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr>
                <tr>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-4">04</td>
                  <td colspan='2' className="border border-gray-400  bg-blue-100 px-4 py-2">Nace Inspectors (Level I & II )</td>
                  <td className="border border-gray-400   bg-blue-100 px-4 py-2">Projects</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">TamilNadu</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">6 Members</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form' target="_blank" className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr>
                {/* <tr>
                  <td className="border border-gray-400 px-4 py-4">05</td>
                  <td colspan='2' className="border border-gray-400 px-4 py-2">NDT Technicians</td>
                  <td className="border border-gray-400 px-4 py-2">Department</td>
                  <td className="border border-gray-400 px-4 py-2">Location</td>
                  <td className="border border-gray-400 px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr>
                <tr>
                  <td className="border border-gray-400   bg-blue-100 px-4 py-4">06</td>
                  <td colspan='2' className="border border-gray-400   bg-blue-100 px-4 py-2">NDT Technicians</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">Department</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2">Location</td>
                  <td className="border border-gray-400  bg-blue-100 px-4 py-2"><a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a></td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className=''>
          <Seventh />
        </div>
        <section className=' bg-blue-100'>
          <div>
            <div className='text-center font-extrabold'>
              <h2 className=' font-extrabold p-3'>
                <span className='text-primary'>We</span> Are Working
              </h2>
              <div className="relative inline-block">
                <span className="bg-primary text-white px-3 py-2 ">Working Process</span>
                <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                  <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
                </span>
              </div>
              <p className='p-3'>Explore Exciting Opportunities:Join Us and Make a Difference!</p>
            </div>
          </div>
          <div className='row p-5'>
            <div className=' col-lg-3 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/pt.jpeg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>01</div>
              </div>
              <div className='process-content'>
                <h4>Conventional NDT</h4>
                <p className=' font-normal'>Experience the Precision:Elevate your quality standards with our Conventional NDT services!</p>
              </div>
            </div>
            <div className=' col-lg-3 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/Advance NDT.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count  bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>02</div>
              </div>
              <div className='process-content'>
                <h4>Advance NDT</h4>
                <p className=' font-normal'>Breaking Boundaries:Unleash the power of Advance NDT solutions with us!</p>
              </div>
            </div>
            <div className=' col-lg-3 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/welding.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>03</div>
              </div>
              <div className='process-content'>
                <h4>Welding Inspection</h4>
                <p className=' font-normal'>Ensure Excellence:Trust our expertise for comprehensive Welding Inspection solutions!</p>
              </div>
            </div>
            <div className=' col-lg-3 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/rope.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>04</div>
              </div>
              <div className='process-content'>
                <h4>Rope Access</h4>
                <p className=' font-normal'>Reach new heights safely:Experience superior Rope Access solutions with us!</p>
              </div>
            </div>
          </div>
        </section>
        <div >
          <Sector />
        </div>
        <section>
          <div className=' text-center font-extrabold'>
            <h2 className=' font-extrabold p-3'>
              <span className='text-primary'>We</span> Are Hiring
            </h2>
            <div className="relative inline-block">
              <span className="bg-primary text-white px-3 py-2 ">Job Opening</span>
              <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
              </span>
            </div>
           
          </div>
          <div className='row p-5'>
            <div className='col-lg-4'>
              <div className='post-hiring border rounded p-5 bg-gray-900 text-white text-center' style={{ height: '400px' }}>
                <div style={{ position: 'relative' }}>
                  <span className='timing border p-2 bg-primary' style={{ position: 'absolute', borderRadius: '20px', top: '-70px', left: '' }}>Full Time</span>
                </div>
                <h3 className='job-title'>NDT Technician</h3>
                <p className=' font-thin'>Seeking Talented NDT Technicians to Join our Growing Team!</p><hr />
                <div className='job-location'>
                  Location :
                  <span>  Chennai, TamilNadu.</span><br /><br />
                  <a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='post-hiring border rounded p-5  bg-gray-900 text-white text-center' style={{ height: '400px' }}>
                <div style={{ position: 'relative' }}>
                  <span className='timing border p-2 bg-primary' style={{ position: 'absolute', borderRadius: '20px', top: '-70px', left: '' }}>Full Time</span>
                </div>
                <h3 className='job-title'>NDT Engineers</h3>
                <p className='font-thin'>Now Hiring: Skilled NDT Engineers to Drive Innovation and Excellence!</p><hr />
                <div className='job-location'>
                  Location :
                  <span> Chennai, TamilNadu.</span><br /><br />
                  <a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='post-hiring border rounded p-5  bg-gray-900 text-white text-center' style={{ height: '400px' }}>
                <div style={{ position: 'relative' }}>
                  <span className='timing border p-2 bg-primary' style={{ position: 'absolute', borderRadius: '20px', top: '-70px', left: '' }}>Full Time</span>
                </div>
                <h3 className='job-title'>NDT Inspectors</h3>
                <p className='font-thin'>Attention NDT Inspectors:Exciting Career Opportunities Await With Us!</p><hr />
                <div className='job-location'>
                  Location :
                  <span>  Chennai, TamilNadu.</span><br /><br />
                  <a href='/Form' className='border-1 border-blue-500 text-blue-500 py-2 px-4 rounded inline-block  text-decoration-none hover:bg-blue-500 hover:text-white'>Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <Nine/>
        </div>
      </div >
    </div>
  )
}

export default Careers
