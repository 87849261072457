import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Col, Container, Row, Dropdown, Button, Carousel, Card } from 'react-bootstrap';
const Form = () => {
  const [formData, setFormData] = useState({
    applicationNumber:'',
    post: '',
    name: '',
    mobile: '',
    email: '',
    tempAddress: '',
    permAddress: '',
    // dateOfBirth: '',
    religion: 'Select Religion',
    maritalStatus: 'Select',
    online: '',
    offline: '',
    degree: '',
    university: '',
    // year:'',
    marks: '',
    division: '',
    ndtTesting: 'Select Testing',
    university1: '',
    siteExperience: '',
    workExperience: '',
    computerSkill: '',
    typingSpeed: '',
    tamil: '',
    english: '',
    hindi: '',
    others: '',
    skill: '',
    fatherName: '',
    spouseName: '',
    spouseOccupation: '',
    fatherOccupation: '',
    employerName: '',
    employer1Name: '',
    interest: '',
    salary: '',
    ambitions: '',
    refName: '',
    refAddress: '',
    refNumber: '',
    refEid: '',
    resume: null
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  useEffect(() => {
    // Generate the application number when the component mounts
    generateApplicationNumber();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  const generateApplicationNumber = () => {
    // Generate a random number between 1 and 500
    const applicationNumber = Math.floor(1 + Math.random() * 500);
    setFormData({
      ...formData,
      applicationNumber: applicationNumber.toString(),
    });
  };
  
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    validateDates(event.target.value, endDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    validateDates(startDate, event.target.value);
  };

  const validateDates = (start, end) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const differenceInMilliseconds = Math.abs(endDate - startDate);
      const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
      const differenceInYears = Math.floor(differenceInMilliseconds / millisecondsInYear);

      if (differenceInYears > 5) {
        // Display error message as an alert
        window.alert('Seems like you have uploaded the expired certificate. Kindly upload the latest one!');
      }
    }
  };


  const [isOthersChecked, setIsOthersChecked] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const handleOthersChange = (event) => {
    setIsOthersChecked(event.target.checked);
  };


  const handleDeclarationChange = (event) => {
    setIsDeclarationChecked(event.target.checked);
  };

   // Function to handle file selection
   const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0] // Update resume state with the selected file
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formDataToSend = new FormData();

    // Append form data to the FormData object
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

      // Append the resume file to the FormData object
      formDataToSend.append('file', formData.resume);

    // Validate form fields
    if (!formData.post || !formData.name || !formData.mobile || !formData.email || !formData.tempAddress || !formData.permAddress || formData.religion === 'Select Religion' || formData.maritalStatus === 'Select') {
      alert('Please fill out all required fields.');
      return;
    }

    // Submit form data if validation passes
    axios.post('https://ameeendt.com/submit-form.php', formDataToSend)
      .then(response => {
        // Check if the response data is valid JSON
        if (response.data && typeof response.data === 'object') {
          console.log('Form data saved:', response.data);
          alert(`Your Application no #  ${formData.applicationNumber}\n\nI wanted to take a moment to thank you for enrolling for the job position at our company. Your application has been received and is currently being processed by our team.\n\nWe appreciate your interest in joining our organization and are confident that your skills and experience will be a valuable asset to our team. Rest assured that we will carefully review your application and will be in touch with you shortly regarding the next steps in the recruitment process.\n\nIn the meantime, if you have any questions or need further information, please do not hesitate to reach out to us on hr@ameeendt.com. We are here to assist you in any way we can.\n\nThank you again for your interest in our company. We look forward to the possibility of working with you in the future.\n\nBest of Luck\n\nRegards\nHR Team\nAMEEE NDT Inspection Engineers PVT LTD`);
        } else {
          console.error('Invalid JSON response:', response.data);
        }

        // Clear the form
        setFormData({
          post: '',
          name: '',
          mobile: '',
          email: '',
          tempAddress: '',
          permAddress: '',
          religion: 'Select Religion',
          maritalStatus: 'Select'
        });
      })
      .catch(error => {
        console.error('There was an error saving the form data!', error.response || error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="flex justify-center m-5 items-center">
     <div className="overflow-scroll " style={{height:'900px'}}>
     <table className="border-collapse border border-sky-900">
        <thead className="border border-sky-900">
          <tr>
            <th className="border border-sky-900 p-2 text-center" colSpan={2}>
              <img src="/assets/ameee/ameeelogo.png" alt="logo" style={{ width: '500px', height: '130px' }} />
              <div>NDT Inspection | Training | Consulting</div>
            </th>
            <th className="border border-sky-900 p-2 text-center" colSpan={1}>Form No 5</th>
            <th colSpan={4} className="border border-sky-900 p-2 text-center">
              <div className="border-b border-sky-900 text-left text-3xl">AMEEE NDT Inspection Engineers PVT LTD</div>
              <div className="text-left">7 & 8 Subhaiya Complex, KR Puram, Behind Sub-Registrar Office, Padappai Kancheepuram (Dist) TamilNadu 601301</div>
            </th>
          </tr>
        </thead>
        <tbody className=' border border-sky-900'>
          <tr className='border'>
            <td className='p-3'></td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Application No</th>
            <td className=" p-2 text-left">
              <input
                type="text"
                name="applicationNumber"
                value={formData.applicationNumber}
                onChange={handleChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Post Applied For <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <input
                type="text"
                name="post"
                value={formData.post}
                onChange={handleChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Upload Your Resume With Photograph <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <input
                type="file"
                accept='.pdf, .doc, .docx'
                name="file"
                onChange={handleFileChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className='text-xl text-blue-900 p-2'>Personal Details</th>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Name <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <input
                type="text"
                name="name"
                placeholder='IN BLOCK LETTERS ONLY'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Mobile No <span className='text-red-500'>*</span></th>
            <td className="p-2 text-left">
              <input
                type="tel"
                name="mobile"
                placeholder='+91'
                pattern='[0-9]{10}' title='Please enter a valid 10-digit mobile number'
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Email <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <input
                type="email"
                name="email"
                placeholder='example@gmail.com'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Temporary Address <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <textarea
                name="tempAddress"
                value={formData.tempAddress}
                onChange={handleChange}
                required
              ></textarea>
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Permanent Address <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <textarea
                name="permAddress"
                value={formData.permAddress}
                onChange={handleChange}
                required
              ></textarea>
            </td>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Date of Birth <span className='text-red-500'>*</span></th>
            <td className=" p-2 text-left">
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                required
              />
            </td>
            <th className="border border-sky-900 p-2 text-left">
              <select
                name="religion"
                value={formData.religion}
                onChange={handleChange}
                required
              >
                <option value="Select Religion">Select Religion</option>
                <option value="christianity">Christianity</option>
                <option value="islam">Islam</option>
                <option value="hinduism">Hinduism</option>
                <option value="buddhism">Buddhism</option>
                <option value="judaism">Judaism</option>
                <option value="others">Others</option>
              </select><span className='text-red-500'>*</span>
            </th>
            <th>
              <select
                name="maritalStatus"
                value={formData.maritalStatus}
                onChange={handleChange}
                required
              >
                <option value="Select">Select</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="widowed">Widowed</option>
                <option value="others">Others</option>
              </select><span className='text-red-500'>*</span>
            </th>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left">Your Interview Preference</th>
            <td className=" p-2 text-left border border-sky-900"><input type='checkbox' name='online' value={formData.online}
              onChange={handleChange}
            />&nbsp; Online Mode</td>
            <td className=" p-2 text-left"><input type='checkbox' name='Offline' value={formData.offline}
              onChange={handleChange}
            /> &nbsp; Offline Mode</td>
          </tr>
          <tr className='border'>
            <th className='text-xl text-blue-900 p-2' colSpan={4}>Educational Qualification</th>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-center'>Examinations/Degree<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>University<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Year<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Marks/Percentage/Grade<span className='text-red-500'>*</span></th>
            <th className=' p-2 text-center'>Division<span className='text-red-500'>*</span></th>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 '>
              <input type='text' name='degree' value={formData.degree} onChange={handleChange} placeholder='SSLC or Equivalent' required />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' value={formData.university} onChange={handleChange} placeholder='Name of the Board' required />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='year' value={formData.year} onChange={handleChange} pattern='[0-9]{4}' placeholder='YYYY' required /> </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='marks' value={formData.marks} onChange={handleChange} placeholder='Numeric value only!' required />
            </td>
            <td className=' p-2'>
              <input type='text' name='division' value={formData.division} onChange={handleChange} placeholder='Enter division' required />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 '>
              <input type='text' name='degree'  placeholder='SSLC or Equivalent'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university'  placeholder='Name of the Board'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='year'  pattern='[0-9]{4}' placeholder='YYYY'  /> </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='marks'  placeholder='Numeric value only!'  />
            </td>
            <td className=' p-2'>
              <input type='text' name='division'  placeholder='Enter division'  />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 '>
              <input type='text' name='degree'  placeholder='SSLC or Equivalent'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university'  placeholder='Name of the Board'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='year'  pattern='[0-9]{4}' placeholder='YYYY'  /> </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='marks'  placeholder='Numeric value only!'  />
            </td>
            <td className=' p-2'>
              <input type='text' name='division'  placeholder='Enter division'  />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 '>
              <input type='text' name='degree'  placeholder='SSLC or Equivalent'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university'  placeholder='Name of the Board'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='year' pattern='[0-9]{4}' placeholder='YYYY' /> </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='marks'  placeholder='Numeric value only!'  />
            </td>
            <td className=' p-2'>
              <input type='text' name='division'  placeholder='Enter division'  />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 '>
              <input type='text' name='degree'  placeholder='SSLC or Equivalent'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university'  placeholder='Name of the Board'  />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='year'  pattern='[0-9]{4}' placeholder='YYYY'  /> </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='marks'  placeholder='Numeric value only!'  />
            </td>
            <td className=' p-2'>
              <input type='text' name='division'  placeholder='Enter division'  />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-center'>NDT Certifications<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Institute<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Certificate-Issued Date<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Certificate-Valid<span className='text-red-500'>*</span></th>
            <th className='border border-sky-900 p-2 text-center'>Site Work Experience<span className='text-red-500'>*</span> </th>
            <th className='border border-sky-900 p-2 text-center'>Total Work Experience<span className='text-red-500'>*</span></th>
            <th className=' p-2 text-center'>Upload</th>
          </tr>
          <tr className=' border'>
            <td className='border border-sky-900 p-2'>
              <select className='w-40' name="ndtTesting"
                value={formData.ndtTesting}
                onChange={handleChange} required>
                <option value='Select Testing'>Select Testing</option>
                <option value='pt'>Penetrant Testing</option>
                <option value='mt'>Magnetic Testing</option>
                <option value='ut'>Ultrasonic Testing</option>
                <option value='rt'>Radiography Testing</option>
                <option value='vt'>Visual Testing</option>
              </select>
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university1' value={formData.university1} onChange={handleChange} placeholder='Name of the Institute' className='w-40' required />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='startDate'
                placeholder='DD/MM/YYYY'
                required
                value={startDate}
                onChange={handleStartDateChange}
              />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='endDate'
                placeholder='DD/MM/YYYY'
                required
                value={endDate}
                onChange={handleEndDateChange}
              />
            </td>
            {/* Display the error message */}
            {dateError && (
              <div className="error-message">{dateError}</div>
            )}
            <td className='border border-sky-900 p-2'>
              <input type='text' name='siteExperience' value={formData.siteExperience} onChange={handleChange} placeholder='Numeric value only!' className='w-40' required />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='workExperience' value={formData.workExperience} onChange={handleChange} placeholder='Numeric value only!' className='w-40' required />
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='file' accept='.pdf, .doc, .docx ' placeholder='file should be less than 300MB' className='w-40' required />
            </td>
          </tr>
          <tr className=' border'>
            <td className='border border-sky-900 p-2'>
              <select className='w-40'>
                <option value=''>Select</option>
                <option value='pt'>Penetrant Testing</option>
                <option value='mt'>Magnetic Testing</option>
                <option value='ut'>Ultrasonic Testing</option>
                <option value='rt'>Radiography Testing</option>
                <option value='vt'>Visual Testing</option>
              </select>
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Name of the Institute' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='startDate'
                placeholder='DD/MM/YYYY'

                value={startDate}
                onChange={handleStartDateChange}
              />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='endDate'
                placeholder='DD/MM/YYYY'
                value={endDate}
                onChange={handleEndDateChange}
              />
            </td>
            {/* Display the error message */}
            {dateError && (
              <div className="error-message">{dateError}</div>
            )}
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='file' accept='.pdf, .doc, .docx ' placeholder='file should be less than 300MB' className='w-40' />
            </td>
          </tr>
          <tr className=' border'>
            <td className='border border-sky-900 p-2'>
              <select className='w-40'>
                <option value=''>Select</option>
                <option value='pt'>Penetrant Testing</option>
                <option value='mt'>Magnetic Testing</option>
                <option value='ut'>Ultrasonic Testing</option>
                <option value='rt'>Radiography Testing</option>
                <option value='vt'>Visual Testing</option>
              </select>
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Name of the Institute' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='startDate'
                placeholder='DD/MM/YYYY'

                value={startDate}
                onChange={handleStartDateChange}
              />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='endDate'
                placeholder='DD/MM/YYYY'
                value={endDate}
                onChange={handleEndDateChange}
              />
            </td>
            {/* Display the error message */}
            {dateError && (
              <div className="error-message">{dateError}</div>
            )}
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='file' accept='.pdf, .doc, .docx ' placeholder='file should be less than 300MB' className='w-40' />
            </td>
          </tr>
          <tr className=' border'>
            <td className='border border-sky-900 p-2'>
              <select className='w-40'>
                <option value=''>Select</option>
                <option value='pt'>Penetrant Testing</option>
                <option value='mt'>Magnetic Testing</option>
                <option value='ut'>Ultrasonic Testing</option>
                <option value='rt'>Radiography Testing</option>
                <option value='vt'>Visual Testing</option>
              </select>
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Name of the Institute' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='startDate'
                placeholder='DD/MM/YYYY'

                value={startDate}
                onChange={handleStartDateChange}
              />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='endDate'
                placeholder='DD/MM/YYYY'
                value={endDate}
                onChange={handleEndDateChange}
              />
            </td>
            {/* Display the error message */}
            {dateError && (
              <div className="error-message">{dateError}</div>
            )}
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='file' accept='.pdf, .doc, .docx ' placeholder='file should be less than 300MB' className='w-40' />
            </td>
          </tr>
          <tr className=' border'>
            <td className='border border-sky-900 p-2'>
              <select className='w-40'>
                <option value=''>Select</option>
                <option value='pt'>Penetrant Testing</option>
                <option value='mt'>Magnetic Testing</option>
                <option value='ut'>Ultrasonic Testing</option>
                <option value='rt'>Radiography Testing</option>
                <option value='vt'>Visual Testing</option>
              </select>
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Name of the Institute' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='startDate'
                placeholder='DD/MM/YYYY'

                value={startDate}
                onChange={handleStartDateChange}
              />
            </td>
            <td className='border border-sky-900 p-2'>
              <input
                type='date'
                name='endDate'
                placeholder='DD/MM/YYYY'
                value={endDate}
                onChange={handleEndDateChange}
              />
            </td>
            {/* Display the error message */}
            {dateError && (
              <div className="error-message">{dateError}</div>
            )}
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2'>
              <input type='text' name='university' placeholder='Numeric value only!' className='w-40' />
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='file' accept='.pdf, .doc, .docx ' placeholder='file should be less than 300MB' className='w-40' />
            </td>
          </tr>
          <tr className='border'>
            <th className='text-xl text-blue-900 p-2 '>Your Skills</th>
          </tr>
          <tr className='border'>
            <th className="border border-sky-900 p-2 text-left " colSpan={2}>Are You familiar with Computers ?<span className='text-red-500'>*</span></th>
            <td className='border border-sky-900 p-2' >
              <input type='text' name="computerSkill"
                value={formData.computerSkill}
                onChange={handleChange}
                placeholder='Yes/No' required />
            </td>
            <th className="border border-sky-900 p-2 text-left" colSpan={2}>Typing Speed In English WPM<span className='text-red-500'>*</span></th>
            <td className=' p-2' >
              <input type='text' name="typingSpeed"
                value={formData.typingSpeed}
                onChange={handleChange}
                placeholder='Words Per Minute' required />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Languages Known<span className='text-red-500'>*</span>
            </th>
            <td className='border border-sky-900 p-2' >
              <input type='checkbox' name="tamil"
                value={formData.tamil}
                onChange={handleChange} /> Tamil
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='checkbox' name="english"
                value={formData.english}
                onChange={handleChange} /> English
            </td>
            <td className='border border-sky-900 p-2' >
              <input type='checkbox' name="hindi"
                value={formData.hindi}
                onChange={handleChange} /> Hindi
            </td>
            <td className=' p-2' >
              <input
                type='checkbox'
                name="others"
                value={formData.others}

                id='others-checkbox'
                onChange={handleOthersChange}
              /> Others
              {isOthersChecked && (
                <div id='others-input-container' className='m'>
                  <input
                    type='text'
                    id='others-input'
                    placeholder='Please Specify'
                  />
                </div>
              )}
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2' colSpan={4}>
              Any other talent, skill or knowledge that you have acquired, which you believe would add to your application?
            </td>
            <td>
              <input type='text' name="skill"
                value={formData.skill}
                onChange={handleChange} />
            </td>
          </tr>
          <tr className='border'>
            <th className='p-2 text-xl text-blue-900' colSpan={4}>
              Details Of Family Members
            </th>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left ' colSpan={2}>
              Your Father's Name<span className='text-red-500'>*</span>
            </th>
            <td className='border border-sky-900 p-2 text-left ' >
              <input type='text' name='fatherName' value={formData.fatherName}
                onChange={handleChange} placeholder='IN BLOCK LETTERS' required />
            </td>
            <th className='border border-sky-900 p-2 text-left ' colSpan={2}>
              Your Spouse Name
            </th>
            <td className=' p-2 text-left ' >
              <input type='text' name='spouseName' value={formData.spouseName}
                onChange={handleChange} placeholder='IN BLOCK LETTERS'  />
            </td>

          </tr>
          <tr className='border'>

            <th className='border border-sky-900 p-2 text-left ' colSpan={2}>
              His Occupation & Last Designation<span className='text-red-500'>*</span>
            </th>
            <td className='p-2'>
              <input type='text' name='spouseOccupation' value={formData.fatherOccupation}
                onChange={handleChange} placeholder='Occupation'  />
            </td>
            <th className='border border-sky-900 p-2 text-left ' colSpan={2}>
              His/Her Occupation & Last Designation
            </th>
            <td className='p-2'>
              <input type='text' name='fatherOccupation' value={formData.spouseOccupation}
                onChange={handleChange} placeholder='Occupation'  />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left ' colSpan={2} >
              Full name and address of Employer<span className='text-red-500'>*</span>
            </th>
            <td className='border border-sky-900 p-2 text-left ' >
              <input type='text' name='employerName' value={formData.employerName}
                onChange={handleChange} placeholder='Name & Address' required />
            </td>
            <th className='border border-sky-900 p-2 text-left ' colSpan={2}>
              Full name and address of Employer
            </th>
            <td className='p-2'>
              <input type='text' name='employer1Name' value={formData.employer1Name}
                onChange={handleChange} placeholder='Name & Address'  />
            </td>
          </tr>
          <tr className='border'>
            <th className='p-2 text-xl text-blue-900' colSpan={4}>
              Family Members
            </th>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-center ' >Name</th>
            <th className='border border-sky-900 p-2 text-center ' >Relationship</th>
            <th className='border border-sky-900 p-2 text-center ' >Age</th>
            <th className=' p-2 text-center ' >Grade</th>

          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className=' p-2 text-left '>
              <input type='text' name='text' />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className=' p-2 text-left '>
              <input type='text' name='text' />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className=' p-2 text-left '>
              <input type='text' name='text' />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className=' p-2 text-left '>
              <input type='text' name='text' />
            </td>
          </tr>
          <tr className='border'>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className='border border-sky-900 p-2 text-left '>
              <input type='text' name='text' />
            </td>
            <td className=' p-2 text-left '>
              <input type='text' name='text' />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Supporting Information
            </th>
            <td className='text-left p-2 '><input type='text' name='text' /></td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={4}>
              Please explain the reasons why you are interested in working with AMEEE NDT Inspection Engineers PVT LTD<span className='text-red-500'>*</span>
            </th>
            <td className='text-left p-2 '><textarea name='interest' value={formData.interest}
              onChange={handleChange} required></textarea></td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Salary Expected (Specify Amount)<span className='text-red-500'>*</span>
            </th>
            <td className='text-left p-2 '><input type='text' name='salary' placeholder='In Numbers Only' value={formData.salary}
              onChange={handleChange} required/></td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              What are your personal ambitions? Please state in a few lines:
            </th>
            <td className='text-left p-2 '><textarea name='ambitions' value={formData.ambitions}
              onChange={handleChange} ></textarea></td>
          </tr>
          <tr className='border'>
            <th className='text-xl text-blue-900 p-2 ' colSpan={4}>Other Details</th>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Are you comfortable working in confined spaces and at heights?
            </th>
            <td className='p-2'>
              <input type='checkbox' /> Yes
            </td>
            <td className='p-2'>
              <input type='checkbox' /> No
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Are you amenable to working in various states or regions across India?
            </th>
            <td className='p-2'>
              <input type='checkbox' /> Yes
            </td>
            <td className='p-2'>
              <input type='checkbox' /> No
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              What serious illness have you had, if any and when:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Are you presently taking medicines for any illness/disease,give details:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Do you smoke? If yes how many cigarettes a day:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Competitive exams you are now preparing for or have appeared in with expected dates of result:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              How many days notice would you require to join?
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Do you possess a conveyance for attending office? Specify, if so:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              Have you been interviewed by us before?
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left' colSpan={2}>
              If you have been involved in any Courts Proceedings, give particulars:
            </th>
            <td className='p-2'>
              <select>
                <option value=''>Select</option>
                <option value='yes'>Yes </option>
                <option value='no'>No</option>
              </select>
            </td>
          </tr>
          <tr className='border'>
            <th className='p-2 text-xl text-blue-900' colSpan={4}>
              References (Professional /Work Related)
            </th>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Name
            </th>
            <td className=' p-2' >
              <input type='text' name='refName' value={formData.refName}
                onChange={handleChange} />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Address
            </th>
            <td className=' p-2' >
              <input type='text' name='refAddress' value={formData.refAddress}
                onChange={handleChange} />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Contact No
            </th>
            <td className=' p-2' >
              <input type="tel" name='refNumber' value={formData.refNumber}
                onChange={handleChange} />
            </td>
            <th className='border border-sky-900 p-2 text-left'>
              Email Id
            </th>
            <td className=' p-2' >
              <input type='email' name='refMail' />
            </td>
          </tr>
          <tr className='border'>
            <th className='border border-sky-900 p-2 text-left'>
              Relationship
            </th>
            <td className=' p-2' >
              <input type="text" name='refRel' />
            </td>
            <th className='border border-sky-900 p-2 text-left'>
              Employee Id
            </th>
            <td className=' p-2' >
              <input type='text' name='refEid' value={formData.refEid}
                onChange={handleChange} />
            </td>
          </tr>
          <tr className='border'>
            <th className='p-2 text-left'>
              Declaration
            </th>
          </tr>
          <tr className='border'>
            <td className='p-2 text-left' colSpan="5">
              <input type='checkbox'
                id='declaration-checkbox'
                onChange={handleDeclarationChange}
                required />
              <span className='ml-3'>
                I hereby declare that the above information is true to the best of my knowledge and belief.
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot >
          <Button type="submit" className=" text-blue-900 text-3xl font-bold  ml-96" >Submit</Button>
        </tfoot>
      </table>
      </div> 
    </form>
  );
};

export default Form;
