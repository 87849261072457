import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients';
import FancyText from '@carefully-coded/react-text-gradient';
import { Helmet } from 'react-helmet';
function Advance() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
                 <Helmet>
                <title>AMEEE NDT |Advance Non-Destructive Testing (NDT) Services in Chennai |Advance NDT Services in Padappai |Advance NDT Services in Mumbai |Advance NDT Services in Haryana |Advance NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Advance non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Advance Non-Destructive Testing (NDT) Services in Chennai ,Advance NDT Services in Mumbai ,Advance NDT Services in Haryana ,Advance NDT Services in India,Best Advance NDT services in Padappai,Best Advance NDT Services in Chennai,Best Advance NDT Services in Bangalore " />
                <meta name="keywords" content="Best Advance ndt services in Andhra Pradesh,Best Advance ndt services in Arunachal Pradesh,Best Advance ndt services in Assam,Best Advance ndt services in Bihar,Best Advance ndt services in Chhattisgarh,Best Advance ndt services in Goa,Best Advance ndt services in Gujarat,Best Advance ndt services in Haryana,
                    Best Advance ndt services in Himachal Pradesh,Best Advance ndt services in Jammu and kashmir,Best Advance ndt services in Jharkhand,Best Advance ndt services in Karnataka,Best Advance ndt services in Kerala,Best Advance ndt services in Madhya Pradesh,Best Advance ndt services in Maharashtra,Best Advance ndt services in Manipur,Best Advancve ndt services in Meghalaya,
                  Best Advance ndt services in Mizoram,Best Advance ndt services in Nagaland,Best Advance ndt services in Odisha,Best Advance ndt services in Punjab,Best Advance ndt services in Rajasthan,Best Advance ndt services in Sikkim,Best Advance ndt services in TamilNadu,Best Advance ndt services in Telengana,Best Advance ndt services in Tripura,Best Advance ndt services in Uttar Pradesh,
                  Best Advance ndt services in Uttarakhand,Best Advance ndt services in West Bengal, Advance ndt services in Andhra Pradesh, Advance ndt services in Arunachal Pradesh, Advance ndt services in Assam, Advance ndt services in Bihar,Advance ndt services in Chhattisgarh,Advance ndt services in Goa, Advance ndt services in Gujarat,Advance ndt services in Haryana,
                   Advance ndt services in Himachal Pradesh,Advance ndt services in Jammu and kashmir,Advance ndt services in Jharkhand, Advance ndt services in Karnataka, Advance ndt services in Kerala,Advance ndt services in Madhya Pradesh,Advance ndt services in Maharashtra,Advance ndt services in Manipur, Advancve ndt services in Meghalaya,
                  Advance ndt services in Mizoram,Best Advance ndt services in Nagaland,Advance ndt services in Odisha,Advance ndt services in Punjab,Advance ndt services in Rajasthan,Advance ndt services in Sikkim, Advance ndt services in TamilNadu,Advance ndt services in Telengana, Advance ndt services in Tripura, Advance ndt services in Uttar Pradesh,
                  Advance ndt services in Uttarakhand, Advance ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Advance NDT Services
                        </FancyText>
                    </h1>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='RemoteVisualInspection' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Remote Visual Inspection</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers cutting-edge Remote Visual Inspection (RVI).
                                            </div><br /><br />
                                            <div className='items-readmore text-left '>
                                                <a href='RemoteVisualInspection' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RemoteVisualInspection' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\rvi3.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='PhasedArrayUltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Phased Array Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers precision Phased Array Ultrasonic Testing (PAUT).
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='PhasedArrayUltrasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='PhasedArrayUltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\pautt3.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>03</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='TimeOfFlightDiffraction' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Time Of Flight Diffraction</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers innovative Time-Of-Flight Diffraction (TOFD) for accurate defect detection.
                                            </div><br />
                                            <div className='items-readmore text-left '>
                                                <a href='TimeOfFlightDiffraction' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='TimeOfFlightDiffraction' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\tofd5.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>04</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='LongRangeUltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Long Range Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers advanced LRUT for comprehensive defect detection.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='LongRangeUltrasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='LongRangeUltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\long7.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>05</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='/ShortRangeUltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Short Range Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers precise Short Range Ultrasonic Testing (SRUT) for accurate defect detection.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='/ShortRangeUltrasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='/ShortRangeUltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\srut4.png' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>06</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='MagneticResonanceUltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Magnetic Resonance Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers MRUT for comprehensive defect detection in structures.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='MagneticResonanceUltrasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='MagneticResonanceUltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\mrut.png' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>07</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='AutomatedUltrasonicTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Automated Ultrasonic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT provides advanced  AUT for efficient defect detection and analysis.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='AutomatedUltrasonicTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='AutomatedUltrasonicTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\ultrasonic.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>08</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='EddyCurrentTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Eddy Current Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers sophisticated Eddy Current Testing (ECT) for precise defect detection in conductive materials.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='EddyCurrentTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='EddyCurrentTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\ect2.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>09</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='RemoteFieldTechnique' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Remote Field Technique</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers advanced Remote Field Technique (RFET) for precise defect detection in ferrous tubing or piping.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='RemoteFieldTechnique' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='RemoteFieldTechnique' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\pmi.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>10</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='InternalRotaryInspectionSystem' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Internal Rotary Inspection System</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT provides precise tube and pipe inspection with Internal Rotary Inspection System (IRIS).
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='InternalRotaryInspectionSystem' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='InternalRotaryInspectionSystem' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\IRIS.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>11</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='MagneticFluxLeakage' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Magnetic Flux Leakage</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT offers accurate defect assessment with MFL for tubes and tanks.
                                            </div><br /><br />
                                            <div className='items-readmore text-left '>
                                                <a href='MagneticFluxLeakage' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='MagneticFluxLeakage' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\MFL Tube.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 mt-5'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>12</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-3'>
                                                <a href='NearFieldElectromagneticTesting' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Near Field Electromagnetic Testing</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                AMEEE NDT provides precise defect detection with NFET using electromagnetic fields.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='NearFieldElectromagneticTesting' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='NearFieldElectromagneticTesting' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\pmi.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Advance
