import { faPlay, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'
import '../Header.css'
import { Helmet } from 'react-helmet'
function Quotation() {
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT-Request For Quote | Quote Us|Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div>
        <div className=' border' style={{ backgroundImage: 'url(assets/ameee/bgimgtransformed.jpeg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '500px', opacity: '.8' }}>
          <h1 className='text-white p-5 text-center text-7xl mt-24'>
            <LinearGradient
              gradient={['to left', '#17acff ,#ff68f0']}
              fallbackColor="black"
            >
              Gets an Quotation
            </LinearGradient>
          </h1>
          <div className='flex  mt-10'>
            <Button href='Contact' className='ml-96 darksoul-gradient-card4 ' style={{ borderRadius: '80px', padding: '10px', height: '50px' }}>Get Appointment</Button>
            {/* <div className='ml-10' style={{border:'1px dashed white',borderRadius:'50%',height:'70px',width:'70px', animation: 'spin 2s linear infinite'}}>
              <FontAwesomeIcon icon={faPlay} className='mt-2 text-2xl text-white bg-blue-600' style={{ borderRadius: '50%', padding: '15px' }} />
            </div> */}
            <div className='ml-10' style={{
              position: 'relative',
              height: '70px',
              width: '70px'
            }}>
              <div className='border-rotate' style={{
                border: '2px dashed white',
                borderRadius: '50%',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                animation: 'spin 5s linear infinite'
              }}></div>
              <FontAwesomeIcon icon={faPlay} className='mt-2 ml-3 text-2xl text-white bg-blue-600' style={{ borderRadius: '50%', padding: '15px', position: 'relative', zIndex: '1' }} />
            </div>

            <div className='text-white text-left ml-10'>
              <h5 className='text-blue-500 text-5xl'>Call Us Now</h5>
              <h3><a href='tel:+91 8169361089' className='text-white decoration-transparent'>+91 8169361089</a></h3>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Quotation
