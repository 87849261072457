import React from 'react'
import First from './First'
import Conventional from './Conventional'
import Advance from './Advance'
import Civil from './Civil'
import { Nine } from './Nine'
import Welding from './Welding'
import Coat from './Coat'
import { Helmet } from 'react-helmet'

function WeldingInspection() {
    return (
        <div>
               <Helmet>
                <title>AMEEE NDT |Welding Inspection Services in Chennai |Welding Inspection Services in Padappai |Welding Inspection Services in Mumbai |Welding Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Welding Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Welding Inspection Services in Chennai ,Welding Inspection Services in Mumbai ,Welding Inspection Services in Haryana ,Welding Inspection Services in India,Best Welding Inspection services in Padappai,Best Welding Inspection Services in Chennai,Best Welding Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Welding Inspection services in Andhra Pradesh,Best Welding Inspection services in Arunachal Pradesh,Best Welding Inspection services in Assam,Best Welding Inspection services in Bihar,Best Welding Inspection services in Chhattisgarh,Best Welding Inspection services in Goa,Best Welding Inspection services in Gujarat,Best Welding Inspection services in Haryana,
                        Best Welding Inspection services in Himachal Pradesh,Best Welding Inspection services in Jammu and kashmir,Best Welding Inspection services in Jharkhand,Best Welding Inspection services in Karnataka,Best Welding Inspection services in Kerala,Best Welding Inspection services in Madhya Pradesh,Best Welding Inspection services in Maharashtra,Best Welding Inspection services in Manipur,Best Welding Inspection services in Meghalaya,
                    Best Welding Inspection services in Mizoram,Best Welding Inspection services in Nagaland,Best Welding Inspection services in Odisha,Best Welding Inspection services in Punjab,Best Welding Inspection services in Rajasthan,Best Welding Inspection services in Sikkim,Best Welding Inspection services in TamilNadu,Best Welding Inspection services in Telengana,Best Welding Inspection services in Tripura,Best Welding Inspection services in Uttar Pradesh,
                   Best Welding Inspection services in Uttarakhand,Best Welding Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className=' z-10  w-full' style={{ backgroundColor: '#020225' }}>
                <First />
            </div>
            <div>
                <Welding/>
                <h4 className='text-center text-5xl font-bold text-blue-900'>Explore Other Services</h4>
                <Conventional/>
                <Advance/>
                <Civil/>
                <Coat/>
            </div>
            <Nine/>
        </div >
    )
}

export default WeldingInspection
