import { faBuilding, faLightbulb, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function Process() {
  return (
    <div>
      <div className='mt-5'>
        <div>
          <h6 className='text-white text-left'>Processing System</h6>
          <h1 className='text-white text-left'>PROCESSING</h1>
        </div>
        <div>
          <p className='text-white font-semibold text-justify'>Knowledge international standards of,technologicaly changes industrial systems we are dedicated to provides seds the best and economical solutions to valued customers there us are many variations of majority have suffered.</p>
        </div>
        <div className=' flex justify-between'>
          <div className='row '>
            <div className=' col-lg-6 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/pt.jpeg' className='zooms-image' alt='process image' style={{ width: '150px', height: '150px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>01</div>
              </div>
              <div className='process-content text-white'>
                <h4>Conventional NDT</h4>
                {/* <p className=' font-normal'>Experience the Precision:Elevate your quality standards with our Conventional NDT services!</p> */}
              </div>
            </div>
            <div className=' col-lg-6 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/Advance NDT.jpg' className='zooms-image' alt='process image' style={{ width: '150px', height: '150px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count  bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>02</div>
              </div>
              <div className='process-content  text-white '>
                <h4>Advance NDT</h4>
                {/* <p className=' font-normal'>Breaking Boundaries:Unleash the power of Advance NDT solutions with us!</p> */}
              </div>
            </div>
            <div className=' col-lg-6 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/welding.jpg' className='zooms-image' alt='process image' style={{ width: '150px', height: '150px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>03</div>
              </div>
              <div className='process-content  text-white'>
                <h4>Welding Inspection</h4>
                {/* <p className=' font-normal'>Ensure Excellence:Trust our expertise for comprehensive Welding Inspection solutions!</p> */}
              </div>
            </div>
            <div className=' col-lg-6 col-md-6 col-sm-6'>
              <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
                <img src='assets/ameee/rope.jpg' className='zooms-image' alt='process image' style={{ width: '150px', height: '150px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
                <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>04</div>
              </div>
              <div className='process-content text-white'>
                <h4>Rope Access</h4>
                {/* <p className=' font-normal'>Reach new heights safely:Experience superior Rope Access solutions with us!</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>

    </div>
  )
}

export default Process
