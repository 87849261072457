import React from 'react'
import Typewriter from "typewriter-effect";

function Nav() {
  return (
    <div className=''>
     <Typewriter 
    loop
    wrapperClassName="typewriter-wrapper"
    cursorClassName="typewriter-cursor"
    onInit={(typewriter) => {
        typewriter
            .typeString("Successful Business")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Keys to Prosperity: Building a Thriving Enterprise")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Unlocking Business Success Through Innovation")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Strategies for Sustainable Growth")
          
            .start(); 
    }}
/>


    </div>
  )
}

export default Nav
