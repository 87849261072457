import React from 'react'
import { Card } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'

function Work() {
    return (
        <div className=' text-white' style={{ backgroundImage: 'url(a1.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className='container p-5' >
                <div className='grid grid-cols-2 text-left'>
                    <div>
                        <h5>How It Work</h5>
                        <h1 className='text-5xl font-bold'>
                            <LinearGradient gradient={['to right', '#17acff ,#ff68f0']}>
                                Our Company Working Process
                            </LinearGradient>
                        </h1>
                        <div className='grid grid-cols-2 mt-5'>
                            <span className='text-center text-7xl text-neutral-400'>01</span>
                            <div>
                                <h3 className='text-blue-500'>Discussions About Project</h3>
                                <p>Forging relationships between multi to national corporations, governments and global NGOs.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 ' >
                            <span className='text-center text-7xl text-neutral-400'>02</span>
                            <div>
                                <h3 className='text-blue-500'>Start Work With Team</h3>
                                <p>Design studio founded in London and expanded our services, and become a multinational firm.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2'>
                            <span className='text-center text-7xl text-neutral-400'>03</span>
                            <div>
                                <h3 className='text-blue-500'>Handover & Save World</h3>
                                <p>We understand the importance of approaching each work integrally and believe in the power</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className=' ' style={{ height: '400px', width: '400px' }}>
                            <Card>
                                <div className='p-3'>
                                    <img src='assets/ameee/vt3.jpg' />
                                </div>
                            </Card>
                        </div>
                        <div className='ml-64 ' style={{ marginTop: '-200px', position: 'absolute', height: '500px', width: '500px' }}>
                            <Card>
                                <div className='p-3'>
                                    <img src='assets/ameee/ultrasonic.jpg' />
                                </div>
                            </Card>
                        </div>
                        <div className='' style={{ height: '400px', width: '400px' }}>
                            <Card>
                                <div className='p-3'>
                                    <img src='assets/ameee/welding.jpg' />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work
