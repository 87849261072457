import React from 'react';
import { Button } from 'react-bootstrap';
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay } from '@fortawesome/free-solid-svg-icons';
import MyCarousel from './MyCarousel';
import Sec_footer from './sec_foooter';


function Second() {
  return (
    <div className='w-full m-0 p-0'>
      <span className='topText p-2  text-lg font-bold text-blue-600'>
        <span className='text-white text-6xl px-2 font-bold'>.</span>Setting the Benchmark
     <span className='  text-white'> Leaders in NDT Services and Global Inspection Excellence</span><span className='text-white text-6xl px-2 font-bold'>.</span></span>
   <h1 className='font-bold text-5xl mt-4  '>Successful Business </h1>
    <h1 className='font-bold text-5xl text-blue-500'>NDT Services & Inspections</h1>
    <div className='flex justify-center mt-5'>
    <Button className='px-3 mx-3 hover:underline'>Get Started</Button>
    <a href="/" class="vedio-button  ">
        <FontAwesomeIcon icon={faPlay} style={{ color: 'white' }}/>
       </a>
       <Button variant="outline-primary" className='mx-3 hover:underline '>    Read More    </Button>
      
       
    </div>
    
    <div className='mt-32 w-full px-12'>
 {/* <Sec_footer/> */}
   
    </div>
    </div>
  );
}




export default Second;

