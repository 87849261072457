import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlay,  faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import Testimony from './Testimony';
import Client from './Client';
import Quote from './Quote';
import { Helmet } from 'react-helmet';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FancyText from '@carefully-coded/react-text-gradient';
function Ten() {
  return (
    <div>
         <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='text-center pb-40'>
      <FancyText
                  gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                  animate
                  animateDuration={5000}
                >
        <h3 className='text-white text-6xl font-bold pt-16'>Want To Work With Us? </h3>
        </FancyText>
        <p className='text-white pt-3 text-4xl font-bold'>Meet Our People. See Our Work. Join Our Team</p>
  
        <div className='flex flex-col sm:flex-row justify-between mt-20'>
          <div className='flex justify-between py-2 border-r-8 border-l-8 text-center w-full sm:w-80 rounded-tl-full rounded-br-full text-2xl cursor-pointer bg-slate-700 text-white font-bold relative'>
            <a href='tel:+91 8169361089' className='pl-12 my-8 text-white decoration-transparent'>+91 8169361089</a>
            <span className='pl-5 bg-blue-600 p-2 rounded-full w-24 h-24 pt-4 border-4 absolute bottom-0 left-0 transform -translate-y-24 translate-x-72'>
              <FontAwesomeIcon icon={faPhone} shake aria-hidden='true' className='text-4xl' />
            </span>
          </div>
          <div>
            <Button href='Contact' className='px-3 sm:ml-4' style={{ fontWeight: 'bold',boxShadow:' 0 5px 5px blue' }}>Contact With Us</Button>
          </div>
          <div className='flex justify-between py-2 border-l-8 border-r-8 mt-4 sm:mt-0 text-center w-full sm:w-80 rounded-bl-full rounded-tr-full text-2xl cursor-pointer bg-slate-700 text-white font-bold relative'>
            <a href='https://web.whatsapp.com/send?phone=8110877771' target='blank' className='pl-12 my-8 text-white decoration-transparent'>+91 8110877771</a>
            <span className='pl-5 bg-green-600 p-2 rounded-full w-24 h-24 pt-4 border-4 absolute bottom-0 left-0 transform -translate-y-28 -translate-x-14'>
              <FontAwesomeIcon icon={faWhatsapp} shake aria-hidden='true' className='text-4xl' />
            </span>
          </div>
        </div>
        <div className='text-center'>
          <a href="/" className="vedio-button ">
            <FontAwesomeIcon icon={faPlay} style={{ color: 'white' }} className='text-5xl' />
          </a>
        </div>
      </div> 
 
          <Testimony/>
          <Client/>
          <div>
          <Quote/>
          </div>
        </div>

  )
}

export default Ten