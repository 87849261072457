import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
import SidebarComponent from './Sidebar'
function Pipeline() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Pipeline Testing & Inspection Services in Chennai |Pipeline Testing & Inspection Services in Padappai |Pipeline Testing & Inspection Services in Mumbai |Pipeline Testing & Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Pipeline Testing & Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Pipeline Testing & Inspection Services in Chennai ,Pipeline Testing & Inspection Services in Mumbai ,Pipeline Testing & Inspection Services in Haryana ,Pipeline Testing & Inspection Services in India,Best Pipeline Testing & Inspection services in Padappai,Best Pipeline Testing & Inspection Services in Chennai,Best Pipeline Testing & Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Pipeline Testing & Inspection services in Andhra Pradesh,Best Pipeline Testing & Inspection services in Arunachal Pradesh,Best Pipeline Testing & Inspection services in Assam,Best Pipeline Testing & Inspection services in Bihar,Best Pipeline Testing & Inspection services in Chhattisgarh,Best Pipeline Testing & Inspection services in Goa,Best Pipeline Testing & Inspection services in Gujarat,Best Pipeline Testing & Inspection services in Haryana,
                        Best Pipeline Testing & Inspection services in Himachal Pradesh,Best Pipeline Testing & Inspection services in Jammu and kashmir,Best Pipeline Testing & Inspection services in Jharkhand,Best Pipeline Testing & Inspection services in Karnataka,Best Pipeline Testing & Inspection services in Kerala,Best Pipeline Testing & Inspection services in Madhya Pradesh,Best Pipeline Testing & Inspection services in Maharashtra,Best Pipeline Testing & Inspection services in Manipur,Best Pipeline Testing & Inspection services in Meghalaya,
                    Best Pipeline Testing & Inspection services in Mizoram,Best Pipeline Testing & Inspection services in Nagaland,Best Pipeline Testing & Inspection services in Odisha,Best Pipeline Testing & Inspection services in Punjab,Best Pipeline Testing & Inspection services in Rajasthan,Best Pipeline Testing & Inspection services in Sikkim,Best Pipeline Testing & Inspection services in TamilNadu,Best Pipeline Testing & Inspection services in Telengana,Best Pipeline Testing & Inspection services in Tripura,Best Pipeline Testing & Inspection services in Uttar Pradesh,
                   Best Pipeline Testing & Inspection services in Uttarakhand,Best Pipeline Testing & Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Pipeline Testing & Inspection</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>
                                        <strong className='text-xl'>AMEEE NDT</strong> is a leading provider of comprehensive non-destructive testing (NDT) services, committed to ensuring the integrity and reliability of materials and components within pipeline systems across diverse industries. Our pipeline testing and inspection services encompass a range of techniques aimed at detecting and assessing potential defects or anomalies that could compromise the safety and performance of pipelines.
                                        Pipeline testing involves rigorous examination and assessment to identify defects such as corrosion, cracks, weld irregularities, and other structural issues. Our experienced technicians employ both manual inspection methods and advanced automated tools to ensure thorough and accurate evaluation.
                                        By leveraging cutting-edge technology and adhering to industry best practices, we deliver precise and reliable inspection results that enable our clients to make informed decisions regarding the maintenance and integrity management of their pipeline assets.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Pipeline Testing and Inspection</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Pipeline testing and inspection aim to identify and assess surface irregularities, defects, or discontinuities that have the potential to compromise the integrity, safety, or functionality of pipeline systems. By conducting thorough evaluations, including visual inspection and other NDT techniques, we ensure the reliability and performance of pipelines across various industries.</p>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div className=''>
                                        <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' style={{ width: '900px' }} />
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-20'>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className='text-gray-500 text-lg'>Pipeline testing and inspection are crucial processes in ensuring the safety, reliability, and efficiency of pipeline systems. These processes involve comprehensive evaluations and assessments to detect potential defects, anomalies, or degradation in pipelines.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-left text-gray-700 text-lg '>Pipeline testing and inspection utilize various techniques and methodologies to assess the condition and integrity of pipelines. This includes non-destructive testing methods such as ultrasonic testing, magnetic particle inspection, radiographic testing, and visual inspection. The principle underlying these methods is to identify and evaluate any imperfections, corrosion, or structural weaknesses that may compromise the performance or safety of the pipeline.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Comprehensive evaluation:</b> Allows thorough assessment of pipeline condition and integrity.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                <b>Early detection:</b> Helps identify potential issues before they escalate into major problems.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='text-blue-900' />
                                                <b>Enhanced safety:</b> Contributes to the prevention of accidents and incidents related to pipeline failures.
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cost and complexity:</b> Implementation and maintenance of testing systems can be expensive and technically challenging.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>False positives:</b> Some testing methods may produce inaccurate or misleading results.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Limited detection capability:</b> Certain defects or anomalies may go undetected by conventional testing methods.
                                            </li><hr />
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <Card className='p-3' style={{ width: '330px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes & book shipment.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content' style={{ width: '900px' }}>
                                                <div className='ml-12'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '300px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content' style={{ width: '900px' }}>
                                            <div className='ml-12'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Pipeline Testing and Inspection Process</h4>
                    <p className='font-semibold'>Pipeline testing and inspection conducted by AMEEE NDT involves comprehensive evaluation of pipeline integrity and condition, both externally and internally. The process includes:</p>
                    <ul className='list-disc ml-8'>
                        <li>Utilizing various non-destructive testing (NDT) methods such as ultrasonic testing, magnetic particle inspection, radiographic testing, and visual inspection.</li>
                        <li>Systematic examination of pipeline surfaces for defects, anomalies, and structural weaknesses.</li>
                        <li>Documentation and analysis of inspection findings, comparing them against industry standards and regulatory requirements.</li>
                        <li>Preparation of detailed inspection reports providing stakeholders with valuable insights for decision-making and maintenance planning.</li>
                        <li>Adherence to stringent safety protocols to ensure the accuracy and reliability of inspection results.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Pipeline Testing and Inspection Equipment, Accessories & Consumables</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables tailored for pipeline testing and inspection requirements. Our offerings include:
                    </p>
                    <ul className='list-disc ml-8'>
                        <li>Ultrasonic testing (UT) equipment for assessing the integrity of pipeline walls.</li>
                        <li>Magnetic particle inspection (MPI) equipment for detecting surface and near-surface defects.</li>
                        <li>Radiographic testing (RT) equipment for internal inspection of pipelines.</li>
                        <li>Visual inspection tools such as inspection lights, magnifying devices, and surface preparation tools.</li>
                        <li>Marking and identification tools for accurately labeling inspection locations.</li>
                        <li>Protective equipment to ensure inspector safety during testing operations.</li>
                        <li>NDT consumables including penetrants and magnetic particles for enhancing defect detection.</li>
                        <li>Documentation tools for recording inspection findings and generating comprehensive reports.</li>
                        <li>Calibration standards to ensure the accuracy and reliability of testing equipment.</li>
                        <li>Training materials to support the professional development of inspection personnel.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our specialized equipment, accessories, and consumables, AMEEE NDT empowers organizations to conduct thorough and precise pipeline testing and inspection, ensuring the integrity and reliability of their pipeline systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Pipeline Testing and Inspection Standards</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for pipeline testing and inspection. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc ml-8'>
                        <li>American Society for Testing and Materials (ASTM) standards such as ASTM E317 for ultrasonic testing of pipeline welds and ASTM E1212 for radiographic examination of weldments.</li>
                        <li>American National Standards Institute (ANSI) standards including ANSI/ASME B31 series for pipeline construction and maintenance.</li>
                        <li>American Petroleum Institute (API) standards like API 570 for inspection, repair, alteration, and rerating of in-service piping systems.</li>
                    </ul>
                    <p className='font-semibold'>
                        By adhering to these standards and codes, AMEEE NDT ensures that pipeline testing and inspection are conducted in accordance with established best practices, guaranteeing the safety, reliability, and compliance of critical pipeline assets.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Parameters in Pipeline Testing and Inspection</h4>
                    <p className='font-semibold'>
                        During pipeline testing and inspection, AMEEE NDT evaluates various parameters to ensure the integrity and reliability of the pipeline system. Key parameters assessed include:
                    </p>
                    <ul className='list-disc ml-8'>
                        <li>Wall thickness: Measurement of the thickness of pipeline walls to detect thinning or corrosion.</li>
                        <li>Surface condition: Examination of the pipeline surface for defects, anomalies, and signs of deterioration.</li>
                        <li>Weld integrity: Assessment of welds for discontinuities, cracks, or incomplete fusion.</li>
                        <li>Internal condition: Inspection of the internal surface of the pipeline for corrosion, scaling, or deposits.</li>
                        <li>Leak detection: Identification of leaks or potential leakage points using various testing methods.</li>
                        <li>Material properties: Evaluation of material properties such as hardness, tensile strength, and ductility.</li>
                    </ul>
                    <p className='font-semibold'>
                        By analyzing these parameters, AMEEE NDT provides valuable insights for asset maintenance and integrity management, facilitating informed decision-making and proactive maintenance planning for pipeline systems.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>Defects Detected in Pipeline Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT specializes in detecting a wide range of defects and anomalies during pipeline testing and inspection. Some of the defects we can capture include:
                    </p>
                    <ul className='list-disc ml-8'>
                        <li>Corrosion: Identification of corrosion spots, pits, and general corrosion along the pipeline surface.</li>
                        <li>Cracks: Detection of surface cracks, weld cracks, and other types of structural cracks that may compromise pipeline integrity.</li>
                        <li>Dimensional deviations: Measurement of dimensional variations such as wall thickness irregularities and diameter changes.</li>
                        <li>Coating imperfections: Inspection for coating defects, including disbondment, blistering, and holiday detection.</li>
                        <li>Weld defects: Recognition of weld discontinuities such as lack of fusion, porosity, and incomplete penetration.</li>
                        <li>Internal anomalies: Detection of internal defects such as scale buildup, deposits, and corrosion pitting.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through meticulous inspection techniques and advanced testing methods, AMEEE NDT ensures comprehensive defect detection in pipelines, enabling proactive maintenance and effective risk mitigation strategies.
                    </p>
                </div>

            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Pipeline Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and guidelines tailored for pipeline testing and inspection. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc ml-8'>
                        <li>American Society for Testing and Materials (ASTM) standards such as ASTM E317 for ultrasonic testing of pipeline welds and ASTM E1212 for radiographic examination of weldments.</li>
                        <li>American National Standards Institute (ANSI) standards including ANSI/ASME B31 series for pipeline construction and maintenance.</li>
                        <li>American Petroleum Institute (API) standards like API 570 for inspection, repair, alteration, and rerating of in-service piping systems.</li>
                    </ul>
                    <p className='font-semibold'>
                        By referencing these standards and guidelines, AMEEE NDT ensures that pipeline testing and inspection are conducted in accordance with established best practices, guaranteeing the integrity, safety, and compliance of critical pipeline assets.
                    </p>
                </div>

            </div>
        </div>
    );
}


export default Pipeline
