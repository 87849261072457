import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

const Bargraph = () => {
 const data = {
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
    datasets: [
      {
        label: 'Data Series 1',
        backgroundColor: '#007bff',
        borderColor: '#007bff',
        borderWidth: 1,
        data: [10, 50, 30, 52, 100, 80, 90],
      },
      {
        label: 'Data Series 2',
        backgroundColor: 'green', 
        borderColor: 'green', 
        borderWidth: 1,
        data: [20, 60, 40, 62, 110, 90, 100], 
      },
    ],
  };
  
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: { color: "white", beginAtZero: true }
      },
      x: {
        ticks: { color: "white", beginAtZero: true },
      },
    },
  };
return (
    <div style={{ height: "500px" }}> 
      <Bar data={data} options={options} />
    </div>
  );
};

export default Bargraph;
