import React from 'react'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLandmark, faLocation, faLocationPin, faMap, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Button } from 'react-bootstrap'
import First from './First'
import Foooter from './Footer'
import { Nine } from './Nine'
import Fourth from './fourth'
import Fifth from './fifth'
import { Helmet } from 'react-helmet'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
function Contact() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT-Contact Us | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
                <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                <First />
            </div>
            <div>
                <section className=' bg-blue-100 p-5'>
                    <div className='container mt-32'>
                        <div className='text-center font-extrabold'>
                            <h2 className=' font-extrabold p-3  text-5xl' id='down'>
                                <span className='text-primary'>We</span> Are Here
                            </h2>
                            <div className="relative inline-block" id='up'>
                                <span className="bg-primary text-white px-3 py-2  text-2xl">We'd Love To Help You</span>
                                <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                                    <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
                                </span>
                            </div>
                            <p className='p-5 text-3xl '>Explore Exciting Opportunities:Join Us and Make a Difference!</p>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className=''>
                                    <aside className=''>
                                        <h4 className='text-5xl text-primary'>Get In Touch With Us</h4>
                                        <div className='' style={{ border: '1px solid black', backgroundColor: '#2b2b2b', marginTop: '15px', borderRadius: '5px', position: 'relative', overflow: 'hidden' }}>
                                            <div className='transform-box' />
                                            <div className='text-left ml-5 mt-2'>
                                                <p className='text-white '>
                                                    <FontAwesomeIcon icon={faPhone} className='text-blue-500 text-2xl' style={{ borderRadius: '50%', border: '1px solid #2273bf', padding: '2%' }} />
                                                    <span className=' font-bold text-2xl ml-5 mt-2' style={{ marginBottom: '20px', position: 'absolute ' }}>Call Us</span>
                                                    <div className='ml-16'>
                                                        +91 8169361089
                                                    </div>
                                                </p>
                                            </div>

                                        </div>
                                        <div className=' ' style={{ border: '1px solid black', backgroundColor: '#2b2b2b', marginTop: '15px', borderRadius: '5px', position: 'relative', overflow: 'hidden' }} >
                                            <div className='transform-box' />
                                            <div className='text-left ml-5 mt-2'>
                                                <p className='text-white'>
                                                    <FontAwesomeIcon icon={faEnvelope} className='text-blue-500 text-2xl' style={{ borderRadius: '50%', border: '1px solid #2273bf', padding: '2%' }} />
                                                    <span className='font-bold text-2xl ml-5 mt-2' style={{ marginBottom: '20px', position: 'absolute ' }}>Write Us</span>
                                                    <div className='ml-16'>
                                                        inspection@ameeendt.com
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className=' ' style={{ border: '1px solid black', backgroundColor: '#2b2b2b', marginTop: '15px', borderRadius: '5px', position: 'relative', overflow: 'hidden' }}>
                                            <div className='transform-box' />
                                            <div className='text-left ml-5 mt-2'>
                                                <p className='text-white'>
                                                    <FontAwesomeIcon icon={faWhatsapp} className='text-blue-500 text-2xl' style={{ borderRadius: '50%', border: '1px solid #2273bf', padding: '2%' }} />
                                                    <span className='font-bold text-2xl ml-5 mt-2' style={{ marginBottom: '20px', position: 'absolute ' }}>Whatsapp</span>
                                                    <div className='ml-16'>
                                                        +91 8110877771
                                                    </div>
                                                </p>
                                            </div></div>
                                        <div className=' ' style={{ border: '1px solid black', backgroundColor: '#2b2b2b', marginTop: '15px', borderRadius: '5px', position: 'relative', overflow: 'hidden' }}>
                                            <div className='transform-box' />
                                            <div className='text-left ml-5 mt-2'>
                                                <p className='text-white'>
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} className='text-blue-500 text-2xl' style={{ borderRadius: '50%', border: '1px solid #2273bf', padding: '2%' }} />
                                                    <span className='font-bold text-2xl ml-5 mt-2' style={{ marginBottom: '20px', position: 'absolute ' }}>Address</span>
                                                    <div className='ml-16'>
                                                        7/8 Subbhiya Complex,KR Puram,Behind Sub-Register office,Padappai,Kancheepuram,TamilNadu. 601301
                                                    </div>
                                                </p>
                                            </div></div>
                                    </aside>
                                </div>
                            </div>
                            <div className='col-sm-6 '>
                                <div className=''>
                                    <h4 className='text-5xl text-primary'>Drop Us A Message</h4>
                                    <div role='form'>
                                        <form method='post'>
                                            <p>
                                                <label>
                                                    Your Name <br />
                                                    <span>
                                                        <input size={40} type='text' placeholder='Your Full Name' className='p-2' style={{ border: '1px solid #3d9bd1', borderRadius: '5px' }} />
                                                    </span>
                                                </label>
                                            </p>
                                            <p>
                                                <label>
                                                    Your Email<br />
                                                    <span>
                                                        <input size={40} type='text' placeholder='Your Email Address' className='p-2' style={{ border: '1px solid #3d9bd1', borderRadius: '5px' }} />
                                                    </span>
                                                </label>
                                            </p>
                                            <p>
                                                <label>
                                                    Subject <br />
                                                    <span>
                                                        <input size={40} type='text' placeholder='General Enquiry' className='p-2' style={{ border: '1px solid #3d9bd1', borderRadius: '5px' }} />
                                                    </span>
                                                </label>
                                            </p>
                                            <p>
                                                <label>
                                                    Your Message (Optional)<br />
                                                    <span>
                                                        <textarea cols={40} rows={10} placeholder='Drop Your Message' className='p-2' style={{ border: '1px solid #3d9bd1', borderRadius: '5px' }} />
                                                    </span>
                                                </label>
                                            </p>
                                            <p>
                                                <Button>Send Request</Button>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="office-address">
                    <div class="container">
                        <div class="col-lg-6 mx-auto text-center font-extrabold">
                            <h2 class=" font-extrabold text-5xl" id='down'>
                                <span className=' text-blue-600'>   We</span> Are Here
                            </h2>
                            {/* <span class="sub-title">
                                Outstanding Location
                            </span> */}
                            <div className="relative inline-block" id='up' >
                                <span className="bg-primary text-white px-3 py-2  text-xl" >Outstanding Location</span>
                                <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                                    <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
                                </span>
                            </div>
                            <p className='mt-5 text-3xl text-gray-600'>
                                Explore Exciting Opportunities:Join Us and Make a Difference!
                            </p>
                        </div>
                        <div class="hover-country">
                            <div class="row row-cols-lg-5 row-cols-md-4 row-cols-2">
                                <div class="col country-office office1">
                                    <div class="country-image">
                                        <img
                                            src="assets/ameee/chennai.jpg" />
                                        <div class="country-content">
                                            <div class="flag-image">
                                                <img
                                                    src="assets/ameee/Railway Inspection.jpeg" />
                                            </div>
                                            <div class="country-text">
                                                <h4 class="title">
                                                    <a href="#">Chennai</a>
                                                </h4>
                                                <p>
                                                    TamilNadu, India
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col country-office office2">
                                    <div class="country-image">
                                        <img
                                            src="assets/ameee/jharkhand.jpeg" />
                                        <div class="country-content">
                                            <div class="flag-image">
                                                <img
                                                    src="assets/ameee/Surface Eddy Current.jpeg" />
                                            </div>
                                            <div class="country-text">
                                                <h4 class="title">
                                                    <a href="#">Jharkhand</a>
                                                </h4>
                                                <p>
                                                    Jharkhand, India </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col country-office office3" >
                                    <div class="country-image" >
                                        <div class="country-content" >
                                            <div class="flag-image" >
                                                <div >
                                                    <FontAwesomeIcon icon={faLocationPin} className='i' />
                                                </div>
                                            </div>
                                            <div class="country-text" >
                                                <h4 class="title">
                                                    <a href="#">Find</a>
                                                </h4>
                                                <p>
                                                    Our Offers </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col country-office office4">
                                    <div class="country-image">
                                        <img
                                            src="assets/ameee/Kerala.jpg" />
                                        <div class="country-content">
                                            <div class="flag-image">
                                                <img
                                                    src="assets/ameee/tube.jpg" />
                                            </div>
                                            <div class="country-text">
                                                <h4 class="title">
                                                    <a href="#">Kerala</a>
                                                </h4>
                                                <p>
                                                    Kerala, India
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col country-office office5">
                                    <div class="country-image">
                                        <img
                                            src="assets/ameee/tvl.jpg" />
                                        <div class="country-content">
                                            <div class="flag-image">
                                                <img
                                                    src="assets/ameee/Windmill.jpg" />
                                            </div>
                                            <div class="country-text">
                                                <h4 class="title">
                                                    <a href="#">Tirunelveli</a>
                                                </h4>
                                                <p>
                                                    TamilNadu, India
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div>
                    <Fifth />
                </div>
                <div>
                    <Nine />
                </div>
            </div >
        </div >
    )
}

export default Contact
