import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Valve1() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Positive Material Identification Services in Valve Testing | Positive Material Identification Services in Padappai | Positive Material Identification Services in Mumbai | Positive Material Identification Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Positive Material Identification services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert PMI services!" />
                <meta name="title" content="Positive Material Identification Services in Valve Testing, PMI Services in Mumbai, PMI Services in Haryana, PMI Services in India, Best PMI Services in Padappai, Best PMI Services in Chennai, Best PMI Services in Bangalore" />
                <meta name="keywords" content="Best PMI Services in Andhra Pradesh, Best PMI Services in Arunachal Pradesh, Best PMI Services in Assam, Best PMI Services in Bihar, Best PMI Services in Chhattisgarh, Best PMI Services in Goa, Best PMI Services in Gujarat, Best PMI Services in Haryana, Best PMI Services in Himachal Pradesh, Best PMI Services in Jammu and Kashmir, Best PMI Services in Jharkhand, Best PMI Services in Karnataka, Best PMI Services in Kerala, Best PMI Services in Madhya Pradesh, Best PMI Services in Maharashtra, Best PMI Services in Manipur, Best PMI Services in Meghalaya, Best PMI Services in Mizoram, Best PMI Services in Nagaland, Best PMI Services in Odisha, Best PMI Services in Punjab, Best PMI Services in Rajasthan, Best PMI Services in Sikkim, Best PMI Services in Tamil Nadu, Best PMI Services in Telangana, Best PMI Services in Tripura, Best PMI Services in Uttar Pradesh, Best PMI Services in Uttarakhand, Best PMI Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Positive Material Identification Services in Valve Testing" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive Positive Material Identification services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert PMI services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Positive Material Identification Services in Valve Testing" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive Positive Material Identification services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert PMI services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full  '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Positive Material Identification- Valve Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Positive Material Identification Services in Valve Testing</strong> are offered by AMEEE NDT, a leading provider of comprehensive testing solutions. Our specialized team conducts meticulous positive material identification assessments in valve testing environments. Utilizing advanced technology and expertise, we ensure accurate evaluation and assessment of valve materials and components in various industrial settings. Our positive material identification services enable us to verify material composition and quality through non-destructive methods, providing crucial insights into material properties and ensuring compliance with industry standards and specifications.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Positive Material Identification in Valve Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Positive material identification in valve testing serves the critical purpose of verifying the composition and quality of valve materials and components through non-destructive methods. This testing process involves using specialized equipment and techniques to analyze the elemental composition of materials, ensuring they meet specified requirements and standards. By conducting positive material identification, our services aim to mitigate risks associated with material discrepancies, verify material traceability, and ensure the integrity and reliability of valves used in industrial applications.</p>
                                    </div>



                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }} >
                                            <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Positive Material Identification in Valve Testing</h4>
                                            <p className='text-gray-500 text-lg'>Positive material identification (PMI) in valve testing has a significant historical background within the valve manufacturing and industrial sectors. Over the years, as the demand for precise material identification in valve components has increased, PMI techniques have evolved to ensure the quality, reliability, and safety of valves used in various applications. The historical progression of PMI methods has been instrumental in enhancing the efficiency and performance of valve testing processes.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Positive Material Identification in Valve Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Positive material identification (PMI) in valve testing employs advanced methodologies to accurately determine the chemical composition and material properties of valve components. Through non-destructive testing techniques such as spectroscopy and elemental analysis, PMI assesses the composition of valve materials, ensuring they meet specified standards and performance requirements. By utilizing PMI in valve testing, manufacturers and industries can verify the quality and authenticity of valve materials, enhancing the safety and reliability of valve systems in diverse industrial applications.</p>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Material Verification:</b> Positive material identification (PMI) in valve testing ensures accurate verification of valve material composition, enhancing quality control.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Quality Assurance:</b> PMI helps in maintaining quality assurance by confirming that valve materials meet specified standards and performance criteria.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Preventive Maintenance:</b> By identifying material inconsistencies or anomalies early, PMI supports preventive maintenance efforts, reducing the risk of valve failures.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Equipment Dependency:</b> PMI requires specialized equipment and trained personnel, increasing dependency on resources and expertise.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface Limitations:</b> Surface conditions and accessibility may limit the effectiveness of PMI, particularly in complex valve configurations or hard-to-reach areas.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Sampling Challenges:</b> PMI may face challenges in obtaining representative samples from valves, especially in situations where valve disassembly is not feasible.
                                            </li>
                                        </ul>
                                    </div>





                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px', height: '570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Positive Material Identification in Valve Testing Process - How We Work</h4>
                    <p className='font-semibold'>Positive material identification (PMI) in valve testing at AMEEE NDT involves a meticulous assessment of valve materials using specialized testing procedures. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Performing positive material identification on valve materials using advanced PMI equipment and techniques to determine their chemical composition and integrity.</li>
                        <li>Recording testing observations and collecting data to verify the material composition of valves and ensure compliance with specified standards and requirements.</li>
                        <li>Thorough analysis of PMI results to confirm the suitability and quality of valve materials for intended applications and operating conditions.</li>
                        <li>Preparation of detailed reports outlining the PMI procedures, material analysis results, and recommendations for ensuring material integrity and performance.</li>
                        <li>Adherence to safety protocols and standards to safeguard personnel and maintain the integrity of the testing environment during PMI testing.</li>
                    </ul>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Positive Material Identification in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for positive material identification (PMI) in valve testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>PMI equipment featuring specialized analyzers and instruments tailored for accurately determining the chemical composition of valve materials.</li>
                        <li>Testing accessories such as sample preparation tools, surface preparation materials, and handling equipment to facilitate PMI procedures on valve components.</li>
                        <li>Consumables including certified reference materials (CRMs), cleaning agents, and verification standards optimized for enhancing the accuracy and reliability of PMI testing in valve environments.</li>
                        <li>Calibration standards calibrated for the specific requirements of PMI equipment, essential for verifying testing accuracy and maintaining calibration integrity.</li>
                        <li>Advanced software for data acquisition, analysis, and reporting to streamline the interpretation and documentation of PMI results in valve testing.</li>
                        <li>Personal protective equipment (PPE) for testing personnel, including helmets, gloves, and safety suits, to ensure their safety during PMI procedures in valve testing environments.</li>
                        <li>Training materials and resources to educate personnel on the principles of PMI, equipment operation, interpretation of testing data, and safety procedures in valve testing environments.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our comprehensive range of equipment and resources, we provide everything necessary to conduct thorough and accurate positive material identification in valve testing.
                    </p>
                </div>



            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Positive Material Identification in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for positive material identification (PMI) in valve testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASTM E1476 Standard Test Method for Metals Identification by Mass Spectrometry</li>
                        <li>ISO 15614-1 Specification and Qualification of Welding Procedures for Metallic Materials - Welding Procedure Test - Part 1: Arc and Gas Welding of Steels and Arc Welding of Nickel and Nickel Alloys</li>
                        <li>ASME B16.34 Valves - Flanged, Threaded, and Welding End</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the accuracy, reliability, and safety of our positive material identification (PMI) procedures in valve testing, helping to verify the composition and quality of valve materials in various industrial applications.
                    </p>
                </div>




            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Positive Material Identification in Valve Testing</h4>
                    <p className='font-semibold'>
                        During positive material identification (PMI) in valve testing, AMEEE NDT evaluates various parameters to ensure the accuracy and reliability of material composition analysis. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Chemical Composition Analysis: Assessing the elemental composition of valve materials to verify their alloy content and conformity to specifications.</li>
                        <li>Material Verification: Verifying the material grade and type of valve components through spectroscopic analysis and identification techniques.</li>
                        <li>Alloy Identification: Identifying specific alloy types present in valve materials to ensure compatibility with intended applications and environmental conditions.</li>
                        <li>Trace Element Detection: Detecting trace elements or contaminants in valve materials that may affect their performance or integrity.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our comprehensive evaluations provide crucial insights for ensuring the quality, reliability, and compliance of valve materials in various industrial applications during positive material identification (PMI) testing.
                    </p>
                </div>



            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Positive Material Identification in Valve Testing</h4>
                    <p className='font-semibold'>
                        During positive material identification (PMI) in valve testing conducted by AMEEE NDT, we have the capability to identify various anomalies and deviations, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Incorrect Material Composition: Identifying discrepancies in material composition, such as incorrect alloy grades or compositions, which may affect valve performance and reliability.</li>
                        <li>Contaminants: Detecting foreign elements or contaminants in valve materials that can compromise their integrity or cause corrosion-related issues.</li>
                        <li>Material Mix-ups: Verifying material consistency and ensuring that valve components are made from the specified materials to prevent compatibility issues or failures.</li>
                        <li>Incomplete Material Traceability: Ensuring proper documentation and traceability of valve materials to maintain quality control and compliance with industry standards.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous positive material identification (PMI) techniques and adherence to stringent quality standards, we ensure accurate assessment and verification of valve materials, contributing to enhanced performance, reliability, and safety in various industrial applications.
                    </p>
                </div>



            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Positive Material Identification in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for positive material identification (PMI) in valve testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME B16.34 Valves - Flanged, Threaded, and Welding End: A standard for valve materials, dimensions, tolerances, and marking requirements, ensuring compliance with material specifications.</li>
                        <li>ASTM E1476 Standard Guide for Metals Identification, providing guidelines for non-destructive methods of identifying metals and alloys, applicable to positive material identification (PMI) in valve testing.</li>
                        <li>ISO 9223 Corrosion of Metals and Alloys - Corrosivity of Atmospheres - Classification, Determination, and Estimation, relevant for assessing corrosion resistance and material compatibility in valve applications.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our positive material identification (PMI) processes in valve testing, ultimately contributing to the integrity and performance of critical valve components.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Valve1
