import { faChevronRight, faComments, faFunnelDollar, faGears, faPhone, faQuoteLeft, faRocket, faTools, faVectorSquare, faWrench } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Carousel, CarouselCaption, Table } from 'react-bootstrap';
import First from './First';
import Foooter from './Footer';
import Fourth from './fourth';
import Fifth from './fifth';
import Typewriter from "typewriter-effect";
import Service1 from './Service1';
import Conventional from './Conventional';
import PageWithCarousel from './PageWithCarousel';
function Service() {
    const carouselItems = [
        {
          imageSrc: 'assets/ameee/images4.jpeg',
          caption: 'First slide caption',
          heading:' NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images6.jpeg',
          caption: 'Second slide caption',
          heading:'Conventional NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images5.jpeg',
          caption: 'third slide caption',
          heading:' Advance NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images1.jpeg',
          caption: 'fourth slide caption',
          heading:' Coating Inspection'
        },
        {
          imageSrc: 'assets/ameee/images2.jpeg',
          caption: 'fifth slide caption',
          heading:' Welding Inspection'
        },
        {
          imageSrc: 'assets/ameee/images3.jpeg',
          caption: 'Sixth slide caption',
          heading:' Rope Access'
        },
        {
          imageSrc: 'assets/ameee/images6.jpeg',
          caption: 'Seventh slide caption',
          heading:' NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images4.jpeg',
          caption: 'eighth slide caption',
          heading:' NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images5.jpeg',
          caption: 'ninth slide caption',
          heading:' NDT Services & Inspection'
        },
        {
          imageSrc: 'assets/ameee/images2.jpeg',
          caption: 'tenth slide caption',
          heading:' NDT Services & Inspection'
        },
      ];
    
     
    return (
        <div>
            <div className='bg-black'>
                <First />
                <PageWithCarousel carouselItems={carouselItems} />

            </div>
            {/* <div className='row p-5 bg-blue-100'>
                <div className='col-lg-6 p-5 '>
                    <img src='assets/ameee/hardness.jpg' title='Hardness Testing' className=' border-b-8 border-black border-l-8 ' style={{ width: '500px', height: '500px' }} />
                    <p className='p-2 text-blue-500 text-left text-3xl font-bold '>Hardness Testing</p>
                    <div className='mt-96'>
                        <img src='assets/ameee/hardness1.jpeg' title='Hardness Testing' className=' border-8 rounded-full border-blue-500  ' style={{ width: '500px', height: '500px' }} />
                    </div>
                    <div className='mt-96'>
                        <img src='assets/ameee/hardness2.jpeg' title='Hardness Testing' className=' border-8 rounded-2xl border-black' style={{ width: '500px', height: '500px' }} />
                    </div>
                    <div className='p-5'>
                        <dl className=' text-justify'>
                            <dt className='text-blue-700 text-xl'>Cases and Findings: </dt>
                            <dl> Hardness testing reveals valuable insights such as:</dl>
                            <ul className='list-disc'>
                                <li>Identifying surface hardening treatments in metal components.</li>
                                <li> Assessing the integrity of welds by measuring hardness near heat-affected zones.</li>
                                <li> Monitoring wear and degradation in machinery components to prevent failures.</li>
                                <li> Evaluating material properties for product development and optimization.</li>
                                <dd>In summary, hardness testing and portable hardness testing methods provide essential
                                    insights into material properties, ensuring quality, performance, and safety across a wide
                                    range of industries and applications.</dd>
                            </ul>
                        </dl>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='text-left'>
                        <h4 className='text-blue-700 text-3xl'>HARDNESS TESTING </h4>
                        <h4>&nbsp;</h4>
                        <dl>
                            <dt className='text-blue-700 text-xl'>Principle:</dt>
                            <dd>Hardness testing measures a material's resistance to deformation, typically by applying a known
                                force or indentation to the surface. The depth or size of the indentation indicates the material's
                                hardness. Portable hardness testing methods utilize handheld devices to assess hardness quickly
                                and conveniently in various environments.</dd>
                            <dt className='text-blue-700 text-xl'>History:</dt>
                            <dd>Hardness testing has a long history, dating back to the early 19th century. It became more
                                standardized with the development of techniques such as the Brinell, Rockwell, and Vickers
                                hardness tests. Portable hardness testing methods emerged later to meet the need for on-site
                                assessments in industries like manufacturing, construction, and maintenance.
                                Hardness testing involves assessing a material's resistance to indentation or penetration, typically
                                by applying a known force to the surface of the material and measuring the resulting indentation.
                                There are several methods of hardness testing, each with its own procedure:
                            </dd>
                            <div className="overflow-x-auto bg-blue-300">
                                <table className="table-auto w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                            <th className="px-4 py-2 bg-blue-500 border">Testing Techniques</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">01</td>
                                            <td className="px-4 py-2 border">Brinell Hardness Test</td>

                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">02</td>
                                            <td className="px-4 py-2 border">Rockwell Hardness Test</td>

                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">03</td>
                                            <td className="px-4 py-2 border">Vickers Hardness Test</td>

                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">04</td>
                                            <td className="px-4 py-2 border">Portable Hardness Test</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <dt className='text-blue-700 text-xl'>Brinell Hardness Test:</dt>
                            <dt className='text-blue-700 text-xl'>Procedure:</dt>
                            <ul className='list-disc'>
                                <li>A hardened steel ball or tungsten carbide ball is pressed into the surface of the material with a
                                    specified load.</li>
                                <li>The diameter of the resulting indentation is measured using a microscope.</li>
                                <li>The Brinell hardness number (BHN) is calculated using the formula: BHN = (applied load) /
                                    (surface area of indentation) .</li>
                                <dt className='text-blue-700 text-xl'>Application:</dt>
                                <dd>Used for testing relatively soft materials or materials with coarse
                                    microstructures, such as non-ferrous metals, castings, and polymers.</dd>
                            </ul>
                            <dt className='text-blue-700 text-xl'>Rockwell Hardness Test:</dt>
                            <dt className='text-blue-700 text-xl'>Procedure:</dt>
                            <ul className='list-disc'>
                                <li>A conical or spherical indenter is pressed into the material surface with a specified initial load
                                    (minor load).</li>
                                <li> A heavier load (major load) is then applied, and the depth of indentation is measured.</li>
                                <li> The Rockwell hardness number (e.g., HRC, HRB) is determined based on the depth of
                                    indentation and the hardness scale.</li>
                            </ul>
                            <dt className='text-blue-700 text-xl'>Application:</dt>
                            <dd>Widely used for testing metals, including steel, alloys, and heat-treated
                                materials.</dd>
                            <dt className='text-blue-700 text-xl'>Vickers Hardness Test:</dt>
                            <dt className='text-blue-700 text-xl'>Procedure:</dt>
                            <ul className='list-disc'>
                                <li>A pyramidal diamond or other geometrically shaped indenter is pressed into the material
                                    surface with a specified load.</li>
                                <li>The diagonal lengths of the resulting indentation are measured using a microscope.</li>
                                <li>The Vickers hardness number (HV) is calculated using the formula:
                                    HV = (applied load) / (surface area of indentation).</li>
                            </ul>
                            <dt className='text-blue-700 text-xl'>Application:</dt>
                            <dd> Suitable for testing a wide range of materials, including metals, ceramics, and
                                thin sections.</dd>
                            <dt className='text-blue-700 text-xl'>Portable Hardness Testing Methods:</dt>
                            <dt className='text-blue-700 text-xl'>Procedure:</dt>
                            <ul>
                                <li>Portable devices, such as rebound hardness testers (e.g., Leeb hardness tester), measure the
                                    hardness of a material by rebounding an impact body off the surface.</li>
                                <li> The device calculates the hardness value based on the rebound velocity or energy, which
                                    correlates with the material's hardness.</li>
                                <li> Measurements are typically displayed on a digital screen.</li>
                            </ul>
                            <dt className='text-blue-700 text-xl'>Application:</dt>
                            <ul className='list-disc'>
                                <li>Used for on-site testing in industries like construction, manufacturing, and
                                    maintenance where mobility and accessibility are essential.</li>
                                Regardless of the method used, hardness testing typically follows these general steps:
                                <li>Prepare the surface of the material by cleaning and ensuring it is flat and free from
                                    contaminants.</li>
                                <li>Apply the specified load or impact force to the material surface using the appropriate
                                    indenter.</li>
                                <li> Measure the resulting indentation or rebound characteristics.</li>
                                <li> Calculate the hardness value using the corresponding formula or calibration standards.</li>
                                <li> Interpret the results based on the material type, testing standard, and application
                                    requirements.</li>
                            </ul>
                            <div className="overflow-x-auto bg-blue-100">
                                <table className="table-auto w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                            <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                            <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">01</td>
                                            <td className="px-4 py-2 border"><strong>Quantifiable results:</strong> Provides numerical values indicating material hardness.</td>
                                            <td className="px-4 py-2 border"><strong>Surface Preparation:</strong>Requires a clean and flat surface for accurate measurements.</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">02</td>
                                            <td className="px-4 py-2 border"><strong>Versatility:</strong>Suitable for a wide range of materials, including metals, plastics, and composites. </td>
                                            <td className="px-4 py-2 border"><strong>Limited to Surface Testing:</strong>Only measures surface hardness, not bulk or internal properties.</td>


                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">03</td>
                                            <td className="px-4 py-2 border"><strong>Non-destructive:</strong> Does not damage the material being tested.</td>
                                            <td className="px-4 py-2 border"><strong>Equipment calibration:</strong>Requires regular calibration to maintain accuracy</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">04</td>
                                            <td className="px-4 py-2 border"><strong>Portability:</strong>Portable methods offer flexibility for on-site testing. </td>
                                            <td className="px-4 py-2 border"><strong>Variability:</strong>Results may vary between different hardness testing methods.</td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            <dt className='text-blue-700 text-xl'>Purpose of Testing:</dt>
                            <dd>Hardness testing assesses a material's ability to resist deformation,
                                which is crucial for determining its suitability for various applications, including structural
                                integrity, wear resistance, and material selection.</dd>
                            <dt className='text-blue-700 text-xl'>Benefits for Client:</dt>
                            <dd>Clients benefit from hardness testing through:</dd>
                            <dt className='text-blue-700 text-xl'>Quality Assurance:</dt>
                            <dd>Ensures materials meet specified hardness requirements.</dd>
                            <dt className='text-blue-700 text-xl'> Performance evaluation:</dt>
                            <dl>Determines the suitability of materials for specific applications.</dl>
                            <dt className='text-blue-700 text-xl'>  Maintenance planning:</dt>
                            <dl>Identifies wear or degradation in components for timely
                                replacement or repair.</dl>
                            <dt className='text-blue-700 text-xl'>  Cost savings:</dt>
                            <dl>Helps prevent failures and optimize material usage.</dl>
                            <dt className='text-blue-700 text-xl'>Industries Using This Testing:</dt>
                            <dd>Hardness testing finds applications across numerous
                                industries, including:</dd>
                            <ul className='list-disc'>
                                <li>Manufacturing (metal fabrication, automotive, aerospace).</li>
                                <li>Construction (building materials, structural steel).</li>
                                <li> Engineering (machinery, tools).</li>
                                <li> Petrochemical and mining.</li>
                                <li> Maintenance and repair services.</li>
                                <li> Quality control and material testing laboratories.</li>
                            </ul>

                        </dl>
                    </div>
                </div>
            </div><hr />
            <div className='row p-5 bg-neutral-100'>
                <div className='col-lg-6'>
                    <div className='text-left'>
                        <h4 className='text-blue-700 text-3xl'>VISUAL TESTING (VT)</h4>
                        <h4>&nbsp;</h4>
                        <dl>
                            <dt className='text-blue-700 text-xl'>Principle:</dt>
                            <dd>Visual Testing (VT) is a non-destructive testing method that relies on direct visual
                                observation to evaluate the surface integrity of a component or structure. It involves the
                                careful examination of surfaces for irregularities, defects, or discontinuities that could
                                compromise their performance or safety.</dd>
                            <dt className='text-blue-700 text-xl'>History:</dt>
                            <dd>Visual inspection has been a part of human practices for centuries, evolving alongside
                                industrialization. From simple visual checks to structured inspection procedures, VT has
                                become an integral part of quality control across various industries.
                            </dd>
                            <div className="overflow-x-auto bg-blue-100">
                                <table className="table-auto w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                            <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                            <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">01</td>
                                            <td className="px-4 py-2 border"><strong>Cost-Effective:</strong> Requires minimal equipment and training.</td>
                                            <td className="px-4 py-2 border"><strong>Subjectivity:</strong> Interpretation may vary among inspectors.</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">02</td>
                                            <td className="px-4 py-2 border"><strong>Versatility:</strong>Applicable to a wide range of materials and surfaces. </td>
                                            <td className="px-4 py-2 border"><strong>Limited to Surface Defects:</strong>Cannot detect subsurface flaws.</td>


                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">03</td>
                                            <td className="px-4 py-2 border"><strong>Real-Time Assessment:</strong>Provides immediate results for quick decision-making. </td>
                                            <td className="px-4 py-2 border"><strong>Environmental limitations:</strong>Poor lighting or visibility can hinder accuracy.</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">04</td>
                                            <td className="px-4 py-2 border"><strong>Simplicity:</strong>Easy to implement and understand, facilitating quick inspections. </td>
                                            <td className="px-4 py-2 border"><strong>Labour-Intensive:</strong>Relies heavily on human labour, which can be time-consuming.</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <dt className='text-blue-700 text-xl'>
                                Purpose of Testing:
                            </dt>
                            <dd>
                                Visual Testing aims to identify surface irregularities, defects, or discontinuities that could
                                compromise the integrity, safety, or functionality of a component or structure.</dd>

                            <dt className='text-blue-700 text-xl'>Benefits for Client:</dt>
                            <dd>Clients benefit from Visual Testing through:</dd>
                            <dd> Improved product quality and reliability.</dd>
                            <dd> Enhanced safety by identifying potential hazards.</dd>
                            <dd> Cost savings by preventing costly failures and downtime.</dd>
                            <dd> Compliance with industry standards and regulations.</dd>
                            <dt className='text-blue-700 text-xl'>Industries Using This Testing:</dt>
                            <dd>Visual Testing finds applications across various industries, including:</dd>
                            <ul className=' list-disc'>
                                <li>Manufacturing (e.g., automotive, aerospace, electronics).</li>
                                <li>Construction and infrastructure.</li>
                                <li>Oil and gas.</li>
                                <li>Power generation.</li>
                                <li> Transportation (e.g., railways, maritime)</li>
                            </ul>
                            <dt className='text-blue-700 text-xl'>Cases and Findings:</dt>
                            <dd>Visual Testing helps identify a wide range of defects, including:</dd>
                            <ul className=' list-disc'>
                                <li>Surface cracks or fractures.</li>
                                <li> Corrosion and erosion.</li>
                                <li> Weld defects (e.g., porosity, undercut, incomplete fusion).</li>
                                <li>Surface finish irregularities.</li>
                                <li> Material contamination or foreign objects.</li>
                            </ul>
                        </dl>

                        <dd>Visual Testing is a fundamental yet powerful NDT method that offers simplicity, versatility,
                            and cost-effectiveness in assessing the integrity of materials and structures, thereby
                            ensuring safety, quality, and reliability across diverse industries.</dd>
                    </div>
                </div>
                <div className='col-lg-6'>

                    <img src='assets/ameee/illustrative image-vt.png' title='Visual Testing' style={{ width: '500px', height: '500px', marginTop: '550px', position: 'absolute', border: '3px double blue', borderRadius: '5px', boxShadow: '5px 5px black' }} />


                </div>
            </div><hr />
            <div className='row p-5 bg-blue-100'>
                <div className='col-lg-6'>
                    <img src='assets/ameee/storage.png' style={{ width: '500px', height: '500px', marginTop: '250px', position: 'absolute', boxShadow: '5px 5px 5px 5px black' }} />
                </div>
                <div className='col-lg-6'>
                    <div className='text-left'>
                        <h4 className='text-blue-700 text-3xl'>STORAGE TANK TESTING AND INSPECTION</h4>
                        <h4>&nbsp;</h4>
                        <h4 className='text-black text-2xl'>Storage Tank Testing & Inspection Long Range Ultrasonic Testing for Storage Tank LRUT</h4>
                        <dl>
                            <dt className='text-blue-700 text-xl'>Storage Tank Testing & Inspection:</dt>
                            <dd>Storage tank inspection is a crucial process in the oil and gas industry. It involves assessing the safety,
                                reliability, and structural integrity of storage tanks to prevent risks such as damage, corrosion, and
                                cracks. Let’s delve into the details:</dd>
                            <dt className='text-blue-700 text-xl'>Principle:</dt>
                            <dd>LRUT utilizes low-frequency ultrasonic waves to inspect the entire wall thickness of storage tank
                                components from a single access point, enabling detection of corrosion, erosion, and other defects in
                                hard-to-reach areas.</dd>
                            <dt className='text-blue-700 text-xl'>History:</dt>
                            <dd>Originally developed for pipeline inspection, LRUT has evolved to become a crucial method for
                                assessing the condition of storage tanks, offering a non-intrusive solution for comprehensive
                                inspection.</dd>
                            <dt className='text-blue-700 text-xl'>Standards Code:</dt>
                            <dd>LRUT procedures adhere to standards such as API 653 ensuring compliance and reliability.</dd>
                            <dt className='text-blue-700 text-xl'> Equipment:</dt>
                            <dd>LRUT systems typically include a transmitter, receiver, and software for data analysis. Advanced
                                instruments offer enhanced capabilities for accurate defect detection and sizing.</dd>
                            <div className="overflow-x-auto bg-blue-200">
                                <table className="table-auto w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                            <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                            <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">01</td>
                                            <td className="px-4 py-2 border"> Provides 360-degree coverage without the need for tank entry or extensive scaffolding.</td>
                                            <td className="px-4 py-2 border">Requires skilled technicians for proper setup, calibration, and data interpretation.</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">02</td>
                                            <td className="px-4 py-2 border">Detects defects in inaccessible areas, such as behind insulation or within coatings.</td>
                                            <td className="px-4 py-2 border">Limited effectiveness in highly attenuative materials or complex geometries.</td>


                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                            <dt className='text-blue-700 text-xl'>Purpose of Testing:</dt>
                            <dd>LRUT aims to assess the structural integrity of storage tanks, identify defects, and prioritize
                                maintenance or repair activities to prevent failures and ensure operational safety.</dd>
                            <dt className='text-blue-700 text-xl'>Benefits:</dt>
                            <ul className='list-disc'>
                                <li>Minimizes downtime by enabling proactive maintenance planning.</li>
                                <li>Enhances safety by identifying potential hazards early.</li>
                                <li>Optimizes asset management and prolongs the lifespan of storage tank infrastructure.</li>
                            </ul>
                            <dt className='text-blue-700 text-xl'>
                                Type of Industries Using LRUT :
                            </dt>
                            <dl>LRUT finds application in various industries relying on storage tanks, including oil and gas,
                                petrochemicals, chemical processing, and water treatment facilities.</dl>
                            <dt className='text-blue-700 text-xl'>
                                Challenges and Findings:
                            </dt>
                            <dl>Challenges may include accurately interpreting complex data and distinguishing between benign
                                anomalies and critical defects. However, LRUT provides invaluable insights into the condition of
                                storage tank assets, facilitating informed decision-making and risk management.</dl>
                        </dl>
                    </div>
                </div>
            </div><hr />
            <div className='row p-5 bg-neutral-100'>
                <div className='col-lg-6'>
                    <img src='assets/ameee/mrut.png' style={{ width: '500px', height: '500px', marginTop: '450px', border: '3px solid black', borderRadius: '5px' }} />

                </div>
                <div className='col-lg-6'>
                    <div className='text-left'>
                        <h4 className='text-blue-700 text-3xl'>STORAGE TANK TESTING AND INSPECTION</h4>
                        <h4>&nbsp;</h4>
                        <h4 className='text-black text-2xl'>Storage Tank Testing & Inspection MRUT</h4>
                        <dl>
                            <dt className='text-blue-700 text-xl'>Storage Tank Testing & Inspection:</dt>
                            <dl>Storage tank inspection is a crucial process in the oil and gas industry. It involves assessing the
                                safety, reliability, and structural integrity of storage tanks to prevent risks such as damage,
                                corrosion, and cracks. Let’s delve into the details:</dl>
                            <dt className='text-blue-700 text-xl'>
                                Principle:
                            </dt>
                            <dd>MRUT (Magnetic Resonance Ultrasonic Testing) combines the power of magnetic resonance
                                imaging (MRI) and ultrasonic testing to assess the structural integrity of storage tanks. It relies
                                on the principles of electromagnetic induction and ultrasonic wave propagation to detect defects
                                and corrosion without the need for tank downtime.</dd>
                            <dt className='text-blue-700 text-xl'>History:</dt>
                            <dd>Originally developed for medical imaging, MRI technology was adapted for industrial use in the
                                late 20th century. MRUT emerged as a solution to the limitations of traditional tank inspection
                                methods, offering comprehensive assessments without disrupting operations.</dd>
                            <dt className='text-blue-700 text-xl'>Standard Codes:</dt>
                            <dd>MRUT procedures adhere to industry standards such as API 653 (American Petroleum Institute)
                                and ASME (American Society of Mechanical Engineers) codes, ensuring quality and reliability in
                                inspections.</dd>
                            <dt className='text-blue-700 text-xl'>Equipment:</dt>
                            <dd>MRUT equipment typically includes specialized probes, sensors, and data acquisition systems.
                                These tools are designed to penetrate tank walls, collect precise data, and generate detailed
                                reports for analysis.</dd>
                            <div className="overflow-x-auto bg-blue-100">
                                <table className="table-auto w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                            <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                            <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">01</td>
                                            <td className="px-4 py-2 border"><strong>Non-Intrusive:</strong>MRUT inspections can be performed without emptying or dismantling tanks,
                                                minimizing downtime and operational disruptions .</td>
                                            <td className="px-4 py-2 border"><strong>Specialized Training:</strong>Proper interpretation of MRUT results requires trained technicians with
                                                expertise in both MRI and ultrasonic testing.</td>
                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">02</td>
                                            <td className="px-4 py-2 border"><strong>Comprehensive:</strong>Provides detailed insights into the condition of tank walls, detecting corrosion,
                                                defects, and structural weaknesses. </td>
                                            <td className="px-4 py-2 border"><strong>Initial Investment:</strong>Acquiring MRUT equipment and training personnel can involve significant
                                                upfront costs.</td>

                                        </tr>
                                        <tr className="hover:bg-gray-300">
                                            <td className="px-2 py-1 border">03</td>
                                            <td className="px-4 py-2 border"><strong>Cost-Effective:</strong> Reduces the need for manual inspections and repairs, leading to long-term cost
                                                savings.</td>
                                            <td className="px-4 py-2 border"><strong>Limited Accessibility:</strong> MRUT may not be suitable for all types of storage tanks or environments,
                                                depending on factors such as size and material.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <dt className='text-blue-700 text-xl'>Purpose of Testing:</dt>
                            <dd>The primary goal of MRUT testing is to ensure the integrity and reliability of storage tanks by
                                detecting defects, corrosion, and structural weaknesses. By identifying issues early, MRUT helps
                                prevent leaks, spills, and environmental contamination, ensuring regulatory compliance and
                                minimizing risks to personnel and assets.</dd>
                            <dt className='text-blue-700 text-xl'>Benefits:</dt>
                            <dt className='text-blue-700 text-xl'>Enhanced Safety:</dt>
                            <dd>Reduced risk of tank failures and associated hazards, protecting personnel,
                                property, and the environment.</dd>
                            <dt className='text-blue-700 text-xl'>Operational Efficiency:</dt>
                            <dd> Minimized downtime and disruptions, allowing for continuous
                                production and optimized maintenance schedules.</dd>
                            <dt className='text-blue-700 text-xl'>Cost Savings:</dt>
                            <dd> Avoidance of costly repairs, insurance claims, and regulatory fines through
                                proactive maintenance and risk management.</dd>
                            <dt className='text-blue-700 text-xl'>Regulatory Compliance: </dt>
                            <dd>Demonstrated commitment to regulatory standards and environmental
                                stewardship, ensuring legal compliance and public trust.</dd>
                            <dt className='text-blue-700 text-xl'>Types of Industries using MRUT:</dt>
                            <ul className=' list-disc'>
                                <li> Oil and Gas</li>
                                <li>Chemical Processing</li>
                                <li>Water and Wastewater Management</li>
                                <li>Power Generation</li>
                                <li>Manufacturing</li>
                            </ul>
                            <dt className='text-blue-700 text-xl' >Challenges and Findings:</dt>
                            <dd>MRUT has been instrumental in identifying critical defects and preventing potential disasters
                                in storage tanks worldwide. Recent case studies highlight its effectiveness in detecting
                                hidden corrosion, leaks, and structural weaknesses, allowing for timely repairs and
                                maintenance interventions. By leveraging MRUT, industries can safeguard their assets,
                                protect the environment, and ensure long-term operational success.</dd>
                        </dl>
                    </div>

                </div><hr />
                <div className='row p-5 bg-blue-100'>

                    <div className='col-lg-6'>
                        <div className='text-left'>
                            <h4 className='text-blue-700 text-3xl'>STORAGE TANK TESTING & INSPECTION </h4>
                            <h4 className='text-black text-2xl'>Storage Tank Testing & Inspection PAUT</h4>
                            <dl>
                                <dt className='text-blue-700 text-xl'>Storage Tank Testing & Inspection:</dt>
                                <dd>Phased Array Ultrasonic Testing (PAUT) employs advanced ultrasonic technology to inspect
                                    storage tanks for defects and anomalies by emitting high-frequency sound waves and
                                    analyzing their reflections.</dd>
                                <dt className='text-blue-700 text-xl'>Principles:</dt>
                                <dd>PAUT revolutionized non-destructive testing in the late 20th century, offering superior defect
                                    detection capabilities over conventional methods.</dd>
                                <dt className='text-blue-700 text-xl'>History:</dt>
                                <dd>PAUT revolutionized non-destructive testing in the late 20th century, offering superior defect
                                    detection capabilities over conventional methods.</dd>
                                <dt className='text-blue-700 text-xl'>Standards Code:</dt>
                                <dl>Adheres to industry standards such as API 650 and API 653, ensuring compliance with
                                    regulatory requirements.</dl>
                                <dt className='text-blue-700 text-xl'>Equipment:</dt>
                                <dd>Utilizes state-of-the-art PAUT equipment, including phased array probes, scanners, and
                                    imaging software, for precise inspection and analysis.</dd>
                                <div className="overflow-x-auto bg-blue-200">
                                    <table className="table-auto w-full border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                                <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                                <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="hover:bg-gray-300">
                                                <td className="px-2 py-1 border">01</td>
                                                <td className="px-4 py-2 border">Enhanced defect detection capabilities. </td>
                                                <td className="px-4 py-2 border">Initial setup costs may be higher than conventional methods.</td>
                                            </tr>
                                            <tr className="hover:bg-gray-300">
                                                <td className="px-2 py-1 border">02</td>
                                                <td className="px-4 py-2 border">Faster inspection times compared to traditional methods.</td>
                                                <td className="px-4 py-2 border">Requires skilled technicians for accurate interpretation of results.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <dt className='text-blue-700 text-xl'>Purpose of Testing:</dt>
                                <dd>
                                    To ensure the structural integrity and safety of storage tanks by detecting defects such as
                                    corrosion, cracks, and weld imperfections.</dd>
                                <dt className='text-blue-700 text-xl'>Benefits:</dt>
                                <ul className=' list-disc'>
                                    <li>Minimized risk of catastrophic failures.</li>
                                    <li>Extended asset lifespan.</li>
                                    <li>Compliance with regulatory requirements.</li>
                                    <li>Reduced downtime and maintenance costs.</li>
                                </ul>

                                <dt className='text-blue-700 text-xl'>
                                    Type Of Industries Using PAUT:
                                </dt>
                                <ul className='list-disc'>
                                    <li>Petrochemical</li>
                                    <li>Oil and Gas </li>
                                    <li> Water and Wastewater</li>
                                    <li> Pharmaceutical</li>
                                    <li>Chemical </li>
                                </ul>
                                <dt className='text-blue-700 text-xl'>Cases and Findings:</dt>
                                <dd>PAUT has successfully detected various defects in storage tanks, including corrosion under
                                    insulation, weld flaws, and material degradation, preventing costly failures and ensuring
                                    uninterrupted operations.</dd>
                            </dl>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <img src='assets/ameee/paut.png' style={{ width: '500px', height: '500px', boxShadow: '5px 5px 5px 5px black' }} />
                        <img src='assets/ameee/paut1.png' style={{ width: '500px', height: '500px', marginTop: '75px', boxShadow: '5px 5px black' }} />
                    </div>
                </div><hr />

                <div className='row p-5'>
                    <div className='col-lg-6'>
                        <img src='assets/ameee/srut.png' style={{ width: '500px', height: '500px', marginTop: '375px', boxShadow: '5px 5px black' }} />

                    </div>
                    <div className='col-lg-6'>
                        <div className='text-left'>
                            <h4 className='text-blue-700 text-3xl'>STORAGE TANK TESTING AND INSPECTION</h4>
                            <h4>&nbsp;</h4>
                            <h4 className='text-black text-2xl'>Storage Tank Testing & Inspection SRUT</h4>
                            <dl>
                                <dt className='text-blue-700 text-xl'>Storage Tank Testing & Inspection:</dt>
                                <dl>Storage tank inspection is a crucial process in the oil and gas industry. It involves assessing the
                                    safety, reliability, and structural integrity of storage tanks to prevent risks such as damage,
                                    corrosion, and cracks. Let’s delve into the details:</dl>
                                <dt className='text-blue-700 text-xl'>
                                    Principle:
                                </dt>
                                <dd>The application of SRUT methods adheres to industry standards such ASME 5 API 653 ensuring
                                    compliance with safety regulations.</dd>
                                <dt className='text-blue-700 text-xl'>History:</dt>
                                <dd>SRUT methods emerged in response to the need for non-destructive testing (NDT)
                                    techniques to ensure the safety and reliability of storage tanks. Developed as an
                                    advancement in ultrasonic testing, SRUT methods have become a standard practice in tank
                                    inspection.</dd>
                                <dt className='text-blue-700 text-xl'>Standard Codes:</dt>
                                <dd>The application of SRUT methods adheres to industry standards such asASME 5 API 653
                                    ensuring compliance with safety regulations.</dd>
                                <dt className='text-blue-700 text-xl'>Equipment:</dt>
                                <dd>SRUT testing requires specialized equipment including ultrasonic transducers, couplants,
                                    and data acquisition systems. Advanced ultrasonic flaw detectors and scanners are used for
                                    precise detection and assessment.</dd>
                                <div className="overflow-x-auto bg-blue-100">
                                    <table className="table-auto w-full border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                                <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                                <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="hover:bg-gray-300">
                                                <td className="px-2 py-1 border">01</td>
                                                <td className="px-4 py-2 border"><strong>High Sensitivity:</strong> Capable of detecting minute flaws and discontinuities.</td>
                                                <td className="px-4 py-2 border"><strong>Skill-dependent:</strong>Proper training is necessary for accurate interpretation of results. </td>
                                            </tr>
                                            <tr className="hover:bg-gray-300">
                                                <td className="px-2 py-1 border">02</td>
                                                <td className="px-4 py-2 border"> <strong>Non-destructive:</strong>SRUT methods allow thorough inspection without causing harm to the
                                                    tank structure. </td>
                                                <td className="px-4 py-2 border"><strong> Surface condition sensitivity:</strong>Surface preparation is crucial for reliable testing.

                                                </td>

                                            </tr>
                                            <tr className="hover:bg-gray-300">
                                                <td className="px-2 py-1 border">03</td>
                                                <td className="px-4 py-2 border"><strong>Real-time results:</strong>Immediate feedback facilitates prompt decision-making .</td>
                                                <td className="px-4 py-2 border"><strong>Limited Penetration:</strong> : SRUT methods may not detect defects deep within thick materials.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <dt className='text-blue-700 text-xl'>Purpose of Testing:</dt>
                                <dd>The primary objective of SRUT testing is to assess the structural integrity of storage tanks,
                                    identifying defects such as corrosion, cracking, and material degradation. This proactive approach
                                    helps prevent catastrophic failures, ensuring operational safety and regulatory compliance.</dd>
                                <dt className='text-blue-700 text-xl'>Benefits:</dt>
                                <dt className='text-blue-700 text-xl'>Enhanced Safety:</dt>
                                <dd>Early detection of defects mitigates the risk of tank failures, protecting
                                    personnel and assets.</dd>
                                <dt className='text-blue-700 text-xl'>Cost savings:</dt>
                                <dd> Preventive maintenance based on SRUT inspections minimizes repair
                                    expenses and potential downtime.</dd>
                                <dt className='text-blue-700 text-xl'>Regulatory compliance:</dt>
                                <dd>Meeting industry standards and regulations safeguards against legal
                                    liabilities and penalties.</dd>

                                <dt className='text-blue-700 text-xl'>Types of Industries using SRUT:</dt>
                                <ul className=' list-disc'>
                                    <li> Oil and Gas</li>
                                    <li>Chemical Manufacturing</li>
                                    <li>Aerospace</li>
                                </ul>
                                <dt className='text-blue-700 text-xl'>Challenges and Findings:</dt>
                                <dd>SRUT methods can pinpoint corrosion spots before they
                                    compromise tank integrity.</dd>
                            </dl>
                        </div>

                    </div><hr />
                    <div className='row p-5 bg-blue-100' >

                        <div className='col-lg-6'>
                            <div className='text-left'>
                                <h4 className='text-blue-700 text-3xl'>BOILER TUBE MACRO TESTING </h4>
                                <h4>&nbsp;</h4>
                                <dl>
                                    <dt className='text-blue-700 text-xl'> Boiler:</dt>
                                    <dd>Inside the boiler, there's a space where fuel (like gas, oil, coal, or wood) is burned. This burning
                                        produces heat. The heat from burning the fuel is transferred to the water or another fluid
                                        inside the boiler. It's like when you heat water on a stove. As the water gets hotter, it eventually
                                        reaches a point where it turns into steam. This steam is very hot and under pressure. The
                                        steam created inside the boiler is then released and can be used for different things. For
                                        example, it can power turbines to generate electricity, heat buildings, or help with industrial
                                        processes.</dd>
                                    <dt className='text-blue-700 text-xl'>Principles:</dt>
                                    <dd>Boiler tube macro testing is a non-destructive examination method used to assess the integrity
                                        and quality of boiler tubes. It involves visually inspecting the tubes for defects, irregularities,
                                        or damage that could compromise their functionality or safety.</dd>
                                    <dt className='text-blue-700 text-xl'>History:</dt>
                                    <dd>The practice of macro testing boiler tubes dates back several decades, evolving alongside
                                        advancements in non-destructive testing (NDT) techniques. Initially, it relied on manual visual
                                        inspections, but modern methods incorporate advanced equipment for more accurate and
                                        efficient assessments.</dd>
                                    <div className="overflow-x-auto bg-blue-200">
                                        <table className="table-auto w-full border-collapse">
                                            <thead>
                                                <tr>
                                                    <th className="px-2 py-2 bg-blue-500 border">S.No</th>
                                                    <th className="px-4 py-2 bg-blue-500 border">Advantages</th>
                                                    <th className="px-4 py-2 bg-blue-500 border">DisAdvantages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="hover:bg-gray-300">
                                                    <td className="px-2 py-1 border">01</td>
                                                    <td className="px-4 py-2 border">Identifies defects early, preventing potential failures and ensuring operational safety. </td>
                                                    <td className="px-4 py-2 border">Relies heavily on the inspector's experience and expertise.</td>
                                                </tr>
                                                <tr className="hover:bg-gray-300">
                                                    <td className="px-2 py-1 border">02</td>
                                                    <td className="px-4 py-2 border">Helps maintain boiler efficiency by detecting issues such as corrosion or erosion.</td>
                                                    <td className="px-4 py-2 border">Limited in detecting defects that may be located internally or on inaccessible surfaces.</td>
                                                </tr>
                                                <tr className="hover:bg-gray-300">
                                                    <td className="px-2 py-1 border">03</td>
                                                    <td className="px-4 py-2 border">Non-destructive nature allows for inspections without causing damage to the tubes.</td>
                                                    <td className="px-4 py-2 border">Requires downtime for inspection, which can impact production schedules.</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                    <dt className='text-blue-700 text-xl'>Working Principle:</dt>
                                    <dd>
                                        During boiler tube macro testing, inspectors visually examine the tubes for signs of damage or
                                        degradation. They look for indications such as cracks, pitting, corrosion, bulges, or any other
                                        irregularities that may compromise the tube's integrity. Inspections are typically conducted at
                                        regular intervals as part of preventive maintenance programs.</dd>
                                    <dt className='text-blue-700 text-xl'>Benefits:</dt>
                                    <ul className=' list-disc'>
                                        <li>Enhances overall boiler reliability and lifespan.</li>
                                        <li>Supports compliance with industry regulations and standards.</li>
                                        <li>Reduces the risk of unexpected breakdowns or accidents.</li>
                                    </ul>
                                    <dt className='text-blue-700 text-xl'>Purpose:</dt>
                                    <dd>The primary purpose of boiler tube macro testing is to ensure the structural integrity and
                                        operational efficiency of boiler tubes, thereby safeguarding personnel, equipment, and the
                                        environment from potential hazards associated with boiler failures.</dd>
                                    <dt className='text-blue-700 text-xl'> Reference Standards:</dt>
                                    <dd>ASME Boiler and Pressure Vessel Code (BPVC) Section V: Non-Destructive Examination</dd>
                                    <dt className='text-blue-700 text-xl'>Type Of Equipment Used:</dt>
                                    <ul className='list-disc'>
                                        <li>Visual inspection tools such as borescopes, mirrors, and cameras.</li>
                                        <li>Lighting equipment to illuminate the inspection area.</li>
                                        <li> Magnifying lenses for detailed examination.</li>
                                    </ul>
                                    <dt className='text-blue-700 text-xl'>
                                        Type Of Industries Used This Method:
                                    </dt>
                                    <ul className='list-disc'>
                                        <li>Thermal Power Plant</li>
                                        <li>Oil and Gas Industries</li>
                                        <li> Petrochemical Industries</li>
                                        <li> Aerospace Industries</li>
                                        <li>Chemical Process Industries</li>
                                    </ul>
                                    <dt className='text-blue-700 text-xl'>Cases and Findings:</dt>
                                    <ul className='list-disc'>
                                        <li>In one case, macro testing revealed significant corrosion in boiler tubes, prompting
                                            immediate repairs and preventing a potential boiler failure.</li>
                                        <li>Another instance involved the detection of fatigue cracks during inspection, leading to
                                            the replacement of affected tubes before they could cause a rupture.</li>
                                    </ul>
                                </dl>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img src='assets/ameee/tube.jpg' style={{ width: '500px', height: '500px', marginTop: '50px', position: 'absolute' }} />

                        </div>
                    </div> */}
            {/* <div style={{ position: 'relative' }}>
                <img src="a1.jpg" style={{ width: '100%', height: 'auto' }} />
                <h1 className='text-8xl' style={{ position: 'absolute', top: '40%', left: '40%', transform: 'translate(-50%, -50%)', color: 'white', padding: '10px' }}>
                    <Typewriter
                        loop
                        cursorClassName="typewriter-cursor"
                        onInit={(typewriter) => {
                            typewriter
                                .typeString("Our Services")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Conventional Services")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Advance Services")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Welder & Welding Services")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Rope Access")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Coating Inspection")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString("Our Services")
                                .pauseFor(1000)
                                .start();
                        }}
                    />
                </h1>
              
            </div> */}
            {/* <section className='p-5'>
        <div>
          <div className='text-center font-extrabold'>
            <h2 className=' font-extrabold p-3'>
              <span className='text-primary'>We</span> Are Working
            </h2>
            <div className="relative inline-block">
              <span className="bg-primary text-white px-3 py-2 ">Working Process</span>
              <span className="absolute top-full left-1/2 transform -translate-x-1/2">
                <div className="absolute  w-3 h-3 bg-primary rotate-45 -top-1/2 -left-1/2"></div>
              </span>
            </div>
            <p className='p-3'>Explore Exciting Opportunities:Join Us and Make a Difference!</p>
          </div>
        </div>
        <div className='row p-5'>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/pt.jpeg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>01</div>
            </div>
            <div className='process-content'>
              <h4>Conventional NDT</h4>
              <p className=' font-normal'>Experience the Precision:Elevate your quality standards with our Conventional NDT services!</p>
            </div>
          </div>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/Advance NDT.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count  bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>02</div>
            </div>
            <div className='process-content'>
              <h4>Advance NDT</h4>
              <p className=' font-normal'>Breaking Boundaries:Unleash the power of Advance NDT solutions with us!</p>
            </div>
          </div>
          
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/welding.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>03</div>
            </div>
            <div className='process-content'>
              <h4>Welding Inspection</h4>
              <p className=' font-normal'>Ensure Excellence:Trust our expertise for comprehensive Welding Inspection solutions!</p>
            </div>
          </div>
          <div className=' col-lg-3 col-md-6 col-sm-6'>
            <div className='process' style={{ position: 'relative', display: 'inline-block' }}>
              <img src='assets/ameee/rope.jpg' alt='process image' style={{ width: '230px', height: '230px', borderRadius: '50%', border: '6px solid white', transition: 'border-color 0.3s' }} onMouseOver={(e) => e.target.style.borderColor = '#3d9bd1'} onMouseOut={(e) => e.target.style.borderColor = 'white'} />
              <div className='process-count bg-black text-white text-center' style={{ position: 'absolute', width: '40px', height: '40px', borderRadius: '100%', top: '15px', right: '-1px', border: '1px solid black' }}>04</div>
            </div>
            <div className='process-content'>
              <h4>Rope Access</h4>
              <p className=' font-normal'>Reach new heights safely:Experience superior Rope Access solutions with us!</p>
            </div>
          </div>
        </div>
        
      </section> */}
            <section>
                <div>
                    <Service1/>
                </div>
                <div>
                    <Conventional/>
                </div>
                <div>
                    <Fourth />
                </div>
                <Fifth />

            </section>
            <div>
                <Foooter />
            </div>
        </div >
        // </div>
        // </div>

    )
}

export default Service
