import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faGears, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Card, Button } from 'react-bootstrap'
import { LinearGradient } from 'react-text-gradients'
import FancyText from '@carefully-coded/react-text-gradient';
import '../Header.css'
import { Helmet } from 'react-helmet'
function Civil() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Civil NDT Services in Chennai |Civil NDT Services in Padappai |Civil NDT Services in Mumbai |Civil NDT Services in Haryana |Civil NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Civil NDT services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Civil NDT Services in Chennai ,Civil NDT Services in Mumbai ,Civil NDT Services in Haryana ,Civil NDT Services in India,Best Civil NDT services in Padappai,Best Civil NDT Services in Chennai,Best Civil NDT Services in Bangalore " />
                <meta name="keywords" content="Best Civil NDT services in Andhra Pradesh,Best Civil NDT services in Arunachal Pradesh,Best Civil NDT services in Assam,Best Civil NDT services in Bihar,Best Civil NDT services in Chhattisgarh,Best Civil NDT services in Goa,Best Civil NDT services in Gujarat,Best Civil NDT services in Haryana,
                    Best Civil NDT services in Himachal Pradesh,Best Civil NDT services in Jammu and kashmir,Best Civil NDT services in Jharkhand,Best Civil NDT services in Karnataka,Best Civil NDT services in Kerala,Best Civil NDT services in Madhya Pradesh,Best Civil NDT services in Maharashtra,Best Civil NDT services in Manipur,Best Civil NDT services in Meghalaya,
                  Best Civil NDT services in Mizoram,Best Civil NDT services in Nagaland,Best Civil NDT services in Odisha,Best Civil NDT services in Punjab,Best Civil NDT services in Rajasthan,Best Civil NDT services in Sikkim,Best Civil NDT services in TamilNadu,Best Civil NDT services in Telengana,Best Civil NDT services in Tripura,Best Civil NDT services in Uttar Pradesh,
                  Best Civil NDT services in Uttarakhand,Best Civil NDT services in West Bengal, Civil NDT services in Andhra Pradesh,Civil NDT services in Arunachal Pradesh,Civil NDT services in Assam, Civil NDT services in Bihar,Civil NDT services in Chhattisgarh,Civil NDT services in Goa, Civil NDT services in Gujarat,Civil NDT services in Haryana,
                  Civil NDT services in Himachal Pradesh,Civil NDT services in Jammu and kashmir,Civil NDT services in Jharkhand, Civil NDT services in Karnataka, Civil NDT services in Kerala,Civil NDT services in Madhya Pradesh,Civil NDT services in Maharashtra,Civil NDT services in Manipur, Civil NDT services in Meghalaya,
                  Civil NDT services in Mizoram,Best Civil NDT services in Nagaland,Civil NDT services in Odisha,Civil NDT services in Punjab,Civil NDT services in Rajasthan,Civil NDT services in Sikkim,Civil NDT services in TamilNadu,Civil NDT services in Telengana,Civil NDT services in Tripura,Civil NDT services in Uttar Pradesh,
                  Civil NDT services in Uttarakhand, Civil NDT services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div>
                <div className='container p-5'>
                    <h1 className='font-bold text-5xl'>
                        <FancyText
                            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                            animate
                            animateDuration={5000}
                        >
                            Civil NDT Services
                        </FancyText>
                    </h1>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>01</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='UltraPulseVelocity' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Ultra Pulse Velocity</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                UPV testing by AMEEE NDT assessing concrete integrity by measuring ultrasonic pulse velocity, revealing internal condition.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='UltraPulseVelocity' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='UltraPulseVelocity' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\upvc.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>02</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='ReboundHammer' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Rebound Hammer</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Rebound Hammer testing by AMEEE NDT assesses concrete strength  by measuring rebound, offering valuable insights into structure integrity.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='ReboundHammer' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='ReboundHammer' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\Rebound1.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>03</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='HalfCellPotential' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '>Half-Cell Potential</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Half-cell potential testing by AMEEE NDT assesses corrosion risk on concrete structures, ensuring structural integrity.
                                            </div><br />
                                            <div className='items-readmore text-left '>
                                                <a href='HalfCellPotential' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='HalfCellPotential' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\half-cell1.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6'>
                            <div className='services-box active shadow-2xl shadow-blue-300 '>
                                <div className='grid-text relative group'>
                                    <div className='services-content bg-transparent'>
                                        <span className='items-number text-5xl ml-48 text-neutral-400'>04</span>
                                        <div className='items-icon mr-48' style={{ position: 'absolute', top: '7%' }}>
                                            <FontAwesomeIcon icon={faGears} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <span className='text-center'>
                                            <h4 className='items-title text-left mt-4 '>
                                                <a href='ThicknessGauging' className='decoration-transparent text-blue-900 font-bold text-3xl group-hover:text-white '> Thickness Gauging</a>
                                            </h4>
                                            <div className='items-content text-left text-lg font-bold'>
                                                Thickness gauging by AMEEE NDT measures material thickness for corrosion assessment, structural integrity, or quality control.
                                            </div>
                                            <div className='items-readmore text-left '>
                                                <a href='ThicknessGauging' className=' group-hover:hidden'>
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} className='p-2 border border-blue-100 bg-blue-100 text-blue-900 text-3xl' style={{ borderRadius: '50%' }} />
                                                </a>
                                                <a href='ThicknessGauging' className={`inline-block ${isHovered ? 'group-hover:inline-block' : 'hidden group-hover:inline-block'}`}>
                                                    <Button style={{ left: '25%', bottom: '0', color: 'black', backgroundColor: 'white', borderRadius: '25px', position: 'absolute', fontWeight: 'bold' }}>Read More  <FontAwesomeIcon icon={faLongArrowAltRight} className='text-blue-900' /></Button>
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='image-container'>
                                    <img src='assets\ameee\ultrasonic.jpg' alt='skill' className='background-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Civil
