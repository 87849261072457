import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Valve2() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Heat Treatment Services in Valve Testing | Heat Treatment Services in Padappai | Heat Treatment Services in Mumbai | Heat Treatment Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Heat Treatment services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert heat treatment services!" />
                <meta name="title" content="Heat Treatment Services in Valve Testing, Heat Treatment Services in Mumbai, Heat Treatment Services in Haryana, Heat Treatment Services in India, Best Heat Treatment Services in Padappai, Best Heat Treatment Services in Chennai, Best Heat Treatment Services in Bangalore" />
                <meta name="keywords" content="Best Heat Treatment Services in Andhra Pradesh, Best Heat Treatment Services in Arunachal Pradesh, Best Heat Treatment Services in Assam, Best Heat Treatment Services in Bihar, Best Heat Treatment Services in Chhattisgarh, Best Heat Treatment Services in Goa, Best Heat Treatment Services in Gujarat, Best Heat Treatment Services in Haryana, Best Heat Treatment Services in Himachal Pradesh, Best Heat Treatment Services in Jammu and Kashmir, Best Heat Treatment Services in Jharkhand, Best Heat Treatment Services in Karnataka, Best Heat Treatment Services in Kerala, Best Heat Treatment Services in Madhya Pradesh, Best Heat Treatment Services in Maharashtra, Best Heat Treatment Services in Manipur, Best Heat Treatment Services in Meghalaya, Best Heat Treatment Services in Mizoram, Best Heat Treatment Services in Nagaland, Best Heat Treatment Services in Odisha, Best Heat Treatment Services in Punjab, Best Heat Treatment Services in Rajasthan, Best Heat Treatment Services in Sikkim, Best Heat Treatment Services in Tamil Nadu, Best Heat Treatment Services in Telangana, Best Heat Treatment Services in Tripura, Best Heat Treatment Services in Uttar Pradesh, Best Heat Treatment Services in Uttarakhand, Best Heat Treatment Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Heat Treatment Services in Valve Testing" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive Heat Treatment services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert heat treatment services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Heat Treatment Services in Valve Testing" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive Heat Treatment services in valve testing, Padappai, India. We offer reliable testing solutions for industries like valve manufacturing, petrochemicals, and more. Contact us today for expert heat treatment services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Heat Treatment- Valve Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Heat Treatment Services in Valve Testing</strong> are offered by AMEEE NDT, a leading provider of comprehensive testing solutions. Our specialized team conducts meticulous heat treatment processes in valve testing environments. Utilizing advanced technology and expertise, we ensure precise heat treatment of valve materials and components in various industrial settings. Our heat treatment services enable us to enhance the mechanical properties and durability of valves through controlled heating and cooling processes, ensuring compliance with industry standards and specifications.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Heat Treatment in Valve Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Heat treatment in valve testing serves the critical purpose of optimizing the mechanical properties and durability of valve materials and components through controlled heating and cooling processes. This treatment process involves applying specific temperature and time cycles to alter the microstructure of materials, enhancing their strength, toughness, and resistance to wear and corrosion. By conducting heat treatment, our services aim to improve the performance, reliability, and service life of valves used in industrial applications.</p>
                                    </div>



                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                    <div>
                                        <div className='' style={{ width: '330px' }}>
                                            <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                        </div>
                                    </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Heat Treatment in Valve Testing</h4>
                                            <p className='text-gray-500 text-lg'>Heat treatment in valve testing has a rich historical background within the valve manufacturing and industrial sectors. Over the years, as the need for enhanced mechanical properties and durability of valve components has grown, heat treatment techniques have evolved to ensure the quality, reliability, and safety of valves used in various applications. The historical progression of heat treatment methods has played a crucial role in optimizing the performance and longevity of valve systems.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Heat Treatment in Valve Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Heat treatment in valve testing employs controlled heating and cooling processes to optimize the mechanical properties and durability of valve components. Through precise temperature and time cycles, heat treatment alters the microstructure of valve materials, enhancing their strength, toughness, and resistance to wear and corrosion. By utilizing heat treatment in valve testing, manufacturers and industries can improve the performance, reliability, and service life of valves in diverse industrial applications.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Enhanced Mechanical Properties:</b> Heat treatment in valve testing improves the mechanical properties of valve components, including strength, toughness, and resistance to wear.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Extended Service Life:</b> By optimizing material properties, heat treatment extends the service life of valves, reducing the frequency of replacements and maintenance downtime.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Improved Performance:</b> Heat treatment enhances the performance of valves in demanding operating conditions, ensuring reliable operation and minimizing the risk of failures.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Complex Process:</b> Heat treatment requires precise control of temperature and time parameters, making it a complex process that demands skilled personnel and specialized equipment.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Material Distortion:</b> Improper heat treatment can cause material distortion or dimensional changes in valve components, affecting their fit, function, and performance.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface Treatment Compatibility:</b> Heat treatment may not be compatible with certain surface treatments or coatings applied to valve components, requiring additional considerations.
                                            </li>
                                        </ul>
                                    </div>






                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                                <div>
                                    <Card className='p-3' style={{ width: '330px', height: '570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                        <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                        <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                        <form>
                                            <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                            <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                        </form>
                                        <div className=''>
                                            <div className="darksoul-gradient-card3">
                                                <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Heat Treatment in Valve Testing Process - How We Work</h4>
                    <p className='font-semibold'>Heat treatment in valve testing at AMEEE NDT involves a systematic approach to optimize the mechanical properties and performance of valve components. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Initial Assessment: Conducting a thorough assessment of valve components to determine the suitability for heat treatment and identify any material flaws or defects.</li>
                        <li>Heat Treatment Planning: Developing a customized heat treatment plan based on the material type, valve configuration, and desired mechanical properties.</li>
                        <li>Heat Treatment Execution: Performing the heat treatment process according to the predefined parameters, including heating, soaking, and cooling stages, with precise control of temperature and time.</li>
                        <li>Quality Assurance: Implementing quality control measures throughout the heat treatment process to ensure uniform heating, proper transformation, and absence of material distortion.</li>
                        <li>Post-Treatment Inspection: Conducting thorough inspections and testing to verify the effectiveness of the heat treatment, including hardness testing, microstructural analysis, and dimensional checks.</li>
                        <li>Documentation and Reporting: Documenting all heat treatment procedures, observations, and test results in comprehensive reports for traceability and compliance purposes.</li>
                    </ul>
                </div>


            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Heat Treatment in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for heat treatment in valve testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Heat Treatment Equipment: Specialized furnaces, heat treatment ovens, and heating elements tailored for precise control of temperature and heating cycles during valve heat treatment.</li>
                        <li>Testing Accessories: Tools for sample handling, fixturing, and positioning, as well as surface preparation materials and protective coatings to ensure uniform heat treatment.</li>
                        <li>Consumables: Heat treatment alloys, quenching media, and temperature-indicating devices optimized for achieving desired mechanical properties and microstructural transformations in valve components.</li>
                        <li>Calibration Standards: Reference materials and calibration instruments calibrated for verifying the accuracy of temperature monitoring and control systems in heat treatment equipment.</li>
                        <li>Software Solutions: Advanced software for monitoring and controlling heat treatment processes, as well as for analyzing and documenting heat treatment parameters and results.</li>
                        <li>Personal Protective Equipment (PPE): Safety gear including heat-resistant gloves, face shields, and protective clothing to ensure the safety of personnel during heat treatment operations.</li>
                        <li>Training Resources: Educational materials, training modules, and workshops designed to enhance the skills and knowledge of personnel involved in heat treatment operations.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our comprehensive range of equipment and resources, we provide everything necessary to conduct efficient and effective heat treatment in valve testing.
                    </p>
                </div>




            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Heat Treatment in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for heat treatment in valve testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASTM A961 Standard Specification for Common Requirements for Steel Flanges, Forged Fittings, Valves, and Parts for Piping Applications</li>
                        <li>ISO 17663 Non-destructive Testing of Welds – General Rules for Fusion Welding of Metallic Materials</li>
                        <li>ASME B&PV Code, Section V: Nondestructive Examination</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the accuracy, reliability, and safety of our heat treatment procedures in valve testing, helping to optimize the mechanical properties and microstructure of valve components for various industrial applications.
                    </p>
                </div>




            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Heat Treatment in Valve Testing</h4>
                    <p className='font-semibold'>
                        During heat treatment in valve testing, AMEEE NDT evaluates various parameters to ensure the optimal mechanical properties and microstructure of valve components. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Temperature Control: Monitoring and controlling the heating and cooling processes to achieve specific temperature profiles required for heat treatment.</li>
                        <li>Heating Rate: Regulating the rate at which the temperature is raised to prevent thermal shock and ensure uniform heating throughout the valve components.</li>
                        <li>Soaking Time: Allowing sufficient time for the material to reach equilibrium at the desired temperature, ensuring complete transformation and phase stability.</li>
                        <li>Cooling Rate: Controlling the cooling rate to achieve the desired mechanical properties and microstructure, such as hardness and grain size, in the valve materials.</li>
                        <li>Heat Treatment Cycle Optimization: Optimizing the heat treatment cycle parameters to minimize distortion, residual stresses, and metallurgical defects in valve components.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our comprehensive evaluations ensure the effectiveness and integrity of heat treatment processes in valve testing, resulting in enhanced performance, durability, and reliability of valve materials in diverse industrial applications.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Heat Treatment in Valve Testing</h4>
                    <p className='font-semibold'>
                        During heat treatment processes conducted by AMEEE NDT as part of valve testing, we have the capability to identify various anomalies and deviations, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Insufficient Heating: Detecting areas within valve components that have not been adequately heated, which may result in incomplete transformation or inadequate material properties.</li>
                        <li>Overheating: Identifying regions subjected to excessive temperatures during heat treatment, leading to material degradation, distortion, or loss of mechanical properties.</li>
                        <li>Uneven Heating: Recognizing temperature variations across valve components, which can cause differential expansion, warping, or residual stress, compromising component integrity.</li>
                        <li>Improper Cooling: Detecting instances where cooling rates are not controlled or uniform, potentially leading to microstructural defects, such as quench cracks or distortion.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous heat treatment processes and adherence to stringent quality standards, we ensure precise control and monitoring of heating and cooling parameters, facilitating the desired material properties and performance characteristics in valve components for optimal reliability and functionality in industrial applications.
                    </p>
                </div>



            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Heat Treatment in Valve Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for heat treatment in valve testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASME B16.34 Valves - Flanged, Threaded, and Welding End: A standard for valve materials, dimensions, tolerances, and marking requirements, ensuring compliance with material specifications.</li>
                        <li>ASTM A961 Standard Specification for Common Requirements for Steel Flanges, Forged Fittings, Valves, and Parts for Piping Applications: Relevant for heat treatment requirements and material properties of valve components.</li>
                        <li>ISO 15156 Petroleum and Natural Gas Industries - Materials for Use in H2S-Containing Environments in Oil and Gas Production: Addresses material requirements and heat treatment considerations for valves used in corrosive environments.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our heat treatment processes in valve testing, ultimately contributing to the integrity and performance of critical valve components.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Valve2
