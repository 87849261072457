import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faAngleDoubleLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
// import SidebarComponent from './Sidebar'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Ship5() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Liquid Penetrant Testing Services in Ship Testing | Liquid Penetrant Testing Services in Padappai | Liquid Penetrant Testing Services in Mumbai | Liquid Penetrant Testing Services in Kanchipuram</title>
                <meta name="description" content="Explore AMEEE NDT's comprehensive Liquid Penetrant Testing services in ship testing, Padappai, India. We offer reliable testing solutions for industries like marine, shipbuilding, and more. Contact us today for expert liquid penetrant testing services!" />
                <meta name="title" content="Liquid Penetrant Testing Services in Ship Testing, Liquid Penetrant Testing Services in Mumbai, Liquid Penetrant Testing Services in Haryana, Liquid Penetrant Testing Services in India, Best Liquid Penetrant Testing Services in Padappai, Best Liquid Penetrant Testing Services in Chennai, Best Liquid Penetrant Testing Services in Bangalore" />
                <meta name="keywords" content="Best Liquid Penetrant Testing Services in Andhra Pradesh, Best Liquid Penetrant Testing Services in Arunachal Pradesh, Best Liquid Penetrant Testing Services in Assam, Best Liquid Penetrant Testing Services in Bihar, Best Liquid Penetrant Testing Services in Chhattisgarh, Best Liquid Penetrant Testing Services in Goa, Best Liquid Penetrant Testing Services in Gujarat, Best Liquid Penetrant Testing Services in Haryana, Best Liquid Penetrant Testing Services in Himachal Pradesh, Best Liquid Penetrant Testing Services in Jammu and Kashmir, Best Liquid Penetrant Testing Services in Jharkhand, Best Liquid Penetrant Testing Services in Karnataka, Best Liquid Penetrant Testing Services in Kerala, Best Liquid Penetrant Testing Services in Madhya Pradesh, Best Liquid Penetrant Testing Services in Maharashtra, Best Liquid Penetrant Testing Services in Manipur, Best Liquid Penetrant Testing Services in Meghalaya, Best Liquid Penetrant Testing Services in Mizoram, Best Liquid Penetrant Testing Services in Nagaland, Best Liquid Penetrant Testing Services in Odisha, Best Liquid Penetrant Testing Services in Punjab, Best Liquid Penetrant Testing Services in Rajasthan, Best Liquid Penetrant Testing Services in Sikkim, Best Liquid Penetrant Testing Services in Tamil Nadu, Best Liquid Penetrant Testing Services in Telangana, Best Liquid Penetrant Testing Services in Tripura, Best Liquid Penetrant Testing Services in Uttar Pradesh, Best Liquid Penetrant Testing Services in Uttarakhand, Best Liquid Penetrant Testing Services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Liquid Penetrant Testing Services in Ship Testing" />
                <meta property="og:description" content="Explore AMEEE NDT's comprehensive liquid penetrant testing services in ship testing, Padappai, India. We offer reliable testing solutions for industries like marine, shipbuilding, and more. Contact us today for expert liquid penetrant testing services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Liquid Penetrant Testing Services in Ship Testing" />
                <meta property="twitter:description" content="Explore AMEEE NDT's comprehensive liquid penetrant testing services in ship testing, Padappai, India. We offer reliable testing solutions for industries like marine, shipbuilding, and more. Contact us today for expert liquid penetrant testing services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Liquid Penetrant Testing- Ship Testing</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-lg text-justify'>
                                        <strong className='text-xl'>Liquid Penetrant Testing Services in Ship Testing</strong> are offered by AMEEE NDT, a leading provider of comprehensive testing solutions. Our specialized team conducts meticulous liquid penetrant testing assessments in ship testing environments. Utilizing advanced technology and expertise, we ensure accurate evaluation and assessment of ship structures in various maritime settings. Our liquid penetrant testing services enable us to detect potential flaws and assess the structural integrity of ship components without the need for physical intervention. This approach enhances operational efficiency and safety while maintaining the highest standards of testing accuracy and reliability.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Liquid Penetrant Testing in Ship Testing</h4>
                                        <p className='p-0 text-gray-500 text-lg text-justify'>Liquid penetrant testing in ship testing serves the critical purpose of evaluating the integrity and performance of ship structures without the need for disassembly. This testing process enables the detection of any surface-breaking defects, cracks, or discontinuities, ensuring the safety and reliability of maritime operations. By employing advanced liquid penetrant testing techniques, our services play a vital role in upholding industry standards and regulatory compliance, contributing to the efficient and safe operation of ships across various maritime sectors.</p>
                                    </div>


                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                   <div>
                                   <div className='' style={{ width: '330px' }}>
                                        <img src='assets/ameee/vt1.jpg' className=' border rounded-lg'  />
                                    </div>
                                   </div>
                                    <div className='text-justify '>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Liquid Penetrant Testing in Ship Testing</h4>
                                            <p className='text-gray-500 text-lg'>Liquid penetrant testing in ship testing has a significant historical background, dating back several decades within the maritime industry. As maritime technologies and safety standards have advanced, liquid penetrant testing methods have evolved to ensure the integrity and reliability of ship structures. The continual development of liquid penetrant testing techniques has played a vital role in enhancing safety protocols and minimizing risks associated with maritime operations.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Liquid Penetrant Testing in Ship Testing</h4>
                                            <p className='text-left text-gray-700 text-lg'>Liquid penetrant testing in ship testing utilizes sophisticated methodologies to detect surface-breaking defects and discontinuities in ship structures without the need for disassembly. These methodologies encompass various testing procedures and equipment designed to apply a penetrant liquid to the surface of the material being tested. After a specified dwell time, excess penetrant is removed, and a developer is applied to draw out penetrant from discontinuities, making them visible for inspection. By ensuring the integrity of ship structures, liquid penetrant testing in ship testing contributes to the overall safety and reliability of maritime operations in diverse maritime applications.</p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface sensitivity:</b> Liquid penetrant testing in ship testing can detect surface-breaking defects, including cracks, laps, seams, and other discontinuities.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Non-destructive:</b> It doesn't alter the structure of the ship, allowing for continuous operation after testing.
                                            </li><br/><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cost-effective:</b> Liquid penetrant testing is generally less expensive compared to other inspection methods, making it an efficient choice for ship maintenance.
                                            </li>
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Cleanliness:</b> The surface being tested must be meticulously cleaned to ensure accurate results, which can be time-consuming and labor-intensive.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface accessibility:</b> Liquid penetrant testing may not be suitable for areas with restricted access or complex geometries, limiting its applicability in certain ship structures.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Human error:</b> Interpretation of test results relies on human judgment and expertise, which can lead to potential discrepancies and false positives if not performed accurately.
                                            </li>
                                        </ul>
                                    </div>



                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                           <div>
                           <Card className='p-3' style={{ width: '330px',height:'570px',background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                           </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5'>
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <Sidebar />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Liquid Penetrant Testing in Ship Testing Process - How We Work</h4>
                    <p className='font-semibold'>Liquid penetrant testing at AMEEE NDT involves a systematic evaluation of ship structures using specialized testing procedures. Our process includes:</p>
                    <ul className='list-disc pl-5'>
                        <li>Preparation of the surface to be tested, ensuring cleanliness and removal of any contaminants that may interfere with the testing process.</li>
                        <li>Application of a penetrant solution to the surface, allowing it to seep into any surface-breaking defects through capillary action.</li>
                        <li>Removal of excess penetrant from the surface, leaving behind only the penetrant trapped in defects.</li>
                        <li>Application of a developer to draw out the trapped penetrant from defects, making them visible against the background surface.</li>
                        <li>Visual inspection or use of ultraviolet light to detect and evaluate indications, including cracks, fissures, and other discontinuities.</li>
                        <li>Recording testing observations and collecting data to identify any defects or anomalies found during the inspection.</li>
                        <li>Preparation of detailed reports outlining the testing procedures, observations, and recommendations for addressing any identified defects.</li>
                        <li>Strict adherence to safety protocols and standards to ensure the safety of personnel and the integrity of the testing environment throughout the liquid penetrant testing process.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Liquid Penetrant Testing in Ship Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically designed for liquid penetrant testing in ship testing. Our offerings include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Penetrant testing kits featuring specialized penetrant solutions, developers, and cleaners optimized for detecting surface-breaking defects on ship surfaces.</li>
                        <li>Testing accessories such as application brushes, spray guns, and inspection lamps to ensure uniform application and thorough coverage of penetrant solutions on ship surfaces.</li>
                        <li>Consumables including emulsifiers, developers, and penetrants formulated for enhanced sensitivity and detection of defects in ship materials.</li>
                        <li>Calibration standards calibrated for the specific requirements of liquid penetrant testing equipment, essential for verifying testing accuracy and maintaining calibration integrity.</li>
                        <li>Advanced software for image processing and defect analysis to streamline the interpretation and documentation of liquid penetrant testing results.</li>
                        <li>Personal protective equipment (PPE) for testing personnel, including goggles, gloves, and aprons, to ensure their safety during liquid penetrant testing procedures on ships.</li>
                        <li>Training materials and resources to educate personnel on the principles of liquid penetrant testing, equipment operation, interpretation of testing data, and safety procedures in ship testing environments.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our comprehensive range of equipment and resources, we provide everything necessary to conduct thorough and accurate liquid penetrant testing in ship testing.
                    </p>
                </div>


            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Liquid Penetrant Testing in Ship Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for liquid penetrant testing in ship testing. Some of the key standards we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASTM E165/E165M Standard Test Method for Liquid Penetrant Examination for General Industry</li>
                        <li>ASNT SNT-TC-1A Personnel Qualification and Certification in Nondestructive Testing</li>
                        <li>ISO 3452-1 Non-destructive testing -- Penetrant testing -- Part 1: General principles</li>
                    </ul>
                    <p className='font-semibold'>
                        Our commitment to these standards ensures the accuracy, reliability, and safety of our liquid penetrant testing procedures in ship testing, helping to maintain the integrity and performance of critical ship structures and components.
                    </p>
                </div>




            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Liquid Penetrant Testing in Ship Testing</h4>
                    <p className='font-semibold'>
                        During liquid penetrant testing in ship testing, AMEEE NDT evaluates various parameters to ensure the integrity and safety of ship structures. These parameters include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Surface Preparation: Ensuring proper cleaning and surface preparation to facilitate the penetration of penetrant materials into surface defects.</li>
                        <li>Penetrant Application: Applying penetrant materials evenly and consistently on the surface being tested to maximize defect detection sensitivity.</li>
                        <li>Dwell Time: Allowing sufficient time for the penetrant to penetrate surface defects adequately.</li>
                        <li>Development Time: Allowing adequate time for the developer to draw penetrant materials out of surface defects and enhance defect visibility.</li>
                        <li>Interpretation of Results: Skillfully interpreting indications revealed by the penetrant testing process to differentiate between relevant defects and false indications.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our comprehensive evaluations provide crucial insights for ensuring the safety, reliability, and regulatory compliance of ship structures during liquid penetrant testing.
                    </p>
                </div>



            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Detected in Liquid Penetrant Testing in Ship Testing</h4>
                    <p className='font-semibold'>
                        During liquid penetrant testing in ship testing conducted by AMEEE NDT, we have the capability to detect various defects and anomalies, including but not limited to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Surface Cracks: Identifying surface cracks, which may indicate structural weaknesses or material defects in ship components.</li>
                        <li>Pinholes: Detecting pinholes or porosity in welds or castings, which can compromise the integrity of ship structures.</li>
                        <li>Corrosion Damage: Revealing corrosion damage on the surface of ship components, which may lead to material degradation and structural failure.</li>
                        <li>Surface Discontinuities: Detecting surface discontinuities such as laps, seams, and cold shuts, which can affect the structural integrity of ship structures.</li>
                    </ul>
                    <p className='font-semibold'>
                        Through our meticulous liquid penetrant testing techniques and rigorous quality standards, we ensure comprehensive detection of surface defects, facilitating informed decision-making and proactive maintenance to enhance the reliability and safety of ship structures.
                    </p>
                </div>



            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Liquid Penetrant Testing in Ship Testing</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications and codes specifically designed for liquid penetrant testing in ship testing. Some of the key references we follow include:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>ASTM E165/E165M Standard Practice for Liquid Penetrant Testing for General Industry.</li>
                        <li>ASNT SNT-TC-1A Recommended Practice for Nondestructive Testing Personnel Qualification and Certification.</li>
                        <li>ISO 3452-1 Non-destructive testing — Penetrant testing — Part 1: General principles.</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning our practices with these industry standards and codes, we ensure the accuracy, reliability, and safety of our liquid penetrant testing processes in ship testing, ultimately contributing to the integrity and performance of critical ship structures.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Sidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className='mt-28 relative'>
            {isHovered && (
                <ProSidebar
                    className='text-left sidebar-expanded bg-blue-900 absolute top-0 bottom-0 z-10'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Menu>
                        <SubMenu className='text-gray-700 text-xl font-bold' label="Related Links">
                            <MenuItem> <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Boiler Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='TubeTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Tube Testing</a> </MenuItem>
                            <MenuItem> <a href='ShipTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Ship Testing</a> </MenuItem>
                            <MenuItem> <a href='ReactorTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Reactor Testing</a> </MenuItem>
                            <MenuItem> <a href='PipelineTesting' className='decoration-transparent text-gray-700 text-xl font-bold'>Pipeline Testing</a> </MenuItem>
                        </SubMenu>
                        <MenuItem><a href='Contact' className='decoration-transparent text-gray-700 text-xl font-bold'>Contact Us</a> </MenuItem>
                        <MenuItem> <a href='Quotation1' className='decoration-transparent text-gray-700 text-xl font-bold'>Request For Quote</a> </MenuItem>
                    </Menu>
                </ProSidebar>
            )}
            <div
                className={`sidebar-toggle absolute ${isHovered ? 'transform -translate-x-full' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <FontAwesomeIcon
                    icon={isHovered ? faAngleDoubleRight : faAngleDoubleLeft}
                    className={`text-5xl text-blue-900 ml-44 ${isHovered ? 'transition-transform duration-300 transform -rotate-180' : 'transition-transform duration-300'}`}
                />
            </div>
        </div>
    );
}


export default Ship5
