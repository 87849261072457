import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import FancyText from '@carefully-coded/react-text-gradient';
import 'react-owl-carousel2/lib/styles.css';
import { Helmet } from 'react-helmet';
function Client() {
  const options = {
    items: 5,
    loop: true,
    autoplay: true,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    autoplaySpeed: 4000,
    responsive: {
      0: {
        items: 1
      },
      300: {
        items: 1
      },
      600: {
        items: 2
      }
      ,
      800: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);
  const [isHovered13, setIsHovered13] = useState(false);
  const [isHovered14, setIsHovered14] = useState(false);
  const [isHovered15, setIsHovered15] = useState(false);
  const [isHovered16, setIsHovered16] = useState(false);
  const [isHovered17, setIsHovered17] = useState(false);
  const [isHovered18, setIsHovered18] = useState(false);
  const [isHovered19, setIsHovered19] = useState(false);
  const [isHovered20, setIsHovered20] = useState(false);
  const [isHovered21, setIsHovered21] = useState(false);
  const [isHovered22, setIsHovered22] = useState(false);
  const [isHovered23, setIsHovered23] = useState(false);

  return (
    <div>
      <Helmet>
        <title>AMEEE NDT-Our Clients |Best NDT Services in Chennai |Best NDT Services in Padappai |Best NDT Services in Mumbai |Best NDT Services in Haryana |Best NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive NDT services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="NDT Services in Chennai ,NDT Services in Mumbai , NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore " />
        <meta name="keywords" content="Best NDT services in Andhra Pradesh,Best NDT services in Arunachal Pradesh,Best NDT services in Assam,Best NDT services in Bihar,Best NDT services in Chhattisgarh,Best NDT services in Goa,Best NDT services in Gujarat,Best NDT services in Haryana,
                    Best NDT services in Himachal Pradesh,Best NDT services in Jammu and kashmir,Best NDT services in Jharkhand,Best NDT services in Karnataka,Best NDT services in Kerala,Best NDT services in Madhya Pradesh,Best NDT services in Maharashtra,Best NDT services in Manipur,Best NDT services in Meghalaya,
                  Best NDT services in Mizoram,Best NDT services in Nagaland,Best NDT services in Odisha,Best NDT services in Punjab,Best NDT services in Rajasthan,Best NDT services in Sikkim,Best NDT services in TamilNadu,Best NDT services in Telengana,Best  NDT services in Tripura,Best NDT services in Uttar Pradesh,
                  Best NDT services in Uttarakhand,Best NDT services in West Bengal,NDT services in Andhra Pradesh,NDT services in Arunachal Pradesh,NDT services in Assam,NDT services in Bihar,NDT services in Chhattisgarh,NDT services in Goa, NDT services in Gujarat,NDT services in Haryana,
                   NDT services in Himachal Pradesh,NDT services in Jammu and kashmir, NDT services in Jharkhand,  NDT services in Karnataka, NDT services in Kerala,NDT services in Madhya Pradesh,NDT services in Maharashtra, NDT services in Manipur,  NDT services in Meghalaya,
                  NDT services in Mizoram,Best NDT services in Nagaland,NDT services in Odisha,NDT services in Punjab,NDT services in Rajasthan,NDT services in Sikkim,NDT services in TamilNadu,NDT services in Telengana,NDT services in Tripura,NDT services in Uttar Pradesh,
                 NDT services in Uttarakhand, NDT services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className='mt-5'>
        <div className='text-center'>
          <FancyText
            gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
            animate
            animateDuration={5000}
          >
            <h1 className='font-bold text-5xl'>
              Working With  Our Premium Customers
            </h1>
          </FancyText>

          <div className='mt-3 text-center'>
            <p className='text-2xl text-white font-semibold  mx-10'>Delivering top-tier NDT techniques and inspections, ensuring utmost precision and reliability for your projects
              and assets.
            </p>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <OwlCarousel options={options}>
          <div>
            <img src='assets/ameee/indianoil1.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)} />
          </div>
          <div>
            <img src='assets/ameee/hplogo.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered1 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered1(true)}
              onMouseLeave={() => setIsHovered1(false)} />
          </div>
          <div>
            <img src='assets/ameee/bpcl.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered2 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)} />
          </div>
          <div>
            <img src='assets/ameee/Hocl.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered3 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered3(true)}
              onMouseLeave={() => setIsHovered3(false)} />
          </div>
          <div>
            <img src='assets/ameee/HPCL Biofuels.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered4 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered4(true)}
              onMouseLeave={() => setIsHovered4(false)} />
          </div>
          <div>
            <img src='assets/ameee/Sugar Mill.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered5 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered5(true)}
              onMouseLeave={() => setIsHovered5(false)} />
          </div>
          <div>
            <img src='assets/ameee/sjvn.jpeg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered6 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered6(true)}
              onMouseLeave={() => setIsHovered6(false)} />
          </div>
          <div>
            <img src='assets/ameee/indianrailways.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered7 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered7(true)}
              onMouseLeave={() => setIsHovered7(false)} />
          </div>
          <div>
            <img src='assets/ameee/tnpllogo.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered8 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered8(true)}
              onMouseLeave={() => setIsHovered8(false)} />
          </div>
          <div>
            <img src='assets/ameee/NPCIL.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered9 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered9(true)}
              onMouseLeave={() => setIsHovered9(false)} />
          </div>
          <div>
            <img src='assets/ameee/ntpc.jpeg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered10 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered10(true)}
              onMouseLeave={() => setIsHovered10(false)} />
          </div>
          <div>
            <img src='assets/ameee/GAIL.svg.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered11 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered11(true)}
              onMouseLeave={() => setIsHovered11(false)} />
          </div>
          <div>
            <img src='assets/ameee/ag.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered12 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered12(true)}
              onMouseLeave={() => setIsHovered12(false)} />
          </div>
          <div>
            <img src='assets/ameee/dpc.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered13 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered13(true)}
              onMouseLeave={() => setIsHovered13(false)} />
          </div>
          <div>
            <img src='assets/ameee/isro.jpeg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered14 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered14(true)}
              onMouseLeave={() => setIsHovered14(false)} />
          </div>
          <div>
            <img src='assets/ameee/HWB logo.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered15 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered15(true)}
              onMouseLeave={() => setIsHovered15(false)} />
          </div>
          <div>
            <img src='assets/ameee/Reliance.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered16 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered16(true)}
              onMouseLeave={() => setIsHovered16(false)} />
          </div>
          <div>
            <img src='assets/ameee/BHEL.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered17 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered17(true)}
              onMouseLeave={() => setIsHovered17(false)} />
          </div>
          <div>
            <img src='assets/ameee/totalenergy.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered18 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered18(true)}
              onMouseLeave={() => setIsHovered18(false)} />
          </div>
          <div>
            <img src='assets/ameee/vestas.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered19 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered19(true)}
              onMouseLeave={() => setIsHovered19(false)} />
          </div>
          <div>
            <img src='assets/ameee/siemens.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered20 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered20(true)}
              onMouseLeave={() => setIsHovered20(false)} />
          </div>
          <div>
            <img src='assets/ameee/caterpillar.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered21 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered21(true)}
              onMouseLeave={() => setIsHovered21(false)} />
          </div>
          <div>
            <img src='assets/ameee/ltlogo.jpg' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered22 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered22(true)}
              onMouseLeave={() => setIsHovered22(false)} />
          </div>
          <div>
            <img src='assets/ameee/cpcllogo.png' style={{ height: '180px', width: '180px', marginLeft: '30px', border: '2px solid #025', padding: '0' }}
              className={isHovered23 ? "colorful-image" : "grayscale-image"}
              onMouseEnter={() => setIsHovered23(true)}
              onMouseLeave={() => setIsHovered23(false)} />
          </div>
        </OwlCarousel>
      </div>
    </div >
  )
}

export default Client
// import React,{useState} from 'react'
// import '../Header.css'
// import Slider from 'react-infinite-logo-slider'
// const Client = () => {
//     const [settings] = useState({
//     dots: true,
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 2000,
//     cssEase: 'linear',
//     width:'250px',
//     height:'250px',
//     duration:70,
//     pauseOnHover: true,
//     blurBorders: false,
//     blurBoderColor: '#fff',
//   });
//     const logos = [
//         { url: 'assets/ameee/IndianOil.png', name: 'Indian Oil' },
//         { url: 'assets/ameee/HPCL.png', name: 'HPCL' },
//         { url: 'assets/ameee/BPCL.png', name: 'BPCL' },
//         { url: 'assets/ameee/Hocl.jpg', name: 'HOCL' },
//         { url: 'assets/ameee/HPCL Biofuels.jpg', name: 'HPCL Biofuels' },
//         { url: 'assets/ameee/Sugar Mill.png', name: 'Sugar Mill' },
//         { url: 'assets/ameee/sjvn.jpeg', name: 'SJVN' },
//         { url: 'assets/ameee/railways.png', name: 'Railways' },
//         { url: 'assets/ameee/tnpl.jpg', name: 'TNPL' },
//         { url: 'assets/ameee/NPCIL.png', name: 'NPCIL' },
//         { url: 'assets/ameee/ntpc.jpeg', name: 'NTPC' },
//         { url: 'assets/ameee/GAIL.svg.png', name: 'GAIL' },
//         { url: 'assets/ameee/ag.png', name: 'AG & P' },
//         { url: 'assets/ameee/dpc.png', name: 'DPC' },
//         { url: 'assets/ameee/isro.jpeg', name: 'ISRO' },
//         { url: 'assets/ameee/HWB logo.png', name: 'HWB' },
//         { url: 'assets/ameee/Reliance.png', name: 'Reliance' },
//         { url: 'assets/ameee/BHEL.png', name: 'BHEL' },
//         { url: 'assets/ameee/total.png', name: 'Total Energy' },
//         { url: 'assets/ameee/vestas.png', name: 'Vestas' },
//         { url: 'assets/ameee/siemens.png', name: 'Siemens Gamesha' },
//         { url: 'assets/ameee/caterpillar.jpg', name: 'Caterpillar' },
//         { url: 'assets/ameee/l&t.jpg', name: 'L & T Shipyard' },
//         { url: 'assets/ameee/cpcl.png', name: 'CPCL' },
//       ];
//       const [isHovered, setIsHovered] = useState(false);
//   return (
//     <div>
//       <Slider
//         width='250px'
//         duration={40}
//         pauseOnHover={true}
//         blurBorders={false}
//         blurBoderColor={'#fff'}
//         >
//           <Slider.Slide>
//           <img src='assets/ameee/cpcl.png'  />
//           </Slider.Slide>
//           <Slider.Slide>
//           <img src='assets/ameee/cpcl.png'  />
//           </Slider.Slide>
//           <Slider.Slide>
//           <img src='assets/ameee/cpcl.png'  />
//           </Slider.Slide>
//         </Slider>
//           <Slider {...settings}>
//           {logos.map((logo, index) => (
//             <Slider.Slide key={index}>
//               <img src={logo.url} alt={logo.name} style={{ height: '100px', width: '100px',marginLeft:'20px' }} className={isHovered ? "colorful-image" : "grayscale-image"}
//                  onMouseEnter={() => setIsHovered(true)}
//                  onMouseLeave={() => setIsHovered(false)} />
//             </Slider.Slide>
//           ))}
//         </Slider>
//         <div></div>
//         <div>
//           <Slider>
//             <Slider.Slide>
//             <img src='assets/ameee/Indianoil.png' style={{ height: '150px', width: '150px',marginLeft:'20px' }}
//             className={isHovered ? "colorful-image" : "grayscale-image"}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)} />
//             </Slider.Slide>
//           </Slider>
//         </div>
//     </div>
//   )
// }

// export default Client
