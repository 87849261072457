import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import '../Header.css'
import { Helmet } from 'react-helmet';
import FancyText from '@carefully-coded/react-text-gradient';

function SectorCard({ imgSrc, website, title, describe }) {
  const [hovered, setHovered] = useState(false);
  const [borderBottomSize, setBorderBottomSize] = useState('145px');
  const [borderBottomSizes, setBorderBottomSizes] = useState('140px');



  const handleMouseEnter = () => {
    setHovered(true);
    setBorderBottomSize('300px');
    setBorderBottomSizes('100px'); // Increase the size on hover
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setBorderBottomSize('145px');
    setBorderBottomSizes('140px'); // Reset the size when not hovered
  };


  return (
    <div>
      <Helmet>
        <title>Services Offered by AMEEE NDT</title>
        <meta name="description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including conventional NDT services,Advance NDT services,third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="keywords" content="AMEEE NDT services, NDT testing services,best Conventional NDT services in India,Advance NDT Services in India,Best NDT services in India,testing & Inspection ,best UT services in India, non-destructive testing, Padappai, third-party inspection, rope access, coating inspection, welding services,Best Ndt services in Chennai,leading service provider" />
        <meta property="og:title" content="Services Offered by AMEEE NDT" />
        <meta property="og:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Services Offered by AMEEE NDT" />
        <meta name="twitter:description" content="Explore the comprehensive range of non-destructive testing (NDT) services offered by AMEEE NDT in Padappai. We provide high-quality testing solutions for various industries including third-party inspection, rope access, coating inspection, and welding services." />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className='' >
        <Card style={{ width: '20rem', borderRadius: 0 }} className='p-0 rounded-none'>
          <div className=' relative'>
            <Card.Img variant="top" src={imgSrc} style={{ width: '20rem', height: '16rem', borderRadius: 0 }} />

            <div >
              {hovered && <h2 className=' rounded-full w-11 pt-1 h-11 ' style={{ position: 'absolute', backgroundColor: '#000040', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} className=' m-3 text-white text-sm' onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} /></h2>}
              {hovered && <p className=' w-full   text-white '
                style={{ position: 'absolute', top: '80%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                {describe}</p>}
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRight: '316px solid transparent',
                  borderBottom: `calc(${borderBottomSizes} + 10px) solid #5e63ec85`, // Adjusted borderBottom size
                  top: hovered ? '0' : '',
                  transition: '0.5s',
                }}
                className="inset-0"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              ></div>

              <div
                style={{
                  width: '60px',
                  borderRight: hovered ? '320px solid transparent' : ' 250px solid transparent',
                  borderBottom: `calc(${borderBottomSize} - 49px) solid #5e63ec85`, // Adjusted borderBottom size
                  top: hovered ? '0' : '',
                  width: hovered ? '100%' : '60px',
                  transition: '0.5s',
                }}
                className="absolute inset-0 "
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              ></div>

              <div
                style={{

                  borderLeft: '316px solid transparent',
                  borderBottom: `calc(${borderBottomSizes} + 10px) solid #25ee435c`, // Adjusted borderBottom size
                  top: hovered ? '0' : '',
                  transition: '0.5s',

                }}
                className="absolute inset-0 "
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              ></div>

              <div
                style={{
                  marginLeft: hovered ? '0' : '69px',
                  borderLeft: hovered ? '320px solid transparent' : ' 250px solid transparent',
                  borderBottom: `calc(${borderBottomSize} - 49px) solid #25ee435c`, // Adjusted borderBottom size
                  top: hovered ? '0' : '',
                  transition: '0.5s',
                }}
                className="absolute inset-0 "
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              ></div>

            </div>
          </div>
          <Card.Body style={{ height: '5rem', backgroundColor: '#000040' }}>
            <div className='flex justify-between w-full'>
              <div className='text-white'>
                <div className='font-bold text-xl '>{website}</div>
                <div className='flex justify-between'>
                  <div className=' text-gray-500'>{title}</div>
                </div>
              </div>
              <div className='justify-center text-center border w-8  h-6 mt-3'>
                <span className='p-2 '>
                  <FontAwesomeIcon icon={faChevronRight} size='xs' className='text-white ' />
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

function Sector() {
  return (
    <div>
      <Helmet>
        <title>Sectors - AMEEE NDT</title>
        <meta name="description" content="Explore the sectors and industries served by AMEEE NDT in Padappai. We provide high-quality non-destructive testing (NDT) services tailored to the specific needs of sectors such as oil and gas, construction, manufacturing, aerospace, and more." />
        <meta name="keywords" content="AMEEE NDT sectors, industries,best Storage Tank Testing,Best Boiler Tube Testing,Shipline testing,Best NDT services in India, NDT services, Padappai, non-destructive testing, oil and gas, construction, manufacturing, aerospace" />
        <meta property="og:title" content="Sectors - AMEEE NDT" />
        <meta property="og:description" content="Explore the sectors and industries served by AMEEE NDT in Padappai. We provide high-quality non-destructive testing (NDT) services tailored to the specific needs of sectors such as oil and gas, construction, manufacturing, aerospace, and more." />
        <meta property="og:image" content="assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="Sectors - AMEEE NDT" />
        <meta name="twitter:description" content="Explore the sectors and industries served by AMEEE NDT in Padappai. We provide high-quality non-destructive testing (NDT) services tailored to the specific needs of sectors such as oil and gas, construction, manufacturing, aerospace, and more." />
        <meta name="twitter:image" content="assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className=''>
        <div className='text-center text-6xl font-bold p-5 sm:w-72 md:w-80 lg:w-full'>
          <span>
            <FancyText
              gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
              animate
              animateDuration={5000}
            >
              Our Sectors
            </FancyText>

          </span>
        </div>
        <div className='text-center mx-10 text-2xl font-bold sm:w-72 md:w-80 lg:w-full'><p style={{ color: '#025' }}>Non-Destructive Testing (NDT) services ensure safety and quality across sectors including oil and gas, aerospace, manufacturing, construction, power generation, automotive, and maritime industries.</p></div>

        <div className=' justify-center mt-5 sm:block md:block lg:flex'>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/oil.jpg" website="Oil & Gas" title="Read More" describe="Supporting oil and gas operations with specialized services." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/cement.jpg" website="Cement" title="Read More" describe="Offering specialized services to support cement production and infrastructure development." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/automotive.jpg" website="Automotive" title="Read More" describe="Providing automotive services: maintenance, repair, and manufacturing." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/offshores.jpg" website="Marine & Offshore" title="Read More" describe="Delivering specialized services for marine and offshore industries." /></div>
        </div>
        <div className=' justify-center mt-4 sm:block md:block lg:flex'>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/thermal.jpg" website="Thermal Power Plant" title="Read more" describe="Supporting thermal power plants with specialized services for efficiency and operation." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/energy.jpg" website="Electricity" title="Read More" describe="Delivering essential services for electricity production, distribution, and infrastructure maintenance." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/Windmill.jpg" website="Windmill" title="Read More" describe="Providing services for windmill operations, including maintenance, repair, and installation." /></div>
          <div className='px-3'>  <SectorCard imgSrc="assets/ameee/ndt-hull-thickness-survey SHIP.jpg" website="Shipping" title="Read More" describe="Providing essential services for shipping, encompassing logistics, transportation, and vessel management." /></div>
        </div>
      </div>
    </div>
  );
}

export default Sector;

