import React, { useState } from 'react'
import First from './First'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faPenToSquare, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { Helmet } from 'react-helmet'
import SidebarComponent from './Sidebar'
function Ship() {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Ship Testing & Inspection Services in Chennai |Ship Testing & Inspection Services in Padappai |Ship Testing & Inspection Services in Mumbai |Ship Testing & Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Ship Testing & Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Ship Testing & Inspection Services in Chennai ,Ship Testing & Inspection Services in Mumbai ,Ship Testing & Inspection Services in Haryana ,Ship Testing & Inspection Services in India,Best Ship Testing & Inspection services in Padappai,Best Ship Testing & Inspection Services in Chennai,Best Ship Testing & Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Ship Testing & Inspection services in Andhra Pradesh,Best Ship Testing & Inspection services in Arunachal Pradesh,Best Ship Testing & Inspection services in Assam,Best Ship Testing & Inspection services in Bihar,Best Ship Testing & Inspection services in Chhattisgarh,Best Ship Testing & Inspection services in Goa,Best Ship Testing & Inspection services in Gujarat,Best Ship Testing & Inspection services in Haryana,
                        Best Ship Testing & Inspection services in Himachal Pradesh,Best Ship Testing & Inspection services in Jammu and kashmir,Best Ship Testing & Inspection services in Jharkhand,Best Ship Testing & Inspection services in Karnataka,Best Ship Testing & Inspection services in Kerala,Best Ship Testing & Inspection services in Madhya Pradesh,Best Ship Testing & Inspection services in Maharashtra,Best Ship Testing & Inspection services in Manipur,Best Ship Testing & Inspection services in Meghalaya,
                    Best Ship Testing & Inspection services in Mizoram,Best Ship Testing & Inspection services in Nagaland,Best Ship Testing & Inspection services in Odisha,Best Ship Testing & Inspection services in Punjab,Best Ship Testing & Inspection services in Rajasthan,Best Ship Testing & Inspection services in Sikkim,Best Ship Testing & Inspection services in TamilNadu,Best Ship Testing & Inspection services in Telengana,Best Ship Testing & Inspection services in Tripura,Best Ship Testing & Inspection services in Uttar Pradesh,
                   Best Ship Testing & Inspection services in Uttarakhand,Best Ship Testing & Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/Weldingvisual1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/vt4.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left '>
                                            <li className='border border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900 group-hover:text-white '>
                                                    <div className=''>
                                                        <span className=''>
                                                            <a href='/PenetrantTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white '>Penetrant Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900 '>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex justify-between p-2  group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <span >
                                                            <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full '>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Ship Testing & Inspection</h1>
                                    <img src='assets/ameee/vt3.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>
                                        <strong className='text-xl'>AMEEE NDT</strong> is a leading provider of comprehensive non-destructive testing (NDT) services, committed to ensuring the integrity and reliability of materials and components in the maritime industry. Ship testing and inspection are crucial aspects of our services, aimed at enhancing the safety and performance of marine vessels.
                                        Ship testing and inspection involve meticulous examinations of various components and systems onboard vessels, including hulls, propulsion systems, electrical systems, and safety equipment. Our experienced inspectors utilize advanced NDT techniques and equipment to detect defects, corrosion, and structural weaknesses, ensuring compliance with regulatory standards and industry best practices.
                                        By partnering with AMEEE NDT for ship testing and inspection, maritime operators can mitigate risks, prevent costly downtime, and prolong the lifespan of their vessels. Our commitment to excellence and safety makes us a trusted partner for maintaining the integrity and seaworthiness of ships worldwide.
                                    </p>

                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Ship Testing and Inspection</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Ship testing and inspection serve to ensure the integrity, safety, and seaworthiness of marine vessels by identifying potential defects, anomalies, or weaknesses that could compromise their performance or functionality.</p>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                   <div>
                                   <div className='' style={{ width: '330px' }} >
                                        <img src='assets/ameee/vt1.jpg' className=' border rounded-lg' />
                                    </div>
                                   </div>
                                    <div className='text-justify'>
                                        <div className='ml-10'>
                                            <h4 className='text-blue-900 font-bold'>History of Ship Testing and Inspection</h4>
                                            <p className='text-gray-500 text-lg'>Ship testing and inspection have been integral practices throughout the maritime industry's history, evolving alongside advancements in vessel construction and technology. From manual visual checks to sophisticated inspection methodologies, the process has played a crucial role in ensuring the safety, reliability, and seaworthiness of marine vessels.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle of Ship Testing and Inspection</h4>
                                            <p className='text-left text-gray-700 text-lg'>Ship testing and inspection involve non-destructive testing (NDT) methods aimed at evaluating the structural integrity and operational efficiency of marine vessels. These methods rely on direct visual observation, advanced sensor technologies, and analytical techniques to assess various components and systems onboard ships. The careful examination of hulls, propulsion systems, electrical systems, and safety equipment helps identify defects, anomalies, or weaknesses that could compromise vessel performance or safety at sea.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/vt4.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/vt5.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Comprehensive evaluation:</b> Allows thorough assessment of ship structures and systems.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Early detection of defects:</b> Helps identify issues before they escalate, reducing maintenance costs and downtime.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Enhanced safety:</b> Ensures compliance with maritime regulations and standards, enhancing vessel safety.
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='font-bold text-blue-900'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Subjectivity:</b> Interpretation of inspection results may vary among inspectors, leading to inconsistent evaluations.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Surface limitations:</b> Limited to detecting defects on the visible surfaces of the ship, unable to identify subsurface flaws.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' />
                                                <b>Environmental factors:</b> Poor lighting or visibility conditions can hinder the accuracy of inspections, especially in underwater scenarios.
                                            </li><hr />
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes & book shipment.</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>
                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-3'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5'>
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/visual.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Ship Testing and Inspection Process</h4>
                    <p className='font-semibold'>Ship testing and inspection conducted by AMEEE NDT involves a meticulous evaluation of vessel integrity and performance. Our process includes:</p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>Comprehensive visual examination: We conduct thorough visual inspections of the ship's surfaces and internal components to detect anomalies such as cracks, corrosion, or structural defects.</li>
                        <li className='mb-2'>Documentation and evaluation: Findings from the inspection are meticulously documented and evaluated against industry standards to ensure compliance and identify areas requiring attention.</li>
                        <li className='mb-2'>Preparation of detailed reports: Detailed reports are prepared, providing stakeholders with valuable insights for decision-making and maintenance planning, tailored specifically for ship operations.</li>
                        <li className='mb-2'>Adherence to maritime safety protocols: AMEEE NDT prioritizes adherence to strict maritime safety protocols during the inspection process to deliver accurate and reliable results, enhancing vessel safety and integrity.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables for Ship Testing and Inspection</h4>
                    <p className='font-semibold'>
                        For ship testing and inspection requirements, AMEEE NDT offers a comprehensive array of equipment, accessories, and consumables tailored to maritime applications.
                        Our offerings include:
                    </p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>Inspection lights: Specialized lighting solutions optimized for ship inspections, ensuring adequate illumination for detailed visual examinations.</li>
                        <li className='mb-2'>Magnifying devices: Tools designed to magnify surface details, enabling inspectors to identify defects and anomalies with precision.</li>
                        <li className='mb-2'>Surface preparation tools: Equipment for surface cleaning and preparation, essential for effective visual inspections in marine environments.</li>
                        <li className='mb-2'>Marking and identification tools: Tools for marking and labeling inspection findings on ship surfaces, facilitating documentation and follow-up actions.</li>
                        <li className='mb-2'>Protective equipment: Personal protective gear suited for maritime conditions, ensuring the safety and well-being of inspectors during ship inspections.</li>
                        <li className='mb-2'>NDT consumables: Specialized penetrants, magnetic particles, and other consumables designed for ship testing and inspection, aiding in defect detection and evaluation.</li>
                        <li className='mb-2'>Documentation tools: Instruments and software for comprehensive documentation of inspection results, supporting regulatory compliance and audit requirements.</li>
                        <li className='mb-2'>Calibration standards: Calibration tools and standards to ensure the accuracy and reliability of inspection equipment used in ship testing.</li>
                        <li className='mb-2'>Training materials: Educational resources and training programs tailored for maritime professionals, enhancing competency in ship testing and inspection practices.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our specialized equipment and resources, we provide everything necessary to conduct thorough and accurate visual inspections tailored specifically for ship testing applications.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes for Ship Testing and Inspection</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-recognized standards and codes specifically tailored for ship testing and inspection. Our commitment to these standards ensures the accuracy, reliability, and safety of our inspections, safeguarding the integrity of critical maritime assets.
                    </p>
                    <p className='font-semibold'>
                        The standards and codes we follow for ship testing and inspection include:
                    </p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>ASTM E18: Standard Test Methods for Rockwell Hardness of Metallic Materials, applicable for evaluating the hardness of ship components.</li>
                        <li className='mb-2'>ASTM E1444: Standard Practice for Magnetic Particle Testing, utilized for detecting surface and near-surface discontinuities in ferromagnetic materials commonly found in ship structures.</li>
                        <li className='mb-2'>ASNT SNT-TC-1A: Recommended Practice for Personnel Qualification and Certification in Nondestructive Testing, providing guidelines for the qualification and certification of personnel involved in ship testing and inspection.</li>
                    </ul>
                    <p className='font-semibold'>
                        By adhering to these standards and codes, we ensure that our ship testing and inspection services meet the highest quality and safety standards, contributing to the reliability and longevity of maritime assets.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters for Ship Testing and Inspection</h4>
                    <p className='font-semibold'>
                        During ship testing and inspection, we meticulously assess various parameters to ensure the integrity and seaworthiness of vessels. These parameters include:
                    </p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>Surface Condition: Comprehensive evaluation of the ship's surface for signs of corrosion, wear, or damage.</li>
                        <li className='mb-2'>Dimensions: Precise measurement of ship components to detect any deviations from design specifications.</li>
                        <li className='mb-2'>Defect Visibility: Examination of the visibility and severity of defects such as cracks, fractures, or structural weaknesses.</li>
                    </ul>
                    <p className='font-semibold'>
                        Our thorough evaluations provide valuable insights for ship maintenance and integrity management, enabling informed decision-making and proactive planning to ensure the safety and reliability of maritime assets.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects Captured in Ship Testing and Inspection</h4>
                    <p className='font-semibold'>
                        In ship testing and inspection conducted by AMEEE NDT, we excel in capturing various defects and anomalies crucial for ensuring the structural integrity and seaworthiness of vessels. These include:
                    </p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>Surface Cracks: Detection of cracks on the ship's surface, which can compromise structural integrity.</li>
                        <li className='mb-2'>Corrosion: Identification of corrosion spots that weaken the metal structure over time.</li>
                        <li className='mb-2'>Dimensional Deviations: Assessment of dimensional irregularities that may affect the ship's performance or stability.</li>
                        <li className='mb-2'>Coating Imperfections: Recognition of imperfections in coatings or protective layers, vital for preventing corrosion and maintaining hull integrity.</li>
                    </ul>
                    <p className='font-semibold'>
                        With our meticulous inspection techniques and expertise, we ensure comprehensive defect detection, enabling proactive maintenance and effective risk mitigation strategies for ships at sea.
                    </p>
                </div>

            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold '>References for Ship Testing and Inspection</h4>
                    <p className='font-semibold'>
                        In ship testing and inspection conducted by AMEEE NDT, we adhere to industry-standard specifications and codes to ensure thorough and reliable evaluations. Some of the key references include:
                    </p>
                    <ul className='list-disc pl-6'>
                        <li className='mb-2'>ASTM E165: Standard Test Method for Liquid Penetrant Examination for Ship Testing</li>
                        <li className='mb-2'>ASTM E1417: Standard Practice for Liquid Penetrant Testing for Ship Inspection</li>
                        <li className='mb-2'>ASNT SNT-TC-1A: Recommended Practice for Personnel Qualification and Certification in Nondestructive Testing, including Ship Inspection</li>
                    </ul>
                    <p className='font-semibold'>
                        By aligning with these standards, we ensure the accuracy, reliability, and safety of our ship testing and inspection processes, contributing to the integrity and performance of maritime assets.
                    </p>
                </div>

            </div>
        </div>
    );
}

export default Ship
