import React from 'react';
import './App.css';
import Header from './Header';
import About from './Components/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Service from './Components/Service';
import Demo from './Demo';
// import Demo from './Components/Third';
import Main from './Components/Main';
import Conventional from './Components/Conventional'
import Careers from './Components/Careers'
import Form from './Components/Form'
import Contact from './Components/Contact';
import Owldemo1 from './Owldemo1';
import { Nine } from './Components/Nine';
import Team from './Components/Team';
import Focus from './Components/Focus';
import Nav from './Components/Nav';
import { Helmet } from 'react-helmet';
import Services from './Components/Services';
import Visual from './Components/Visual';
import Penetrant from './Components/Penetrant';
import Magnetic from './Components/Magnetic';
import Ultrasonic from './Components/Ultrasonic';
import Radiography from './Components/Radiography';
import Positive from './Components/Positive';
import Hardness from './Components/Hardness';
import Leak from './Components/Leak';
import Rvi from './Components/Rvi';
import Advance from './Components/Advance';
import Welding from './Components/Welding';
import Rope from './Components/Rope';
import Coat from './Components/Coat';
import Civil from './Components/Civil';
import Quotation from './Components/Quotation';
import Quotation1 from './Components/Quotation1';
import Boiler from './Components/Boiler';
import Tube from './Components/Tube';
import Phased from './Components/Phased';
import Time from './Components/Time';
import Long from './Components/Long';
import Upv from './Components/Upv';
import Hammer from './Components/Hammer';
import Halfcell from './Components/Halfcell';
import Gauge from './Components/Gauge';
import Wps from './Components/Wps';
import Welding1 from './Components/Welding1';
import Welding2 from './Components/Welding2';
import Coat1 from './Components/Coat1';
import Rope1 from './Components/Rope1';
import Rope2 from './Components/Rope2';
import Short from './Components/Short';
import Mrut from './Components/Mrut';
import Automated from './Components/Automated';
import Eddy from './Components/Eddy';
import Rft from './Components/Rft';
import Iris from './Components/Iris';
import Mfl from './Components/Mfl';
import Nfet from './Components/Nfet';
import Pipeline from './Components/Pipeline';
import Ship from './Components/Ship';
import Reactor from './Components/Reactor';
import Pressure from './Components/Pressure';
import Conventionalndt from './Components/Conventionalndt';
import Advancendt from './Components/Advancendt';
import RopeAccess from './Components/RopeAccess';
import Civilndt from './Components/Civilndt';
import CoatingInspection from './Components/CoatingInspection';
import WeldingInspection from './Components/WeldingInspection';
import Resources from './Components/Resources';
import ContactForm from './Components/ContactForm';
import Boilervisual from './Components/Boilervisual';
import BoilerRemote from './Components/BoilerRemote';
import BoilerTubeut from './Components/BoilerTubeut';
import BoilerUs from './Components/Boilermicro';
import Boilermicro from './Components/Boilermicro';
import Boilermacro from './Components/Boilermacro';
import Boilerthickness from './Components/Boilerthickness';
import Tube1 from './Components/Tube1';
import Tube2 from './Components/Tube2';
import Tube3 from './Components/Tube3';
import Tube4 from './Components/Tube4';
import Tube5 from './Components/Tube5';
import Tube6 from './Components/Tube6';
import Tube7 from './Components/Tube7';
import Tube8 from './Components/Tube8';
import Pipe1 from './Components/Pipe1';
import Pipe2 from './Components/Pipe2';
import Pipe3 from './Components/Pipe3';
import Pipe4 from './Components/Pipe4';
import Pipe5 from './Components/Pipe5';
import Pipe6 from './Components/Pipe6';
import Storage1 from './Components/Storage1';
import Storage2 from './Components/Storage2';
import Storage3 from './Components/Storage3';
import Storage4 from './Components/Storage4';
import Storage5 from './Components/Storage5';
import Storage6 from './Components/Storage6';
import Pressure1 from './Components/Pressure1';
import Pressure2 from './Components/Pressure2';
import Pressure3 from './Components/Pressure3';
import Pressure4 from './Components/Pressure4';
import Pressure5 from './Components/Pressure5';
import Pressure6 from './Components/Pressure6';
import Reactor1 from './Components/Reactor1';
import Reactor2 from './Components/Reactor2';
import Reactor3 from './Components/Reactor3';
import Reactor4 from './Components/Reactor4';
import Reactor5 from './Components/Reactor5';
import Reactor6 from './Components/Reactor6';
import Ship1 from './Components/Ship1';
import Ship2 from './Components/Ship2';
import Ship3 from './Components/Ship3';
import Ship4 from './Components/Ship4';
import Ship5 from './Components/Ship5';
import Ship6 from './Components/Ship6';
import Valve1 from './Components/Valve1';
import Valve2 from './Components/Valve2';
import Valve3 from './Components/Valve3';
import Valve4 from './Components/Valve4';
import Valve5 from './Components/Valve5';
import Valve6 from './Components/Valve6';
import Valve7 from './Components/Valve7';
import Storage from './Components/Storage';
import Valve from './Components/Valve';
import Form2 from './Components/Form2';
function App() {
  const news = ['Breaking news item 1', 'Breaking news item 2', 'Breaking news item 3',]
  return (
    <>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services in Bangalore" />
        <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
        <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.ameeendt.com" />
        <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Router>
        <div className='App'>
          <Routes>
            <Route path="/About" element={<About />} />
            <Route path="/" element={<Demo news={news} />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/Form" element={<Form />} />
            <Route path="/Form2" element={<Form2/>} />
            <Route path="/ConventionalNDT" element={<Conventional />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Team" element={<Team />} />
            <Route path="/Focus" element={<Focus />} />
            <Route path="/VisualTesting" element={<Visual />} />
            <Route path="/PenetrantTesting" element={<Penetrant />} />
            <Route path="/MagneticTesting" element={<Magnetic />} />
            <Route path="/UltrasonicTesting" element={<Ultrasonic />} />
            <Route path="/RadiographyTesting" element={<Radiography />} />
            <Route path="/PositiveMaterialIdentification" element={<Positive />} />
            <Route path="/HardnessTesting" element={<Hardness />} />
            <Route path="/LeakTesting" element={<Leak />} />
            <Route path="/AdvanceNDT" element={<Advance />} />
            <Route path="/CoatInspection" element={<Coat />} />
            <Route path="/Quotation" element={<Quotation />} />
            <Route path="/Quotation1" element={<Quotation1 />} />
            <Route path="/BoilerTubeTesting" element={<Boiler />} />
            <Route path="/TubeTesting" element={<Tube />} />
            <Route path="/PipelineTesting" element={<Pipeline />} />
            <Route path="/ShipTesting" element={<Ship />} />
            <Route path="/StorageTesting" element={<Storage />} />
            <Route path="/ValveTesting" element={<Valve />} />
            <Route path="/ReactorTesting" element={<Reactor />} />
            <Route path="/PressureVesselTesting" element={<Pressure />} />
            <Route path="/RemoteVisualInspection" element={<Rvi />} />
            <Route path="/PhasedArrayUltrasonicTesting" element={<Phased />} />
            <Route path="/TimeOfFlightDiffraction" element={<Time />} />
            <Route path="/LongRangeUltrasonicTesting" element={<Long />} />
            <Route path="/ShortRangeUltrasonicTesting" element={<Short />} />
            <Route path="/MagneticResonanceUltrasonicTesting" element={<Mrut />} />
            <Route path="/AutomatedUltrasonicTesting" element={<Automated />} />
            <Route path="/EddyCurrentTesting" element={<Eddy />} />
            <Route path="/RemoteFieldTechnique" element={<Rft />} />
            <Route path="/InternalRotaryInspectionSystem" element={<Iris />} />
            <Route path="/MagneticFluxLeakage" element={<Mfl />} />
            <Route path="/NearFieldElectromagneticTesting" element={<Nfet />} />
            <Route path="/UltraPulseVelocity" element={<Upv />} />
            <Route path="/ReboundHammer" element={<Hammer />} />
            <Route path="/HalfCellPotential" element={<Halfcell />} />
            <Route path="/ThicknessGauging" element={<Gauge />} />
            <Route path="/WeldingProcedureSpecification" element={<Wps />} />
            <Route path="/WeldingProcedureQualificationRecord" element={<Welding1 />} />
            <Route path="/WeldersPerformanceQualificationTest" element={<Welding2 />} />
            <Route path="/CoatingInspection" element={<CoatingInspection />} />
            <Route path="/RopeAccesswithNDT" element={<Rope1 />} />
            <Route path="/RopeAccesswithPainting" element={<Rope2 />} />
            <Route path="/CoventionalNDTServices" element={<Conventionalndt />} />
            <Route path="/AdvanceNDTServices" element={<Advancendt />} />
            <Route path="/RopeAccess" element={<RopeAccess />} />
            <Route path="/CivilNDT" element={<Civilndt />} />
            <Route path="/Coat" element={<Coat1 />} />
            <Route path="/OurResources" element={<Resources />} />
            <Route path="/BoilerVisualInspection" element={<Boilervisual />} />
            <Route path="/BoilerRemoteVisualInspection" element={<BoilerRemote />} />
            <Route path="/BoilerTubeUltrasonicTesting" element={<BoilerTubeut />} />
            <Route path="/Boilermicro" element={<Boilermicro />} />
            <Route path="/Boilermacro" element={<Boilermacro />} />
            <Route path="/Boilerthickness" element={<Boilerthickness />} />
            <Route path="/RemoteVisualInspection-Tubetesting" element={<Tube1 />} />
            <Route path="/EddyCurrentTesting-Tubetesting" element={<Tube2 />} />
            <Route path="/PositiveMaterialIdentification-Tubetesting" element={<Tube3 />} />
            <Route path="/RemoteFieldEddyCurrentTesting-Tubetesting" element={<Tube4/>} />
            <Route path="/IRIS-Tubetesting" element={<Tube5/>} />
            <Route path="/MFL-Tubetesting" element={<Tube6/>} />
            <Route path="/NFET-Tubetesting" element={<Tube7/>} />
            <Route path="/MicroStructure-Tubetesting" element={<Tube8/>} />
            <Route path="/RemoteVisualInspection-Pipelinetesting" element={<Pipe1/>} />
            <Route path="/EddyCurrentTesting-Pipelinetesting" element={<Pipe2/>} />
            <Route path="/PositiveMaterialIdentification-Pipelinetesting" element={<Pipe3/>} />
            <Route path="/MicroStructure-Pipelinetesting" element={<Pipe4/>} />
            <Route path="/ImageReplica-Pipelinetesting" element={<Pipe5/>} />
            <Route path="/HolidayTesting-Pipelinetesting" element={<Pipe6/>} />
            <Route path="/LongRangeUltrasonicTesting-Storagetesting" element={<Storage1/>} />
            <Route path="/ShortRangeUltrasonicTesting-Storagetesting" element={<Storage2/>} />
            <Route path="/MagneticResonanceUltrasonicTesting-Storagetesting" element={<Storage3/>} />
            <Route path="/PhasedArrayUltrasonicTesting-Storagetesting" element={<Storage4/>} />
            <Route path="/TimeOfFlightDiffraction-Storagetesting" element={<Storage5/>} />
            <Route path="/UltrasonicTesting-Storagetesting" element={<Storage6/>} />
            <Route path="/VisualInspection- PressureVesselTesting" element={<Pressure1/>} />
            <Route path="/UltrasonicTesting- PressureVesselTesting" element={<Pressure2/>} />
            <Route path="/RadiographicTesting- PressureVesselTesting" element={<Pressure3/>} />
            <Route path="/MagneticParticleTesting- PressureVesselTesting" element={<Pressure4/>} />
            <Route path="/DyePenetrantTesting- PressureVesselTesting" element={<Pressure5/>} />
            <Route path="/AcousticEmissionTesting- PressureVesselTesting" element={<Pressure6/>} />
            <Route path="/VisualInspection- ReactorTesting" element={<Reactor1/>} />
            <Route path="/UltrasonicTesting- ReactorTesting" element={<Reactor2/>} />
            <Route path="/RadiographicTesting- ReactorTesting" element={<Reactor3/>} />
            <Route path="/MagneticParticleTesting- ReactorTesting" element={<Reactor4/>} />
            <Route path="/LiquidPenetrantTesting- ReactorTesting" element={<Reactor5/>} />
            <Route path="/PressureTesting- ReactorTesting" element={<Reactor6/>} />
            <Route path="/VisualInspection- ShipTesting" element={<Ship1/>} />
            <Route path="/UltrasonicTesting- ShipTesting" element={<Ship2/>} />
            <Route path="/RadiographicTesting- ShipTesting" element={<Ship3/>} />
            <Route path="/MagneticParticleTesting- ShipTesting" element={<Ship4/>} />
            <Route path="/LiquidPenetrantTesting- ShipTesting" element={<Ship5/>} />
            <Route path="/DestructiveTesting- ShipTesting" element={<Ship6/>} />
            <Route path="/PositiveMaterialIdentification- ValveTesting" element={<Valve1/>} />
            <Route path="/HeatTreatment- ValveTesting" element={<Valve2/>} />
            <Route path="/UltrasonicTesting- ValveTesting" element={<Valve3/>} />
            <Route path="/RadiographicTesting- ValveTesting" element={<Valve4/>} />
            <Route path="/MagneticParticleTesting- ValveTesting" element={<Valve5/>} />
            <Route path="/DyePenetrationTesting- ValveTesting" element={<Valve6/>} />
            <Route path="/TreviTesting- ValveTesting" element={<Valve7/>} />
            <Route path="/WeldingInspection" element={<WeldingInspection />} />
          </Routes>
          {/* <Form/> */}
        </div>
      </Router>
    </>
  );
}

export default App;
