import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Long = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Long-Range Ultrasonic Testing (LRUT) Services in Chennai |Long-Range Ultrasonic Testing (LRUT)Services in Padappai |Long-Range Ultrasonic Testing (LRUT) Services in Mumbai |Long-Range Ultrasonic Testing (LRUT) Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Long-Range Ultrasonic Testing (LRUT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Long-Range Ultrasonic Testing (LRUT) Services in Chennai ,Long-Range Ultrasonic Testing (LRUT) Services in Mumbai ,Long-Range Ultrasonic Testing (LRUT) Services in Haryana ,Long-Range Ultrasonic Testing (LRUT) Services in India,Best Long-Range Ultrasonic Testing (LRUT) services in Padappai,Best Long-Range Ultrasonic Testing (LRUT) Services in Chennai,Best Long-Range Ultrasonic Testing (LRUT) Services in Bangalore" />
                <meta name="keywords" content="Best Long-Range Ultrasonic Testing (LRUT) services in Andhra Pradesh,Best Long-Range Ultrasonic Testing (LRUT) services in Arunachal Pradesh,Best Long-Range Ultrasonic Testing (LRUT) services in Assam,Best Long-Range Ultrasonic Testing (LRUT) services in Bihar,Best Long-Range Ultrasonic Testing (LRUT) services in Chhattisgarh,Best Long-Range Ultrasonic Testing (LRUT) services in Goa,Best Long-Range Ultrasonic Testing (LRUT) services in Gujarat,Best Long-Range Ultrasonic Testing (LRUT) services in Haryana,
                        Best Long-Range Ultrasonic Testing (LRUT) services in Himachal Pradesh,Best Long-Range Ultrasonic Testing (LRUT) services in Jammu and kashmir,Best Long-Range Ultrasonic Testing (LRUT) services in Jharkhand,Best Long-Range Ultrasonic Testing (LRUT) services in Karnataka,Best Long-Range Ultrasonic Testing (LRUT) services in Kerala,Best Long-Range Ultrasonic Testing (LRUT) services in Madhya Pradesh,Best Long-Range Ultrasonic Testing (LRUT) services in Maharashtra,Best Long-Range Ultrasonic Testing (LRUT) services in Manipur,Best Long-Range Ultrasonic Testing (LRUT) services in Meghalaya,
                    Best Long-Range Ultrasonic Testing (LRUT) services in Mizoram,Best Long-Range Ultrasonic Testing (LRUT) services in Nagaland,Best Long-Range Ultrasonic Testing (LRUT) services in Odisha,Best Long-Range Ultrasonic Testing (LRUT) services in Punjab,Best Long-Range Ultrasonic Testing (LRUT) services in Rajasthan,Best Long-Range Ultrasonic Testing (LRUT) services in Sikkim,Best Long-Range Ultrasonic Testing (LRUT) services in TamilNadu,Best Long-Range Ultrasonic Testing (LRUT) services in Telengana,Best Long-Range Ultrasonic Testing (LRUT) services in Tripura,Best Long-Range Ultrasonic Testing (LRUT) services in Uttar Pradesh,
                   Best Long-Range Ultrasonic Testing (LRUT) services in Uttarakhand,Best Long-Range Ultrasonic Testing (LRUT) services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/long.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/long3.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/long7.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold group text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='RemoteVisualInspection' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Remote Visual Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 group border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PhasedArrayUltrasonicTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Phased Array Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex justify-between group-hover:bg-blue-900 p-2'>
                                                    <div>
                                                        <a href='TimeOfFlightDiffraction' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Time Of Flight Diffraction</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShortRangeUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Short Range Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='MagneticResonanceUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Magnetic Resonance Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white  text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900  justify-between p-2'>
                                                    <div>
                                                        <a href='AutomatedUltrasonicTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Automated Ultrasonic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='EddyCurrentTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Eddy Current Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='RemoteFieldTechnique' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Remote Field Technique </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='InternalRotaryInspectionSystem' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Internal Rotary Inspection System</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='MagneticFluxLeakage' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Magnetic Flux Leakage</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 font-bold text-xl '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='NearFieldElectromagneticTesting' className='group-hover:text-white decoration-transparent  text-gray-700 font-medium'>Near Field Electromagnetic Testing</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Long Range Ultrasonic Testing</h1>
                                    <img src='assets/ameee/long7.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500 text-justify text-lg'>
                                        <strong className='text-xl'>AMEEE NDT</strong> is a trusted provider of advanced non-destructive testing (NDT) solutions, specializing in Long-Range Ultrasonic Testing (LRUT) services. With a relentless commitment to excellence and innovation, we offer comprehensive inspection solutions tailored to diverse industries and applications. Our team of highly skilled engineers and technicians leverages cutting-edge technology and industry expertise to deliver reliable insights, ensuring the integrity, safety, and performance of critical assets.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Long-Range Ultrasonic Testing (LRUT) plays a pivotal role in non-destructive testing (NDT), offering a sophisticated method to assess the integrity of structures and components over considerable distances. The primary aim of LRUT is to detect and evaluate defects, corrosion, or anomalies that may compromise the performance or safety of assets.</p>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>LRUT is extensively applied across various industries such as oil and gas, petrochemicals, pipelines, and storage tanks. In the oil and gas sector, for instance, LRUT is utilized to inspect pipelines for corrosion, erosion, and wall-thickness variations over long distances, enabling proactive maintenance and integrity management. Similarly, in petrochemical plants, LRUT aids in identifying defects in piping systems and storage tanks, ensuring operational reliability and safety.</p>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>By offering the capability to inspect large areas from a single test location, LRUT reduces the need for extensive scaffolding or dismantling, resulting in significant time and cost savings. Moreover, LRUT facilitates early detection of defects, enabling timely intervention and preventive measures to mitigate risks and extend the service life of assets.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                <div>
                                <div className='mt-5' style={{ width: '330px' }}>
                                        <img src='assets/ameee/long.jpg' className=' border rounded-lg'  />
                                    </div>
                                </div>
                                    <div className='text-justify '>
                                        <div className='ml-12'>
                                            <h4 className='text-blue-900 font-bold'>History</h4>
                                            <p className='text-gray-500 text-lg'>Long-Range Ultrasonic Testing (LRUT) has evolved as a groundbreaking technique in non-destructive testing, integrating ultrasonic technology with advanced signal processing methodologies. Its evolution has been spurred by the necessity for more accurate and efficient inspection techniques across diverse sectors, encompassing oil and gas, petrochemicals, infrastructure, and beyond.</p>
                                            <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                            <p className='text-left text-gray-700 text-lg'>Long-Range Ultrasonic Testing (LRUT) is a sophisticated non-destructive testing method that utilizes ultrasonic waves and specialized probe configurations to examine the integrity of structures and components over considerable distances. The principle involves analyzing ultrasonic signals reflected or scattered by defects, providing comprehensive insights into their nature, size, and location. LRUT offers exceptional sensitivity and precision, making it indispensable for safeguarding the reliability and durability of critical assets.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 group text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className=' group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border group mt-4 border-neutral-400 text-lg '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/long5.jpeg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/long6.jpeg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Efficient Inspection:</b> : LRUT enables rapid and comprehensive inspection of large structures from a single
                                                access point. </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Cost-Effective:</b>  LRUT offers significant cost savings compared to traditional inspection methods,
                                                minimizing downtime and operational disruptions.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Remote Accessibility:</b>LRUT allows for remote inspections of inaccessible or hazardous locations,
                                                enhancing safety and minimizing risks to personnel.
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Complex Data Interpretation: </b> LRUT data interpretation may require specialized training and expertise to
                                                identify and assess defects accurately. <br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Resolution:</b> LRUT may have limitations in detecting small or localized defects compared to other
                                                inspection techniques.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Environmental Factors:</b>  LRUT performance may be affected by environmental conditions such as
                                                temperature, humidity, and material properties. </li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                               <div>
                               <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                               </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5'>
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5'>
                                            <div className='ml-2'>
                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className=' ' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/tofd5.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Process - How We Work</h4>
                    <p className='font-semibold'>Long-Range Ultrasonic Testing (LRUT) by AMEEE NDT offers advanced assessment of both surface conditions and internal characteristics of components without the need for physical contact.
                        It involves comprehensive ultrasonic scanning to detect and characterize anomalies such as corrosion, erosion, or structural weaknesses within materials, utilizing cutting-edge LRUT technology.
                        Inspection findings undergo thorough documentation and comparison against industry standards.
                        Detailed reports are generated, providing stakeholders with invaluable insights for decision-making and maintenance planning.
                        AMEEE NDT prioritizes adherence to safety protocols, ensuring precise and reliable inspection outcomes, even in challenging remote environments.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Equipment, Accessories & Consumables</h4>
                    <p className='font-semibold'>
                        For Long-Range Ultrasonic Testing (LRUT) requirements, AMEEE NDT offers a comprehensive range of equipment, accessories, and consumables specifically tailored for ultrasonic inspection operations.
                        Our offerings include advanced LRUT systems, specialized probes and configurations suitable for long-distance inspections, ultrasonic couplants and gels optimized for LRUT applications, data acquisition and analysis software tailored for long-range testing, as well as calibration standards and reference blocks designed for LRUT calibration.
                        From state-of-the-art LRUT equipment to essential consumables, we provide all necessary resources for conducting precise and reliable LRUT inspections.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Standards and Standard Codes</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard references such as ASTM E213, ASTM E1961, and ISO 28017 specifically tailored for Long-Range Ultrasonic Testing (LRUT).
                        Our commitment to these standards ensures the precision and reliability of our inspections, preserving the safety and integrity of critical assets.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Parameters</h4>
                    <p className='font-semibold'>
                        During Long-Range Ultrasonic Testing (LRUT), we evaluate various parameters including transducer selection, coupling method, and signal attenuation characteristics to assess the condition of structures over long distances.
                        Our comprehensive assessments provide essential insights for asset maintenance and integrity management, facilitating informed decision-making and proactive maintenance planning.
                    </p>
                </div>

            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Defects we can Capture</h4>
                    <p className='font-semibold'>
                        At AMEEE NDT, our long-range ultrasonic testing (LRUT) services excel in capturing a wide array of defects and anomalies, including internal flaws such as corrosion, erosion, discontinuities, and material degradation.
                        Through precise LRUT inspection techniques, we ensure comprehensive defect detection, enabling proactive maintenance and effective risk mitigation strategies.
                    </p>
                </div>
            </div>
        </div>
    )
}
function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Long-Range Ultrasonic Testing (LRUT)</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard references such as ASTM E2775, ASTM E2934, and ISO 28017 specifically tailored for long-range ultrasonic testing (LRUT).
                        Our dedication to these references ensures the precision and reliability of our inspections, preserving the integrity and safety of critical assets.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Long
