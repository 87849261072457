import React, { useState } from 'react'
import HorizontalTimeline from "react-horizontal-timeline";
import FancyText from '@carefully-coded/react-text-gradient';
function Timeline() {
    const [value, setValue] = useState(0);
    const [previous, setPrevious] = useState(0);
    
    const VALUES = ["2022", "2019", "2017", "2016"];
    const heading = [
        "AMEEE NDT Inspection Engineers PVT LTD",
        "ME Enterprise Engineers",
        "ME Enterprise Engineers",
        "ME Enterprise Engineers",
        "ME Enterprise Engineers"
    ]

    const description = [
        "AMEEENDT started to provide Advance NDT, Conventional NDT in India & Globally continued to provide inspector supply to Indian NDT company and Global NDT service provider.",
        "MEEE started to provide Advance NDT & Conventional NDT in India & Globally continued to provide inspector supply to Indian NDT company and Global NDT service provider.",
        "MEEE continued to provide Inspector supply to Indian NDT Company.",
        "MEEE continued to provide Inspector supply to Indian NDT Company."
    ];

    return (
        <div className=" mt-44 p-5">
            <div style={{
                width: "60%",
                height: "100px",
                margin: "0 auto",
                fontSize: '30px',
                fontFamily: 'cursive',
                fontWeight: 'bolder',
                color: '#025'
            }}>
                <HorizontalTimeline
                    styles={{ outline: "#DFA867", foreground: "#19295C" }}
                    index={value}
                    indexClick={(index) => {
                        setValue(index);
                        setPrevious(value);
                    }}
                    values={VALUES}
                />
            </div>
            <div className=" mt-10">
                <div className='text-6xl font-bold mt-5' style={{ fontFamily: 'cursive' }}>
                    <FancyText
                        gradient={{ from: 'rgb(59 238 221)', to: 'blue' }}
                        animate
                        animateDuration={5000}
                    >
                        {heading[value]}
                    </FancyText>
                </div>
                <div className='text-3xl font-bold mt-5 mx-5' style={{ color: '#025' }}>
                    {description[value]}
                </div>
            </div>
        </div>
    )
}

export default Timeline
