import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faContactBook,faBars,faCompactDisc, faHouse, faEnvelope, faMicroscope, faFileContract, faSquarePhoneFlip, faPhone, faTrophy, faCircleChevronRight, faSearch, faMessage, faEllipsisH, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';

const MobileMenu = () => {
    const [showMenu, setShowMenu] = useState(false);

    const handleIconClick = () => {
      setShowMenu(!showMenu);
    };
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
  
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
   

  return (
    <div className='flex'>
    <div>  <img src='assets/ameee/ameeelogo.png' alt='logo' className='border-neutral-500  w-32 h-12'  />
  
  </div>
  <div className='ml-32 py-3 px-3 border-r  border-neutral-500'>
  <a href='/' ><FontAwesomeIcon icon={faHouse} className='p-0 text-xl'/></a>
  </div>
  <div className=' py-3 px-3 border-r border-neutral-500'><FontAwesomeIcon icon={faBars} className='p-0 text-xl' onClick={handleShow}/>
  <Offcanvas show={show} onHide={handleClose} className="p-0" style={{backgroundColor:'rgba(2, 24, 57, 0.755)'}}>
          <Offcanvas.Header closeButton className=''>
            <Offcanvas.Title>
            <img src='assets/ameee/ameeelogo.png' alt='logo' className=' w-44 h-16'  />
            <span className='font-bold absolute textlogo  px-2 py-1 top-16 mt-1 text-sm '>AMEEE NDT INSPECTION ENGINEERS PRIVATE LIMITED</span>
      </Offcanvas.Title>
          </Offcanvas.Header><hr></hr>
          <Offcanvas.Body>
          <div style={{ backgroundColor: 'black' }}>
          <Accordion defaultActiveKey="0" style={{backgroundColor:'rgba(2, 24, 57, 0.755)'}} >
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{backgroundColor:'rgba(2, 24, 57, 0.755)'}}>About Us</Accordion.Header>
          <Accordion.Body style={{backgroundColor:'rgba(2, 24, 57, 0.755)'}} className='text-white'>
          <a href='About' className='dropdown-item hover:text-blue-800' >About Our Company</a>
          <a href="Team" className='dropdown-item'>About Our Team</a>
           <a href="Focus" className='dropdown-item'>About Our Focus</a>
          <a href="About" className='dropdown-item'>Our Resources</a>
        
          </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="1">
          <Accordion.Header>Service</Accordion.Header>
          <Accordion.Body>
          <Accordion defaultActiveKey="1"  >
        <Accordion.Item eventKey="6">
          <Accordion.Header>  Conventional NDT</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                        <li className=' border-b-2  border-neutral-400'><a href='VisualTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} />Visual Testing (VT)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='PenetrantTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  />Liquid Penetrant Test(PT)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='MagneticTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} />Magnetic Particle Testing(MPT)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='UltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} />Ultrasonic Testing(UT)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='RadiographyTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  />Radiography Testing (RT)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='LeakTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} />Leak Testing</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='PositiveMaterialIdentification' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  />Positive Material Identification (PMI)</a></li>
                                        <li className=' border-b-2  border-neutral-400'><a href='HardnessTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  />Hardness Testing</a></li>
                                      </ul>
          
           </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="7">
          <Accordion.Header>  Advance NDT</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='RemoteVisualInspection' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Remote Visual Inspection (RVI)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='PhasedArrayUltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Phased Array UltrasonicTesting (PAUT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='TimeOfFlightDiffraction' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Time Of Flight Diffraction (TOFD)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='LongRangeUltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Long Range Ultrasonic Testing (LRUT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShortRangeUltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Short Range Ultrasonic Testing(SRUT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='MagneticResonanceUltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Resonance Ultrasonic Testing(MRUT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='AutomatedUltrasonicTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Automated Ultrasonic Testing (AUT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='EddyCurrentTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Eddy Current Testing (ECT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='RemoteFieldTechnique' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Remote Field Technique (RFET)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='InternalRotaryInspectionSystem' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Internal Rotary Inspection System(IRIS) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='MagneticFluxLeakage' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Magnetic Flux Leakage (MFL)for Tube</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='NearFieldElectromagneticTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Near Field Electromagnetic Testing(NFET)</a></li>
                                    </ul>
          
           </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="8">
          <Accordion.Header>  Civil NDT</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='UltraPulseVelocity' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  />Ultra Pulse Velocity (UPVC)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReboundHammer' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Rebound Hammer </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='HalfCellPotential' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Half-Cell Potential</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ThicknessGauging' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Thickness Gauging</a></li>
                                    </ul>
          
           </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="9">
          <Accordion.Header>  
           Welding Inspection </Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                  <li className=' border-b-2  border-neutral-400'><a href='WeldingProcedureSpecification' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Welding Procedure Specification </a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='WeldingProcedureQualificationRecord' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Welding Procedure Qualification Record</a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='WeldersPerformanceQualificationTest' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Welders Performance Qualification Test</a> </li>
                                </ul>
          
           </Accordion.Body>
        </Accordion.Item>
  
  
        <Accordion.Item eventKey="10">
          <Accordion.Header>  Coating Inspection</Accordion.Header>
          <Accordion.Body>
        
          <ul className='list-unstyled mb-3 text-sm'>
                                        <li className=' border-b-2  border-neutral-400'><a href='CoatingInspection' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Coating Testing</a></li>
                                      </ul>
           </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="11">
          <Accordion.Header>     Rope Access</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='RopeAccesswithNDT' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Rope Access with NDT</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='RopeAccesswithPainting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Rope Access with Painting</a></li>
                                    </ul>
          
           </Accordion.Body>
        </Accordion.Item>
        </Accordion>
          </Accordion.Body>
  
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Application</Accordion.Header>
          <Accordion.Body>
          <Accordion defaultActiveKey="2"  >

        <Accordion.Item eventKey="1">
          <Accordion.Header>  Boiler Tube Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Boiler Visual Inspection</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Boiler Remote Visual Inspection</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Boiler Tube Ultrasonic Testing</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Boiler Tube USFD</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Boiler UT Testing</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='BoilerTubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Boiler Tube Thickness Measurement</a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header> Tube Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Remote Visual Inspection (RVI)</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Eddy Current Testing (ECT) </a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Positive Material Identification (PMI)</a> </li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Remote Field Eddy Current Testing (RFET) </a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Internal Rotary Inspection System (IRIS)</a> </li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Magnetic Flux Leakage (MFL) </a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> NFET</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='TubeTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Micro Structure</a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>  Pipeline Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2 border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Remote Visual Inspection (RVI)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Eddy Current Testing (ECT) </a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Positive Material Identification (PMI)</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Micro Structure</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Image Replica / Insitu Metallography</a></li>
                                      <li className=' border-b-2 border-neutral-400'><a href='PipelineTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Holiday Testing</a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header> Storage Tank Testing & Inspection </Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Long Range Ultrasonic Testing for Storage Tank (LRUT)</a></li>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Short Range Ultrasonic Testing</a></li>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Resonance Ultrasonic Testing</a> </li>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Phased Array Ultrasonic Testing </a></li>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Time Of Flight Diffraction</a></li>
                                <li className=' border-b-2  border-neutral-400'><a href='Service' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Ultrasonic Testing</a></li>
                              </ul>
           </Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="5">
          <Accordion.Header>Valve Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Positive Material Identification(PMI)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Heat Treatment (PWHT) , SR</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Ultrasonic Testing(UT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Radiographic Testing (RT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Particle Testing (MPT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='Services' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Dye Penetration Testing </a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header> Ship Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Visual Inspection </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Ultrasonic Testing (UT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Radiographic Testing(RT)</a> </li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Particle Testing (MPT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Liquid Penetrant Testing (LPT)</a> </li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ShipTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Destructive Testing (DT) </a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header> Reactor Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Visual Inspection </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Ultrasonic Testing (UT) </a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Radiographic Testing (RT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Particle Testing (MPT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Liquid Penetrant Testing (LPT)</a></li>
                                      <li className=' border-b-2  border-neutral-400'><a href='ReactorTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Pressure Testing</a></li>
                                    </ul>
           </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>  Pressure Vessel Testing & Inspection</Accordion.Header>
          <Accordion.Body>
          <ul className='list-unstyled mb-3 text-sm'>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Visual Inspection</a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Ultrasonic Testing (UT)</a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Radiographic Testing (RT)</a> </li>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Magnetic Particle Testing (MPT) </a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}} /> Dye Penetrant Testing</a></li>
                                  <li className=' border-b-2  border-neutral-400'><a href='PressureVesselTesting' style={{ textDecoration: 'none', color: 'black' }}><FontAwesomeIcon icon={faCircleChevronRight} style={{color:'#025'}}  /> Acoustic Emission Testing (AET)</a></li>
                                </ul>
           </Accordion.Body>
        </Accordion.Item>



        </Accordion>
      
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Careers</Accordion.Header>
          <Accordion.Body>
          <a href='Careers' className='font-bold  hover:text-blue-600' style={{ textDecoration: 'none', color: 'black' }}>  Careers</a>
      
          </Accordion.Body>
        </Accordion.Item>
  
        <Accordion.Item eventKey="4">
          <Accordion.Header>Contact</Accordion.Header>
          <Accordion.Body>
          <a href='/Contact' style={{ textDecoration: 'none', color: 'black' }}> Contact </a>
      
          </Accordion.Body>
        </Accordion.Item>
      </Accordion></div>
          </Offcanvas.Body>
        </Offcanvas>


  
      </div>
      <div className=' py-3 px-3 border-r  border-neutral-500'>
  <FontAwesomeIcon icon={faCompactDisc} className='p-0 text-xl' onClick={handleShow1}/>
  <Offcanvas className='text-white' show={show1} onHide={handleClose1} placement="start" style={{backgroundColor:'rgba(2, 24, 57, 0.755)'}}>
        <Offcanvas.Header closeButton>
        <Offcanvas.Title>
            <img src='assets/ameee/ameeelogo.png' alt='logo' className=' w-44 h-16'  />
            <span className='font-bold absolute textlogo  px-2 py-1 top-16 mt-1 text-sm '>AMEEE NDT INSPECTIO ENGINEERS PRIVATE LIMITED</span>
      </Offcanvas.Title>
        </Offcanvas.Header><hr></hr>
        <Offcanvas.Body>
          <div className='flex'>
            <div>  <FontAwesomeIcon icon={faPhone} className='pr-2 text-primary hover:-translate-x-4 transition duration-1000 hover:text-white' size='xl' /></div>
          <div>Call Us<br/>
 <span>
   +91 8169361089<br/>
    +91 8110877771<br/>
    +044 29994089
 </span>
    
          </div>
          </div><hr></hr>
          <div className='flex'>
            <div><FontAwesomeIcon icon={faEnvelope} className='pr-2 text-primary hover:-translate-x-4 transition duration-1000 hover:text-white' size='xl' /> </div>
           
          <div> Write Us <br/>
          inspection@ameeendt.com
          </div>
          </div><hr></hr>
          <div className='flex'>
            <div><FontAwesomeIcon icon={faAward} className='pr-2 text-primary hover:-translate-x-4  transition duration-1000 ' size='2xl' /></div>
            <div>
            Certified<br/>
            ISO 9001:2015<br/>
    ISO 14001<br/>
    ISO 45001<br/>
    AS9100<br/>
    ISO/IEC 17025
            </div>
          </div><hr></hr>

<div className='flex'>
    <div>
    <FontAwesomeIcon icon={faTrophy} className='pr-2 text-primary hover:translate-x-3 hover:-translate-x-4 transition duration-1000 hover:text-white' size='xl' />
              
    </div>
    <div>
    Leading<br/>
    Provider
    </div>
</div>

        </Offcanvas.Body>
      </Offcanvas>
  </div>
   
    </div>
  );
}

export default MobileMenu;
