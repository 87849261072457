import React, { useState, useEffect, useRef, Component } from 'react';
import '../Header.css'
import { Navbar, Nav, Container, CarouselItem, CarouselCaption, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlus, faMinus, faFileContract, faTrophy, faAward, faContactBook, faAngleDoubleRight, faPlay, faSearch, faUser, faBook, faCircleChevronRight, faTag, faChevronRight, faWrench, faAngleRight, faSmile, faGlobe, faCogs, faUsers, faCircle, faClock, faComments, faMapMarker, faRoadSpikes, faPaintRoller, faHotTub, faAngleDoubleDown, faCircleCheck, faBuilding, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Carousel } from 'react-bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import OwlCarousel from './sec_foooter';
import { Helmet } from 'react-helmet';
import { LinearGradient } from 'react-text-gradients';
import Process from './Process';
import { faWatchmanMonitoring } from '@fortawesome/free-brands-svg-icons';
import Company from './Company';
import History from './History';
const Third = () => {
  const [showContent, setShowContent] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [treeVisible, setTreeVisible] = useState(false);
  const [processVisible, setProcessVisible] = useState(false);
  const [companyVisible, setCompanyVisible] = useState(true);
  const [historyVisible, setHistoryVisible] = useState(false)
  const toggleTree = () => {
    setTreeVisible(true);
    setProcessVisible(false);
    setCompanyVisible(false);
    setHistoryVisible(false);
  };

  const toggleProcess = () => {
    setTreeVisible(false);
    setProcessVisible(true);
    setCompanyVisible(false);
    setHistoryVisible(false);
  };

  const toggleCompany = () => {
    setTreeVisible(false);
    setProcessVisible(false);
    setCompanyVisible(true);
    setHistoryVisible(false);
  };

  const toggleHistory = () => {
    setTreeVisible(false);
    setProcessVisible(false);
    setCompanyVisible(false);
    setHistoryVisible(true);
  };
  return (
    <div>
      <Helmet>
        <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India</title>
        <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="keywords" content="AMEEE NDT, NDT services,UT sevices in all over the country,best UT services in chennai,best Ut services in TamilNadu,Best NDT services in country, non-destructive testing, Padappai, India, aerospace NDT, automotive NDT, industrial inspection, NDT services in Padappai" />
        <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta property="og:url" content="https://www.ameeendt.com" />
        <meta name="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
        <meta name="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
        <meta name="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='container  justify-between sm:block md:flex-row lg:flex'>
        <div className='row mt-5 align-items-start w-1/2 '>
          <div className='col-md-auto position-relative' >
            <div className='choose-img-effect choose-image' style={{ background: 'url(assets/ameee/bgimgtransformed.jpeg)' }}>
              <img className="img-fluid phone-image  water-ripple-container  mt-32" src='assets\ameee\praveen.png'
                style={{ width: '650px', height: '700px' }} alt='' />
              {/* <div className='water-ripple-overlay'></div> */}
            </div>
            <div className='position-absolute top-24 start-0 m-3'>
              <div className='border border-dark bg-dark text-light p-3'>
                <div className="yellow-line  "></div>
                <div className='flex justify-between items-center'>
                  <div className='icon-container' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <FontAwesomeIcon icon={faTrophy} className={`text-primary size-14 transition-transform ${isHovered ? 'translate-x-[-10px]' : ''}`} aria-hidden='true' />
                  </div>
                  <div><h6 className='p-2 text-lg font-bold'> Awards Winning Company</h6></div>
                </div>
              </div>
            </div>
            <div>
              <ul>
                <div className='absolute  ' style={{ top: '57%', left: '53%' }}>
                  <li className=' blackRgb hover:bg-blue-500' onClick={toggleCompany}>
                    <div className='mr-40 text-white'>
                      <FontAwesomeIcon icon={faCircleCheck} className='absolute mt-4 text-2xl ' />
                    </div>
                    <div className='ml-24 text-white text-left'>
                      <h4>About Us</h4>
                      <p>Company Overview</p>
                    </div>
                  </li>
                  <div >
                    <li className='blueBg w-64 hover:bg-blue-500' onClick={toggleHistory}>
                      <div className='mr-44 text-white '>
                        <FontAwesomeIcon icon={faCircleCheck} className='absolute mt-4 text-2xl ' />
                      </div>
                      <div className='ml-24 text-left text-white'>
                        <h4>Our History</h4>
                        <p>Defening Milestones</p>
                      </div>
                    </li>
                  </div>

                  <li className=' blueBg w-64 hover:bg-blue-500' onClick={toggleProcess}>
                    <div className='mr-44 text-white'>
                      <FontAwesomeIcon icon={faCircleCheck} className='absolute mt-4 text-2xl ' />
                    </div>
                    <div className='ml-24 text-left text-white'>
                      <h4>Processing</h4>
                      <p>Processing & Develop</p>
                    </div>
                  </li>
                  <li className='blueBg w-64 hover:bg-blue-500' onClick={toggleTree}>
                    <div className='mr-44 text-white'>
                      <FontAwesomeIcon icon={faCircleCheck} className='absolute mt-4 text-2xl ' />
                    </div>
                    <div className='ml-24 text-left text-white'>
                      <h4>Technology</h4>
                      <p>Successful business</p>
                    </div>
                  </li>

                </div>


              </ul>
              <div>

              </div>
            </div>
            <div className="blue-line top" ></div>
            <div className="blue-line left" style={{ animation: 'moveInwards s infinite alternate' }}></div>
            <div className="blue-line bottom"></div>
          </div>
        </div>

        <div className='px-10 w-1/2  water-ripple-containers mt-16  '>
          <div className='water-ripple-overlays'></div>
          <div className='sm:flex md:flex '>
            {companyVisible && <Company />}
          </div>
          <div className=''>
            {treeVisible && <Tree />}
          </div>
          <div >
            {historyVisible && <History />}
          </div>
          <div className='ml-44' >
            {processVisible && <Process />}
          </div>
        </div>
      </div>

    </div >
  )
}


function Tree() {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);




  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDetails1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleDetails2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const toggleDetails3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  return (
    <div className='mt-44'>
      <div className=' blackRgb flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Our Vision </span></div>
        <div>
          <span className='bg-white rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-blue'>
            {isExpanded ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'blue' }} />} </span>
        </div>
      </div>
      {isExpanded && (
        <p className='p-2 font-bold text-white'>To become a leading Organization in providing effective, competitive and timely services for Industrial Installations.Provide the highest quality of flexible services that yield a long-term benefit to our Clients, Shareholders and Employees.</p>)}



      <div className='blueBg flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails1}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Our Mission</span></div>
        <div>
          <span className='bg-blue-600 rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
        </div>
      </div>
      {isExpanded1 && (
        <p className='p-2  font-bold text-white'>Strive to meet, or exceed, our
          Client's needs at every
          opportunity here.</p>)}



      <div className=' blackRgb flex justify-between rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails2}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Objectives</span></div>
        <div>
          <span className='bg-white rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded2 ? <FontAwesomeIcon icon={faMinus} style={{ color: 'blue' }} /> : <FontAwesomeIcon icon={faPlus} style={{ color: 'blue' }} />} </span>
        </div>
      </div>
      {isExpanded2 && (
        <p className='p-2  font-bold text-white '>Inspections,Trainings & Consultancy Services in conjunction with Quality,Health,Safety and Environment Management.</p>)}


      <div className='flex justify-between bg-black rounded-bl-3xl rounded-tr-3xl py-2 my-3' onClick={toggleDetails3}>
        <div><span className='px-4 text-lg text-white font-bold w-96'>Purpose</span></div>
        <div>
          <span className='bg-blue-600 rounded-bl-3xl rounded-tr-3xl px-3  pt-2 pb-2 text-center font-bold text-2xl text-white'>
            {isExpanded3 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />} </span>
        </div>
      </div>
      {isExpanded3 && (
        <p className='p-2  font-bold text-white'>Offer the most reliable and
          consistent quality service by evaluating and developing methods of testing and
          training services to keep pace with the latest developments in technology and
          client requirements.</p>)}


    </div>
  )
}



export default Third