import React from 'react'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import { faTrophy, faUser } from '@fortawesome/free-solid-svg-icons'
function About3() {
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT | Non-Destructive Testing (NDT) Services in Chennai | NDT Services in Padappai |NDT Services in Mumbai |NDT Services in Haryana |NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Non-Destructive Testing (NDT) Services in Chennai ,NDT Services in Mumbai ,NDT Services in Haryana ,NDT Services in India,Best NDT services in Padappai,Best NDT Services in Chennai,Best NDT Services inBangalore " />
                <meta name="keywords" content="Best ndt services in Andhra Pradesh,Best ndt services in Arunachal Pradesh,Best ndt services in Assam,Best ndt services in Bihar,Best ndt services in Chhattisgarh,Best ndt services in Goa,Best ndt services in Gujarat,Best ndt services in Haryana,
        Best ndt services in Himachal Pradesh,Best ndt services in Jammu and kashmir,Best ndt services in Jharkhand,Best ndt services in Karnataka,Best ndt services in Kerala,Best ndt services in Madhya Pradesh,Best ndt services in Maharashtra,Best ndt services in Manipur,Best ndt services in Meghalaya,
        Best ndt services in Mizoram,Best ndt services in Nagaland,Best ndt services in Odisha,Best ndt services in Punjab,Best ndt services in Rajasthan,Best ndt services in Sikkim,Best ndt services in TamilNadu,Best ndt services in Telengana,Best ndt services in Tripura,Best ndt services in Uttar Pradesh,
        Best ndt services in Uttarakhand,Best ndt services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='about-image'>
                <div className='about-section '>
                    <div className='row p-5'>
                        <div className='col-lg-5' style={{ backgroundColor: '#007bff', padding: '15px', borderRadius: '5px', width: '500px', height: '220px' }}>
                            <div className='flex mx-auto'>
                                <div className=''>
                                    <FontAwesomeIcon icon={faUser} className='text-5xl text-white font-bold' /><br />
                                    <span className='text-white text-5xl font-bold'>45+</span>
                                    <h5 className='text-2xl text-white font-semibold mt-3'>Years Of Experience</h5>
                                </div>
                                <div className=' w-full ' style={{ backgroundColor: 'black', clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)' }}>
                                    <div className='p-3 ml-20'>
                                        <FontAwesomeIcon icon={faTrophy} className='text-5xl text-white font-bold mt-4' /><br />
                                        <span className='text-white text-5xl font-bold'>120</span>
                                        <h5 className='text-2xl text-white font-semibold mt-3'>Awards Received</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='flex'>
                                <div className='p-3'>
                                    <h4 className='text-white mt-12 text-4xl'>Our agency manager a vast amount of digital support services to business</h4>
                                </div>
                                <div className='' style={{ width: '600px', borderRadius: '25px', animation: 'moveLeftRight 5s infinite alternate', background: 'conic-gradient(from 45deg, #0073c7, #0000)' }}>
                                    <img src='assets/ameee/welding.jpg' style={{ width: '275px', height: '275px', padding: '5px', borderRadius: '25px', border: '1px solid #0000', objectFit: 'cover' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About3
