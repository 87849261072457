import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Welding2 = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Welding Inspection Services in Chennai |Welding Inspection Services in Padappai |Welding Inspection Services in Mumbai |Welding Inspection Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Welding Inspection services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Welding Inspection Services in Chennai ,Welding Inspection Services in Mumbai ,Welding Inspection Services in Haryana ,Welding Inspection Services in India,Best Welding Inspection services in Padappai,Best Welding Inspection Services in Chennai,Best Welding Inspection Services in Bangalore" />
                <meta name="keywords" content="Best Welding Inspection services in Andhra Pradesh,Best Welding Inspection services in Arunachal Pradesh,Best Welding Inspection services in Assam,Best Welding Inspection services in Bihar,Best Welding Inspection services in Chhattisgarh,Best Welding Inspection services in Goa,Best Welding Inspection services in Gujarat,Best Welding Inspection services in Haryana,
                        Best Welding Inspection services in Himachal Pradesh,Best Welding Inspection services in Jammu and kashmir,Best Welding Inspection services in Jharkhand,Best Welding Inspection services in Karnataka,Best Welding Inspection services in Kerala,Best Welding Inspection services in Madhya Pradesh,Best Welding Inspection services in Maharashtra,Best Welding Inspection services in Manipur,Best Welding Inspection services in Meghalaya,
                    Best Welding Inspection services in Mizoram,Best Welding Inspection services in Nagaland,Best Welding Inspection services in Odisha,Best Welding Inspection services in Punjab,Best Welding Inspection services in Rajasthan,Best Welding Inspection services in Sikkim,Best Welding Inspection services in TamilNadu,Best Welding Inspection services in Telengana,Best Welding Inspection services in Tripura,Best Welding Inspection services in Uttar Pradesh,
                   Best Welding Inspection services in Uttarakhand,Best Welding Inspection services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/welding.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/weldingvisual1.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border group border-neutral-400 font-bold text-xl'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/WeldingProcedureSpecification' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welding Procedure Specification</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2 '>
                                                    <div>
                                                        <a href='/WeldingProcedureQualificationRecord' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welding Procedure Qualification Record</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-xl group'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/WeldersPerformanceQualificationTest' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Welders Performance Qualification Test </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Welders Performance Qualification Test</h1>
                                    <img src='assets/ameee/welding.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT </strong>offers Welders Performance Qualification Test services to ensure welders meet industry standards and certification requirements for quality welding performance.The Welders Performance Qualification Test, commonly known as WPQT, assesses a welder's ability to produce welds of acceptable quality according to specified standards and criteria, ensuring competency and compliance in welding processes.

                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Welding Performance Qualification Test is conducted to assess the performance and capabilities of welders in producing sound and reliable welds under specified conditions. By qualifying welders, it ensures compliance with industry standards and regulatory requirements. Welding Performance Qualification Test involves rigorous testing and evaluation of welder skills, techniques, and adherence to welding procedures, aiming to demonstrate the ability of welders to consistently produce high-quality welds that meet specified criteria.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                  <div>
                                  <div className='' style={{ width: '330px' }}>
                                        <img src='assets/ameee/weldingvisual1.jpg' className=' border rounded-lg'  />
                                    </div>
                                  </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <div>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className='text-gray-500 text-lg'>Welding Performance Qualification Test has its origins in the need to assess and certify the skills and capabilities of welders. It emerged as a structured method to evaluate welder performance and ensure compliance with industry standards and regulations. Initially developed to verify welding techniques and skills, Welding Performance Qualification Test evolved to become an essential aspect of welder certification and quality assurance across industries.</p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg'>Welding Performance Qualification Test assesses the skills and capabilities of welders through rigorous testing and evaluation of welding techniques, adherence to welding procedures, and weld quality. By providing detailed records of qualified welders, Welding Performance Qualification Test ensures confidence in welder performance and competence, facilitating compliance with industry standards and regulatory requirements across diverse applications.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700 ' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='TubeTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PipelineTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ShipTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg'>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='ReactorTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 group  border-neutral-400 text-lg '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white text-gray-700' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/weldingvisual1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/weldingvisual.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Compliance:</b>It helps companies and welders comply with industry standards, regulations, and certification requirements, ensuring that welds meet the specified criteria.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Quality Assurance:</b>WPQT ensures that welders possess the necessary skills and knowledge to produce high-quality welds, thus improving overall quality assurance in welding processes.<br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Skill Verification:</b>WPQT verifies the competency and proficiency of welders, giving employers confidence in their ability to perform welding tasks effectively and safely.
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Cost:</b>Conducting WPQT can be costly, involving expenses for materials, equipment, supervision, and testing procedures. Additionally, if a welder fails the test, there may be costs associated with retesting or additional training.<br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Time-Consuming: </b>WPQT requires time for preparation, testing, evaluation, and documentation, which can disrupt production schedules and project timelines.<br /><br />
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Scope:</b> WPQT evaluates a welder's performance based on specific test conditions and criteria, which may not fully represent all the welding tasks or environments they will encounter in actual work situations.</li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                              <div>
                              <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                              </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5' >
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/welding.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Performance Qualification Test - How We Work</h4>
                    <p className='font-semibold'>Welding Performance Qualification Test, conducted by AMEEE NDT, is essential for assessing and certifying the skills and capabilities of welders. We meticulously administer tests and evaluations of welding techniques, adherence to welding procedures, and weld quality to qualify welders.
                        Our comprehensive records provide evidence of qualified welders, detailing the testing procedures, results, and compliance with industry standards. This ensures confidence in the performance and competence of welders, supporting compliance with industry regulations and quality standards across diverse applications.</p>
                </div>

            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Performance Qualification Test Equipment & Resources</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, resources, and documentation tailored specifically for welding performance qualification test.
                        Our product lineup includes welding performance testing equipment, skill assessment tools, performance evaluation forms, and training materials.
                        Additionally, we provide technical support, training sessions, and consulting services to assist organizations in conducting welding performance qualification tests and certifying welders.
                        With our dedicated support and expertise, we empower organizations to assess and certify the skills and capabilities of welders, ensuring compliance and quality in welding operations.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Performance Qualification Test Standards</h4>
                    <p className='font-semibold'>
                        AMEEE NDT follows industry-standard specifications such as AWS D1.1 and ASME Section IX for welding performance qualification test.
                        Our dedication to these standards ensures the assessment and certification of welder skills and capabilities in accordance with industry best practices and regulatory requirements.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Welding Performance Qualification Test Parameters</h4>
                    <p className='font-semibold'>
                        In welding performance qualification test, we evaluate parameters such as welding techniques, joint configurations, material compatibility, and adherence to welding procedures.
                        Our comprehensive analysis ensures the assessment and certification of welder skills and capabilities to produce sound and reliable welds under specified conditions, ensuring compliance with industry standards and regulatory requirements.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Anomalies Detected by Welding Performance Qualification Test</h4>
                    <p className='font-semibold'>
                        Through welding performance qualification test, AMEEE NDT proficiently identifies anomalies such as welding defects, inadequate weld quality, and deviations from specified welding techniques.
                        Our rigorous testing protocols ensure thorough anomaly detection, enabling corrective actions and improvements in welder skills and techniques to meet industry standards and regulatory requirements across diverse industrial applications.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Welding Performance Qualification Test</h4>
                    <p className='font-semibold'>
                        AMEEE NDT follows industry-standard guidelines and specifications such as AWS D1.1 and ASME Section IX for welding performance qualification test.
                        Our dedication to these references ensures the assessment and certification of welder skills and capabilities in accordance with industry best practices and regulatory requirements, supporting the reliability and compliance of welding operations across various industries.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Welding2
