import React, { useState } from 'react'
import First from './First'
import '../Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faChevronLeft, faChevronRight, faCircleCheck, faGears, faLongArrowAltRight, faLongArrowRight, faPaperclip, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Card } from 'react-bootstrap'
import { Carousel, Nav } from 'react-bootstrap';
import Service1 from './Service1'
import Foooter from './Footer'
import Fourth from './fourth'
import { faPadlet } from '@fortawesome/free-brands-svg-icons'
import Sidebar from './Sidebar'
import SidebarComponent from './Sidebar'
import { Helmet } from 'react-helmet'
const Rope1 = () => {
    const [showContent, setShowContent] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(true)
    const [equipmentVisible, setEquipmentVisible] = useState(false)
    const [standardVisible, setStandardVisible] = useState(false)
    const [parametersVisible, setParametersVisible] = useState(true)
    const [defectsVisible, setDefectsVisible] = useState(false)
    const [referencesVisible, setReferencesVisible] = useState(false)
    const toggleProcedure = () => {
        setProcedureVisible(true);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleEquipment = () => {
        setProcedureVisible(false);
        setEquipmentVisible(true);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleStandard = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(true);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleParameters = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(true);
        setDefectsVisible(false);
        setReferencesVisible(false);
    }
    const toggleDefect = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(true);
        setReferencesVisible(false);
    }
    const toggleReferences = () => {
        setProcedureVisible(false);
        setEquipmentVisible(false);
        setStandardVisible(false);
        setParametersVisible(false);
        setDefectsVisible(false);
        setReferencesVisible(true);
    }
    return (
        <div>
            <Helmet>
                <title>AMEEE NDT |Rope Access with NDT Services in Chennai |Rope Access with NDT Services in Padappai |Rope Access with NDT Services in Mumbai |Rope Access with NDT Services in Kanchipuram |</title>
                <meta name="description" content="Explore Ameee NDT's comprehensive Rope Access with NDT services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta name="title" content="Rope Access with NDT Services in Chennai ,Rope Access with NDT Services in Mumbai ,Rope Access with NDT Services in Haryana ,Rope Access with NDT Services in India,Best Rope Access with NDT services in Padappai,Best Rope Access with NDT Services in Chennai,Best Rope Access with NDT Services in Bangalore" />
                <meta name="keywords" content="Best Rope Access with NDT services in Andhra Pradesh,Best Rope Access with NDT services in Arunachal Pradesh,Best Rope Access with NDT services in Assam,Best Rope Access with NDT services in Bihar,Best Rope Access with NDT services in Chhattisgarh,Best Rope Access with NDT services in Goa,Best Rope Access with NDT services in Gujarat,Best Rope Access with NDT services in Haryana,
                        Best Rope Access with NDT services in Himachal Pradesh,Best Rope Access with NDT services in Jammu and kashmir,Best Rope Access with NDT services in Jharkhand,Best Rope Access with NDT services in Karnataka,Best Rope Access with NDT services in Kerala,Best Rope Access with NDT services in Madhya Pradesh,Best Rope Access with NDT services in Maharashtra,Best Rope Access with NDT services in Manipur,Best Rope Access with NDT services in Meghalaya,
                    Best Rope Access with NDT services in Mizoram,Best Rope Access with NDT services in Nagaland,Best Rope Access with NDT services in Odisha,Best Rope Access with NDT services in Punjab,Best Rope Access with NDT services in Rajasthan,Best Rope Access with NDT services in Sikkim,Best Rope Access with NDT services in TamilNadu,Best Rope Access with NDT services in Telengana,Best Rope Access with NDT services in Tripura,Best Rope Access with NDT services in Uttar Pradesh,
                   Best Rope Access with NDT services in Uttarakhand,Best Rope Access with NDT services in West Bengal" />
                <meta name="author" content="AMEEE NDT Inspection Engineers Private Limited" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.ameeendt.com" />
                <meta property="og:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="og:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="og:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.ameeendt.com" />
                <meta property="twitter:title" content="AMEEE NDT | Non-Destructive Testing (NDT) Services in Padappai, India" />
                <meta property="twitter:description" content="Explore Ameee NDT's comprehensive non-destructive testing (NDT) services in Padappai, India. We offer reliable testing solutions for industries like aerospace, automotive, and more. Contact us today for expert NDT services!" />
                <meta property="twitter:image" content="https://www.ameeendt.com/assets/ameee/ameeelogo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='bg-slate-100'>
                <div>
                    <div className=' z-10 fixed w-full' style={{ backgroundColor: '#020225' }}>
                        <First />
                    </div>
                    <div className='pt-40'>
                        <div>
                            <Carousel >
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope.jpg"
                                        alt="First slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope1.jpg"
                                        alt="Second slide"
                                        style={{ height: '400px' }}
                                    />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/ameee/rope2.jpg"
                                        alt="Third slide"
                                        style={{ height: '400px' }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className='m-2'>
                            <div className='justify-center sm:block md:block lg:flex'>
                                <div className='mr-10 mt-2'>
                                    <Card className='pr-8 pt-3' style={{ width: '330px' }}>
                                        <h1 className='text-center underline mb-3'>All Service</h1>
                                        <ul className='text-left'>
                                            <li className='border border-neutral-400 font-bold text-xl group '>
                                                <div className='flex group-hover:bg-blue-900 justify-between p-2'>
                                                    <div>
                                                        <a href='/RopeAccesswithNDT' className='group-hover:text-white decoration-transparent text-gray-700 font-medium'>Rope Access with NDT</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border border-neutral-400 mt-4 font-bold text-xl group'>
                                                <div className='flex justify-between group-hover:bg-blue-900 p-2'>
                                                    <div>
                                                        <a href='/RopeAccesswithPainting' className='decoration-transparent group-hover:text-white text-gray-700 font-medium'>Rope Access with Painting</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className=' p-2 mt-4 font-bold text-xl underline '><h5>Explore Other Services:</h5></li>
                                            <li className='border border-neutral-400 font-bold text-lg group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/VisualTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Visual Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group '>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/MagneticTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Magnetic Particle Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/UltrasonicTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ultrasonic Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group'>
                                                <div className='flex justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/RadiographyTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Radiography Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group '>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/PositiveMaterialIdentification' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Positive Material Identification </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/HardnessTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Hardness Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 font-bold text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='/LeakTesting' className='decoration-transparent  text-gray-700 font-medium group-hover:text-white'>Leak Testing </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className=' text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </Card>
                                    <Card className='pr-8 pt-3 mt-5 hover:bg-blue-900 hover:text-white' style={{ width: '330px' }}>
                                        <div>
                                            <h5 className='text-center underline mb-3 '>
                                                Download Our Company Profile
                                            </h5>
                                        </div>
                                    </Card>
                                </div>
                                <div className='w-full'>
                                    <h1 className='font-bold text-left mb-3  text-blue-900'>Rope Access with NDT</h1>
                                    <img src='assets/ameee/rope.jpg' style={{ borderRadius: '5px', height: '350px', width: '100%' }} />
                                    <h1 className='mt-3 mb-4 text-left text-blue-900 font-bold'>Description of our Service</h1>
                                    <p className='p-0 text-gray-500  text-justify text-lg '>
                                        <strong className='text-xl'> AMEEE NDT </strong> offers comprehensive non-destructive testing (NDT) services combined with rope access expertise, ensuring efficient and thorough inspections of challenging and inaccessible areas.Rope access with NDT combines specialized rope access techniques with non-destructive testing (NDT) methods, enabling efficient and safe inspection of difficult-to-reach areas such as tall structures, confined spaces, and offshore installations.
                                    </p>
                                    <div>
                                        <h4 className='text-left text-blue-900 font-bold'>Purpose of Testing</h4>
                                        <p className='p-0 text-gray-500 text-justify text-lg'>Rope Access with NDT  is conducted to assess the proficiency and competence of technicians in conducting non-destructive testing (NDT) inspections while utilizing rope access techniques. This evaluation ensures that technicians can effectively perform inspections in challenging-to-reach areas, such as tall structures or confined spaces, using rope access methods. By certifying technicians, this test guarantees compliance with industry standards and safety regulations. Rope Access with NDT  entails a thorough assessment of technician skills, adherence to safety protocols, and proficiency in NDT techniques, aiming to demonstrate their capability to consistently conduct inspections and ensure adherence to defined criteria for safety and quality.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='justify-center mb-10 sm:block md:block lg:flex'>
                                  <div>
                                  <div className='mt-5' style={{ width: '330px' }}>
                                        <img src='assets/ameee/rope1.jpg' className=' border rounded-lg'  />
                                    </div>
                                  </div>
                                    <div className='text-justify '>
                                        <div className=' ml-10'>
                                            <div>
                                                <h4 className='text-blue-900 font-bold'>History</h4>
                                                <p className='text-gray-500 text-lg'>Rope Access with NDT finds its origins in the need to certify technicians and ensure the effective inspection of structures using non-destructive testing (NDT) techniques while employing rope access methods. It emerged as a structured approach to evaluate technician competence and ensure adherence to safety standards and regulations. Initially developed to verify inspection techniques and proficiency in challenging environments, Rope Access with NDT has evolved into a critical aspect of technician certification and safety assurance practices across various industries.</p>
                                                <h4 className='text-left text-blue-900 font-bold'>Principle</h4>
                                                <p className='text-left text-gray-700 text-lg'>Rope Access with NDT assesses the proficiency and capabilities of technicians through meticulous testing and evaluation of rope access techniques, adherence to safety protocols, and proficiency in NDT inspections. By maintaining comprehensive records of qualified technicians, Rope Access with NDT  ensures confidence in technician competence and performance, guaranteeing alignment with safety standards and regulatory requirements in various challenging environments where rope access and NDT are utilized.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 justify-center sm:block md:block lg:flex'>
                                <div className='mr-10'>
                                    <Card className='pr-8 pt-3 ' style={{ width: '330px' }}>
                                        <h1 className='mb-3 underline text-center'>Applications</h1>
                                        <ul className=' mt-3'>
                                            <li className='border border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='BoilerTubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Boiler Tube Testing & Inspection </a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='TubeTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Tube Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PipelineTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pipeline Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ShipTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Ship Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='ReactorTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Reactor Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='border mt-4 border-neutral-400 text-lg group'>
                                                <div className='flex  justify-between p-2 group-hover:bg-blue-900'>
                                                    <div>
                                                        <a href='PressureVesselTesting' className='decoration-transparent text-gray-700 font-medium group-hover:text-white'>Pressure Vessel Testing & Inspection</a>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon icon={faLongArrowRight} className='text-gray-700 group-hover:text-white' />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                                <div className='text-left'>
                                    <div className='flex justify-center '>
                                        <div className='w-full mr-5'>
                                            <img src='assets/ameee/rope1.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                        <div className='w-full'>
                                            <img src='assets/ameee/rope.jpg' style={{ borderRadius: '5px', height: '300px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Advantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-1 text-blue-900' /><b>Versatility: </b> Rope access with NDT allows inspection in challenging and inaccessible locations such as tall structures, confined spaces, and offshore installations.</li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b> Cost-Effective:</b>Often more cost-effective than traditional access methods like scaffolding or cranes, as it requires minimal equipment and setup time.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Efficiency:</b> Enables rapid deployment and inspection, minimizing downtime and disruption to operations.<br /><br />
                                            </li><hr />
                                        </ul>
                                        <ul className='text-left'>
                                            <li><h4 className='text-blue-900 font-bold'>Disadvantages</h4></li>
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' />
                                                <b>Training Requirements:</b>Requires specialized training and certification for technicians to ensure safety and proficiency in rope access techniques.<br /></li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Weather Dependency: </b> Adverse weather conditions such as high winds or storms can affect the feasibility and safety of rope access operations.
                                            </li><hr />
                                            <li className='text-lg text-gray-700'><FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-blue-900' /><b>Limited Payload:</b>  Rope access systems have weight limitations, restricting the equipment and personnel that can be deployed.</li><hr />
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='sm:block md:block lg:flex'>
                               <div>
                               <Card className='p-3' style={{ width: '330px',height:'570px', background: 'linear-gradient(to right, #025, #000)' }}>
                                    <h3 className='text-left text-white text-4xl font-bold'>Request For Quote</h3>
                                    <p className='text-left text-white font-semibold'>Get immediate quotes .</p>
                                    <form>
                                        <input type='text' placeholder='Your Name' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <input type='email' placeholder='Email' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} /><br />
                                        <textarea placeholder='Your Question Here' className='bg-transparent mb-5' style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid white', color: 'white' }} />
                                    </form>
                                    <div className=''>
                                        <div className="darksoul-gradient-card3">
                                            <a href='Contact' className='decoration-transparent font-bold text-2xl text-white ml-8 mt-3' >Contact Now </a>
                                        </div>
                                    </div>
                                </Card>
                               </div>
                                <div className=''>
                                    <div className='justify-between sm:block md:block lg:flex' >
                                        <div>
                                            <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                                <li onClick={toggleProcedure}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Process </h6>
                                                </li>
                                                <li onClick={toggleEquipment}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Equipmental Accessories </h6>
                                                </li>
                                                <li onClick={toggleStandard}>
                                                    <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Standards </h6>
                                                </li>

                                            </ul>
                                            <div className='process-content px-5' >
                                                <div className='ml-2'>
                                                    {procedureVisible && <Procedure />}
                                                    {equipmentVisible && <Equipment />}
                                                    {standardVisible && <Standards />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ul className='sm:block md:block lg:flex justify-center ml-2'>
                                            <li onClick={toggleParameters}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Parameters </h6>
                                            </li>
                                            <li onClick={toggleDefect}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> Defects We Can Capture </h6>
                                            </li>
                                            <li onClick={toggleReferences}>
                                                <h6 className='font-bold  bg-black text-white p-3' style={{ border: '1px solid white', width: '370px', background: 'linear-gradient(to right, #025, #000)' }}> <FontAwesomeIcon icon={faPenToSquare} /> References </h6>
                                            </li>

                                        </ul>
                                        <div className='process-content px-5'>
                                            <div className='ml-2'>

                                                {parametersVisible && <Parameters />}
                                                {defectsVisible && <Defects />}
                                                {referencesVisible && <References />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Service1 />
                            <div className='' style={{ position: 'fixed', top: '15%', left: '84%', bottom: '0', zIndex: '1000' }}>
                                <SidebarComponent />
                            </div>
                        </div>
                        <Fourth />
                        <div style={{ backgroundImage: 'url(assets/ameee/rope.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            <Foooter />
                        </div>
                    </div >
                </div >
            </div >
        </div>
    )
}


function Procedure() {
    return (
        <div className=' ' >
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with NDT - How We Work</h4>
                    <p className='font-semibold'>Rope Access with NDT , conducted by AMEEE NDT, is essential for evaluating and certifying the proficiency and competency of technicians in conducting non-destructive testing (NDT) inspections while utilizing rope access techniques. We meticulously administer tests and assessments of rope access skills, adherence to safety protocols, and proficiency in NDT techniques to qualify technicians.
                        Our detailed records serve as documentation of certified technicians, outlining the testing procedures, results, and alignment with safety standards. This instills confidence in technician competence and performance, ensuring compliance with regulatory requirements and safety standards in various challenging environments where rope access and NDT are utilized.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Equipment() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with NDT Equipment & Consumables</h4>
                    <p className='font-semibold'>
                        AMEEE NDT offers a comprehensive range of equipment, resources, and documentation tailored specifically for rope access with NDT inspections.
                        Our product lineup includes specialized NDT equipment suitable for rope access, safety gear for technicians, inspection report templates, and educational materials.
                        Additionally, we provide technical support, training sessions, and consulting services to assist organizations in conducting rope access with NDT inspections and certifying technicians.
                        With our dedicated support and expertise, we empower organizations to evaluate and certify the proficiency of technicians, ensuring adherence to safety standards and quality in NDT inspections conducted through rope access methods.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Standards() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with NDT Standards</h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard specifications such as ASNT, IRATA, and SPRAT for rope access with NDT.
                        Our commitment to these standards ensures the evaluation and certification of technician proficiency and capabilities in alignment with established industry practices and safety regulations.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Parameters() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Rope Access with NDT  Parameters</h4>
                    <p className='font-semibold'>
                        In rope access with NDT, we assess parameters including rope access proficiency, safety practices, NDT technique proficiency, and adherence to inspection protocols.
                        Our thorough evaluation guarantees the assessment and certification of technician proficiency and capabilities to conduct NDT inspections safely and effectively while utilizing rope access techniques, ensuring compliance with industry standards and safety regulations.
                    </p>
                </div>
            </div>
        </div>
    )
}
function Defects() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>Anomalies Detected by Rope Access with NDT </h4>
                    <p className='font-semibold'>
                        Through rope access with NDT, AMEEE NDT proficiently identifies anomalies such as NDT equipment malfunctions, safety breaches, and deviations from specified inspection protocols.
                        Our meticulous testing procedures ensure comprehensive anomaly detection, facilitating corrective measures and enhancements in technician proficiency and techniques to uphold industry standards and safety compliance across various challenging environments.
                    </p>
                </div>
            </div>
        </div>
    )
}

function References() {
    return (
        <div className=''>
            <div className=''>
                <div className='p-0 text-gray-500 text-lg text-justify'>
                    <h4 className='text-blue-900 text-xl font-bold'>References for Rope Access with NDT </h4>
                    <p className='font-semibold'>
                        AMEEE NDT adheres to industry-standard guidelines and specifications such as ASNT, IRATA, and SPRAT for rope access with NDT .
                        Our commitment to these references ensures the evaluation and certification of technician proficiency and capabilities in alignment with established industry practices and safety regulations, supporting the reliability and safety compliance of NDT inspections conducted through rope access methods.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Rope1
